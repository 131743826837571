import { UserModel, UserRoleModel } from "../Models/UserModel";
import { MoudleResponsibilityModel, UserRoleMasterModel } from "../Models/UserRoleModel";

export class UserInfo {
  public static EmailID: string = "localhost";

  public static UserName: string = "Farhan Khan";
  public static UserRoles: UserRoleModel;
  public static MyRoles: MoudleResponsibilityModel[];
  public static UserRolesMaster: UserRoleMasterModel[]
}

export class CompanyInfo {
  public static CompanyName: string = "Zaibunco Industries PVT. LTD.";
  public static Contact: string = "+91-7084423456";
  public static Email: string = "info@zaibunco.com";
  public static Address: string = "Head Office :- 611, BLOCK C, DEFENCE COLONY <br/> JAJMAU  KANPUR 208010, Uttar Pradesh, India <br/> Factory Add:- 955, SHESHPUR NARI <br/> BANTHER UNNAO 209862, Uttar Pradesh, India ";
  public static GST: string = "09AADCK8892N1ZK";
  public static ShortAddress: string = "955, SHESHPUR NARI, BANTHAR, UNNAO,"
  public static ShortAddress2ndLine: string = "Uttar Pradesh - 209862, India"
  public static HeadOfficAddress: string = "611, BLOCK C, DEFENCE COLONY, JAJMAU, KANPUR, Uttar Pradesh - 208010, India";
  public static FactoryAddress: string = "955, SHESHPUR NARI, BANTHER, UNNAO, Uttar Pradesh - 209862, India";
  // public static Website: string = "www.zaibunco.co.in";
 
}



