<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Sales Order List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Sales Order here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button *ngIf="this.permission.Workplan && showPlanBtn" style="margin-right: 5px;" nz-button nzType="primary"
          (click)="showModalwpl('new')">
          Add Work Plan
        </button>
        <button *ngIf="this.permission.Add" style="margin-right: 5px;" nz-button nzType="primary"
          (click)="AddSaleOrder()">
          Add Sale Order
        </button>
        <button nz-button nzType="primary" (click)="showSearchSection()">
          Search
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <div *ngIf="showsearch" nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label>Customer</label>
                <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                  [(ngModel)]="request.CustomerId" (ngModelChange)="onFilterChange()" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                    [nzLabel]="s.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                <label>Formulation Code</label>
                <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                  nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" (ngModelChange)="onFilterChange()"
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                    [nzLabel]="s.SaleFormulationCode"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                <label>Article Name</label>
                <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30" [(ngModel)]="request.ArticleName"
                  (ngModelChange)="onFilterChange()" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Color</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                  (ngModelChange)="onFilterChange()" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                    [nzLabel]="s.ColorName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Grain Name</label>
                <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" (ngModelChange)="onFilterChange()"
                  nzSize="default" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                    [nzLabel]="s.GrainName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Production Status</label>
                <nz-select name="select-error" [(ngModel)]="request.Status" (ngModelChange)="onFilterChange()"
                  nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of status" [nzValue]="data.Value" [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Sale Order No.</label>

                <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
                  [(ngModel)]="request.SaleOrderNumber" (ngModelChange)="onFilterChange()" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Work Plan No.</label>

                <input nz-input name="workPlanNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                  [(ngModel)]="request.WorkPlanNo" (ngModelChange)="onFilterChange()" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Added By</label>
                <input nz-input name="" nzAllowClear placeholder="Enter Username" maxlength="30"
                  [(ngModel)]="request.AddedBy" (ngModelChange)="onFilterChange()" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date Type</label>
                <nz-select name="select-error" [(ngModel)]="request.DateType" (ngModelChange)="onFilterChange()">
                  <nz-option *ngFor="let data of dateTypeList" [nzValue]="data.Value" [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="request.DateType=='saleorderdate'">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label> Order Date (From)</label>
                <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                  [(ngModel)]="request.FromSaleOrderDate" (ngModelChange)="onFilterChange()"
                  nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="request.DateType=='saleorderdate'">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label> Order Date (To)</label>
                <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                  [(ngModel)]="request.ToSaleOrderDate" (ngModelChange)="onFilterChange()"
                  nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="request.DateType=='addeddate'">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label> Added Date (From)</label>
                <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                  [(ngModel)]="request.FromAddedDate" (ngModelChange)="onFilterChange()" nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="request.DateType=='addeddate'">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label> Added Date (To)</label>
                <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                  [(ngModel)]="request.ToAddedDate" (ngModelChange)="onFilterChange()" nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="request.DateType=='deliverydate'">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label> Delivery Date (From)</label>
                <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                  [(ngModel)]="request.FromDeliveryDate" (ngModelChange)="onFilterChange()"
                  nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="request.DateType=='deliverydate'">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label> Delivery Date (To)</label>
                <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                  [(ngModel)]="request.ToDeliveryDate" (ngModelChange)="onFilterChange()" nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Order Type</label>
                <nz-select name="select-error" [(ngModel)]="request.OrderType" (ngModelChange)="onFilterChange()"
                  nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of orderTypeList" [nzValue]="data" [nzLabel]="data"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Product Type</label>
                <nz-select [(ngModel)]="request.ProductType" (ngModelChange)="onFilterChange()" nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of PUPVCTypeList" [nzValue]="data.Value"
                    [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Order Status</label>
                <nz-select name="select-error" [(ngModel)]="request.SaleOrderStatus" (ngModelChange)="onFilterChange()"
                  nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of SaleOrderStatusList" [nzValue]="data" [nzLabel]="data"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div style="float: right; margin-bottom: 5%; margin-right: 10px">
          <div style="display: flex">
            <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="onSearch()">
              Search
            </button>
            <button nz-button nzSize="small" (click)="onReset()">Reset</button>
            <button nz-button nzSize="small" (click)="export()">
              <i nz-icon nzType="export" nzTheme="outline"></i> Export
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-modal [(nzVisible)]="isVisible1" [nzStyle]="{ top: '20px' }" [nzTitle]="modalTitle" [nzContent]="modalContent"
      [nzFooter]="modalFooter" (nzOnCancel)="handleCancel1()">
      <ng-template #modalTitle>{{ PopUpTitle1 }}</ng-template>

      <ng-template #modalContent>
        <form nz-form>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Select Product Category">
                  <label>WorkPlan Date </label>
                  <nz-date-picker class="form-select mb-2" name="WorkPlanDate" [(ngModel)]="NewWorkPlan.WorkPlanDate"
                    nzAllowClear="false"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="10">
              <nz-form-item>
                <nz-form-control [nzSpan]="40">
                  <label class="required">Production Line No.</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewWorkPlan.ProductionLineNo"
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option nzValue="1" nzLabel="Line 1"></nz-option>
                    <nz-option nzValue="2" nzLabel="Line 2"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="10">
              <nz-form-item>
                <nz-form-control [nzSpan]="40">
                  <label class="required">Work Shift</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="NewWorkPlan.WorkShift"
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option nzValue="day" nzLabel="Day Shift"></nz-option>
                    <nz-option nzValue="night" nzLabel="Night Shift"></nz-option>

                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Select Product Category">
                  <label>Production Details</label>
                  <input type="text" placeholder="Production Details" nz-input
                    [(ngModel)]="NewWorkPlan.ProductionDetails" name="ProductionDetails" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </ng-template>

      <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleCancel1()">
          Cancel
        </button>
        <button nz-button nzType="primary" (click)="handleOk1(this.WorkPlanPopupAction)" [nzLoading]="isLoading1">
          Save WorkPlan
        </button>
      </ng-template>
    </nz-modal>

    <nz-table style="width: 100%" [nzScroll]="{ x: '1200px', y: '515px' }" nzSize="small" [nzPageSize]="20"
      style="width: 100%" #basicTable [nzData]="this.FilteredList" [nzLoading]="isTableLoading" nzBordered
      nzShowPagination="true" nzShowSizeChanger [nzPageSizeOptions]=[50,100,200,500] [nzShowTotal]="totalTemplate"
      nzShowQuickJumper="true" nzResponsive=true>

      <thead>
        <tr>
          <th nzWidth="70px" style="text-align: center" nzLeft *ngIf="this.permission.Workplan">Select</th>
          <th nzWidth="60px">S. No.</th>
          <th nzWidth="110px">Order Type</th>
          <th nzWidth="150px">Sale Order No.</th>
          <th nzWidth="150px">WorkPlan No</th>
          <th nzWidth="150px">Order Status</th>
          <th nzWidth="150px">Order Status Action By</th>
          <th nzWidth="130px">Order Status Action Date</th>
          <th nzWidth="190px">Customer</th>
          <th nzWidth="170px">Formulation Code</th>
          <th nzWidth="150px">Category</th>
          <th nzWidth="160px">Article Name</th>
          <th nzWidth="120px">Color Name</th>
          <th nzWidth="115px">Grain Name</th>
          <th nzWidth="100px">Order QTY</th>
          <th nzWidth="115px">Order Date</th>
          <th nzWidth="125px">Delivery Date</th>
          <th nzWidth="200px">Added/Updated By</th>
          <th nzWidth="115px">Added/Updated Date</th>
          <th nzWidth="150px">Remark</th>
          <th nzWidth="200px" style="text-align: center" nzRight>Status</th>
          <th nzWidth="110px" style="text-align: center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td nzLeft style="text-align: center" *ngIf="this.permission.Workplan">
            <input *ngIf="data.WorkPlanStatus != true && data.SaleOrderStatus == 'Active'" style="margin-right: 5px"
              type="checkbox" [disabled]="data.SaleOrderStatus == 'Hold'" [checked]="data.IsChecked"
              (change)="data.IsChecked = !data.IsChecked; add()" />
          </td>
          <td>{{ data.SerialNo }}</td>
          <td>{{ data.SaleOrderType }}</td>
          <td>
            <span>
              <nz-tag [nzColor]="'blue'"><a (click)="OpenViewPop(data.SaleOrderId)">
                  {{data.SaleOrderNumber}}
                </a></nz-tag>
            </span>
            <span
              *ngIf="data.LinkedSaleOrder != null && data.LinkedSaleOrder[0]?.LinkedSaleOrder == data.SaleOrderId && data.LinkedSaleOrder.length > 0">
              <br>
              <nz-tag *ngFor="let link of data.LinkedSaleOrder" [nzColor]="'cyan'"><a
                  (click)="OpenViewPop(link.ParentSaleOrder)">
                  {{link.ParentSaleOrderNumber}}
                </a></nz-tag>
            </span>
            <span
              *ngIf="data.ParentSaleOrder != null && data.ParentSaleOrder[0]?.ParentSaleOrder == data.SaleOrderId && data.ParentSaleOrder.length > 0">
              <br>
              <nz-tag *ngFor="let link of data.ParentSaleOrder" [nzColor]="'cyan'"><a
                  (click)="OpenViewPop(link.LinkedSaleOrder)">
                  {{link.LinkedSaleOrderNumber}}
                </a></nz-tag>
            </span>
          </td>
          <td>{{ data.WorkPlanNumber}}</td>
          <td>
            <nz-tag *ngIf="data.SaleOrderStatus == 'Approval Pending'" [nzColor]="'yellow'">{{ data.SaleOrderStatus
              }}</nz-tag>
            <nz-tag *ngIf="data.SaleOrderStatus == 'Active'" [nzColor]="'blue'">{{ data.SaleOrderStatus }}</nz-tag>
            <nz-tag *ngIf="data.SaleOrderStatus == 'Hold'" [nzColor]="'red'">{{ data.SaleOrderStatus }}</nz-tag>
          </td>
          <td>{{ data.OrderStatusActionBy }}</td>
          <td>{{ data.OrderStatusActionDate | DatetimeConverter }}</td>
          <td>{{ data.CustomerName }}</td>
          <td>{{ data.SaleFormulationCode }}</td>
          <td>{{ data.Category }}</td>
          <td>{{ data.SaleOrderProduction.ManufacturingProductName }}</td>
          <td>{{ data.SaleOrderProduction.ColorName }}</td>
          <td>{{ data.SaleOrderProduction.GrainName }}</td>
          <td>{{ data.SaleOrderProduction.OrderQuantity }}</td>
          <td>{{ data.SaleOrderDate | date: "dd-MMM-yyyy" }}</td>
          <td>{{ data.DeliveryDate | date: "dd-MMM-yyyy" }}</td>
          <td>{{ data.AddedBy }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td>{{ data.Remarks }}</td>
          <td nzRight style="text-align: center">
            <nz-tag [nzColor]="'blue'" (click)="OpenTimelinePop(data)"> <a>{{ data.Status }}</a></nz-tag>
          </td>
          <td nzRight style="text-align: center">
            <div>
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item (click)="OpenViewPop(data.SaleOrderId)">View</li>
                  <li style="width: 120px;" nz-menu-item (click)="OpenTimelinePop(data)">Timeline</li>
                  <li style="width: 120px;" nz-menu-item (click)="OpenViewTrailReports(data.SaleOrderId)">View Trail
                    Report</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableChangeWorkPlanButton(data)"
                    (click)="EditPostProcess(data)">Edit Post Process</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableActionButton(data)" (click)="Edit(data)">Edit</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableActionButton(data)"
                    (click)="handleOpenGSMUpdate(data)">Edit GSM</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableDeleteButton(data)" (click)="handleDelete(data)">
                    Delete</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableHoldButton(data)" (click)="handleHold(data)">
                    Hold</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableApproveButton(data)" (click)="handleActive(data)">
                    Approve</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="this.permission.Add"
                    routerLink="/home/salesorder/copy/{{data.SaleOrderId}}">Copy</li>
                  <!-- <li style="width: 120px;" nz-menu-item *ngIf="EnableCostingButton(data)"
                    (click)="OpenCostingPop(data)">Costing</li> -->
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableSandwichRemove(data.Status)"
                    (click)="SaleOrderLinkingRemove(data.LinkedSaleOrder)">Remove Sandwich Link</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableChangeWorkPlanButton(data)"
                    (click)="showModalwpl('change', data)">Change WorkPlan</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
<app-SoDrawer></app-SoDrawer>

<nz-drawer [nzClosable]="true" [nzVisible]="isTimelineVisible" nzPlacement="right" [nzWidth]="1000"
  nzTitle="Sales Order Timeline" (nzOnClose)="handleTimelineCancel()">
  <ng-container *nzDrawerContent>
    <div>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="TimeLineObject" nzSize="small" [nzColumn]="6">
        <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
          {{ TimeLineObject.SaleOrderType }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="CUSTOMER" [nzSpan]="2">{{ TimeLineObject.CustomerName }}</nz-descriptions-item>

        <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1">
          {{
          TimeLineObject.SaleOrderNumber
          }}
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Sale Order Date">
          {{ TimeLineObject.SaleOrderDate | date: "dd-MMM-yyyy" }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Delivery Date">
          {{ TimeLineObject.DeliveryDate | date: "dd-MMM-yyyy" }}
        </nz-descriptions-item>
      </nz-descriptions>
      <div style="margin-top: 20px;">
        <button nz-button nzSize="large" [nzLoading]="refreshBtnLoading" nzType="primary"
          (click)="GetTimelineRefresh(TimeLineObject.SaleOrderNumber)">Refresh</button>
      </div>
      <nz-divider nzText="Time Line"></nz-divider>

      <nz-steps nzDirection="vertical">
        <nz-step *ngIf="GetTimeLineStatus(0) != 'hide'" nzTitle="Sale Order Created" [nzStatus]='GetTimeLineStatus(0)'
          [nzDescription]="GetTimeLineDescription(0)" [nzSubtitle]="GetTimeLineSubtitle(0)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(1) != 'hide'" nzTitle="Work plan Submitted" [nzStatus]='GetTimeLineStatus(1)'
          [nzDescription]="GetTimeLineDescription(1)" [nzSubtitle]="GetTimeLineSubtitle(1)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(2) != 'hide'" nzTitle="Inspection done" [nzStatus]='GetTimeLineStatus(2)'
          [nzDescription]="GetTimeLineDescription(2)" [nzSubtitle]="GetTimeLineSubtitle(2)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(3) != 'hide'" nzTitle="Raw Material Requested"
          [nzStatus]='GetTimeLineStatus(3)' [nzDescription]="GetTimeLineDescription(3)"
          [nzSubtitle]="GetTimeLineSubtitle(3)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(4) != 'hide'" nzTitle="Raw Material Issued" [nzStatus]='GetTimeLineStatus(4)'
          [nzDescription]="GetTimeLineDescription(4)" [nzSubtitle]="GetTimeLineSubtitle(4)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(5) != 'hide'" nzTitle="Production Started" [nzStatus]='GetTimeLineStatus(5)'
          [nzDescription]="GetTimeLineDescription(5)" [nzSubtitle]="GetTimeLineSubtitle(5)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(7) != 'hide'" nzTitle="Jumbo Creation In-Progress"
          [nzStatus]='GetTimeLineStatus(7)' [nzDescription]="GetTimeLineDescription(7)"
          [nzSubtitle]="GetTimeLineSubtitle(7)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(8) != 'hide'" nzTitle="All Jumbo Created & Sent To Post Process"
          [nzStatus]='GetTimeLineStatus(8)' [nzDescription]="GetTimeLineDescription(8)"
          [nzSubtitle]="GetTimeLineSubtitle(8)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(9) != 'hide'" nzTitle="Print Assigned" [nzStatus]='GetTimeLineStatus(9)'
          [nzDescription]="GetTimeLineDescription(9)" [nzSubtitle]="GetTimeLineSubtitle(9)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(10) != 'hide'" nzTitle="Print In-Process" [nzStatus]='GetTimeLineStatus(10)'
          [nzDescription]="GetTimeLineDescription(10)" [nzSubtitle]="GetTimeLineSubtitle(10)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(11) != 'hide'" nzTitle="Print Completed" [nzStatus]='GetTimeLineStatus(11)'
          [nzDescription]="GetTimeLineDescription(11)" [nzSubtitle]="GetTimeLineSubtitle(11)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(12) != 'hide'" nzTitle="Embossing Assigned" [nzStatus]='GetTimeLineStatus(12)'
          [nzDescription]="GetTimeLineDescription(12)" [nzSubtitle]="GetTimeLineSubtitle(12)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(13) != 'hide'" nzTitle="Embossing In-Process"
          [nzStatus]='GetTimeLineStatus(13)' [nzDescription]="GetTimeLineDescription(13)"
          [nzSubtitle]="GetTimeLineSubtitle(13)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(14) != 'hide'" nzTitle="Embossing Completed"
          [nzStatus]='GetTimeLineStatus(14)' [nzDescription]="GetTimeLineDescription(14)"
          [nzSubtitle]="GetTimeLineSubtitle(14)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(15) != 'hide'" nzTitle="Vacuum Assigned" [nzStatus]='GetTimeLineStatus(15)'
          [nzDescription]="GetTimeLineDescription(15)" [nzSubtitle]="GetTimeLineSubtitle(15)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(16) != 'hide'" nzTitle="Vacuum In-Process" [nzStatus]='GetTimeLineStatus(16)'
          [nzDescription]="GetTimeLineDescription(16)" [nzSubtitle]="GetTimeLineSubtitle(16)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(17) != 'hide'" nzTitle="Vacuum Completed" [nzStatus]='GetTimeLineStatus(17)'
          [nzDescription]="GetTimeLineDescription(17)" [nzSubtitle]="GetTimeLineSubtitle(17)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(18) != 'hide'" nzTitle="Lacquer Assigned" [nzStatus]='GetTimeLineStatus(18)'
          [nzDescription]="GetTimeLineDescription(18)" [nzSubtitle]="GetTimeLineSubtitle(18)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(19) != 'hide'" nzTitle="Lacquer In-Process" [nzStatus]='GetTimeLineStatus(19)'
          [nzDescription]="GetTimeLineDescription(19)" [nzSubtitle]="GetTimeLineSubtitle(19)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(20) != 'hide'" nzTitle="Lacquer Completed" [nzStatus]='GetTimeLineStatus(20)'
          [nzDescription]="GetTimeLineDescription(20)" [nzSubtitle]="GetTimeLineSubtitle(20)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(21) != 'hide'" nzTitle="Tumbling Assigned" [nzStatus]='GetTimeLineStatus(21)'
          [nzDescription]="GetTimeLineDescription(21)" [nzSubtitle]="GetTimeLineSubtitle(21)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(22) != 'hide'" nzTitle="Tumbling In-Process"
          [nzStatus]='GetTimeLineStatus(22)' [nzDescription]="GetTimeLineDescription(22)"
          [nzSubtitle]="GetTimeLineSubtitle(22)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(23) != 'hide'" nzTitle="Tumbling Completed" [nzStatus]='GetTimeLineStatus(23)'
          [nzDescription]="GetTimeLineDescription(23)" [nzSubtitle]="GetTimeLineSubtitle(23)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(24) != 'hide'" nzTitle="Sent for Quality Check(Final Inspection)"
          [nzStatus]='GetTimeLineStatus(24)' [nzDescription]="GetTimeLineDescription(24)"
          [nzSubtitle]="GetTimeLineSubtitle(24)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(25) != 'hide'" nzTitle="Move To Dispatch" [nzStatus]='GetTimeLineStatus(25)'
          [nzDescription]="GetTimeLineDescription(25)" [nzSubtitle]="GetTimeLineSubtitle(25)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(26) != 'hide'" nzTitle="Partially Dispatch Ready"
          [nzStatus]='GetTimeLineStatus(26)' [nzDescription]="GetTimeLineDescription(26)"
          [nzSubtitle]="GetTimeLineSubtitle(26)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(27) != 'hide'" nzTitle="Partially Dispatch Completed"
          [nzStatus]='GetTimeLineStatus(27)' [nzDescription]="GetTimeLineDescription(27)"
          [nzSubtitle]="GetTimeLineSubtitle(27)"></nz-step>
        <nz-step *ngIf="GetTimeLineStatus(28) != 'hide'" nzTitle="All Dispatch Ready" [nzStatus]='GetTimeLineStatus(28)'
          [nzDescription]="GetTimeLineDescription(28)" [nzSubtitle]="GetTimeLineSubtitle(28)"></nz-step>
        <!-- <nz-step *ngIf="GetTimeLineStatus(28) != 'hide'" nzTitle="All Dispatch Completed" [nzStatus]='GetTimeLineStatus(28)' [nzDescription]="GetTimeLineDescription(28)" [nzSubtitle]="GetTimeLineSubtitle(28)"></nz-step> -->
        <nz-step *ngIf="GetTimeLineStatus(30) != 'hide'" nzTitle="All Dispatch Completed"
          [nzStatus]='GetTimeLineStatus(30)' [nzDescription]="GetTimeLineDescription(30)"
          [nzSubtitle]="GetTimeLineSubtitle(30)"></nz-step>
        <nz-step
          *ngIf="GetTimeLineStatus(101) != 'hide' && this.ActivityLogList != null && this.ActivityLogList[0].LinkedSaleOrder != null"
          nzTitle="Sandwich Order Merged into Upper Order No. - {{this.ActivityLogList[0].LinkedSaleOrder[0].ParentSaleOrderNumber}}"
          [nzStatus]='GetTimeLineStatus(101)' [nzDescription]="GetTimeLineDescription(101)"
          [nzSubtitle]="GetTimeLineSubtitle(101)"></nz-step>


      </nz-steps>

      <div *ngIf="TimeLineObject">
        <p *ngIf="TimeLineObject.Status == 'LiningOrderMerged'"><b>This Order is only for Lining to create Sandwich
            Order and the produciton flow is stopped now </b></p>
      </div>
      <div *ngIf="TimeLineObject">
        <p *ngIf="TimeLineObject.Status == 'LiningOrderMerged'"><b>and this order won't be shown in any further steps in
            production.</b></p>
      </div>
      <!--<nz-timeline>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 0? "red" : (SelectedSaleOrderStatusEnum > 0? "green" : "gray")'>
                  Sale
                  Order Created
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 1? "red" : (SelectedSaleOrderStatusEnum > 1? "green" : "gray")'>
                  Work
                  plan Submitted
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 2? "red" : (SelectedSaleOrderStatusEnum > 2? "green" : "gray")'>
                  Inspection
                  done
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 3? "red" : (SelectedSaleOrderStatusEnum > 3? "green" : "gray")'>
                  RawMaterial
                  Requested
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 4? "red" : (SelectedSaleOrderStatusEnum > 4? "green" : "gray")'>
                  RawMaterial
                  Issued
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 5? "red" : (SelectedSaleOrderStatusEnum > 5? "green" : "gray")'>
                  Production
                  Complete
              </nz-timeline-item>-->
      <!-- <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 6? "red" : (SelectedSaleOrderStatusEnum > 6? "green" : "gray")'>MiSxing</nz-timeline-item> -->

      <!--<nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 7? "red" : (SelectedSaleOrderStatusEnum > 7? "green" : "gray")'>
                  In
                  Jumbo
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 8? "red" : (SelectedSaleOrderStatusEnum > 8? "green" : "gray")'>
                  Move
                  To PostProcess
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 9? "red" : (SelectedSaleOrderStatusEnum > 9? "green" : "gray")'>
                  Print
                  Assigned
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 10? "red" : (SelectedSaleOrderStatusEnum > 10? "green" : "gray")'>
                  Print
                  In Process
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 11? "red" : (SelectedSaleOrderStatusEnum > 11? "green" : "gray")'>
                  Print
                  Completed
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 12? "red" : (SelectedSaleOrderStatusEnum > 12? "green" : "gray")'>
                  Embossing
                  Assigned
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 13? "red" : (SelectedSaleOrderStatusEnum > 13? "green" : "gray")'>
                  Embossing
                  In Process
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 14? "red" : (SelectedSaleOrderStatusEnum > 14? "green" : "gray")'>
                  Embossing
                  Completed
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 15? "red" : (SelectedSaleOrderStatusEnum > 15? "green" : "gray")'>
                  Vacuum
                  Assigned
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 16? "red" : (SelectedSaleOrderStatusEnum > 16? "green" : "gray")'>
                  Vacuum
                  In Process
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 17? "red" : (SelectedSaleOrderStatusEnum > 17? "green" : "gray")'>
                  Vacuum
                  Completed
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 18? "red" : (SelectedSaleOrderStatusEnum > 18? "green" : "gray")'>
                  Lacquer
                  Assigned
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 19? "red" : (SelectedSaleOrderStatusEnum > 19? "green" : "gray")'>
                  Lacquer
                  In Process
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 20? "red" : (SelectedSaleOrderStatusEnum > 20? "green" : "gray")'>
                  Lacquer
                  Completed
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 21? "red" : (SelectedSaleOrderStatusEnum > 21? "green" : "gray")'>
                  Tumbling
                  Assigned
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 22? "red" : (SelectedSaleOrderStatusEnum > 22? "green" : "gray")'>
                  Tumbling
                  In Process
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 23? "red" : (SelectedSaleOrderStatusEnum > 23? "green" : "gray")'>
                  Tumbling
                  Completed
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 24? "red" : (SelectedSaleOrderStatusEnum > 24? "green" : "gray")'>JumboInspection</nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 25? "red" : (SelectedSaleOrderStatusEnum > 25? "green" : "gray")'>
                  Move
                  To Dispatch
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 26? "red" : (SelectedSaleOrderStatusEnum > 26? "green" : "gray")'>
                  Dispatch
                  Ready
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 27? "red" : (SelectedSaleOrderStatusEnum > 27? "green" : "gray")'>
                  Partially
                  Dispatched
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 28? "red" : (SelectedSaleOrderStatusEnum > 28? "green" : "gray")'>
                  All
                  Dispatch Completed
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 29? "red" : (SelectedSaleOrderStatusEnum > 29? "green" : "gray")'>
                  Dispatch
                  Vehicle Gate Out
              </nz-timeline-item>
              <nz-timeline-item [nzColor]='SelectedSaleOrderStatusEnum == 30? "red" : (SelectedSaleOrderStatusEnum > 30? "green" : "gray")'>
                  Dispatch
                  Completed
              </nz-timeline-item>
          </nz-timeline>-->
    </div>
  </ng-container>
</nz-drawer>


<nz-modal [(nzVisible)]="IsGSMUpdateVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="800" [nzTitle]="modalTitleDispatch"
  [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch" (nzOnCancel)="handleGSMUpdateCancel()">
  <ng-template #modalTitleDispatch>Update GSMs for Order No. - {{this.SaleOrderGSMUpdate.SaleOrderNumber}}</ng-template>

  <ng-template #modalContentDispatch>
    <div nz-row [nzGutter]="15" style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px;">
      <div nz-col [nzSpan]="6">
      </div>
      <div nz-row [nzGutter]="15">
        <div nz-col [nzSpan]="3">
        </div>
        <ng-container *ngFor="let item of this.SaleOrderGSMUpdate.SaleOrderProduction.FormulationMixing">
          <div nz-col [nzSpan]="4" *ngIf="item.MixingName.trim().toLowerCase() === 'pre skin'">
            <nz-form-item>
              <nz-form-control [nzSpan]="20" nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                <label style="margin-left: -1px;">Pre Skin</label><br>
                <input nz-input type="number" [(ngModel)]="SaleOrderGSMUpdate.SaleOrderProduction.PreSkinGsm"
                  [ngModelOptions]="{standalone: true}" name="PreSkinGsm" (ngModelChange)="calculateGrandTotal()" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="item.MixingName.trim().toLowerCase() === 'skin'">
            <nz-form-item>
              <nz-form-control [nzSpan]="20" nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                <label style="margin-left: -1px;">Skin</label>
                <input nz-input type="number" [(ngModel)]="SaleOrderGSMUpdate.SaleOrderProduction.SkinGsm"
                  [ngModelOptions]="{standalone: true}" name="SkinGsm" (ngModelChange)="calculateGrandTotal()" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="item.MixingName.trim().toLowerCase() === 'foam'">
            <nz-form-item>
              <nz-form-control [nzSpan]="20" nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                <label style="margin-left: -1px;">Foam</label>
                <input nz-input type="number" [(ngModel)]="SaleOrderGSMUpdate.SaleOrderProduction.FoamGsm"
                  [ngModelOptions]="{standalone: true}" name="FoamGsm" (ngModelChange)="calculateGrandTotal()" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" *ngIf="item.MixingName.trim().toLowerCase() === 'adhesive'">
            <nz-form-item>
              <nz-form-control [nzSpan]="20" nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                <label style="margin-left: -1px;">Adhesive</label>
                <input nz-input type="number" [(ngModel)]="SaleOrderGSMUpdate.SaleOrderProduction.AdhesiveGsm"
                  [ngModelOptions]="{standalone: true}" name="AdhesiveGsm" (ngModelChange)="calculateGrandTotal()" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
        <div nz-col [nzSpan]="4">
          <nz-form-item>
            <nz-form-control [nzSpan]="20" nzErrorTip="Cannot be empty, either enter 0 or a higher value">
              <label style="margin-left: -1px;">Fabric (GSM)</label>
              <input nz-input type="number" [(ngModel)]="SaleOrderGSMUpdate.SaleOrderProduction.FabricGsm" readonly
                [ngModelOptions]="{standalone: true}" name="FabricGsm" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col [nzSpan]="15">
      </div>
      <div nz-col [nzSpan]="8">
        <nz-descriptions [nzColumn]="1">
          <nz-descriptions-item nzTitle="Total (GSM)" nzColon="true">
            <input nz-input type="text" readonly style="width:110px;"
              [(ngModel)]="SaleOrderGSMUpdate.SaleOrderProduction.TotalGsm" [ngModelOptions]="{standalone: true}" />
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooterDispatch>

    <button nz-button nzType="primary" (click)="SaveUpdateGSM()" [disabled]="!this.permission.Edit">Update GSM</button>
    <button nz-button nzType="default" (click)="handleGSMUpdateCancel()">Cancel</button>
  </ng-template>
</nz-modal>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>
<app-Timeline></app-Timeline>
<nz-modal [nzWidth]="900" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisiblePopUp" [nzTitle]="PopUpTitle2"
  [nzContent]="modalContent2" [nzMaskClosable]="false" [nzFooter]="null" (nzOnCancel)="CancelPostProcess()">
  <ng-template #modalTitle>{{ PopUpTitle2 }}</ng-template>

  <ng-template #modalContent2>
    <app-post-process-options [SelectedPostProcess]="SelectedPostProcess" (handleProcess)="handleProcess($event)"
      [PrintMasterList]="PrintMasterList" [EmbossingList]="EmbossingList" [LacquerList]="LacquerList"
      [VaccumList]="VaccumList" [TumblingList]="TumblingList"></app-post-process-options>
    <div class="text-center">
      <button nz-button nzType="primary" (click)="SavePostProcess()">
        Save
      </button>
    </div>
  </ng-template>
</nz-modal>