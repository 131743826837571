import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../environments/environment";
import { ESalesOrderStatus, Modules, Responsibility } from "../../Models/Enums";
import { AlertMessageService } from "../../Services/AlertMessageService";
import { AuthService } from "../../Services/auth.service";
import { LoadingService } from "../../Services/loadingService";
import { SoDrawerService } from "src/PmsUIApp/Services/SoDrawerService";



@Component({
    selector: 'app-ActivityLog',
    templateUrl: './ActivityLog.component.html'
})
export class ActivityLogComponent implements OnInit {
    ApiUrl = environment.Api_Url;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    permission = {
        View: false
    }
    statusList = Object.values(ESalesOrderStatus).filter(value => typeof value != 'number');
    request = {
        SaleOrderNumber: null,
        Status: null,
        DateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 3)),
        DateTo: new Date(new Date().setHours(23, 59, 59)),
        Addedby: null
    }
    ActivityLogList: any;
    constructor(private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService,
        private soDrawer: SoDrawerService
    ) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.ActivityLog, Responsibility.View);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.GetAllActivityLog();
    }
    GetAllActivityLog() {
        this.isTableLoading = true;
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, this.request).subscribe(res => {
            console.log(res);
            this.ActivityLogList = res;
            this.isTableLoading = false;
        }, res => {
            this.GetAllActivityLog();
        });
    }
    OpenViewPop(data: any) {
        this.soDrawer.SaleOrderId = data.SaleOrderId;
        this.soDrawer.show();
    }
    handleCancel(): void {
        this.isVisible = false;
    }
}