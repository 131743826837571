import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductAllocationModel, StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { RackModel, StoreModel } from '../../Models/MasterModel';
import { TransportVehicleModel } from '../../Models/TransportModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { StockLabelResponseModel } from '../../Models/BarcodeLabelModel';
import { LoadingService } from '../../Services/loadingService';

@Component({
  templateUrl: './StockProductAllocation.component.html'
})
export class StockProductAllocationComponent {
  id: number = 0;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  NewStockProduct: StockProductModel = new StockProductModel;
  FilteredProductList: ProductModel[] = [];
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel;
  NewInvoice: InvoiceModel = new InvoiceModel;
  NewAllocation: StockProductAllocationModel = new StockProductAllocationModel;
  TransportVehicleList: TransportVehicleModel[] = [];
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  StoreID: number = 0;
  RackID: number = 0;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  StockProductLabelList: StockLabelResponseModel[] = [];
  FilteredStockProductLabelList: StockLabelResponseModel[] = [];
  IsLabelListVisible: boolean = false;
  totalItemsCountSelectedLabel: number = 0;
  SelectedLabelIds: number[] = [];
  isLoading: boolean = false;
  isAnyItemChecked: boolean = false;
  IsCheckedAll: boolean = false;
  BarcodeOpenStage: string = '';

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private alertService: AlertMessageService, private auth: AuthService,
    private loader: LoadingService
  ) { }


  GetStockDetails() {
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe(res => {
      this.NewStock = res;

      this.NewStock.StockProduct.forEach((nos) => {
        nos.ExpandRecord = false;
      })
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetStockDetails();
      }
    });
  }

  rejectedchange(item: StockProductModel) {
    item.AcceptedQuantity = item.Quantity - item.RejectedQuantity;
  }

  AddAllocation(spl: StockProductModel) {
    spl.NewRecord = true;
    this.NewAllocation = new StockProductAllocationModel();
  }
  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllStore()
      }
    });
  }
  onSelectedStoreChange() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res.filter(x => x.StoreId == this.NewAllocation.StoreId);
      this.RackID = 0;
    }, res => { });
  }
  SaveAllocation(spl: StockProductModel) {

    if (spl.StockProductAllocation == undefined) {
      spl.StockProductAllocation = [];
    }
    if (this.NewAllocation.Quantity <= 0) {
      this.alertService.error("Enter valid Quantity ")
      return
    }
    if(this.NewAllocation.StoreId <= 0) {
      this.alertService.error("Select Store");
      return;
    }
    if(this.NewAllocation.RackId <= 0) {
      this.alertService.error("Select Rack");
      return;
    }

    var totalqtyAccepted = spl.StockProductAllocation.filter(x => x.InspectionType == 'Accepted').reduce((accumulator, current) => {

      return parseInt(accumulator.toString()) + parseInt(current.Quantity.toString());
    }, 0);
    var totalqtyRejected = spl.StockProductAllocation.filter(x => x.InspectionType == 'Rejected').reduce((accumulator, current) => {
      return parseInt(accumulator.toString()) + parseInt(current.Quantity.toString());
    }, 0);

    if (this.NewAllocation.InspectionType == 'Accepted') {
      totalqtyAccepted += parseInt(this.NewAllocation.Quantity.toString());
      if ((totalqtyAccepted) > (spl.AcceptedQuantity)) {
        this.alertService.error("Total Quantity in " + this.NewAllocation.InspectionType + " InspectionType cannot more than " + spl.AcceptedQuantity)
        return
      }
    } else {
      totalqtyRejected += parseInt(this.NewAllocation.Quantity.toString());
      if ((totalqtyRejected) > (spl.RejectedQuantity)) {
        this.alertService.error("Total Quantity in " + this.NewAllocation.InspectionType + " InspectionType cannot more than " + spl.RejectedQuantity)
        return
      }
    }

    var isExistingAllocation = spl.StockProductAllocation.find(x => 
      x.InspectionType == this.NewAllocation.InspectionType && 
      x.RackId == this.NewAllocation.RackId
    );

    if (spl.AcceptedQuantity)
      spl.NewRecord = false;
    this.NewAllocation.StockProductId = spl.StockProductId;
    this.NewAllocation.RackName = this.RackList.filter(x => x.RackId == this.NewAllocation.RackId)[0].RackName;
    this.NewAllocation.RackCode = this.RackList.filter(x => x.RackId == this.NewAllocation.RackId)[0].RackCode;
    this.NewAllocation.StoreName = this.StoreList.filter(x => x.StoreId == this.NewAllocation.StoreId)[0].StoreName;
    
    if (isExistingAllocation) {
      // Update existing allocation
      isExistingAllocation.Quantity += parseInt(this.NewAllocation.Quantity.toString());
      
      // Merge existing and new label IDs
      isExistingAllocation.StockLabelIds = [
        ...new Set([...isExistingAllocation.StockLabelIds, ...this.NewAllocation.StockLabelIds])
      ];
    } else {
      // Add new allocation
      spl.StockProductAllocation.push({
        ...this.NewAllocation,
        StockLabelIds: [...this.NewAllocation.StockLabelIds]
      });
    }
console.log(spl.StockProductAllocation);
console.log(this.NewAllocation);
    // Reset NewAllocation
    this.NewAllocation = new StockProductAllocationModel();
    spl.NewRecord = false;
  }

  SaveAllocationtoDB(spl: StockProductModel) {
    {
      let url = this.ApiUrl + "stock/stockallocation";
      this.http.post<StockProductModel>(url, spl).subscribe({
        next: res => {
          this.alertService.success("Stock allocated Successfully");
          this.StockProductLabelList = [];
          this.GetStockDetails()
        },
        error: res => { this.alertService.error("An error has been occured. Please try again") },
      });
    }
  }

  RemoveAllocation(spl: StockProductModel, alloc: StockProductAllocationModel) {
    var index = spl.StockProductAllocation.indexOf(alloc);
    if (index !== -1) {
      spl.StockProductAllocation.splice(index, 1);
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.GetStockDetails();
    this.GetAllStore()
  }

  Quantitychange(data: StockProductAllocationModel) {

    if (data.Quantity == 0) {

      return
    }
  }
  onSelectedLabelCheckboxChange(data: StockLabelResponseModel) {
    // Toggle the checked state of the item
    data.IsChecked = !data.IsChecked;

    // Update isAnyItemChecked based on whether any item is checked
    this.isAnyItemChecked = this.FilteredStockProductLabelList.some(item => item.IsChecked);
  }
  handleLabelListCancel() {
    this.IsLabelListVisible = false;
    this.FilteredStockProductLabelList = [];
  }
  SetInspectionStatusColor(data: StockLabelResponseModel) {
    if (data.InspectionStatus == 'Accepted') {
      return '#96f0b1';
    } else if (data.InspectionStatus == 'Rejected') {
      return '#f09696';
    } else {
      return '';
    }
  }
  fetchStockProductLabelList(StockProductId: number) {
    this.loader.show();
    let url = this.ApiUrl + "stock/getstocklabelsbyproductstock";
    let StockLabelRequest = {
      StockProductId: StockProductId
    }
    this.http.post(url, StockLabelRequest).subscribe({
      next: (res: any) => {
        this.StockProductLabelList = res;
        this.totalItemsCountSelectedLabel = this.StockProductLabelList.length;
        this.loader.hide();
        this.IsLabelListVisible = true;
        console.log(res);
      }, error: (err: any) => {
        this.loader.hide();
        console.log(err);
      }
    })
  }



  openBarcodeList(StockProduct: StockProductModel, InspectionType: string, OpenStage: string, RackId: number = 0) {
    this.BarcodeOpenStage = OpenStage;
    if (InspectionType == null || InspectionType == undefined) {
      this.alertService.error("First select Inspection Type");
      return;
    }
    if (this.StockProductLabelList.length == 0) {
      let url = this.ApiUrl + "stock/getstocklabelsbyproductstock";
      let StockLabelRequest = {
        StockProductId: StockProduct.StockProductId
      }
      this.http.post(url, StockLabelRequest).subscribe({
        next: (res: any) => {
          this.StockProductLabelList = res;
          if (OpenStage == 'BeforeSave') {
            this.FilteredStockProductLabelList = this.StockProductLabelList.filter(item => item.InspectionStatus == InspectionType && !StockProduct.StockProductAllocation.some(allocation => allocation.StockLabelIds.includes(item.StockLabelId)));
          } else if (OpenStage == 'AfterSave') {
            this.FilteredStockProductLabelList = this.StockProductLabelList.filter(item => item.InspectionStatus == InspectionType && StockProduct.StockProductAllocation.some(allocation => allocation.StockLabelIds.includes(item.StockLabelId) && allocation.RackId == RackId));
          }

          this.totalItemsCountSelectedLabel = this.FilteredStockProductLabelList.length;
          this.IsCheckedAll = false;
          this.loader.hide();
          this.IsLabelListVisible = true;
          console.log(res);
        }, error: (err: any) => {
          this.loader.hide();
          console.log(err);
        }
      })
    }
    else {
      if (OpenStage == 'BeforeSave') {
        this.FilteredStockProductLabelList = this.StockProductLabelList.filter(item => item.InspectionStatus == InspectionType && !StockProduct.StockProductAllocation.some(allocation => allocation.StockLabelIds.includes(item.StockLabelId)));
        this.totalItemsCountSelectedLabel = this.FilteredStockProductLabelList.length;
      } else if (OpenStage == 'AfterSave') {
        this.FilteredStockProductLabelList = this.StockProductLabelList.filter(item => item.InspectionStatus == InspectionType && StockProduct.StockProductAllocation.some(allocation => allocation.StockLabelIds.includes(item.StockLabelId) && allocation.RackId == RackId));
        this.totalItemsCountSelectedLabel = this.FilteredStockProductLabelList.length;
      }
      this.IsCheckedAll = false;
      this.IsLabelListVisible = true;
    }

  }
  SaveSelectedLabels() {
    this.isLoading = true;
    this.SelectedLabelIds = [];
    this.SelectedLabelIds = this.FilteredStockProductLabelList.filter(item => item.IsChecked).map(item => item.StockLabelId);
    this.NewAllocation.StockLabelIds = this.SelectedLabelIds;

    this.NewAllocation.Quantity = this.FilteredStockProductLabelList.filter(item => item.IsChecked).reduce((accumulator, current) => {
      return accumulator + current.Quantity;
    }, 0);

    this.IsLabelListVisible = false;
    this.isLoading = false;
  }
  CheckAll() {
    this.FilteredStockProductLabelList.forEach(item => {
      item.IsChecked = this.IsCheckedAll;
    });
    this.isAnyItemChecked = this.FilteredStockProductLabelList.some(item => item.IsChecked);
  }
  onAllocationClose(data: StockProductModel) {
    data.NewRecord = false;
    this.FilteredStockProductLabelList = [];
  }
}
