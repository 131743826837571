import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './Authentication/auth/auth.component';
import { ExpenseGuard } from './Authentication/expense.guard';
import { LogoutComponent } from './Authentication/logout/logout.component';
import { GateInComponent } from './GateManagement/GateIn/GateIn.component';
import { GateOutComponent } from './GateManagement/GateOut/GateOut.component';
import { GatePassComponent } from './GateManagement/GatePass/GatePass.component';
import { HomeComponent } from './Home/Home.component';
import { AddStockComponent } from './InventoryManagement/AddStock/AddStock.component';
import { StockDetailsComponent } from './InventoryManagement/StockDetails/StockDetails.component';
import { StockInspectionComponent } from './InventoryManagement/StockInspection/StockInspection.component';
import { StockListComponent } from './InventoryManagement/StockList/StockList.component';
import { StockManageRejectedItemsComponent } from './InventoryManagement/StockManageRejectedItems/StockManageRejectedItems.component';
import { StockProductAllocationComponent } from './InventoryManagement/StockProductAllocation/StockProductAllocation.component';
import { BranchComponent } from './Master/branch/branch.component';
import { DeliveryTermComponent } from './Master/delivery-term/delivery-term.component';
import { DepartmentComponent } from './Master/department/department.component';
import { PaymentTermComponent } from './Master/payment-term/payment-term.component';
import { ProductCategoryComponent } from './Master/product-category/product-category.component';
import { ProductFirstSubCategoryComponent } from './Master/product-first-sub-category/product-first-sub-category.component';
import { ProductSecSubCategoryComponent } from './Master/product-sec-sub-category/product-sec-sub-category.component';
import { RackComponent } from './Master/rack/rack.component';
import { StoreComponent } from './Master/store/store.component';
import { TagComponent } from './Master/tag/tag.component';
import { ProductListComponent } from './Product/ProductList/ProductList.component';
import { SupplierListComponent } from './SupplierManagement/Supplier/supplier-list/supplier-list.component';
import { TransportManagementComponent } from './transportManagement/transportManagement.component';
import { PurchaseorderComponent } from './PurchaseOrder/Add/purchaseorder.component';
import { POListComponent } from './PurchaseOrder/List/polist.component';
import { MeasurementConversionComponent } from './Master/measurement-conversion/measurement-conversion.component';
import { ColorComponent } from './Master/color/color.component';
import { ElementComponent } from './Master/element/element.component';
import { GrainComponent } from './Master/grain/grain.component';
import { CustomerListComponent } from './CustomerManagement/customer-list/customer-list.component';

import { AdminComponent } from './Admin/Users/Admin.component';
import { IssueComponent } from './Issue/Add/Issue.component';
import { IssueListComponent } from './Issue/List/Issuelist.component';
import { CostingComponent } from './Costing/Add/Costing.component';
import { CostingListComponent } from './Costing/List/Costinglist.component';
import { ProcessComponent } from './Process/Add/process.component';
import { ProcessListComponent } from './Process/List/processlist.component';
import { MixingComponent } from './Mixing/Add/mixing.component';
import { MixingListComponent } from './Mixing/List/mixinglist.component';

import { GatepassprintComponent } from './GateManagement/GatePassPrint/gatepassprint.component';
import { POprintComponent } from './PurchaseOrder/Print/poprint.component';
import { POEmailComponent } from './PurchaseOrder/Email/poemail.component';
import { EmbossingListComponent } from './Production/Post Process/embossing-list/embossing-list.component';
import { VaccumListComponent } from './Production/Post Process/vaccum-list/vaccum-list.component';
import { TumblingListComponent } from './Production/Post Process/tumbling-list/tumbling-list.component';
import { LacquerListComponent } from './Production/Post Process/lacquer-list/lacquer-list.component';
import { PostprocessprintComponent } from './Production/Post Process/postprocessprint/postprocessprint.component';
import { MixingprintComponent } from './Production/mixingprint/mixingprint.component';
import { ProcessprintComponent } from './Production/processprint/processprint.component';

import { OpeningStockComponent } from './InventoryManagement/OpeningStock/OpeningStock.component';
import { StockComponent } from './InventoryManagement/stock/stock.component';

import { StartscreenComponent } from './startscreen/startscreen.component';
import { NewmixingComponent } from './Production/newmixing/newmixing.component';
import { ThicknessComponent } from './Master/thickness/thickness.component';
import { WidthComponent } from './Master/width/width.component';
import { ProductWiseStockComponent } from './InventoryManagement/Product-wise-stock/Product-wise-stock.component';
import { ConsumptionListComponent } from './Consumption/List/consumptionlist.component';
import { ConsumptionComponent } from './Consumption/Add/consumption.component';
import { ResponsibilityMasterComponent } from './Admin/ResponsibilityMaster/ResponsibilityMaster.component';
import { RolesMasterComponent } from './Admin/RolesMaster/RolesMaster.component';
import { StoreWiseStockComponent } from './InventoryManagement/StorewiseStock/StoreWiseStock';
import { PostprocessComponent } from './Production/Post Process/PostProcess/postprocess.component';
import { DispatchComponent } from './Production/Dispatch/dispatch.component';
import { FinalInspectionComponent } from './Production/FinalInspection/Add/FinalInspection.component';

import { OutPassPrintComponent } from './OutPass/outpassPrint/outpassprint.component';
import { OutPassAddComponent } from './OutPass/Add/outpassAdd.component';
import { OutPassListComponent } from './OutPass/list/outpasslist.component';

import { StartProductionComponent } from './Production/StartProduction/start-production.component';

import { WorkplanListComponent } from './Production/workplan-list/workplan-list.component';
import { InspectionPrintComponent } from './Production/WorkPlan/inspectionprint/inspectionprint.component';
import { JumboListComponent } from './Production/WorkPlan/Jumbolist/jumbolist.componrnt';
import { JumboMasterComponent } from './Production/WorkPlan/JumboMaster/jumbomaster.component';
import { JumboPrintComponent } from './Production/WorkPlan/jumboprint/jumboprint.component';


import { FormulationCodeListComponent } from './Production/FormulationCode/List/formulation-list.component';
import { FormulationcodeComponent } from './Production/FormulationCode/Add/newformulationcode.component';
import { MeasurementUnitComponent } from './Master/measurement-unit/measurement-unit.component';
import { WithSupplierProductWiseStockReportComponent } from './Reports/StockReports/WithSupplierProductWiseStock/WithSupplierProductWiseStock.component';
import { proformaAddComponent } from './SalesOrder/proformaInvoice/Add/proformaAdd.component';
import { proformaListComponent } from './SalesOrder/proformaInvoice/List/proformalist.component';
import { proformaPrintComponent } from './SalesOrder/proformaInvoice/Print/proformaPrint.component';
import { PurchaseReportComponent } from './Reports/PurchaseReports/PurchaseReport/PurchaseReport.component';
import { PresalesorderComponent } from './SalesOrder/SalesOrder/Add/presalesorder.component';
import { SaleslistComponent } from './SalesOrder/SalesOrder/List/saleslist.component';
import { ProductStockHistoryReportComponent } from './Reports/ProductStockHistory/ProductStockHistory.component';
import { BankDetailsComponent } from './Master/BankDetails/bankDetails.component';
import { CategoryWiseStock } from './Reports/StockReports/CategoryWiseStock/CategoryWiseStock.component';
import { UsersLoginHistoryComponent } from './Admin/UsersLoginHistory/UsersLoginHistory.component';
import { DemandListComponent } from './PurchaseOrder/DemandList/DemandList.component';
import { YieldReportComponent } from './Reports/YieldReport/YieldReport.component';
import { ProductionPlanningReportComponent } from './Reports/ProductionPlanningReport/ProductionPlanningReport.component';
import { PostProcessReportComponent } from './Reports/PostProcessReport/PostProcessReport.component';

import { ProductStockSummaryReportComponent } from './Reports/ProductStockSummary/ProductStockSummary.component';

import { InspectionAllPrintComponent } from './Production/WorkPlan/inspectionallprint/inspectionallprint.component';
import { PackagingComponent } from './Dispatch/Packaging/Add/Packaging.component';
import { PackagingListComponent } from './Dispatch/Packaging/List/PackagingList.component';
import { PackingListPrintComponent } from './Dispatch/Packaging/Print/PackingListPrint.component';
import { WastageReportComponent } from './Reports/WastageReport/WastageReport.component';
import { MBFormulationComponent } from './Production/PigmentMB/Add/MBFormulation.component';
import { ActivityLogComponent } from './Production/ActivityLog/ActivityLog.component';
import { FinalInspectionList } from './Production/FinalInspection/List/FinalInspectionList.component';
import { LinkSaleOrderComponent } from './Production/Post Process/LinkSaleOrder/LinkSaleOrder.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ProductionStatusReportComponent } from './Reports/ProductionStatusReport/ProductionStatusReport.component';
import { GeneralConfigurationComponent } from './Admin/GeneralConfiguration/GeneralConfiguration.component';
import { CostingListPrintComponent } from './Costing/CostingListPrint/CostingListPrint.component';
import { SalesReportComponent } from './Reports/SalesReport/SalesReport.component';
import { ConsumptionPendingListComponent } from './Consumption/PendingList/pendinglist.component';
import { ConsumptionReportComponent } from './Reports/StockReports/ConsumptionReport/consumptionreport.component';
import { InspectionDetailsPrintComponent } from './Production/WorkPlan/InspectionDetailsPrint/InspectionDetailsPrint.component';
import { PackagingListEmailComponent } from './Dispatch/Packaging/Email/PackagingListEmail.component';
import { PasteConsumptionReportComponent } from './Reports/PasteConsumptionReport/PasteConsumptionReport.component';
import { SingleOrderCostingPrint } from './Costing/SingleOrderCostingPrint/SingleOrderCostingPrint.component';
import { IssueSlipPrintComponent } from './Issue/IssueSlipPrint/issueslipprint.component';
import { FormulationPrintComponent } from './Production/FormulationCode/Print/formulationprint.component';
import { MeasurementConversionNewComponent } from './QuickTools/measurement-conversion-new/measurement-conversion-new.component';
import { DesignationComponent } from './Master/designation/designation.component';
import { FactoryWorkersComponent } from './Master/factoryworkers/factoryworkers.component';
import { OutPassPurposeComponent } from './Master/OutPassPurpose/outpasspurpose.component';
import { DigitalWeightComponent } from './IoTDevices/DigitalWeight/digital-weight/digital-weight.component';
import { KnittingDivisionStockListComponent } from './InventoryManagement/KnittingDivisionStockList/KnittingDivisionStockList.component';
import { CostEstimationComponent } from './Costing/Estimation/Add/costEstimationList.component';
import { CostEstimationListComponent } from './Costing/Estimation/List/costEstimationList.component';
import { CostEstimationListPrintComponent } from './Costing/Estimation/PrintList/costEstimationListPrint.component';
import { CostEstimationPrintComponent } from './Costing/Estimation/Print/costEstimationPrint.component';
import { SalesOrderTrailsReportComponent } from './SalesOrder/SalesOrder/SalesOrderTrailsReport/SalesOrderTrailsReport.component';
import { OverheadCostComponent } from './Costing/Overhead/OverheadCost.component';
import { ManufacturingHeartbeatReportComponent } from './Reports/ManufacturingHeartbeatReport/ManufacturingHeartbeatReport.component';
import { BarcodeLabelPrintComponent } from './Features/BarcodeLabelManagement/components/barcodelabelprint/barcodelabelprint.component';
import { StockQualityInspectionComponent } from './InventoryManagement/StockQualityInspection/StockQualityInspection.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/start' },
  { path: '', pathMatch: 'full', redirectTo: '/welcome' },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'stocklist',
        component: StockListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockinspectionlist',
        component: StockListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockallocationlist',
        component: StockListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockqualityinspectionlist',
        component: StockListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'productwiseStock',
        component: ProductWiseStockComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'storewisestock',
        component: StoreWiseStockComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockmanagerejectedlist',
        component: StockListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockqualityinspection/:id',
        component: StockQualityInspectionComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockinspection/:id',
        component: StockInspectionComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockproductallocation/:id',
        component: StockProductAllocationComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockmanagerejecteditems/:id',
        component: StockManageRejectedItemsComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stockdetails/:id',
        component: StockDetailsComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'updatestock/:id',
        component: OpeningStockComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'addstock',
        component: AddStockComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'knittingdivisionstocklist',
        component: KnittingDivisionStockListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'stock',
        component: StockComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'openingstock',
        component: OpeningStockComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'gatein',
        component: GateInComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'gateout',
        component: GateOutComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'gatepass',
        component: GatePassComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'outpass/add',
        component: OutPassAddComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'outpass/list',
        component: OutPassListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'outpasspurpose',
        component: OutPassPurposeComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'productlist',
        component: ProductListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'supplierlist',
        component: SupplierListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'transportlist',
        component: TransportManagementComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'branchlist',
        component: BranchComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'BankDetailslist',
        component: BankDetailsComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'thicknesslist',
        component: ThicknessComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'widthlist',
        component: WidthComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'departmentlist',
        component: DepartmentComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'designation',
        component: DesignationComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'factoryworkers',
        component: FactoryWorkersComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'colorlist',
        component: ColorComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'elementlist',
        component: ElementComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'grainlist',
        component: GrainComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'taglist',
        component: TagComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'storelist',
        component: StoreComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'racklist',
        component: RackComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'productcategorylist',
        component: ProductCategoryComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'productfirstsubcategorylist',
        component: ProductFirstSubCategoryComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'productsecsubcategorylist',
        component: ProductSecSubCategoryComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'paymenttermlist',
        component: PaymentTermComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'deliverytermlist',
        component: DeliveryTermComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'po/add',
        component: PurchaseorderComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'po/:type/:id',
        component: PurchaseorderComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'po/list',
        component: POListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'po/demandlist',
        component: DemandListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'salesorder/proformaInvoice/add',
        component: proformaAddComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'costing/estimation/add',
        component: CostEstimationComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'costing/estimation/add/:id',
        component: CostEstimationComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'costing/estimation/list',
        component: CostEstimationListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'salesorder/trailreport/:id',
        component: SalesOrderTrailsReportComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'salesorder/proformaInvoice/:type/:id',
        component: proformaAddComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'salesorder/proformaInvoice/list',
        component: proformaListComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'quicktools/measurementconversion-new',
        component: MeasurementConversionNewComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'measurementconversion',
        component: MeasurementConversionComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'measurementunit',
        component: MeasurementUnitComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'customerlist',
        component: CustomerListComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'salesorder/add',
        component: PresalesorderComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'salesorder/list',
        component: SaleslistComponent,
        canActivate: [ExpenseGuard],
      },
      // {
      //   path: 'salesorder/edit/:id',
      //   component: PresalesorderComponent,
      //   canActivate: [ExpenseGuard],
      // },
      {
        path: 'salesorder/:type/:id',
        component: PresalesorderComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/formulationcode/add',
        component: FormulationcodeComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/formulationcode/edit/:id',
        component: FormulationcodeComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/formulationcode/list',
        component: FormulationCodeListComponent,
        canActivate: [ExpenseGuard],
      },
      {

        path: 'production/start',
        component: StartProductionComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/postprocess',
        component: PostprocessComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/postprocess/lamination',
        component: LinkSaleOrderComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/dispatch',
        component: DispatchComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'dispatch/packaging/add',
        component: PackagingComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'dispatch/packaging/:type/:id',
        component: PackagingComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'dispatch/packaging/list',
        component: PackagingListComponent,
        canActivate: [ExpenseGuard],
      },
      {

        path: 'production/finalInspection/add', component: FinalInspectionComponent, canActivate: [ExpenseGuard],
      },
      {

        path: 'production/finalInspection/add/:JumboNo', component: FinalInspectionComponent, canActivate: [ExpenseGuard],
      },
      {
        path: 'production/finalInspection/list', component: FinalInspectionList, canActivate: [ExpenseGuard],
      },
      {
        path: 'production/jumbo/add',
        component: JumboMasterComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/jumbo/list',
        component: JumboListComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'production/workplan',
        component: WorkplanListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/ActivityLog',
        component: ActivityLogComponent,
        canActivate: [ExpenseGuard],
      },


      {
        path: 'jumbomaster',
        component: JumboMasterComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'newmixing/add',
        component: NewmixingComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'production/embossinglist',
        component: EmbossingListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/postprocessprint',
        component: PostprocessprintComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/vaccumlist',
        component: VaccumListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/tumblinglist',
        component: TumblingListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/lacquerlist',
        component: LacquerListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'issue/add',
        component: IssueComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'issue/list',
        component: IssueListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'costing/add',
        component: CostingComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'costing/list',
        component: CostingListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'costing/overhead',
        component: OverheadCostComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'admin/users',
        component: AdminComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'admin/operations',
        component: ResponsibilityMasterComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'admin/rolemaster',
        component: RolesMasterComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'admin/generalconfig',
        component: GeneralConfigurationComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'admin/usersloginhistory',
        component: UsersLoginHistoryComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'process/add',
        component: ProcessComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'process/add/:id',
        component: ProcessComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'process/list',
        component: ProcessListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/mixing/add',
        component: MixingComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/mixing/add/:id',
        component: MixingComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/mixing/list',
        component: MixingListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'consumption/add',
        component: ConsumptionComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'consumption/add/:workplanid/:saleorderid/:consumedorder',
        component: ConsumptionComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'consumption/list',
        component: ConsumptionListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'consumption/pendingorders',
        component: ConsumptionPendingListComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'production/pigmentmb/add',
        component: MBFormulationComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'digitalWeightMachine',
        component: DigitalWeightComponent,
        canActivate: [ExpenseGuard],
      },

      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        canActivate: [ExpenseGuard],
      },
      {
        path: 'reports',
        //component: WithSupplierProductWiseStockReportComponent,
        children: [
          {
            path: 'productwisestockwithsupplier',
            component: WithSupplierProductWiseStockReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'purchase',
            component: PurchaseReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'productstockhistory',
            component: ProductStockHistoryReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'categorywisestock',
            component: CategoryWiseStock,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'yieldreport',
            component: YieldReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'wastagereport',
            component: WastageReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'productionplanningreport',
            component: ProductionPlanningReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'postprocessreport',
            component: PostProcessReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'productstocksummaryreport',
            component: ProductStockSummaryReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'productionstatusreport',
            component: ProductionStatusReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'pasteconsumptionreport',
            component: PasteConsumptionReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'salesreport',
            component: SalesReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'stockconsumptionreport',
            component: ConsumptionReportComponent,
            canActivate: [ExpenseGuard],
          },
          {
            path: 'manufacturingheartbeat',
            component: ManufacturingHeartbeatReportComponent,
            canActivate: [ExpenseGuard],
          }

        ]
      },
      {
        path: 'welcome',
        loadChildren: () =>
          import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
      },
    ],
  },
  {
    path: 'login',
    component: AuthComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'outpass/print/:id',
    component: OutPassPrintComponent,
    canActivate: [ExpenseGuard],
  },
  {
    path: 'jumboprint/:id/:JumboId',
    component: JumboPrintComponent,
  },
  {
    path: 'inspectionprint/:id/:JumboId/:InspectionId',
    component: InspectionPrintComponent,
  },
  {
    path: 'inspectionallprint/:id/:JumboId',
    component: InspectionAllPrintComponent,
  },
  {
    path: 'production/formulationprint/:id',
    component: FormulationPrintComponent
  },
  {
    path: 'production/mixingprint/:id',
    component: MixingprintComponent,
  },
  {
    path: 'inspectiondetailsprint/:id',
    component: InspectionDetailsPrintComponent,
  },
  {
    path: 'production/processprint/:id',
    component: ProcessprintComponent,
  },

  {
    path: 'gatepassprint/:id',
    component: GatepassprintComponent,
  },
  {
    path: 'poprint/:id',
    component: POprintComponent,
  },
  {
    path: 'poemail/:id',
    component: POEmailComponent,
  },
  {
    path: 'dispatchpackagingemail/:id',
    component: PackagingListEmailComponent,
  },
  {
    path: 'proformaPrint/:id',
    component: proformaPrintComponent,
  },
  {
    path: 'packinglistprint/:id',
    component: PackingListPrintComponent,
    canActivate: [ExpenseGuard],
  },
  {
    path: 'costinglistprint',
    component: CostingListPrintComponent,
    canActivate: [ExpenseGuard],
  },
  {
    path: 'costestimationlistprint',
    component: CostEstimationListPrintComponent,
    canActivate: [ExpenseGuard],
  },
  {
    path: 'costestimationprint/:id',
    component: CostEstimationPrintComponent,
    canActivate: [ExpenseGuard],
  },
  {
    path: 'ordercostingprint/:id/:type',
    component: SingleOrderCostingPrint,
    canActivate: [ExpenseGuard],
  },
  {
    path: 'welcome',
    component: StartscreenComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'issueslipprint/:id',
    component: IssueSlipPrintComponent,
  },
  {
    path: 'barcodelabel/:StockProductId/:StockId/:ProductId/:NumberOfLabels/:action',
    component: BarcodeLabelPrintComponent,
  },
  {
    path: 'barcodelabel/:action',
    component: BarcodeLabelPrintComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
  })],
  exports: [RouterModule],
})
export class PmsUIAppRoutingModule { }
