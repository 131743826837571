<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Stock Quality Inspection</nz-page-header-title>
      <nz-page-header-subtitle>Inspect your Stocks Quality details here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>

    <nz-descriptions nzBordered>
      <nz-descriptions-item nzTitle="Supplier Name">{{NewStock.Invoice.SupplierName}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Stock Date"> {{NewStock.StockDate | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
    </nz-descriptions>

    <br><br>
    <nz-table [nzPageSize]="100" #basicTable [nzData]="this.NewStock.StockProduct">
      <thead>
        <tr>
          <th></th>
          <th>Product Type</th>
          <th>Product Name</th>
          <th>Unit</th>
          <th>Quantity</th>
          <th>Grade</th>
          <th>Accepted Qty</th>
          <th style="width: 8%;">Rejected Qty</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-data [ngForOf]="basicTable.data">
          <tr>
            <td [(nzExpand)]="data.ExpandRecord"></td>
            <td>{{ data.ProductType }}</td>
            <td>{{ data.ProductName }}</td>
            <td>{{data.Unit}}</td>
            <td>{{data.Quantity}}</td>
            <td>{{data.Grade}}</td>
            <td>{{data.AcceptedQuantity}}</td>
            <td>
              <input nz-input *ngIf="!this.NewStock.IsQualityInspectionCompleted" (blur)="rejectedchange(data)"
                [(ngModel)]="data.RejectedQuantity" />
              <span *ngIf="this.NewStock.IsQualityInspectionCompleted" class="fw-bolder">{{data.RejectedQuantity}}</span>
            </td>
            <td>
              <input nz-input *ngIf="!this.NewStock.IsQualityInspectionCompleted" [(ngModel)]="data.Comments" />
              <span *ngIf="this.NewStock.IsQualityInspectionCompleted" class="fw-bolder">{{data.Comments}}</span>
            </td>
          </tr>
          <tr [nzExpand]="data.ExpandRecord">
            <table style="table-layout: auto; background: white; width: 80%;" ng-reflect-list-of-col-width=",,,20%">
              <thead class="ant-table-thead ng-star-inserted">
                <tr class="ant-table-row ng-star-inserted">
                  <th style="background: aliceblue;" class="ant-table-cell">File Upload</th>
                  <th style="background: aliceblue;" class="text-end ant-table-cell">
                    <a nz-button nzSize="small" nzType="primary" class="btn btn-light-success"
                      (click)="data.ExpandRecord = false">Save Draft</a>
                  </th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody ng-star-inserted">
                <tr *ngIf="data.NewRecord" class="ant-table-thead ng-star-inserted">
                  <td>
                    <div class="row mb-4" *ngIf="!this.NewStock.IsQualityInspectionCompleted">
                      <div class="col-md-12">
                        <nz-upload [nzMultiple]="true" [nzLimit]="5"
                          [nzFileList]="stockProductFiles[data.StockProductId] || []"
                          (nzFileListChange)="updateFileList($event, data.StockProductId)"
                          [nzBeforeUpload]="beforeUploadFactory(data.StockProductId)"
                          [nzAccept]="'.jpg,.jpeg,.png,.pdf'"
                          [nzShowUploadList]="{ showPreviewIcon: true, showRemoveIcon: true }">
                          <button nz-button>
                            <i nz-icon nzType="upload"></i>
                            Upload Inspection Reports & Images
                          </button>
                        </nz-upload>
                        <div *ngIf="getUploadProgress(data.StockProductId).length > 0" class="mt-3">
                          <div *ngFor="let progress of getUploadProgress(data.StockProductId)" class="mb-2">
                            <span>{{progress.filename}}</span>
                            <nz-progress [nzPercent]="progress.progress"></nz-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </tr>
        </ng-template>

      </tbody>
    </nz-table>
    <br>
    <div class="d-flex justify-content-end">
      <!--begin::Button-->
      <a routerLink="/home/stockqualityinspectionlist" class="btn btn-success">Back to Stock Inspection List</a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button type="submit" id="kt_ecommerce_add_product_submit"
        *ngIf="!this.NewStock.IsQualityInspectionCompleted && this.permission.Add" (click)="SaveStockInspection()"
        class="btn btn-primary">
        <span class="indicator-label">Save Inspection</span>
        <span class="indicator-progress">Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
      </button>
      <p *ngIf="!this.permission.Add" style="color: red;"><b>You don't have permission to complete Stock Inspection</b>
      </p>
      <!--end::Button-->
    </div>
  </div>
</div>