import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductModel } from '../../../Models/ProductModel';
import { LinkedSaleOrderModel, SaleOrderCostingModel, SaleOrderModel, SaleOrderProductionEmbossingModel, SaleOrderProductionModel, SaleOrderProductionPrintModel, SaleOrderProductionTumblingModel, SaleOrderProductionVacuumModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import {
    WorkPlanMasterModel,
} from 'src/PmsUIApp/Models/WorkPlanModel';
import { UserInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { LoadingService } from '../../../Services/loadingService';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ESalesOrderStatus, Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../../Services/auth.service';
import { FormulationCodeModel } from '../../../Models/FormulationCodeModel';
import { CustomerModel } from '../../../Models/SupplierModel';
import { ColorModel, GrainModel, PostProcessCostingMasterModel } from 'src/PmsUIApp/Models/MasterModel';
import moment from 'moment';
import { DatetimeConverter } from '../../../Services/DatetimeConverter.pipe';
import { TimelineService } from '../../../Services/TimelineService';
import { ProductionLacquerModel } from '../../../Models/ProductionModel';
import { SoDrawerService } from '../../../Services/SoDrawerService';

@Component({
    selector: 'app-saleslist',
    templateUrl: './saleslist.component.html',
    styleUrls: ['./saleslist.component.css'],
})
export class SaleslistComponent implements OnInit {
    validateForm!: UntypedFormGroup;
    ApiUrl = environment.Api_Url;
    SalesList: SaleOrderModel[] = [];
    GSMUpdateList: SaleOrderModel;
    SaleOrderGSMUpdate: SaleOrderModel = new SaleOrderModel;
    WorkPlanList: any[] = [];
    FilteredList: any[] = [];
    NewSaleOrder: any;
    SelectedPostProcess: any[] = [];
    postProcessArray: any = [];
    PrintMasterList: any = [];
    EmbossingList: any = [];
    TumblingList: any = [];
    LacquerList: any = [];
    VaccumList: any = [];
    PopUpTitle2: string = "Update Post Process"
    SaleOrderPostProcessOrder =
        {
            PrintProcess: 0,
            EmbossingProcess: 0,
            LacquerProcess: 0,
            VacuumProcess: 0,
            TumblingProcess: 0
        }
    isVisiblePopUp = false;
    showProcess: boolean = false;
    PostProcess: string[] = [
        "Print", "Embossing", "Lacquar", "Vaccum", "Tumbling"
    ]
    TimeLineObject: any;
    searchValue = '';
    NewWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel();
    SalesListOriginal: SaleOrderModel[] = [];
    Orderlist!: SaleOrderModel;
    NewOrderProductItem: any = {
        CategoryID: 0,
        FirstCategoryID: 0,
        SecondCategoryID: 0,
        ProductId: 0,
        ColorId: 0,
        Width: '',
        Thick: '',
        Unit: '',
        Rate: '',
        Quantity: '',
        Grade: '',
    };
    isVisible = false;
    isTimelineVisible = false;
    isVisible1 = false;
    isVisibleCosting = false;
    havePostProcess = false;
    isLoading: boolean = false;
    isLoading1: boolean = false;
    isTableLoading: boolean = false;
    TotalCoating: number = 0;
    TotalCostLm: number = 0;
    TotalLaquerPrice: number = 0;
    Rejection: number = 0;
    InlineScraping: number = 0;
    RejectionConstant: number = 6;
    OverheadCost: number = 25;
    PerLMConstant: number = 1.45;
    ProductionCostLm: number = 0;
    TotalCostPerLm: number = 0;
    typeList: any[] = [];
    PopUpTitle: string = 'Add New Order';
    MaterialType: string = 'PVC';
    NameError = 'Enter Order name';
    ProductList: ProductModel[] = [];
    MeasureUnits: MeasureUnitModel[] = [];
    DefaultDate = new Date().toUTCString();
    selectedProduct = 0;
    PopUpTitle1: string = 'Add New Work Plan';
    orderTypeList: any = [
        'Sample',
        'Trial',
        'Product',
        'Job Work'
    ];
    SaleOrderStatusList: any = [
        'Approval Pending',
        'Active',
        'Hold'
    ];
    PUPVCTypeList: any = [
        {
            "Text": "GZ(PVC)",
            "Value": 'GZ-'
        },
        {
            "Text": "GZY(PU)",
            "Value": 'GZY-'
        }
    ]
    SelectedSaleOrderStatusEnum: ESalesOrderStatus = 0;
    addedBy: any = [];
    status: any = [
        {
            "Text": "NotYet",
            "Value": 0
        },
        {
            "Text": "WorkPlan",
            "Value": 1
        },
        {
            "Text": "Inspection",
            "Value": 2
        },
        {
            "Text": "RawMaterialRequested",
            "Value": 3
        },
        {
            "Text": "RawMaterialIssued",
            "Value": 4
        },
        {
            "Text": "ProductionStarted",
            "Value": 5
        },
        {
            "Text": "Mixing",
            "Value": 6
        },
        {
            "Text": "InJumbo",
            "Value": 7
        },
        {
            "Text": "MoveToPostProcess",
            "Value": 8
        },
        {
            "Text": "PrintAssigned",
            "Value": 9
        },
        {
            "Text": "PrintInProcess",
            "Value": 10
        },
        {
            "Text": "PrintCompleted",
            "Value": 11
        },
        {
            "Text": "EmbossingAssigned",
            "Value": 12
        },
        {
            "Text": "EmbossingInProcess",
            "Value": 13
        },
        {
            "Text": "EmbossingCompleted",
            "Value": 14
        },
        {
            "Text": "VacuumAssigned",
            "Value": 15
        },
        {
            "Text": "VacuumInProcess",
            "Value": 16
        },
        {
            "Text": "VacuumCompleted",
            "Value": 17
        },
        {
            "Text": "LacquerAssigned",
            "Value": 18
        },
        {
            "Text": "LacquerInProcess",
            "Value": 19
        },
        {
            "Text": "LacquerCompleted",
            "Value": 20
        },
        {
            "Text": "TumblingAssigned",
            "Value": 21
        },
        {
            "Text": "TumblingInProcess",
            "Value": 22
        },
        {
            "Text": "TumblingCompleted",
            "Value": 23
        },
        {
            "Text": "JumboInspection",
            "Value": 24
        },
        {
            "Text": "MoveToDispatch",
            "Value": 25
        },
        {
            "Text": "PartialDispatchReady",
            "Value": 26
        },
        {
            "Text": "PartialDispatchCompleted",
            "Value": 27
        },
        {
            "Text": "DispatchReady",
            "Value": 28
        },
        {
            "Text": "DispatchCompleted",
            "Value": 30
        },
        {
            "Text": "LiningOrderMerged",
            "Value": 101
        }
    ];

    userList: any[] = [];

    salesOrderNo: any;
    orderDateFrom: any;
    orderDateTo: any;
    addedBySelect: any = '';
    CustomerName: any;
    ManufacturingProductName: any;
    SaleFormulationCode: any;
    SaleOrderType: any;
    PUPVCType: any = 'All';
    SaleOrderNumber: any;
    AddedBy: any;
    Status: any;
    SaleOrderDate: any;
    DeliveryDate: any;

    exportoptions = {
        headers: [
            'SaleOrderType',
            'SaleOrderNumber',
            'Delivery Date',
            'AddedBy',
            'SaleOrderDate',
            'Status',
        ],
    };

    fields: any;
    exportfields = [
        {
            SaleOrderType: '',
            SaleOrderNumber: '',
            Status: '',
            DeliveryDate: '',
            AddedBy: '',
            SaleOrderDate: '',
        },
    ];
    showPlanBtn: boolean = false;
    WorkPlanNo: string = '';
    LotNo: string = '';
    ProductionDetails: string = '';
    BatchNo: string = '';
    WorkPlanDate: string = '';
    showsearch: boolean = false;
    data: any;
    dataCopy: any;
    orderType: any;
    statusType: any;
    selectedAddedBy: any;
    permission = {
        View: false,
        Add: false,
        Edit: false,
        Delete: false,
        Manage: false,
        Workplan: false,
        Costing: false,
        Approval: false
    }
    CostingPermission = {
        View: false,
        Add: false,
        Delete: false,
        Manage: false
    }
    count: number;
    CustomerList: CustomerModel[] = [];
    FormulationCodeList: FormulationCodeModel[] = [];
    ArticleNameList: string[];
    ColorList: any;
    ColorName: any;
    GrainList: any;
    PostProcessCostingList: PostProcessCostingMasterModel[] = [];
    GrainName: any;
    request = {
        FromAddedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
        ToAddedDate: new Date(new Date().setHours(23, 59, 59)),
        FromSaleOrderDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
        ToSaleOrderDate: new Date(new Date().setHours(23, 59, 59)),
        FromDeliveryDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
        ToDeliveryDate: new Date(new Date().setHours(23, 59, 59)),
        SaleFormulationCodeId: 0,
        SaleOrderNumber: null,
        DateType: 'addeddate', // options are - addeddate, saleorderdate, deliverydate
        Status: null,
        AddedBy: '',
        ArticleName: '',
        CustomerId: 0,
        GrainId: 0,
        ColorId: 0,
        ProductType: '', //options are - GZ- or GZY-
        OrderType: '',
        SaleOrderStatus: '',
        WorkPlanNo: '',
    }

    dateTypeList = [
        {
            "Text": "Added Date",
            "Value": 'addeddate'
        },
        {
            "Text": "Sale Order Date",
            "Value": 'saleorderdate'
        },
        {
            "Text": "Delivery Date",
            "Value": 'deliverydate'
        }];
    totalItemsCount: number = 0;
    FabricProductCostPerLm: number = 0;
    ActivityLogList: any;
    refreshBtnLoading: boolean;
    FabricName: string;
    FabricColorName: string;
    IsTimelineOpen = false;
    IsGSMUpdateVisible: boolean = false;
    WorkPlanPopupAction: string = 'new';
    SelectedOrderForWorkPlanChange: number = 0;
    constructor(
        private fb: UntypedFormBuilder,
        public http: HttpClient,
        private alertService: AlertMessageService,
        private modalService: NzModalService,
        private loader: LoadingService,
        private router: Router,
        private auth: AuthService,
        private datePipe: DatetimeConverter,
        private timeLine: TimelineService,
        private soDrawer: SoDrawerService
    ) { }

    ngOnInit() {
        const existingFilters = sessionStorage.getItem('saleorderrequestfilters');
        if (existingFilters) {
            this.request = JSON.parse(existingFilters);
        }
        this.GetAllPrint();
        this.GetAllEmbossing();
        this.GetAllTumbling();
        this.GetAllLacquer();
        this.GetAllVaccum();
        this.permission.View = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Add);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Delete);
        this.permission.Manage = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Manage);
        this.permission.Edit = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Edit);
        this.permission.Approval = this.auth.CheckResponsibility(Modules.SalesOrder, Responsibility.Approval);
        this.permission.Workplan = this.auth.CheckResponsibility(Modules.Workplan, Responsibility.Add);
        this.CostingPermission.View = this.auth.CheckResponsibility(Modules.Costing, Responsibility.View);
        this.CostingPermission.Add = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Add);
        this.CostingPermission.Manage = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Manage);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.validateForm = this.fb.group({
            CustomerId: [null, [Validators.required]],
            SaleOrderNo: [null, [Validators.required]],
            OrderType: [null, [Validators.required]],
            OrderCreationDate: [null, [Validators.required]],
            DeliveryDate: [null, [Validators.required]],
        });
        this.GetAllCustomer();
        this.GetAllFormulationCode();
        this.GetFilteredSalesOrder();
        this.GetAllColor();
        this.GetAllGrain();
    }
    space(el: any) {
        if (el.target.selectionStart === 0 && el.code === "Space") {
            el.preventDefault();
        }
    }
    onFilterChange() {
        sessionStorage.setItem('saleorderrequestfilters', JSON.stringify(this.request));
    }
    onSearch() {
        this.GetFilteredSalesOrder();

    }

    onReset() {
        this.isTableLoading = true;
        this.SaleOrderType = '';
        this.SaleOrderNumber = '';
        this.Status = '';
        this.AddedBy = '';
        this.SaleOrderDate = '';
        this.DeliveryDate = '';
        this.CustomerName = ''
        this.ManufacturingProductName = ''
        this.SaleFormulationCode = '';
        this.ColorName = '';
        this.GrainName = '';
        this.onSearch();
        this.isTableLoading = false;
    }
    export() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        if (this.exportfields.length > 0)
            new AngularCsv(
                this.exportfields,
                'sale-Order-list-export' + exportdate,
                this.exportoptions
            );
    }
    get f() {
        return this.validateForm.controls;
    }

    validateIssue(): boolean {
        var IsValid = true;
        var CheckCount = this.SalesList.filter((x) => x.IsChecked == true).length;
        if (CheckCount == 0) {
            this.alertService.error('Select Order');
            return false;
        } else {
            this.SalesList.filter((x) => x.IsChecked).forEach((x) => { });
            return IsValid;
        }
    }

    showModalwpl(action: string, data?: any): void {
        if (action == 'change') {
            this.PopUpTitle1 = 'Change WorkPlan : ' + data.SaleOrderNumber;
            this.WorkPlanPopupAction = 'change';
            this.SelectedOrderForWorkPlanChange = data.SaleOrderId;
        }
        else {
            this.PopUpTitle1 = 'Add New Work Plan';
        }
        this.NewWorkPlan.WorkPlanDate = new Date().toString();
        this.isVisible1 = true;
    }
    showSearchSection(): void {
        this.showsearch = !this.showsearch;
    }
    saveWorkPaln(): void {
        if (this.NewWorkPlan.ProductionLineNo == "") {
            this.alertService.error("Please select Production Line to assign this order correctly.");
            return;
        }
        else if (this.NewWorkPlan.WorkShift == "") {
            this.alertService.error("Please select correct Shift Slot to assign it to correct planning.");
            return;
        }
        this.loader.show();
        this.NewWorkPlan.WorkPlanDate =
            this.NewWorkPlan.WorkPlanDate.toString().substr(0, 10);
        this.NewWorkPlan.WorkPlanOrder = this.WorkPlanList;
        this.NewWorkPlan.AddedBy = UserInfo.EmailID;
        let url = this.ApiUrl + 'workplan/addworkplanwithorders';
        this.http.post<WorkPlanMasterModel>(url, this.NewWorkPlan).subscribe({
            next: (res) => {
                this.alertService.success('WorkPlan Saved Successfully');
                this.isLoading = false;
                this.showPlanBtn = false;
                this.GetFilteredSalesOrder();
                this.loader.hide();
            },
            error: (res) => {
                this.alertService.error(res.error);
                this.isLoading = false;
                this.GetFilteredSalesOrder();
                this.loader.hide();
            },
        });
    }
    ChangeWorkPlan(): void {
        if (this.NewWorkPlan.ProductionLineNo == "") {
            this.alertService.error("Please select Production Line to assign this order correctly.");
            return;
        }
        else if (this.NewWorkPlan.WorkShift == "") {
            this.alertService.error("Please select correct Shift Slot to assign it to correct planning.");
            return;
        }
        var selectedOrders = this.SalesList.filter(item => item.SaleOrderId == this.SelectedOrderForWorkPlanChange).map(
            (finalResult) => {
                return {
                    OrderId: finalResult.SaleOrderId,
                };
            }
        );
        this.WorkPlanList = selectedOrders;
        this.loader.show();
        this.NewWorkPlan.WorkPlanDate =
            this.NewWorkPlan.WorkPlanDate.toString().substr(0, 10);
        this.NewWorkPlan.WorkPlanOrder = this.WorkPlanList;
        let url = this.ApiUrl + 'workplan/changeworkplanforsingleorder';
        this.http.post<any>(url, this.NewWorkPlan).subscribe({
            next: (res) => {
                this.alertService.success(res.Result.Message);
                this.isLoading = false;
                this.showPlanBtn = false;
                this.GetFilteredSalesOrder();
                this.NewWorkPlan = new WorkPlanMasterModel();
                this.WorkPlanPopupAction = 'new';
                this.loader.hide();
            },
            error: (res) => {
                this.alertService.error(res.error);
                this.isLoading = false;
                this.GetFilteredSalesOrder();
                this.loader.hide();
            },
        });
    }
    handleOk1(action: string): void {
        this.isLoading1 = true;
        if (action == 'new') {
            this.saveWorkPaln();
        }
        else {
            this.ChangeWorkPlan();
        }
        setTimeout(() => {
            this.isVisible1 = false;
            this.isLoading1 = false;
        }, 3000);
    }
    handleCancel1(): void {
        this.isVisible1 = false;
        this.WorkPlanPopupAction = 'new';
    }

    add() {
        var selectedOrders = this.SalesList.filter((item) => item.IsChecked).map(
            (finalResult) => {
                return {
                    OrderId: finalResult.SaleOrderId,
                };
            }
        );
        this.WorkPlanList = selectedOrders;
        if (this.WorkPlanList.length > 0) {
            this.showPlanBtn = true;
        } else {
            this.showPlanBtn = false;
        }
    }

    GetFilteredSalesOrder() {
        if (this.request.CustomerId == null) {
            this.request.CustomerId = 0;
        }
        if (this.request.SaleFormulationCodeId == null) {
            this.request.SaleFormulationCodeId = 0;
        }
        if (this.request.ColorId == null) {
            this.request.ColorId = 0;
        }
        if (this.request.GrainId == null) {
            this.request.GrainId = 0;
        }
        this.isTableLoading = true;
        let url = this.ApiUrl + 'saleorder/getallsaleorderswithfilter';
        this.http.post<SaleOrderModel[]>(url, this.request).subscribe(
            (res) => {
                this.SalesList = res;
                this.SalesListOriginal = res;
                this.FilteredList = res;
                this.isTableLoading = true;

                let searchKeys: any = [];
                if (this.SaleOrderType) {
                    searchKeys.push('SaleOrderType');
                }

                if (this.CustomerName) {
                    searchKeys.push('CustomerName');
                }

                if (this.ColorName) {
                    searchKeys.push('ColorName');
                }
                if (this.GrainName) {
                    searchKeys.push('GrainName');
                }

                const here: any = this;

                this.isTableLoading = false;
                let count = this.totalItemsCount = 0;
                console.log(` this.SalesListOriginal`, this.SalesListOriginal)
                this.exportfields = [];
                this.SalesListOriginal.forEach((x) => {
                    count++;
                    x.SerialNo = count;
                    this.fields = {};
                    this.fields.SaleOrderType = x.SaleOrderType;
                    this.fields.SaleOrderNumber = x.SaleOrderNumber;
                    this.fields.DeliveryDate = x.DeliveryDate;
                    this.fields.AddedBy = x.AddedBy;
                    this.fields.SaleOrderDate = x.SaleOrderDate
                    this.fields.Status = x.Status;
                    this.exportfields.push(this.fields);
                });
                this.totalItemsCount = count;
                this.isTableLoading = false;
            },
            (res) => { }
        );
    }
    GetAllCustomer() {

        let url = this.ApiUrl + "customer/getallcustomers";
        this.http.get<CustomerModel[]>(url).subscribe(res => {
            this.CustomerList = res;


        }, res => {
            this.count++;
            if (this.count < 2) { this.GetAllCustomer(); }
        });
    }
    GetAllFormulationCode() {
        let url = this.ApiUrl + "saleorder/getallformulationcodes";
        this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
            this.FormulationCodeList = res;
        }, res => {
            this.count++;
            if (this.count < 2) { this.GetAllFormulationCode(); }
        });
    }
    GetAllColor() {
        this.isTableLoading = true;
        let url = this.ApiUrl + "Color/getallColors";
        this.http.get<ColorModel[]>(url).subscribe(res => {
            this.ColorList = res;
            this.isTableLoading = false;
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllColor();
            }
        });
    }
    GetAllGrain() {
        this.isTableLoading = true;
        let url = this.ApiUrl + "Grain/getallGrains";
        this.http.get<GrainModel[]>(url).subscribe(res => {
            this.GrainList = res;
            this.isTableLoading = false;
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllGrain();
            }
        });
    }

    OpenCostingPop(data: any) {
        this.loader.show();
        this.isVisibleCosting = true;
        this.GetPostProcessCosting();
        this.GetSaleOrderDetails(parseInt(data.SaleOrderId))
    }
    GetPostProcessCosting() {
        let url = this.ApiUrl + "saleorder/getpostprocesscosting";
        this.http.get<PostProcessCostingMasterModel[]>(url).subscribe(res => {
            this.PostProcessCostingList = res;
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetPostProcessCosting();
            }
        });
    }

    GetSaleOrderDetails(SaleOrderId: number) {
        this.InlineScraping = 0;
        this.havePostProcess = false
        let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + SaleOrderId;
        this.http.get<any>(url).subscribe(res => {

            this.Orderlist = res;
            this.calclulateTotalSalePrice()
            this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
            this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
            this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
            if (this.Orderlist.SaleOrderCosting != null) {
                this.RejectionConstant = this.Orderlist.SaleOrderCosting.Rejection;
                this.InlineScraping = this.Orderlist.SaleOrderCosting.InlineScraping;
                this.PerLMConstant = this.Orderlist.SaleOrderCosting.PerLmconstant;
                this.OverheadCost = this.Orderlist.SaleOrderCosting.OverheadCost;
            }
            else {
                this.RejectionConstant = this.Orderlist.SaleOrderProduction.RejectionPercentAutoCalculated;
            }

            if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null || this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null ||
                this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null || this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null ||
                this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null) {
                this.havePostProcess = true;
            }

            this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach((element) => {

                this.Orderlist.SaleOrderProduction.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
            });
            this.Orderlist.SaleOrderProduction.MixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
            // this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
            //   this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(element.Total) ? 0 : element.Total;
            //   this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(parseInt(element.WeightGsm?.toString())) ? 0 : parseInt(element.WeightGsm.toString());
            // })
            this.TotalCostLm = 0
            this.Orderlist.SaleOrderProduction.InspectionFormulationMixing.forEach(element => {
                if (element.MixingName != "PRE SKIN") {
                    this.TotalCoating += element.GSM;
                }
                element.TotalQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
                element.TotalCost = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0
                let clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.GSM;
                element.CostPerLm = isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
                this.TotalCostLm = parseFloat((this.TotalCostLm + element.CostPerLm).toFixed(2));
            })
            this.TotalLaquerPrice = 0
            this.Orderlist.SaleOrderProduction.Lacquer.forEach(element => {
                element.TotalQuantity = parseFloat((element.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0;
                element.TotalPerUnitPrice = parseFloat(element.LacquerRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0;
                this.TotalLaquerPrice = this.TotalLaquerPrice + element.TotalPerUnitPrice;
            });
            if (this.Orderlist.FormulationFabricProductId != null) {
                this.calculateFabricProductCostPerLm();
            }
            this.Rejection = parseFloat(((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice) * (this.RejectionConstant / 100)).toFixed(2));
            this.calclulatePostProesscost();
            let finishcost = this.calculatefinishingcost();
            this.ProductionCostLm = parseFloat((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice + this.Rejection + parseFloat(finishcost)).toFixed(2));
            this.TotalCostPerLm = parseFloat((this.ProductionCostLm + this.OverheadCost).toFixed(2));
            this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingGSMTotal + this.Orderlist.SaleOrderProduction.RawGSMTotal;
            var totalprint = this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalprint);
            var totalEmbossing = this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalEmbossing);
            var totalVaccum = this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalVaccum);
            var totalTumbling = this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalTumbling);
            //var totalLacquer = this.Orderlist.SaleOrderProduction?.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            //console.log(totalLacquer);
            var totalElement = this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(isNaN(totalElement) ?? 0);
            var totalRaw = this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce((sum, current) => sum + parseFloat(current.TotalCost?.toString()), 0) ?? 0
            console.log(totalRaw);
            this.Orderlist.GrandTotal = parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) + parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
                parseFloat(isNaN(totalprint) == true ? "0" : totalprint.toString()) +
                parseFloat(isNaN(totalEmbossing) == true ? "0" : totalEmbossing.toString()) +
                parseFloat(isNaN(totalVaccum) == true ? "0" : totalVaccum.toString()) +
                parseFloat(isNaN(totalTumbling) == true ? "0" : totalTumbling.toString()) +
                /*parseFloat(isNaN(totalLacquer) == true ? "0" : totalLacquer.toString()) +*/
                parseFloat(isNaN(totalElement) == true ? "0" : totalElement.toString()) +
                parseFloat(totalRaw.toString());
            this.loader.hide();
        });


    }

    calclulatePostProesscost() {
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null) {
            let perunitcost = this.PostProcessCostingList.filter(x => x.PostProcessName == "Print" && x.SaleOrderType == this.MaterialType)[0].Cost;
            // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCompletedQuantity * perunitcost;
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost = perunitcost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null) {
            let perunitcost = this.PostProcessCostingList.filter(x => x.PostProcessName == "Embossing" && x.SaleOrderType == this.MaterialType)[0].Cost;
            // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCompletedQuantity * perunitcost;
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost = perunitcost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null) {
            let perunitcost = this.PostProcessCostingList.filter(x => x.PostProcessName == "Tumbling" && x.SaleOrderType == this.MaterialType)[0].Cost;
            // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCompletedQuantity * perunitcost;
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost = perunitcost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null) {
            let perunitcost = this.PostProcessCostingList.filter(x => x.PostProcessName == "Vacuum" && x.SaleOrderType == this.MaterialType)[0].Cost;
            // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCompletedQuantity * perunitcost;
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost = perunitcost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null) {
            let perunitcost = this.PostProcessCostingList.filter(x => x.PostProcessName == "Lacquer" && x.SaleOrderType == this.MaterialType)[0].Cost;
            // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCompletedQuantity * perunitcost;
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost = perunitcost;
        }
    }

    Recalculate() {
        this.TotalCostLm = 0;
        this.Orderlist.SaleOrderProduction.InspectionFormulationMixing.forEach(element => {
            if (element.MixingName != "PRE SKIN") {
                this.TotalCoating += element.GSM;
            }
            element.TotalQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
            element.TotalCost = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0
            let clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.GSM;
            element.CostPerLm = isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
            this.TotalCostLm = parseFloat((this.TotalCostLm + element.CostPerLm).toFixed(2));
        });

        this.TotalLaquerPrice = 0
        this.Orderlist.SaleOrderProduction.Lacquer.forEach(element => {
            element.TotalQuantity = parseFloat((element.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0;
            element.TotalPerUnitPrice = parseFloat(element.LacquerRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0;
            this.TotalLaquerPrice = this.TotalLaquerPrice + element.TotalPerUnitPrice;
        });
        this.calculateFabricProductCostPerLm();
        this.Rejection = parseFloat(((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice) * (this.RejectionConstant / 100)).toFixed(2));
        let finishcost = this.calculatefinishingcost();
        this.ProductionCostLm = parseFloat((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice + this.Rejection + parseFloat(finishcost)).toFixed(2));
        this.TotalCostPerLm = parseFloat((this.ProductionCostLm + this.OverheadCost).toFixed(2))
    }

    calclulateLMcost(PerLMConstant: number) {
        this.PerLMConstant = PerLMConstant;
        this.Recalculate();
    }
    calculateInline(res: number) {
        this.InlineScraping = res;
        this.Recalculate();
    }
    calculateRMCost() {
        let res = this.calculatefinishingcost();
        res = (parseFloat(res) + this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice).toFixed(2)
        return res;
    }

    calclulateRejection(rejection: number) {
        this.Rejection = parseFloat(((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice) * (rejection / 100)).toFixed(2));
        this.RejectionConstant = rejection;
        this.Recalculate();
    }

    calclulateProductinCostPerLm(overheadcost: number) {
        this.TotalCostPerLm = parseFloat((this.ProductionCostLm + overheadcost).toFixed(2))
    }
    calculateFabricProductCostPerLm() {
        if (this.Orderlist.FormulationFabricProductUnit == 'Mtrs') {
            this.FabricProductCostPerLm = this.Orderlist.FormulationFabricProductPrice
        }
        else {
            this.FabricProductCostPerLm = parseFloat((((this.Orderlist.SaleOrderProduction.FabricGsm / 1000) * this.PerLMConstant) * this.Orderlist.FormulationFabricProductPrice).toFixed(2));
        }
    }

    calculatefinishingcost() {
        let res = 0
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null) {
            res = (this.TotalLaquerPrice / this.Orderlist.SaleOrderProduction.ManufacturingQuantity) + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost;
        }
        res = res + this.InlineScraping;
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null) {
            res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null) {
            res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null) {
            res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost;
        }
        if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null) {
            res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost;
        }
        return res.toFixed(2);
    }


    savecosting(): void {
        let soc = new SaleOrderCostingModel();
        soc.SaleOrderId = this.Orderlist.SaleOrderId;
        soc.FabricCost = this.FabricProductCostPerLm * this.Orderlist.SaleOrderProduction.ManufacturingQuantity;
        soc.FabricCostLm = this.FabricProductCostPerLm;
        soc.PasteCostLm = this.TotalCostLm;
        soc.GrainCostLm = this.Orderlist.SaleOrderProduction.GrainPrice;
        soc.FinishingCostLm = (parseFloat(this.calculatefinishingcost()));
        soc.RmcostLm = parseFloat(this.calculateRMCost());
        soc.InlineScraping = this.InlineScraping;
        soc.Rejection = this.RejectionConstant;
        soc.ProductionCostLm = this.ProductionCostLm;
        soc.PerLmconstant = this.PerLMConstant;
        soc.OverheadCost = this.OverheadCost;
        soc.SaleOrderMaterialType = this.MaterialType;
        soc.PrintCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null ?
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost : 0;
        soc.EmbossingCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null ?
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost : 0;
        soc.TumblingCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null ?
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost : 0;
        soc.VacuumCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null ?
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost : 0;
        soc.LacquerCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null ?
            this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost : 0;
        this.loader.show();
        let url = this.ApiUrl + 'costing/addupdatecosting';
        this.http.post<any>(url, soc).subscribe({
            next: (res) => {
                this.alertService.success(res);
                this.Orderlist.SaleOrderCosting = soc;
                this.loader.hide();
            },
            error: (res) => {
                this.alertService.error(res.error.ResponseBody);
                this.loader.hide();
            },
        });
    }

    OpenViewPop(data: any) {
        this.soDrawer.SaleOrderId = data;
        this.soDrawer.show();
    }

    OpenViewTrailReports(data: any) {
        this.router.navigate(['/home/salesorder/trailreport/' + data]);
    }
    handleCancel(): void {
        this.isVisible = false;
    }

    workplanCancel(): void {
        this.isVisible = false;
    }

    OpenTimelinePop(data: any): void {
        this.IsTimelineOpen = false;
        this.timeLine.SaleOrderNo = data.SaleOrderNumber;
        this.timeLine.show();
    }

    handleTimelineCancel(): void {
        this.isTimelineVisible = false;
    }
    calclulateTotalSalePrice() {
        this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
    }
    calculateTotalProfitLoss() {
        return (parseFloat(this.Orderlist.SaleOrderProduction.TotalSalePrice) - this.TotalCostPerLm * this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)
    }
    EnableActionButton(data: any) {
        if (data.Status == 'NotYet' && (this.permission.Manage || this.permission.Edit))
            return true
        else if (data.Status == 'WorkPlan' && (this.permission.Manage || this.permission.Edit))
            return true;
        else
            return false;
    }
    EnableChangeWorkPlanButton(data: any) {
        if (data.Status == 'NotYet' && (this.permission.Manage || this.permission.Edit))
            return false
        else if (data.Status == 'JumboInspection' && (this.permission.Manage || this.permission.Edit))
            return false;
        else if (data.Status == 'MoveToDispatch' && (this.permission.Manage || this.permission.Edit))
            return false;
        else if (data.Status == 'PartialDispatchReady' && (this.permission.Manage || this.permission.Edit))
            return false;
        else if (data.Status == 'PartialDispatchCompleted' && (this.permission.Manage || this.permission.Edit))
            return false;
        else if (data.Status == 'DispatchReady' && (this.permission.Manage || this.permission.Edit))
            return false;
        else if (data.Status == 'DispatchCompleted' && (this.permission.Manage || this.permission.Edit))
            return false;
        else
            return true;
    }
    EnableDeleteButton(data: any) {
        if (data.Status == 'NotYet' && (this.permission.Delete || this.permission.Manage))
            return true
        else if (data.Status == 'WorkPlan' && (this.permission.Delete || this.permission.Manage))
            return true;
        else
            return false;
    }

    EnableHoldButton(data: any) {
        if (data.Status == "NotYet" && data.SaleOrderStatus != "Hold" && this.permission.Manage) {
            return true;
        }
        else if (data.Status == "NotYet" && data.SaleOrderStatus != "Hold" && this.permission.Approval) {
            return true;
        }
        return false;
    }
    EnableApproveButton(data: any) {
        if (data.Status == "NotYet" && data.SaleOrderStatus != "Active" && this.permission.Approval) {
            return true;
        }
        return false;
    }

    EnableCostingButton(data: any) {
        if (this.CostingPermission.View && (data.Status == "MoveToDispatch" || data.Status == "PartialDispatchReady" || data.Status == "PartialDispatchCompleted" ||
            data.Status == "DispatchReady" || data.Status == "DispatchCompleted" || data.Status == "LiningOrderMerged"))
            return true
        else
            return false;
    }
    Edit(data: any) {
        if (data.Status == 'WorkPlan') {
            const modal1 = this.modalService.confirm({
                nzTitle: 'Sale Order Editing Warning',
                nzContent: 'This Sale Order has been added to WorkPlan. Editing any details now will remove this order from WorkPlan and change the Status to NotYet. Click on Proceed to continue.',
                nzOkText: 'Proceed',
                nzCancelText: 'Cancel',
                nzOnOk: () => this.router.navigate(['/home/salesorder/edit/' + data.SaleOrderId]),
            });
        }
        else if (data.SaleOrderStatus == 'Active') {
            const modal1 = this.modalService.confirm({
                nzTitle: 'Sale Order Editing Warning',
                nzContent: 'This Sale Order has already been approved. Editing any details now will require it to be approved again. Click on Proceed to continue',
                nzOkText: 'Proceed',
                nzCancelText: 'Cancel',
                nzOnOk: () => this.router.navigate(['/home/salesorder/edit/' + data.SaleOrderId]),
            });
        }
        else
            this.router.navigate(['/home/salesorder/edit/' + data.SaleOrderId]);
    }
    Copy(data: any) {
        this.router.navigate(['/home/salesorder/add/' + data.SaleOrderId]);
    }
    AddSaleOrder() {
        this.router.navigate(['/home/salesorder/add'])
    }
    Delete() {
        let url = this.ApiUrl + "saleorder/DeleteSaleOrder/" + this.salesOrderNo;
        this.http.get<any>(url).subscribe({

            next: res => {

                this.alertService.success("Sales Order Deleted Successfully"); this.isLoading = this.isVisible = false;
                this.GetFilteredSalesOrder();

            },
            error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },

        });

    }
    handleDelete(data: any) {
        this.salesOrderNo = data.SaleOrderId
        const modal = this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Are you sure to delete this Sales Order?',
            nzOnOk: () => this.Delete(),
        });

        setTimeout(() => modal.destroy(), 5000);

    }

    handleHold(data: any) {
        this.salesOrderNo = data.SaleOrderId
        const modal = this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Are you sure to put this Sales Order on hold?',
            nzOnOk: () => this.HoldFn(),
        });

        setTimeout(() => modal.destroy(), 5000);
    }

    handleActive(data: any) {
        this.salesOrderNo = data.SaleOrderId
        const modal = this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Are you sure to Approve this Sales Order from ' + data.CustomerName + '?',
            nzOnOk: () => this.ActiveFn(),
        });

        setTimeout(() => modal.destroy(), 5000);
    }

    HoldFn() {
        let url = this.ApiUrl + "saleorder/HoldSaleOrder/" + this.salesOrderNo;
        this.http.get<any>(url).subscribe({
            next: res => {
                this.alertService.success(res.ResponseBody); this.isLoading = this.isVisible = false;
                this.GetFilteredSalesOrder();
            },
            error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },
        });
    }

    ActiveFn() {
        let url = this.ApiUrl + "saleorder/ApproveSaleOrder/" + this.salesOrderNo;
        this.http.get<any>(url).subscribe({
            next: res => {
                this.alertService.success(res.ResponseBody); this.isLoading = this.isVisible = false;
                this.GetFilteredSalesOrder();
            },
            error: res => { this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false; },
        });
    }

    handleCancelInspection() {
        this.isVisibleCosting = false;
        this.Orderlist = null;
        this.loader.hide();
    }
    GetAllActivityLog(SaleOrderNumber: string) {
        var request = {
            SaleOrderNumber: SaleOrderNumber,
            Status: null,
            DateFrom: null,
            DateTo: null,
            Addedby: null
        }

        this.isTableLoading = true;
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, request).subscribe(res => {
            console.log(res);
            this.ActivityLogList = res;
            this.isTimelineVisible = true;
            this.isTableLoading = false;
        }, res => {
            this.GetAllActivityLog(SaleOrderNumber);
        });
    }
    GetTimelineRefresh(SaleOrderNumber: string) {
        this.loader.show();
        this.refreshBtnLoading = true;
        var request = {
            SaleOrderNumber: SaleOrderNumber,
            Status: null,
            DateFrom: null,
            DateTo: null,
            Addedby: null
        }
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, request).subscribe(res => {
            this.ActivityLogList = res;
            this.refreshBtnLoading = false;
            this.loader.hide();
        }, res => { });
    }
    GetTimeLineStatus(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);
            var last = ESalesOrderStatus[this.ActivityLogList[0]?.Status as keyof typeof ESalesOrderStatus];
            console.log("last", last, "st", st);
            if (stt != null) {
                if (st > last) {
                    return "wait";
                }
                else if (stt[0] === undefined) {
                    return "hide";
                }
                else if (stt.length > 0) {
                    return "finish";
                }

                else
                    return "wait";


            }
            else
                return "error";
        } else { return "error"; }
    }
    GetTimeLineDescription(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);
            var last = ESalesOrderStatus[this.ActivityLogList[0]?.Status as keyof typeof ESalesOrderStatus];
            if (stt != null) {
                if (st > last) {
                    return "";
                }
                else
                    if (stt[0] === undefined) {
                        return "Skipped";
                    }
                    else
                        return "By : " + stt[0]?.AddedBy;
            }
            else
                return "";
        } else { return ""; }
    }
    GetTimeLineSubtitle(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);

            if (stt != null) {
                if (stt[0] === undefined) {
                    return "";
                }
                else
                    return "On : " + this.datePipe.transform(stt[0]?.AddedDate);
            }
            else
                return "";
        } else { return ""; }
    }
    SaleOrderLinkingRemove(data: LinkedSaleOrderModel) {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/removelinksaleorder";
        this.http.post<any>(url, data[0]).subscribe({
            next: res => {
                this.alertService.success(res);
                this.isLoading = false;
                this.GetFilteredSalesOrder();
                this.loader.hide();
            },
            error: res => {
                this.alertService.error(res.error.ResponseBody);
                this.GetFilteredSalesOrder();
                this.isLoading = false;
                this.loader.hide();
            }
        });
    }
    EnableSandwichRemove(data: string) {
        if (this.permission.Delete && data == "LiningOrderMerged")
            return true
        else
            return false
    }
    GetProductType(data: string) {
        if (data.split('-')[0] == "GZ")
            return "PVC"
        else
            return "PU"
    }
    // GetAllColor() {
    //   let url = this.ApiUrl + "Color/getallColors";
    //   this.http.get<ColorModel[]>(url).subscribe(res => {
    //     this.ColorList = res;

    //   }, res => {
    //     this.count++
    //     if (this.count < 2) {
    //       this.GetAllColor()
    //     }
    //   });
    // }
    // GetAllGrain() {
    //   let url = this.ApiUrl + "Grain/getallGrains";
    //   this.http.get<GrainModel[]>(url).subscribe(res => {
    //     this.GrainList = res;

    //   }, res => {
    //     this.count++
    //     if (this.count < 2) {
    //       this.GetAllGrain()
    //     }
    //   });
    // }
    calculateGrandTotal() {
        this.SaleOrderGSMUpdate.SaleOrderProduction.TotalGsm =
            parseFloat(this.SaleOrderGSMUpdate?.SaleOrderProduction?.PreSkinGsm?.toString()) +
            parseFloat(this.SaleOrderGSMUpdate?.SaleOrderProduction?.SkinGsm?.toString()) +
            parseFloat(this.SaleOrderGSMUpdate?.SaleOrderProduction?.FoamGsm?.toString()) +
            parseFloat(this.SaleOrderGSMUpdate?.SaleOrderProduction?.AdhesiveGsm?.toString()) +
            parseFloat(this.SaleOrderGSMUpdate?.SaleOrderProduction?.FabricGsm?.toString());
    }
    handleGSMUpdateCancel() {
        this.IsGSMUpdateVisible = false;
    }
    handleOpenGSMUpdate(data: SaleOrderModel) {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/getsaleorderformulationmixing/" + data.SaleOrderId;
        this.http.get<SaleOrderModel>(url).subscribe({
            next: res => {
                this.SaleOrderGSMUpdate = res;
                this.calculateGrandTotal();
                this.IsGSMUpdateVisible = true;
                this.loader.hide();
            },
            error: res => {
                this.loader.hide();
                this.IsGSMUpdateVisible = false;
                this.alertService.error("Error occured, please contact Administrator.");
            }
        });
    }
    SaveUpdateGSM() {
        this.loader.show();
        let url = this.ApiUrl + "saleorder/updatesaleordergsm";
        this.http.post<any>(url, this.SaleOrderGSMUpdate.SaleOrderProduction).subscribe({
            next: res => {
                this.loader.hide();
                this.alertService.success(res.ResponseBody);
                this.IsGSMUpdateVisible = false;
                this.GetFilteredSalesOrder();

            },
            error: res => {
                this.loader.hide();
                this.IsGSMUpdateVisible = false;
                this.alertService.error(res.error.ResponseBody);
                this.GetFilteredSalesOrder();
            }
        });
    }
    EditPostProcess(data) {
        this.GetSalesOrderDatabyid(data.SaleOrderId);
        this.isVisiblePopUp = true;
    }
    SavePostProcess() {
        let url = this.ApiUrl + "saleorder/EditPostProcess";
        this.http.post<any>(url, this.NewSaleOrder).subscribe({
            next: res => {
                this.isLoading = false;
                if (res == 'An error has occured. Please contact administrator') {
                    this.alertService.error(res);
                } else {
                    this.alertService.success("Entry Saved Successfully");
                    this.router.navigate(['/home/salesorder/list'])
                }
            },
            error: res => { this.alertService.error(res.error); this.isLoading = false; },

        });
    }
    CancelPostProcess() {
        this.isVisiblePopUp = false;
    }
    GetSalesOrderDatabyid(id: number) {

        let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + id;
        this.http.get<SaleOrderModel>(url).subscribe(res => {
            this.NewSaleOrder = res;
            this.SelectedPostProcess = [];
            this.SelectedPostProcess = [...this.NewSaleOrder.SaleOrderPostProcessOrder];
            this.SelectedPostProcess.forEach((pro) => {
                switch (pro.PostProcessName) {
                    case "Print":
                        const selectedPrintProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionPrint.filter((print) =>
                            print.PrintMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
                        if (selectedPrintProcess.length > 0) {
                            const selecteProcessId = selectedPrintProcess[0].PrintMasterId
                            pro.selectedProcess = this.PrintMasterList.filter(x => x.PrintMasterId === selecteProcessId)[0]
                        }
                        break;
                    case "Embossing":
                        const selectedEmbossingProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionEmbossing.filter((print) =>
                            print.EmbossingMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
                        if (selectedEmbossingProcess.length > 0) {
                            const selecteProcessId = selectedEmbossingProcess[0].EmbossingMasterId
                            pro.selectedProcess = this.EmbossingList.filter(x => x.EmbossingMasterId === selecteProcessId)[0]
                        }
                        break;
                    case "Vaccum":
                        const selectedVaccumProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionVacuum.filter((print) =>
                            print.VacuumMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
                        if (selectedVaccumProcess.length > 0) {
                            const selecteProcessId = selectedVaccumProcess[0].VacuumMasterId
                            pro.selectedProcess = this.VaccumList.filter(x => x.VacuumMasterId === selecteProcessId)[0]
                        }
                        break;
                    case "Tumbling":
                        const selectedTumblingProcess = this.NewSaleOrder.SaleOrderProduction.SaleOrderProductionTumbling.filter((print) =>
                            print.TumblingMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
                        if (selectedTumblingProcess.length > 0) {
                            const selecteProcessId = selectedTumblingProcess[0].TumblingMasterId
                            pro.selectedProcess = this.TumblingList.filter(x => x.TumblingMasterId === selecteProcessId)[0]
                        }
                        break;
                    case "Lacquar":
                        const selectedLacquarProcess = this.NewSaleOrder.SaleOrderProduction.Lacquer.filter((print) =>
                            print.LacquerMasterId === pro.ProcessMasterId && print.Rank === pro.Rank)
                        if (selectedLacquarProcess.length > 0) {
                            const selecteProcessId = selectedLacquarProcess[0].LacquerMasterId
                            pro.selectedProcess = this.LacquerList.filter(x => x.LacquerMasterId === selecteProcessId)[0]
                        }
                }
            });
            this.SaleOrderPostProcessOrder.PrintProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Print")[0]?.Rank ?? 0;
            this.SaleOrderPostProcessOrder.EmbossingProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Embossing")[0]?.Rank ?? 0;
            this.SaleOrderPostProcessOrder.LacquerProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Lacquar")[0]?.Rank ?? 0;
            this.SaleOrderPostProcessOrder.VacuumProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Vaccum")[0]?.Rank ?? 0;
            this.SaleOrderPostProcessOrder.TumblingProcess = this.NewSaleOrder.SaleOrderPostProcessOrder.filter(x => x.PostProcessName == "Tumbling")[0]?.Rank ?? 0;
        });
    }
    GetAllPrint() {

        let url = this.ApiUrl + "print/getallprints";
        this.http.get<SaleOrderProductionPrintModel[]>(url).subscribe(res => {
            this.PrintMasterList = res;
            this.PrintMasterList.forEach(x => x.Quantity = 1);
        }, res => {
            this.count++;
            if (this.count < 2) { this.GetAllPrint(); }
        });
    }
    GetAllEmbossing() {

        let url = this.ApiUrl + "embossing/getallembossings";
        this.http.get<SaleOrderProductionEmbossingModel[]>(url).subscribe(res => {
            this.EmbossingList = res;
            this.EmbossingList.forEach(x => x.Quantity = 1);

        }, res => {
            this.count++;
            if (this.count < 2) { this.GetAllEmbossing(); }
        });
    }
    GetAllTumbling() {
        let url = this.ApiUrl + 'tumbling/getalltumblings';
        this.http.get<SaleOrderProductionTumblingModel[]>(url).subscribe(
            (res) => {
                this.TumblingList = res;
                this.TumblingList.forEach(x => x.Quantity = 1);
            },
            (res) => {
                this.count++;
                if (this.count < 2) { this.GetAllTumbling(); }
            }
        );
    }
    GetAllLacquer() {
        let url = this.ApiUrl + 'lacquer/getalllacquers';
        this.http.get<ProductionLacquerModel[]>(url).subscribe(
            (res) => {
                this.LacquerList = res;

            },
            (res) => {
                this.count++;
                if (this.count < 2) { this.GetAllLacquer(); }
            }
        );
    }
    GetAllVaccum() {

        let url = this.ApiUrl + 'vacuum/getallvacuums';
        this.http.get<SaleOrderProductionVacuumModel[]>(url).subscribe(
            (res) => {
                this.VaccumList = res;
                this.VaccumList.forEach(x => x.Quantity = 1);
            },
            (res) => {
                this.count++;
                if (this.count < 2) { this.GetAllVaccum(); }
            }
        );
    }
    removeProcess(process: string) {
        const index = this.SelectedPostProcess.findIndex((postPro) => postPro.PostProcessName === process)
        this.SelectedPostProcess.splice(index, 1);
    }
    handleShowProcessList() {
        this.showProcess = !this.showProcess
    }
    getSequenceList() {
        const tempList = new Array(this.SelectedPostProcess.length).fill(0).map((_, index) => index + 1);
        return tempList
    }
    handleProcess(process: string) {
        this.SelectedPostProcess.push({
            PostProcessName: process,
            Rank: 0,
            selectedProcess: ''
        })
        this.SetDefaultPostProcessOrder()
    }
    SetDefaultPostProcessOrder() {
        if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Print').length == 0) {
            this.SaleOrderPostProcessOrder.PrintProcess = 0;
        }
        if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Embossing').length == 0) {
            this.SaleOrderPostProcessOrder.EmbossingProcess = 0;
        }
        if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Lacquar').length == 0) {
            this.SaleOrderPostProcessOrder.LacquerProcess = 0;
        }
        if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Vaccum').length == 0) {
            this.SaleOrderPostProcessOrder.VacuumProcess = 0;
        }
        if (this.SelectedPostProcess.filter(x => x.PostProcessName == 'Tumbling').length == 0) {
            this.SaleOrderPostProcessOrder.TumblingProcess = 0;
        }
    }
    closeProcessList() {
        this.showProcess = false;
    }
}
