import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { environment } from '../../../../environments/environment';
import { Modules, Responsibility } from '../../../Models/Enums';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel } from '../../../Models/MasterModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { ProductModel } from '../../../Models/ProductModel';
import { PurchaseOrderModel, PurchaseOrderPrintModel, PurchaseOrderProductModel } from '../../../Models/PurchaseOrderModel';
import { SupplierModel } from '../../../Models/SupplierModel';
import { AuthService } from '../../../Services/auth.service';
import { LoadingService } from '../../../Services/loadingService';
import { PurchaseOrderReportModel } from 'src/PmsUIApp/Models/ReportModel';
import { UserModel } from 'src/PmsUIApp/Models/UserModel';
import { DatetimeConverter } from '../../../Services/DatetimeConverter.pipe';
import { PoTimelineService } from 'src/PmsUIApp/Services/PoTimeLineService';
import { PoDrawerService } from 'src/PmsUIApp/Services/PoDrawerService';

@Component({
  selector: 'app-PurchaseReport',
  templateUrl: './PurchaseReport.component.html',
  styleUrls: ['./PurchaseReport.css']
})

export class PurchaseReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  searchValue = '';
  isTableLoading: boolean = false;
  PurchaseOrderList: PurchaseOrderReportModel[] = [];
  PurchaseOrderListOg: PurchaseOrderReportModel[] = [];
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any;
  PurchaseOrder!: PurchaseOrderModel;
  exportfields: PurchaseOrderPrintModel[] = [];
  exportoptions = {
    headers: [
      'Supplier Name',
      'PO Number',
      'Delivery Term',
      'Payment Term',
      'Product Name',
      "Currency",
      'PO Total Amount',
      'PO Date',

      'GRN',
      'Reference',
      'Added Date',
      'Added By',
      "Status"
    ],
  };
  fields: PurchaseOrderPrintModel = new PurchaseOrderPrintModel();
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  UnitList: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  refreshBtnLoading: boolean;
  TimeLineObject: any;
  ActivityLogList: any;
  IsTimelineOpen = false;
  isTimelineVisible = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  request = {
    DateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    DateTo: new Date(new Date().setHours(23, 59, 59)),
    ProductId: 0,
    Unit: "",
    ProductType: "",
    SupplierId: 0,
    ProductCategoryId: 0,
    ProductFirstSubCategoryId: 0,
    ProductSecSubCategoryId: 0,
    Status: "",
    ActionBy: ""
  }
  count: number;
  backDatePO = 'false';
  UserList: UserModel[];
  isPOVisible: boolean = false;
  SelectedPO: PurchaseOrderModel;
  constructor(private authService: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    public http: HttpClient,
    private loader: LoadingService,
    private auth: AuthService,
    private datePipe: DatetimeConverter,
    private timeLine: PoTimelineService,
    private poDrawer: PoDrawerService
  ) {
  }
  // GetAllPurchaseOrder() {
  //   this.isTableLoading = true;
  //   let url = this.ApiUrl + 'purchaseorder/getallpurchaseordersforlist';

  //   this.http.get<PurchaseOrderModel[]>(url).subscribe(
  //     (res) => {

  //       this.PurchaseOrderList = res;
  //       this.PurchaseOrderListOg = res;

  //       console.log(` this.PurchaseOrderList`, this.PurchaseOrderList)

  //       this.exportfields = [];
  //       this.PurchaseOrderList.forEach((x) => {
  //         this.fields = new PurchaseOrderPrintModel();
  //         this.fields.SupplierName = x.SupplierName;
  //         this.fields.Ponumber = x.Ponumber;
  //         this.fields.DeliveryTerm = x.DeliveryTerm;
  //         this.fields.PaymentTerm = x.PaymentTerm;
  //         this.fields.ProductName = x.PurchaseOrderProduct[0].ProductName;
  //         this.fields.PototalAmount = x.PototalAmount;
  //         this.fields.Currency = x.PurchaseOrderProduct[0].Currency
  //         this.fields.PocreationDate = x.PocreationDate
  //         this.fields.Grn = x.Grn;
  //         this.fields.Reference = x.Reference;
  //         this.fields.AddedDate = x.AddedDate;
  //         this.fields.AddedBy = x.AddedBy;
  //         this.fields.Status = x.Status
  //         this.exportfields.push(this.fields);

  //       });

  //       this.isTableLoading = false;
  //       console.log(`this.exportfields`, this.exportfields)
  //     },
  //     (res) => {
  //       this.count++;
  //       if (this.count < 2) { this.GetAllPurchaseOrder(); }
  //     }
  //   );
  // }
  GetPurchaseReport() {
    this.request.ProductCategoryId = this.CategoryID;
    this.request.ProductFirstSubCategoryId = this.FirstCategoryID;
    this.request.ProductSecSubCategoryId = this.SecondCategoryID;
    if (isNaN(this.request.SupplierId) || this.request.SupplierId == null) {
      this.request.SupplierId = 0;
    }
    if (isNaN(this.request.ProductId) || this.request.ProductId == null) {
      this.request.ProductId = 0;
    }
    if (isNaN(this.request.ProductCategoryId) || this.request.ProductCategoryId == null) {
      this.request.ProductCategoryId = 0;
    }
    if (isNaN(this.request.ProductFirstSubCategoryId) || this.request.ProductFirstSubCategoryId == null) {
      this.request.ProductFirstSubCategoryId = 0;
    }
    if (isNaN(this.request.ProductSecSubCategoryId) || this.request.ProductSecSubCategoryId == null) {
      this.request.ProductSecSubCategoryId = 0;
    }
    if (this.request.Unit == null) {
      this.request.Unit = "";
    }
    this.request.ProductType = this.SelectedProductType;
    this.isTableLoading = true;
    let url = this.ApiUrl + 'report/purchasereport';

    this.http.post<PurchaseOrderReportModel[]>(url, this.request).subscribe(
      (res) => {

        this.PurchaseOrderList = res.filter(x => x.PurchaseOrderProduct.length > 0);
        this.PurchaseOrderListOg = res.filter(x => x.PurchaseOrderProduct.length > 0);

        console.log(` this.PurchaseOrderList`, this.PurchaseOrderList)

        this.exportfields = [];
        this.PurchaseOrderList.forEach((x) => {
          this.fields = new PurchaseOrderPrintModel();
          this.fields.SupplierName = x.SupplierName;
          this.fields.Ponumber = x.Ponumber;
          this.fields.DeliveryTerm = x.DeliveryTerm;
          this.fields.PaymentTerm = x.PaymentTerm;
          this.fields.ProductName = x.PurchaseOrderProduct[0].ProductName;
          this.fields.PototalAmount = x.PototalAmount;
          this.fields.Currency = x.PurchaseOrderProduct[0].Currency
          this.fields.PocreationDate = x.PocreationDate
          this.fields.Grn = x.Grn;
          this.fields.Reference = x.Reference ?? "";
          this.fields.AddedDate = x.AddedDate;
          this.fields.AddedBy = x.AddedBy?.Name;
          this.fields.Status = x.Status
          this.exportfields.push(this.fields);

        });

        this.isTableLoading = false;
        console.log(`this.exportfields`, this.exportfields)
      },
      (res) => {
        this.count++;
        if (this.count < 2) { this.GetPurchaseReport(); }
      }
    );
  }
  GetAllUnit() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "data/getmeasureunits";
    this.http.get<any>(url).subscribe(res => {
      this.UnitList = res;


      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnit(); }
    });
  }
  GetAllSupplier() {
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllSupplier(); }
    });
  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsPurchase, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsPurchase, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsPurchase, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllSupplier();
    this.GetAllUnit();
    this.GetAllProducts();
    this.GetAllProductCategory();
    //this.GetAllPurchaseOrder();
    this.GetPurchaseReport();
    this.GetAllUsers();
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reset(): void {
    this.searchValue = '';
    this.search();
    //this.GetAllPurchaseOrder();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'stock-report-export' + exportdate,
        this.exportoptions
      );
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }




  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }



  search() {

    var res = this.PurchaseOrderListOg;
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: PurchaseOrderModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.PocreationDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.PocreationDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.PurchaseOrderList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.PurchaseOrderList = res.filter((item: PurchaseOrderModel) => {
        if (
          item?.DeliveryTerm?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.Reference?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.PaymentTerm?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }

        else if (
          item?.SupplierName?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.PurchaseOrderProduct[0].ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.PurchaseOrderProduct[0].Unit?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item.PurchaseOrderProduct[0].Rate == +this.searchValue
        ) {
          return true;
        }


        return false;
      });




    }

  }

  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetBGColor(data: PurchaseOrderReportModel): string {
    if (this.backDatePO == 'true') {
      if (new Date(data.PocreationDate).toDateString() < new Date(data.AddedDate).toDateString()) {
        return 'yellow';
      }
      else {
        return '';
      }
    }
    else
      return '';
  }
  GetQtyDiffColor(data: PurchaseOrderProductModel) {
    if (data.RecievedQuantity > (((data.Quantity / 100) * 10) + data.Quantity)) {
      return '#f55e5e'
    } else {
      return ''
    }
  }
  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  handleCancel(): void {
    this.isPOVisible = false;
  }
  ShowPODetails(data: PurchaseOrderModel) {
    this.SelectedPO = data;
    this.isPOVisible = true;
    this.poDrawer.Poid = data.Poid;
    this.poDrawer.show();
  }
  OpenTimelinePop(data: any): void {
    this.IsTimelineOpen = false;
    this.timeLine.Poid = data.Poid;
    this.timeLine.show();

    // this.TimeLineObject = data;
    // this.GetAllActivityLog(data.Poid);
  }
}
