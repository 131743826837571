import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BarcodeLabelUpdateModalState } from '../../../Models/BarcodeLabelModel';


interface SplitModalState {
    isVisible: boolean;
    StockLabelId: number;
    SerialNo: string;
    Quantity: number;
}

@Injectable({
    providedIn: 'root'
})
export class BarcodeLabelUpdateService {
    private modalStateSubject = new BehaviorSubject<BarcodeLabelUpdateModalState>({ isVisible: false, serialNo: null, action: null, currentRackId: null, stockProductId: null });
    public modalState$: Observable<BarcodeLabelUpdateModalState> = this.modalStateSubject.asObservable();

    private stockProductIdSubject = new BehaviorSubject<number | null>(null);
    public stockProductId$ = this.stockProductIdSubject.asObservable();

    private stockCurrentRackIdSubject = new BehaviorSubject<number | null>(null);
    public stockCurrentRackId$ = this.stockCurrentRackIdSubject.asObservable();

    private stockLabelIdsSubject = new BehaviorSubject<number[]>([]);
    public stockLabelIds$ = this.stockLabelIdsSubject.asObservable();

    private splitModalStateSubject = new BehaviorSubject<SplitModalState>({ isVisible: false, StockLabelId: 0, SerialNo: '', Quantity: 0 });
    splitModalState$ = this.splitModalStateSubject.asObservable();

    openModal(serialNo: string, action: string, currentRackId: number = 0, stockProductId: number = 0): void {
        this.modalStateSubject.next({ isVisible: true, serialNo, action, currentRackId, stockProductId });
    }

    closeModal(action: string): void {
        this.modalStateSubject.next({ isVisible: false, serialNo: null, action, currentRackId: null, stockProductId: null });
    }
    closeModalShowScanList(): void {
        this.modalStateSubject.next({ isVisible: false, serialNo: null, action: 'ShowScanList', currentRackId: null, stockProductId: null });
    }
    setStockProductId(id: number): void {
        this.stockProductIdSubject.next(id);
    }
    getStockProductId(): number | null {
        return this.stockProductIdSubject.getValue();
    }
    setStockCurrentRackId(id: number): void {
        this.stockCurrentRackIdSubject.next(id);
    }
    getStockCurrentRackId(): number | null {
        return this.stockCurrentRackIdSubject.getValue();
    }
    resetValues(): void {
        this.stockProductIdSubject.next(null);
        this.modalStateSubject.next({ isVisible: false, serialNo: null, action: null, currentRackId: null, stockProductId: null });
        this.splitModalStateSubject.next({ isVisible: false, StockLabelId: 0, SerialNo: '', Quantity: 0 });
        this.stockCurrentRackIdSubject.next(null);
    }

    setStockLabelIds(ids: number[]) {
        this.stockLabelIdsSubject.next(ids);
    }

    getStockLabelIds(): number[] {
        return this.stockLabelIdsSubject.getValue();
    }

    openSplitModal(StockLabelId: number, SerialNo: string, Quantity: number) {
        this.splitModalStateSubject.next({ isVisible: true, StockLabelId, SerialNo, Quantity });
    }

    closeSplitModal() {
        this.splitModalStateSubject.next({ isVisible: false, StockLabelId: 0, SerialNo: '', Quantity: 0 });
    }
}
