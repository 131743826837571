<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Stock Availability</nz-page-header-title>
      <nz-page-header-subtitle>Product wise report with supplier</nz-page-header-subtitle>
      <nz-page-header-extra>

      </nz-page-header-extra>

    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="this.filterPanelTxt" [nzActive]="false"
        (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">

            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Type</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                    (ngModelChange)="onSelectedProductTypeChange()" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                    <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                    <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Category</label>

                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID"
                    name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                    <nz-option *ngFor="let s of this.FilteredProductCategoryList;" [nzValue]="s.ProductCategoryId"
                      [nzLabel]="s.ProductCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Sub Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Sub Category"
                    (ngModelChange)="GetAllSecondCategory($event)">
                    <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                      [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">2nd Sub Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                    nzPlaceHolder="Second Sub Category" (ngModelChange)="GetSecondCategoryFilteredProduct()">
                    <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                      [nzLabel]="s.ProductSecSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Name</label>
                  <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="SelectedProduct"
                    (ngModelChange)="UpdateProduct($event)" name="ProductId" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s"
                      [nzLabel]="s.ProductName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Measure unit</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedUnit"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Supplier</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.SupplierId"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                      [nzLabel]="s.SupplierName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Store </label>
                  <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()"
                    [(ngModel)]="StoreID" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId"
                      [nzLabel]="s.StoreName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Rack</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="RackID" nzAllowClear
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Received By</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.ReceivedBy"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email" [nzLabel]="s.Name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">WIP Store</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                    [(ngModel)]="request.IncludeWIPStore" nzPlaceHolder="Choose">
                    <nz-option nzValue=true nzLabel="Exclude"></nz-option>
                    <nz-option nzValue=false nzLabel="Only WIP"></nz-option>
                    <nz-option nzValue=null nzLabel="Include"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Quality</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.ProductQuality"
                    nzPlaceHolder="Choose">
                    <nz-option nzValue="" nzLabel="All"></nz-option>
                    <nz-option nzValue="IMPORTED" nzLabel="IMPORTED"></nz-option>
                    <nz-option nzValue="DOMESTIC" nzLabel="DOMESTIC"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" style="margin-top: 30px;">
                  <label class="form-label">Show Only Low Stock:</label>
                  <input style="margin-left: 10px" type="checkbox" [(ngModel)]="request.isMinumQuantityCheck" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <!-- <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Batch No.</label>
                  <input nz-input name="BatchNo" nzAllowClear placeholder="Enter Batch No." maxlength="30"
                    [(ngModel)]="request.BatchNo" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>
        </div>
        <div style="float: right; margin-bottom: 2%; margin-right: 10px">
          <div style="display: flex">
            <button nz-button nzType="primary" (click)="GetReport()">
              Apply Filters
            </button>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right" *ngIf="this.FilteredReportList.length>0">
      <div style="margin-left: -108px; margin-bottom: -57px;">
        <nz-form-item>
          <nz-form-control [nzSpan]="3" nzErrorTip="Enter Amount">
            <label style="margin-left:-218px;padding-right: 10px;">Select Date Search By:</label>
            <nz-select nzPlaceHolder="Choose Date" name="select-error" style="width: 160px;"
              [(ngModel)]="dateTypeSelected" nzAllowClear>
              <nz-option *ngFor="let data of MDate" [nzValue]="data" [nzLabel]="data"></nz-option>
            </nz-select>
            <!-- {{dateTypeSelected}} -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
    <nz-table *ngIf="this.FilteredReportList.length>0" [nzPageSize]="100" style="width: 100%;" nzBordered
      [nzScroll]="{ x: '1200px',y:'1200px' }" nzSize="small" #basicTable [nzData]="[{}]" [nzLoading]="isTableLoading">
      <thead>
        <tr>
          <th nzLeft nzWidth="60px">SNo.</th>
          <th nzWidth="200px" nzLeft>Product Name</th>
          <th nzWidth="100px">Type</th>
          <th nzWidth="100px">Code</th>
          <th nzWidth="140px" nzLeft>Total Available QTY</th>
          <th nzWidth="110px">Minimum QTY</th>
          <th nzWidth="190px">Batch No.</th>
          <th nzWidth="120px">Quality</th>
          <th nzWidth="200px">Store Name</th>
          <th nzWidth="200px">Rack Name</th>
          <th nzWidth="200px">Supplier</th>
          <th nzWidth="100px">Barcode</th>
          <th nzWidth="120px">Available QTY</th>
          <th nzWidth="100px">Received QTY</th>
          <th nzWidth="140px">Price/Unit</th>
          <th nzWidth="140px">Total Amount</th>
          <th nzWidth="120px">MFG Date</th>
          <th nzWidth="120px">Expiry Date</th>
          <th nzWidth="200px">Received Date</th>
          <th nzWidth="250px">Received By</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.FilteredReportList;let i=index">
          <td nzLeft style="vertical-align: top;">{{i+1}}</td>
          <td nzLeft style="vertical-align: top;">{{ data.ProductName }}</td>
          <td style="vertical-align: top;">{{ data.ProductType }}</td>
          <td style="vertical-align: top;">{{ data.ProductCode }}</td>

          <td style="vertical-align: top;" [ngStyle]="{'background-color': GetConditionalColorForLowStock(data)}"
            nzLeft>{{ data.TotalQty }} {{ data.Unit }}</td>
          <td style="vertical-align: top;">{{ data.MinimumQuantity }}</td>
          <td colspan="14" style="padding: 0; border: 0; vertical-align: top; " *ngIf="data.TotalQty > 0">
            <table>
              <tr *ngFor="let item of data.ChildList">
                <td style="width: 190px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{ item.Batch
                  }}</td>
                <td style="width: 120px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.ProductQuality }}</td>
                <td style="width: 200px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; " nzBreakWord>{{
                  item.StoreName }}</td>
                <td style="width: 200px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; " nzBreakWord>{{
                  item.RackName }}</td>
                <td style="width: 200px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">
                  {{item.SupplierName}}</td>
                <!-- <td style="width: 100px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.Barcode }}</td> -->
                <td *ngIf="!item.IsBarcodeLabelExist" style="width: 100px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">No Barcode</td>
                <td *ngIf="item.IsBarcodeLabelExist" style="width: 100px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; "><a
                    class="btn btn-sm btn-success"
                    (click)="openScannedBarcodeList(item.StockProductId, item.RackId)">View</a></td>
                <td style="width: 120px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.Quantity }} {{ data.Unit }}</td>
                <td style="width: 100px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.RecievedQuantity }}</td>
                <td style="width: 140px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">&#8377;{{
                  item.PricePerUnit}} / {{ data.Unit }} </td>
                <td style="width: 140px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">&#8377;{{
                  item.TotalAmount }} </td>
                <td style="width: 120px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.ManufacturedDate | date:'dd-MMM-yyyy'}}</td>
                <td style="width: 120px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.ExpiryDate | date:'dd-MMM-yyyy' }}</td>
                <td style="width: 200px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.ReceivedDate | DatetimeConverter }}</td>
                <td style="width: 250px; padding: 10px; border: 1px solid #f0f0f0; border-bottom: none; ">{{
                  item.ReceivedBy }}</td>
              </tr>
            </table>
          </td>
          <td colspan="13" style="padding: 0; border: 0; vertical-align: top; " *ngIf="data.TotalQty == 0"></td>

        </tr>
      </tbody>
    </nz-table>



  </div>
</div>