import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { v4 as uuidv4 } from 'uuid';
import { StorageService } from '../../Services/storage.service';
import { FileUploadModel } from '../../Models/UploadModel';
import { IUploadProgress } from '../../Models/StockModel';
import * as moment from 'moment';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';

@Component({
  templateUrl: './StockQualityInspection.component.html',
  styleUrls: ['./StockQualityInspection.component.css']
})
export class StockQualityInspectionComponent implements OnInit {
  id: number = 0;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  NewStockProduct: StockProductModel = new StockProductModel();
  FilteredProductList: ProductModel[] = [];
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];
  SelectedProductType: string = "";
  NewStock: StockModel = new StockModel();
  NewInvoice: InvoiceModel = new InvoiceModel();
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  fileList: NzUploadFile[] = [];
  uploadProgress: IUploadProgress[] = [];
  sasTokenData: any = null;
  StorageContainerName: string = 'qualityinspection';
  stockProductFiles: { [key: number]: NzUploadFile[] } = {};
  uploadProgressMap: { [key: number]: IUploadProgress[] } = {};
  IsSaveBtLoading: boolean = false;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private alertService: AlertMessageService, private auth: AuthService,
    private storageService: StorageService, private loader: LoadingService) { }


  GetStockDetails() {
    this.loader.show()
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe({
      next: res => {
        this.NewStock = res;
        this.NewStock.StockProduct.forEach((nos) => {
          nos.ExpandRecord = false;
          nos.NewRecord = true;
        })
        this.loader.hide()
      },
      error: () => {
        this.loader.hide()
      }
    });
  }

  rejectedchange(item: StockProductModel) {
    if (item.RejectedQuantity > item.AcceptedQuantity) {
      item.RejectedQuantity = 0;
      return
    }
    item.AcceptedQuantity = item.Quantity - item.RejectedQuantity;
  }

  // SaveStockInspection() {
  //   let url = this.ApiUrl + "stock/stockqualityinspection";
  //   this.http.post<any>(url, this.NewStock).subscribe({
  //     next: res => {
  //       this.alertService.success(res);
  //       this.router.navigate(['/home/stockinspectionlist'])
  //     },
  //     error: res => { this.alertService.error("An error has been occured. Please try again") },
  //   });
  // }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.StockQualityInspection, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.StockQualityInspection, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.StockQualityInspection, Responsibility.Delete);
    console.log(this.permission)
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.GetStockDetails();
  }

  beforeUploadFactory = (stockProductId: number) => {
    return (file: NzUploadFile): boolean => {
      if (!this.stockProductFiles[stockProductId]) {
        this.stockProductFiles[stockProductId] = [];
      }
      this.stockProductFiles[stockProductId] = this.stockProductFiles[stockProductId].concat(file);
      return false; // Prevent automatic upload
    };
  };

  updateFileList = (fileList: NzUploadFile[], stockProductId: number): void => {
    this.stockProductFiles[stockProductId] = fileList;
  };

  getUploadProgress(stockProductId: number): IUploadProgress[] {
    return this.uploadProgressMap[stockProductId] || [];
  }

  private updateProgress(filename: string, progress: number, stockProductId: number): void {
    if (!this.uploadProgressMap[stockProductId]) {
      this.uploadProgressMap[stockProductId] = [];
    }

    const existingProgress = this.uploadProgressMap[stockProductId].find(p => p.filename === filename);
    if (existingProgress) {
      existingProgress.progress = progress;
    } else {
      this.uploadProgressMap[stockProductId].push({ filename, progress });
    }
  }

  private async uploadFileToBlob(file: NzUploadFile, blobUrl: string, stockProductId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', blobUrl, true);
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          this.updateProgress(file.name, progress, stockProductId);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          resolve(true);
        } else {
          reject(false);
        }
      };

      xhr.onerror = () => reject(false);
      xhr.send(file as any);
    });
  }

  private async uploadFiles(stockProduct: StockProductModel): Promise<FileUploadModel[]> {
    const uploadedFiles: FileUploadModel[] = [];
    
    try {
      const tokenResponse = await this.storageService.getStorageSASToken('qualityinspection').toPromise();
      this.sasTokenData = tokenResponse;

      const files = this.stockProductFiles[stockProduct.StockProductId] || [];
      
      for (const file of files) {
        const guid = uuidv4();
        const extension = file.name.split('.').pop();
        const dateFolder = moment().format('YYYY/MM/DD');
        const filePath = `qualityinspection/${dateFolder}/${this.NewStock.StockId}/${stockProduct.StockProductId}/${guid}.${extension}`;
        
        const blobUrl = `${this.sasTokenData.StorageAccountHost}/${filePath}?${this.sasTokenData.StorageAccountToken}`;

        const uploadSuccess = await this.uploadFileToBlob(file, blobUrl, stockProduct.StockProductId);
        if (!uploadSuccess) {
          throw new Error(`Failed to upload file: ${file.name}`);
        }

        uploadedFiles.push({
          FileName: file.name,
          FilePath: filePath,
          ContainerName: this.StorageContainerName
        });
      }

      return uploadedFiles;

    } catch (error) {
      console.error('Upload files error:', error);
      throw new Error(`Failed to upload files for product ${stockProduct.ProductName}: ${error.message}`);
    }
  }

  // Modify the existing SaveStockInspection method
  async SaveStockInspection() {
    try {
      this.IsSaveBtLoading = true;

      // Check if files are selected for all products
      const missingFiles = this.NewStock.StockProduct.some(p =>
        !this.stockProductFiles[p.StockProductId] ||
        this.stockProductFiles[p.StockProductId].length === 0
      );

      if (missingFiles) {
        this.IsSaveBtLoading = false;
        this.alertService.error('Please upload inspection reports and any required images for all products.');
        return;
      }

      // Upload files for all products
      try {
        const uploadPromises = this.NewStock.StockProduct.map(async (stockProduct) => {
          const uploadedFiles = await this.uploadFiles(stockProduct);
          if (uploadedFiles.length === 0) {
            throw new Error(`File upload failed for product ${stockProduct.ProductName}`);
          }
          return { stockProduct, uploadedFiles };
        });

        // Wait for all uploads to complete
        const uploadResults = await Promise.all(uploadPromises);

        // If all uploads successful, assign files to respective products
        uploadResults.forEach(({ stockProduct, uploadedFiles }) => {
          stockProduct.FileUploads = uploadedFiles;
        });

        // All uploads successful, proceed with API call
        let url = this.ApiUrl + "stock/stockqualityinspection";
        this.http.post<any>(url, this.NewStock).subscribe({
          next: res => {
            this.IsSaveBtLoading = false;
            this.alertService.success(res);
            this.router.navigate(['/home/stockqualityinspectionlist'])
          },
          error: (error) => {
            this.IsSaveBtLoading = false;
            console.error('API Error:', error);
            this.alertService.error("An error has occurred while saving. Please try again");
          },
        });

      } catch (uploadError) {
        this.IsSaveBtLoading = false;
        console.error('Upload Error:', uploadError);
        this.alertService.error(`File upload failed: ${uploadError.message}`);
        return;
      }

    } catch (error) {
      this.IsSaveBtLoading = false;
      console.error('General Error:', error);
      this.alertService.error("Error saving inspection. Please try again.");
    }
  }
}
