import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../environments/environment";
import { UserInfo } from "../../Authentication/UserInfo";
import { Modules, Responsibility, OutpassStatus } from "../../Models/Enums";
import { AdminStoreModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, RackModel, StoreModel } from "../../Models/MasterModel";
import { OutpassItemModel, OutPassModel, OutpassStatusActionModel } from "../../Models/OutPassModel";
import { AlertMessageService } from "../../Services/AlertMessageService";
import { AuthService } from "../../Services/auth.service";
import * as moment from 'moment';
import { LoadingService } from "src/PmsUIApp/Services/loadingService";
import { ProductModel } from "src/PmsUIApp/Models/ProductModel";
import { CustomerModel } from "src/PmsUIApp/Models/SupplierModel";
import { GateInModel } from "src/PmsUIApp/Models/GateInModel";
import { TransportModel, TransportVehicleModel } from "src/PmsUIApp/Models/TransportModel";
import { OutpassTimelineService } from "src/PmsUIApp/Services/OutpassTimelineService";
@Component({
  selector: "app-outpasslist",
  templateUrl: "./outpasslist.component.html",
  styleUrls: ["./outpasslist.component.css"],
})
export class OutPassListComponent implements OnInit {
  ApiUrl = environment.Api_Url;

  isVisible = false;
  IsProductsVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  searchValue = "";
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any

  visible = false;
  error: any = { isError: false, errorMessage: "" };
  OutPassList: OutPassModel[] = [];
  outPassOriginal: OutPassModel[] = [];
  OutPass!: OutPassModel;
  OutPassProducts: OutpassItemModel[] = [];

  AdminStoreList: AdminStoreModel[] = [];
  RackListOriginal: RackModel[] = [];
  RackList: RackModel[] = [];
  fields: OutPassModel = new OutPassModel();
  exportoptions = {
    headers: [
      "OutpassTo",
      "OutpassType",
      "OutpassDate",
      "OutpassNumber",
      "Purpose",
      "Remark"

    ],
  };
  exportfields: OutPassModel[] = [];

  permission = {
    View: false,
    Add: false,
    Cancel: false,
    Approval: false,
    Manage: false,
    AddTransport: false
  }
  count: number;
  totalItemsCount: number;
  request = {
    OutpassToCustomerId: 0,
    OutpassTo: "",
    OutpassNumber: "",
    OutpassType: "",
    PurposeId: 0,
    FromDate: "",
    ToDate: "",
    IsOutpassIn: null,
    OutpassProductName: ""
  }
  FilteredProductList: ProductModel[];
  ProductList: ProductModel[];
  SelectedProductType: string;
  ProductCategoryList: ProductCategoryModel[];
  CategoryID: number;
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  FirstCategoryID: number;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[];
  SecondCategoryID: number;
  CustomerList: CustomerModel[];
  IsActivitiesActionPopVisible: boolean = false;
  OutpassStatusAction: OutpassStatusActionModel = new OutpassStatusActionModel();
  outpassStatusList = Object.values(OutpassStatus);
  selectedOutpass: OutPassModel;
  IsTransportVisible: boolean = false;
  IsExtendExpectedReturnVisible: boolean = false;
  OutpassModify: OutPassModel;
  TransportList: TransportModel[];
  TransportVehicleList: TransportVehicleModel[] = [];
  VehicleStatus: string = null;
  constructor(public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private loader: LoadingService,
    private OutpassTimeline: OutpassTimelineService) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.Add);
    this.permission.Cancel = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.Cancel);
    this.permission.Approval = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.Approval);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.Manage);
    this.permission.AddTransport = this.auth.CheckResponsibility(Modules.Transport, Responsibility.Add);

    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllOutPass();
    this.GetAllStore();
    this.GetAllRack();
  }
  GetAllStore() {
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {


        res.forEach((x) => {
          var str = new AdminStoreModel();
          str.StoreId = x.StoreId;
          str.StoreName = x.StoreName;
          this.AdminStoreList.push(str);
        });
        this.GetUserStores(UserInfo.EmailID);
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;
      this.RackListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllRack(); }
    });
  }
  GetUserStores(UserName: string) {
    this.isTableLoading = true;
    this.AdminStoreList.forEach((x) => (x.IsChecked = false));
    let url = this.ApiUrl + 'user/getuserstores/' + UserName;
    this.http.get<number[]>(url).subscribe(
      (res) => {
        console.log(`res is what`, res);
        this.AdminStoreList.forEach((s) => {
          if (res.includes(s.StoreId)) {
            s.IsChecked = true;
          }
        });
        this.AdminStoreList = this.AdminStoreList.filter((x) => x.IsChecked);

        this.isTableLoading = false;
      },
      (res) => { }
    );
  }
  GetStorewiseStock(data: OutpassItemModel) {

    this.RackList = this.RackListOriginal.filter(x => x.StoreId == data.ReturnedStoreId);
  }
  GetAllOutPass() {
    let url = this.ApiUrl + 'outpass/getalloutpasswithfilters';
    this.http.post<OutPassModel[]>(url, this.request).subscribe(
      (res) => {
        this.outPassOriginal = res;
        this.OutPassList = res
        this.isTableLoading = false;
        this.exportfields = [];
        let count = this.totalItemsCount = 0;
        this.OutPassList.forEach((x) => {
          count++;
          x.SerialNo = count;
          this.fields = new OutPassModel();
          this.fields.OutpassTo = x.OutpassTo
          this.fields.OutpassType = x.OutpassType
          this.fields.OutpassDate = x.OutpassDate
          this.fields.OutpassNumber = x.OutpassNumber
          this.fields.Purpose = x.Purpose
          this.fields.Remark = x.Remark

          this.exportfields.push(this.fields);

        });
        this.totalItemsCount = count;
        this.isTableLoading = false;
        console.log(` this.OutPassList`, this.OutPassList)
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllOutPass();
        }
      }
    );
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  reset(): void {
    this.searchValue = "";
    this.search();
    this.GetAllOutPass();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'Out-Pass-export' + exportdate,
        this.exportoptions
      );
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.outPassOriginal;
    var res2 = this.outPassOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: OutPassModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.OutpassDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.OutpassDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.OutPassList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.OutPassList = res.filter(
        (item: OutPassModel) =>
          item?.OutpassType?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.OutpassNumber?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.OutpassTo?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Purpose?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Remark?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.OutpassType?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

  print(data: OutPassModel) {
    window.open(`${window.location.origin}/outpass/print/` + data.OutpassId);
  }
  cancel() { }
  OpenInoutPass(data: OutPassModel) {

    this.isVisible = true;
    this.OutPass = data;
    console.log(this.OutPass)
  }
  Save() {
    debugger
    var StoreItemsCount = this.OutPass.OutpassItems.filter(x => x.RackId > 0).length;
    var ReturnStoreItemsCount = this.OutPass.OutpassItems.filter(x => x.ReturnedRackId > 0).length;
    if (StoreItemsCount > ReturnStoreItemsCount) {
      this.alertService.error('Please select return rack for all store products');
      return;
    }
    this.OutPass.AddedBy = UserInfo.EmailID;
    let url = this.ApiUrl + 'outpass/inOutpass';
    this.http.post<any>(url, this.OutPass).subscribe({
      next: (res) => {
        this.alertService.success('Outpass Saved Successfully');
        this.isLoading = false;
        this.handleCancel();
        this.GetAllOutPass();
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;

      },
    });
  }
  OpenProductList(data: OutpassItemModel[]) {
    this.OutPassProducts = data
    this.IsProductsVisible = true;
  }
  handleProductsViewCancel(): void {
    this.IsProductsVisible = false;
    this.OutPassProducts = [];
  }
  GetProductName(data: OutPassModel) {
    if (data.OutpassItems.length == 0) {
      return 'NA'
    }
    else if (data.OutpassItems.length == 1) {
      return data.OutpassItems[0].ProductName
    }
    else {
      return data.OutpassItems[0].ProductName + '<span class="spn"> & <a class="btn-link"> ' + (data.OutpassItems.length - 1) + ' more </a></span>'
    }

  }
  GetCellColorforNonStoreItems(data: OutPassModel) {
    if (data.OutpassItems.filter(x => x.StockProductId < 1).length > 0) {
      return 'yellow';
    }
    else {
      return '';
    }
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllProducts();
      this.GetAllCustomer();
    }
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
    this.GetAllProductCategory();
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        const filteredProductCategoryIds = this.FilteredProductList.map(x => x.ProductCategoryId);
        this.ProductCategoryList = res.filter(x => filteredProductCategoryIds.includes(x.ProductCategoryId));
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetAllCustomer() {
    this.loader.show();
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe({
      next: res => {
        this.CustomerList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetAllCustomer(); }
      }
    });
  }
  handleActivitiesActionCancel() {
    this.IsActivitiesActionPopVisible = false;
  }
  ShowOutpassStatusChange(data: OutPassModel) {
    this.OutpassStatusAction = new OutpassStatusActionModel();
    this.selectedOutpass = data
    this.IsActivitiesActionPopVisible = true;
  }
  SaveStatusChange() {
    this.OutpassStatusAction.OutpassId = this.selectedOutpass.OutpassId;
    let url = this.ApiUrl + 'outpass/outpassstatusactions';
    this.http.post<any>(url, this.OutpassStatusAction).subscribe({
      next: (res) => {
        this.alertService.success('Outpass status updated successfully');
        this.IsActivitiesActionPopVisible = false;
        this.GetAllOutPass(); // Refresh the list
      },
      error: (res) => {
        this.alertService.error(res.error);
      },
    });
  }
  StatusChangeConfirmation(OutpassId: number, Status: string) {
    const modal1 = this.modalService.confirm({
      nzTitle: 'Confirmation',
      nzContent: 'Are you sure to proceed?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.ChangeOutpassStatus(OutpassId, Status),
    });
    setTimeout(() => modal1.destroy(), 30000);
  }
  ChangeOutpassStatus(OutpassId: number, Status: string) {
    this.OutpassStatusAction = new OutpassStatusActionModel();
    this.OutpassStatusAction.OutpassId = OutpassId;
    this.OutpassStatusAction.Status = Status;
    let url = this.ApiUrl + 'outpass/outpassstatusactions';
    this.http.post<any>(url, this.OutpassStatusAction).subscribe({
      next: (res) => {
        this.alertService.success('Outpass status updated successfully');
        this.IsActivitiesActionPopVisible = false;
        this.GetAllOutPass(); // Refresh the list
      },
      error: (res) => {
        this.alertService.error(res.error);
      },
    });
  }
  EnableApproveButton(data: OutPassModel) {
    return data.Status == 'Approval Pending' && this.permission.Approval
  }
  EnableOnHoldButton(data: OutPassModel) {
    return data.Status == 'Approval Pending' && (this.permission.Cancel || this.permission.Manage)
  }
  EnableCancelButton(data: OutPassModel) {
    return data.Status == 'Approval Pending' && (this.permission.Add || this.permission.Manage)
  }
  EnablePrintButton(data: OutPassModel) {
    return ((data.Status == 'Approved' || data.Status == 'Approval Pending') && this.permission.View)
  }
  handletransportcancel() {
    this.IsTransportVisible = false;
    this.OutpassModify = new OutPassModel();
    this.TransportList = [];
  }
  showTransportModal(data: OutPassModel): void {
    this.GetAllTransport(data);

  }
  ShowOutpassReturnExtendModel(data: OutPassModel) {
    this.OutpassModify = new OutPassModel();
    this.selectedOutpass = data;
    this.OutpassModify.ExpectedReturnDate = this.selectedOutpass.ExpectedReturnDate;
    this.OutpassModify.OutpassId = this.selectedOutpass.OutpassId;
    this.IsExtendExpectedReturnVisible = true;
  }
  handleExtendExpectedReturnCancel() {
    this.IsExtendExpectedReturnVisible = false;
    this.OutpassModify = new OutPassModel();
  }
  disabledPastDate = (DateValue: Date): boolean => {
    if (!DateValue || !this.OutpassModify.ExpectedReturnDate) {
      return false;
    }
    return DateValue.getTime() <= new Date(this.OutpassModify.ExpectedReturnDate).getTime();
  };
  GetAllTransport(data: OutPassModel) {
    this.loader.show()
    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe({
      next: res => {
        this.TransportList = res
        this.OutpassModify = data;
        if (this.OutpassModify.TransportId > 0) {
          this.onSelectedTransportChange(this.OutpassModify.TransportId);
        }
        this.loader.hide();
        this.IsTransportVisible = true;
      },
      error: res => {
        this.loader.hide();
        this.alertService.error("An error has been occured. Please try again");
        this.count++;
        if (this.count < 2) {
          this.GetAllTransport(data);
        }
      },
    });
  }
  onSelectedTransportChange($event: number) {
    var selectedTransport = this.TransportList.filter(x => x.TransportId == $event)[0];
    this.TransportVehicleList = selectedTransport.TransportVehicle;

  }

  onSelectedVehicleChange($event: number) {
    let url = this.ApiUrl + "gate/getvehiclestatus?vehicleid=" + $event;
    this.http.get<GateInModel>(url).subscribe({
      next: res => {
        this.VehicleStatus = null;
        if (res != null) {
          this.VehicleStatus = "Selected Vehicle is already Gate-in";
          if (res.GatePassIssue == true) {
            this.VehicleStatus = this.VehicleStatus + " and Gate pass issued";
          }
        }
      },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
      },
    });
  }
  SaveModifyOutpass() {
    if (this.OutpassModify.VehicleId == 0 && this.OutpassModify.TransportId == 0 && this.OutpassModify.Remark == '') {
      this.alertService.error('Remark is required');
      return;
    }
    let url = this.ApiUrl + 'outpass/modifyoutpass';
    this.http.post<any>(url, this.OutpassModify).subscribe({
      next: (res) => {
        this.alertService.success('Outpass updated successfully');
        if (this.OutpassModify.VehicleId > 0) {
          this.handletransportcancel();
        }
        else {
          this.handleExtendExpectedReturnCancel();
        }

        this.GetAllOutPass(); // Refresh the list
      },
      error: (res) => {
        this.alertService.error(res.error);
      },
    });
  }
  OpenTimeline(OutpassId: number) {
    this.OutpassTimeline.OutpassId = OutpassId;
    this.OutpassTimeline.show();
  }
}
