<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Gate Out</nz-page-header-title>
      <nz-page-header-subtitle>Manage your gate out functions here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Date Type</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.DateType" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.dateTypeList;" [nzValue]="s.Value"
                      [nzLabel]="s.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>From Date</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="fromdate" class="form-control"
                    [(ngModel)]="request.FromDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>To Date</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="todate" class="form-control"
                    [(ngModel)]="request.ToDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Purchase Order No.</label>
                  <input nz-input name="purchaseOrderNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                    [(ngModel)]="request.PONumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Supplier</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.SupplierId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                      [nzLabel]="s.SupplierName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Invoice No.</label>
                  <input nz-input name="invoiceNo" nzAllowClear placeholder="Enter Full Number" maxlength="30"
                    [(ngModel)]="request.InvoiceNumber" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Transport</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedSupplierCode" nzSize="default"
                    [(ngModel)]="request.TransportId" (ngModelChange)="GetAllVehicleForFilter($event)"
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                      [nzLabel]="s.TransportCompanyName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Vehicle</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="Vehicle" nzSize="default"
                    [(ngModel)]="request.VehicleId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilterVehicleList;" [nzValue]="s.VehicleId"
                      [nzLabel]="s.VehicleNumber"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Gate Out Type</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="GateInType" nzSize="default"
                    [(ngModel)]="request.Type" nzPlaceHolder="Choose">
                    <nz-option nzValue="Delivery" nzLabel="Delivery"></nz-option>
                    <nz-option nzValue="Order Dispatch" nzLabel="Order Dispatch"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Gate Out Status</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="GatePass" nzSize="default"
                    [(ngModel)]="request.IsGateOutCompleted" nzPlaceHolder="Choose">
                    <nz-option nzValue=true nzLabel="Completed"></nz-option>
                    <nz-option nzValue=false nzLabel="Pending"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                (click)="GetAllGateInRecords()">
                Apply Filter
              </button>
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="ResetFilters()">
                Reset
              </button>
              <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search" [(ngModel)]="searchValue" 
      (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i> Export</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.GateInList" nzBordered
      [nzLoading]="isTableLoading" [nzScroll]="{ x: '1200px',y:'515px' }" nzShowPagination="true" nzShowSizeChanger
      [nzPageSizeOptions]=[50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="50px">Id</th>
          <th nzLeft nzWidth="150px">Vehicle Number</th>
          <th nzWidth="350px">Transport Company</th>
          <th nzWidth="130px">PO Number</th>
          <th nzWidth="150px">Invoice Number</th>
          <th nzWidth="100px">In Weight</th>
          <th nzWidth="100px">Out Weight</th>
          <th nzWidth="150px">Type</th>
          <!--<th>Invoice Number</th>-->
          <th nzWidth="150px">Driver Name</th>
          <th nzWidth="150px">Driver Number</th>
          <th nzWidth="120px">Gate Pass Issued Date</th>

          <th nzWidth="120px">Gate-Out Date</th>
          <th nzWidth="130px">Added By</th>
          <th nzWidth="100px" style="text-align:center">Pass Issued</th>
          <th nzWidth="100px" style="text-align:center">Gate-Out</th>

          <th nzWidth="250px" style="text-align:center" nzRight></th>
          <!-- <th nzWidth="20%">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.GateInId }}</td>
          <td nzLeft>{{ data.VehicleNumber }}</td>
          <td>{{ data.TransportCompanyName }}</td>
          <td>{{ GetAllPONumber(data) }}</td>
          <td>{{ GetInvoices(data.Invoice) }}</td>
          <td>{{ data.InWeight }}</td>
          <td>{{ data.OutWeight }}</td>
          <td>{{ data.Type }}</td>
          <!--<td>{{ data.Invoice.InvoiceNumber }}</td>-->
          <td>{{ data.GateInPerson }}</td>
          <td>{{ data.GateInPersonContact }}</td>
          <td>{{ data.GatePassIssueDate | DatetimeConverter}}</td>
          <td>{{ data.GateOutDate | DatetimeConverter}}</td>
          <td>{{ data.GateOutAddedBy }}</td>
          <td style="text-align:center">
            <div class="badge badge-light-danger" *ngIf="!data.GatePassIssue">Not-Issued</div>
            <div class="badge badge-light-success" *ngIf="data.GatePassIssue">Issued</div>
          </td>

          <td style="text-align:center">
            <div class="badge badge-light-danger" *ngIf="!data.GateOut">Not Yet</div>
            <div class="badge badge-light-success" *ngIf="data.GateOut">Completed</div>
          </td>

          <td style="text-align:center" nzRight>
            <button [disabled]="isLoading || data.GateOut || data.GatePassIssue != true" *ngIf="this.permission.Add"
              class="btn btn-sm btn-light-primary" nz-popconfirm
              nzPopconfirmTitle="Are you sure you want to gate out the vehicle?" nzOkText="ok" nzCancelText="cancel"
              (nzOnConfirm)="OpenImagePopop(data)">
              <i nz-icon nzType="loading" *ngIf="isLoading"></i>
              <i nz-icon nzType="export" nzTheme="outline"></i>
              <span class="indicator-label">Gate Out</span>
            </button>
            <button class="btn btn-sm btn-light-primary" *ngIf="isLoading || data.GateOut || data.GatePassIssue != true"
              (click)="GetImages(data.GateInId)">View Images</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal [nzWidth]="600" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isImageVisible" [nzTitle]="modalTitle1"
  [nzContent]="modalContent1" [nzFooter]=null (nzOnCancel)="handleCancelImage()">
  <ng-template #modalTitle1>Upload Images</ng-template>

  <ng-template #modalContent1>
    <nz-form-item>
      <nz-form-label [nzSpan]="5">Upload Image</nz-form-label>
      <nz-form-control [nzSpan]="18" nzErrorTip="Contact Person Name required">
        <input class="form-select mb-2" [(ngModel)]="uploadinvoice" [ngModelOptions]="{standalone: true}" type="file" accept="image/*"
          multiple="multiple" (change)="onChange($event)">
        <div *ngIf="filesSelected" hidden="true">
          <h2>Upload Progress</h2>
          <pre>{{uploadProgress$ | async | json}}</pre>
        </div>
      </nz-form-control>
    </nz-form-item>

    <div *ngIf="IsDriverInputVisible">
      <nz-divider></nz-divider>
      <nz-card nzType="inner" nzTitle="Driver Details - Sales Order Dispatch without GateIn">
        <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Driver Name</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Contact Driver Name required">
              <input nz-input formControlName="GateInPerson" name="GateInPerson" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Driver Number</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
              <input nz-input formControlName="GateInPersonContact" name="GateInPersonContact"
                onkeypress="if (this.value.length > 9) return false;" />
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-card>
    </div>
    <nz-divider></nz-divider>
    <div class="text-center">
      <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="GateOut()">Gate Out</button>
    </div>
  </ng-template>



</nz-modal>


<nz-modal [nzWidth]="1000" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isImageViewVisible" [nzTitle]="modalTitle2"
  [nzContent]="modalContent2" [nzFooter]=null (nzOnCancel)="handleCancelViewImage()">
  <ng-template #modalTitle2>Gate Out Images</ng-template>

  <ng-template #modalContent2>
    <!--<nz-carousel nzAutoPlay *ngIf="FilteredImageArray.length>0">
    <div nz-carousel-content *ngFor="let index of FilteredImageArray">

      <img src="{{ index.URL }}" />
    </div>
  </nz-carousel>-->

    <div class="row" *ngIf="FilteredImageArray.length>0">
      <div class="column" *ngFor="let index of FilteredImageArray">
        <a href="{{ index.URL }}" target="_blank">
          <img src="{{ index.URL }}" alt="Snow" style="width:100%">
        </a>
      </div>

    </div>
    <div *ngIf="FilteredImageArray.length==0">No image found</div>
  </ng-template>



</nz-modal>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>