import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OutpassItemModel, OutPassModel } from 'src/PmsUIApp/Models/OutPassModel';
import { AdminStoreModel, OutPassPurposeModel, RackModel, StoreModel } from '../../Models/MasterModel';
import { UserInfo } from '../../Authentication/UserInfo';
import { IssueSaleOrderProductsStockModel, ProductStockStoreReportModel } from '../../Models/StockProductModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { TransportModel, TransportVehicleModel } from 'src/PmsUIApp/Models/TransportModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { GateInModel } from 'src/PmsUIApp/Models/GateInModel';

@Component({
  selector: 'app-outpassAdd',
  templateUrl: './outpoassAdd.component.html',
  styleUrls: ['./outpassAdd.css'],
})
export class OutPassAddComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  isLoading: boolean = false;
  isTableLoading: boolean = true;
  NewOutPass: OutPassModel = new OutPassModel;
  MeasureUnits: MeasureUnitModel[] = [];
  SelectedPurpose: OutPassPurposeModel;
  SelectTransport: TransportModel;
  SelectedCustomer: CustomerModel;
  productItem = {
    CategoryID: 0,
    FirstCategoryID: 0,
    SecondCategoryID: 0,
    ProductID: 0,
    ProductName: '',
    UnitID: 0,
    Unit: '',
    Grade: 'NA',
    IGST: 0,
    Rate: 0,
    Quantity: 0,
    Amount: 0,
  };
  isVisible = false;
  PopUpTitle = 'Add Stock';
  SelectedStoreID = 0;
  SelectedRackID = 0;
  SelectedStockProductId = 0;
  SelectedQty = 0;
  SelectedAmount = 0;
  selectedStoreDn: any;
  selectedunit = '';
  AdminStoreList: AdminStoreModel[] = [];
  StoreWiseStock: IssueSaleOrderProductsStockModel[] = [];
  ProductList: IssueSaleOrderProductsStockModel[] = [];
  RackListOriginal: RackModel[] = [];
  RackList: RackModel[] = [];
  IsFromStore = false
  ProductName = '';
  private route$: Subscription = new Subscription();
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  OutPassPurposeList: OutPassPurposeModel[];
  CustomerMaster: boolean = true;
  CustomerList: CustomerModel[];
  TransportList: TransportModel[] = [];
  TransportVehicleList: TransportVehicleModel[] = [];
  VehicleStatus: string = '';
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private loader: LoadingService
  ) { }
  GetAllStore() {
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {


        res.forEach((x) => {
          var str = new AdminStoreModel();
          str.StoreId = x.StoreId;
          str.StoreName = x.StoreName;
          this.AdminStoreList.push(str);
        });
        this.GetUserStores(UserInfo.EmailID);
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;
      this.RackListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllRack(); }
    });
  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => { this.GetAllUnits() });
  }
  GetUserStores(UserName: string) {
    this.isTableLoading = true;
    this.AdminStoreList.forEach((x) => (x.IsChecked = false));
    let url = this.ApiUrl + 'user/getuserstores/' + UserName;
    this.http.get<number[]>(url).subscribe(
      (res) => {
        console.log(`res is what`, res);
        this.AdminStoreList.forEach((s) => {
          if (res.includes(s.StoreId)) {
            s.IsChecked = true;
          }
        });
        this.AdminStoreList = this.AdminStoreList.filter((x) => x.IsChecked);

        this.isTableLoading = false;
      },
      (res) => { }
    );
  }
  GetStorewiseStock(data: any) {
    this.selectedStoreDn = data;

    if (this.SelectedStoreID > 0) {
      this.SelectedRackID = 0;
      //this.loader.show();
      this.isTableLoading = true;
      let url =
        this.ApiUrl +
        'consume/getallproductstoconsumebystoreid/' +
        this.SelectedStoreID;
      this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(
        (res) => {
          console.log(`check data`, res);
          this.StoreWiseStock = res.filter((x) => x.Quantity > 0);

          this.RackList = this.RackListOriginal.filter(x => x.StoreId == this.SelectedStoreID);
          console.log(this.RackList)
          //this.RackList =  [...new Set(this.rl.map(item => item))];
          this.StoreWiseStock.forEach((x) => {
            this.CheckQty(x);

            //this.exportfields = [];
            //this.NewEdit.forEach((x) => {
            //  this.fields.ProductName = x.ProductName;
            //  this.fields.ProductType = x.ProductType;
            //  this.fields.Quantity = x.Quantity;
            //  this.exportfields.push(this.fields);
            //});
          });
          // this.loader.hide();
          this.isTableLoading = false;
        },
        (res) => { }
      );
    }
  }
  OnRackChange() {
    this.ProductList = this.StoreWiseStock.filter(x => x.RackId == this.SelectedRackID)
  }
  OnProductChange() {
    this.SelectedQty = this.ProductList.filter(x => x.StockProductId == this.SelectedStockProductId)[0].RemainingQuantity;
    this.selectedunit = this.ProductList.filter(x => x.StockProductId == this.SelectedStockProductId)[0].Unit;
  }
  CheckQty(data: ProductStockStoreReportModel) {
    if (data.ConsumptionQuantity > data.Quantity) {
      data.ConsumptionQuantity = data.Quantity;
      data.RemainingQuantity = 0;
      this.alertService.error(
        'ConsumptionQuantity cannot be more than ' + data.Quantity
      );
    } else {
      //console.log(data.Quantity);
      //console.log(data.ConsumptionQuantity);

      data.RemainingQuantity =
        (data.Quantity ?? 0) - (data.ConsumptionQuantity ?? 0);
      //console.log(data.RemainingQuantity);
    }

    if (data.SCQuantity > data.Quantity) {
      data.SCQuantity = data.Quantity;
      data.SCRemainingQuantity = 0;
      this.alertService.error(
        'ConsumptionQuantity cannot be more than ' + data.SCQuantity
      );
    } else {
      //console.log(data.SCQuantity);
      //console.log(data.ConsumptionQuantity);

      data.SCRemainingQuantity = (data.Quantity ?? 0) - (data.SCQuantity ?? 0);
      //console.log(data.RemainingQuantity);
    }
  }
  handleOk(): void {
    this.Save()

  }
  handleCancel(): void {
    this.isVisible = false;
  }
  showModal() {
    this.isVisible = true;
  }
  AddStock() {
    var item = new OutpassItemModel;
    if (this.IsFromStore) {
      item.RackId = this.SelectedRackID;
      item.StockProductId = this.SelectedStockProductId;
      item.Quantity = this.SelectedQty;
      item.Amount = this.SelectedAmount;
      item.Total = item.Quantity * item.Amount;
      item.ProductName = this.ProductList.filter(x => x.StockProductId == this.SelectedStockProductId)[0].ProductName
      item.RackName = this.RackList.filter(x => x.RackId == this.SelectedRackID)[0].RackName;
      item.StoreName = this.RackList.filter(x => x.RackId == this.SelectedRackID)[0].StoreName;
      item.Unit = this.selectedunit;
    } else {
      item.Quantity = this.SelectedQty;
      item.Amount = this.SelectedAmount;
      item.ProductName = this.ProductName;
      item.Total = item.Quantity * item.Amount;
      item.Unit = this.selectedunit;
    }
    this.NewOutPass.OutpassItems.push(item)
    this.handleCancel()
    this.reset()
  }
  reset() {
    this.SelectedStoreID = 0;
    this.SelectedRackID = 0;
    this.SelectedStockProductId = 0;
    this.SelectedQty = 0;
    this.SelectedAmount = 0;
    this.ProductName = '';
  }
  Save() {
    if (this.SelectedCustomer == null && this.CustomerMaster == true) {
      this.alertService.error("Select Customer from Drop Down.");
      return;
    }
    if (this.NewOutPass.OutpassTo == "" && this.CustomerMaster == false) {
      this.alertService.error("Enter Name in Out Pass To");
      return;
    }
    if (this.NewOutPass.OutpassType == "") {
      this.alertService.error("Please select Out Pass Type.");
      return;
    }
    if (this.NewOutPass.OutpassType == "Returnable" && this.NewOutPass.ExpectedReturnDate == null) {
      this.alertService.error("Please select expected return date.");
      return;
    }
    if (this.NewOutPass.Remark.length > 150) {
      this.alertService.error("Remark cannot be more than 150 characters");
      return;
    }
    if (this.NewOutPass.Purpose.length > 150) {
      this.alertService.error("Purpose cannot be more than 150 characters");
      return;
    }
    if (this.SelectedPurpose == null) {
      this.alertService.error("Please select appropriate purpose.");
      return;
    }
    if (this.NewOutPass.OutpassItems.length < 1) {
      this.alertService.error("Please add atleast one item.");
      return;
    }
    if (this.CustomerMaster) {
      this.NewOutPass.OutpassTo = this.SelectedCustomer.CustomerName;
      this.NewOutPass.OutpassToCustomerId = this.SelectedCustomer.CustomerId;
    }
    this.NewOutPass.Purpose = this.SelectedPurpose.PurposeName;
    this.NewOutPass.PurposeId = this.SelectedPurpose.PurposeId;
    let url = this.ApiUrl + 'outpass/addoutpass';
    this.http.post<any>(url, this.NewOutPass).subscribe({
      next: (res) => {
        this.alertService.success('Out Pass Saved Successfully');
        this.isLoading = false;

        this.router.navigate(['/home/outpass/list']);
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;

      },
    });
  }
  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.OutPass, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/welcome']);
    }
    this.validateForm = this.fb.group({

    });
    this.GetAllCustomer();
    this.GetAllUnits();
    this.GetAllStore();
    this.GetAllRack();
    this.GetAllOutPassPurposes();
    this.GetAllTransport();
    this.NewOutPass.OutpassDate = new Date()
  }
  get f() {
    return this.validateForm.controls;
  }
  handleRemoveRow(data: any) {
    this.NewOutPass.OutpassItems = this.NewOutPass.OutpassItems.filter((obj) => obj !== data);

  }
  GetAllOutPassPurposes() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "outpass/getalloutpasspurpose";
    this.http.get<OutPassPurposeModel[]>(url).subscribe(res => {
      this.OutPassPurposeList = res;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllOutPassPurposes();
      }
    });
  }
  GetAllCustomer() {
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllCustomer(); }
    });
  }
  GetAllTransport() {
    let url = this.ApiUrl + "transport/getalltransport";
    this.http.get<TransportModel[]>(url).subscribe({
      next: res => { this.TransportList = res },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
        this.count++;
        if (this.count < 2) {
          this.GetAllTransport();
        }
      },
    });
  }
  onSelectedTransportChange($event: number) {
    var transport = this.TransportList.filter(x => x.TransportId == $event)[0];
    this.TransportVehicleList = transport.TransportVehicle;

  }

  onSelectedVehicleChange($event: number) {
    let url = this.ApiUrl + "gate/getvehiclestatus?vehicleid=" + $event;
    this.http.get<GateInModel>(url).subscribe({
      next: res => {
        this.VehicleStatus = null;
        if (res != null) {
          this.VehicleStatus = "Selected Vehicle is already Gate-in";
          if (res.GatePassIssue == true) {
            this.VehicleStatus = this.VehicleStatus + " and Gate pass issued";
          }
        }
      },
      error: res => {
        this.alertService.error("An error has been occured. Please try again");
      },
    });
  }
}
