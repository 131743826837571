import { InvoiceModel } from "./InvoiceModel";

export class GateInModel {
  GateInId: number = 0;
  VehicleId: number = 0;
  TransportCompanyName: string = '';
  VehicleNumber: string = '';
  VehicleType: string = '';
  SupplierId: number = 0;
  SupplierName: string = '';
  InvoiceId: number = 0;
  GateIn: boolean = false;
  GateInDate: string = '';
  GateInPersonContact: string = '';
  GateInPerson: string = '';
  GatePassIssue: boolean = false;
  GatePassIssueDate: string = '';
  GateOut: boolean = false;
  GateOutDate: string = '';
  AddedBy: string = '';
  GatePassAddedBy: string = '';
  GatePassAddedByName: string = '';
  GateOutAddedBy: string = '';
  AddedDate: string = '';
  Invoice: InvoiceModel[] = [];
  SaleOrderId: number[] = [];
  Type!: string;
  InWeight: string = '';
  OutWeight: string = '';
  IsOutpassRequired: string = '';
}


export class GateInPrintModel {
  VehicleNumber: string = '';
  TransportCompanyName: string = '';
  GateInPerson: string = '';
  GateInPersonContact: string = '';
  GateInDate: string = '';
  GatePassIssueDate: string = '';
  GateOutDate: string = '';
  AddedBy: string = '';
  GateIn: string = '';
  GatePassIssue: string = '';
  GateOut: string = '';


}
