<div *ngIf="this.IssueSlipProducts.length > 0" id="pdfTable" style="border:1px solid;background-color: white;">
  <div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
    <p style="font-size: 14px;"><b>{{this.Company.CompanyName}}</b></p>
  </div>
  <div style="text-transform: uppercase; font-size: 12px; text-align: center; color: black; border: 1px solid black;">
    <b>Software Generated Material Issue Slip No: <b>{{IssueSlipProducts[0].IssueSlipNumber}}</b></b>
  </div>
  <div style="text-transform: uppercase; font-size: 12px; text-align: center; color: black; border: 1px solid black;">
    <b>Status: <b>{{IssueSlipProducts[0].Status}}</b></b>
  </div>
  <!-- <nz-descriptions *ngIf="IssueSlipProducts.length > 0" nzBordered nzSize="small" [nzColumn]="2"
    style="border: 1px solid black;">
    <nz-descriptions-item nzTitle="Issue Slip No."
      style="font-size: x-small;"><b>{{IssueSlipProducts[0].IssueSlipNumber}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Requested Date Time"><b>{{IssueSlipProducts[0].SlipGeneratedDate |
        DatetimeConverter}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Requested By"></nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Store/Department Name"><b>{{IssueSlipProducts[0].ToStoreName}}</b></nz-descriptions-item>
  </nz-descriptions> -->
  <div>
    <table class="producttable" style="text-transform: uppercase;">
      <tbody>
        <tr>
          <td><b>TO/My STORE NAME: {{IssueSlipProducts[0].ToStoreName}}</b></td>
          <td><b>FROM STORE NAME: {{IssueSlipProducts[0].FromStoreName}}</b></td>
        </tr>
        <tr>
          <td><b>TO RACK NAME: {{IssueSlipProducts[0].ToRackName}}</b></td>
          <td><b>FROM RACK NAME: {{IssueSlipProducts[0].FromRackName}}</b></td>
        </tr>
        <tr>
          <td>Requested Department: <b>{{IssueSlipProducts[0].RequestedDeptName}}</b></td>
          <td>Date Time: <b>{{IssueSlipProducts[0].SlipGeneratedDate | DatetimeConverter}}</b></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>
    <table class="producttable" style="font-size: smaller;">
      <thead>
        <tr>
          <th width="50px">S.No.</th>
          <th>ITEM NAME</th>
          <th>UNIT</th>
          <th>ORDER NO.</th>
          <th>DEMAND QTY</th>
          <th>ISSUED QTY</th>
          <th>REMARKS</th>
          <th>Requested By</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of IssueSlipProducts; let i=index">
          <tr>
            <td>{{ data.IssueIdList }}</td>
            <td>{{ data.ProductName }}</td>
            <td>{{ data.Unit }}</td>
            <td>{{ data.SaleOrderNumber }}</td>
            <td>{{ data.DemandQuantity }}</td>
            <td>{{ data.Quantity }}</td>
            <td>{{ data.Remark }}</td>
            <td>{{ data.CreatedBy }}</td>
          </tr>
          <tr *ngIf="data.Status === 'Approved' && data.LabelDetails.length > 0">
            <td colspan="9" class="label-details">
              <table class="subtable">
                <thead>
                  <tr>
                    <th>Serial No.</th>
                    <th>Packaging Unit</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let label of data.LabelDetails">
                    <td>{{ label.SerialNo }}</td>
                    <td>{{ label.PackagingUnit }}</td>
                    <td>{{ label.Quantity }} {{ data.Unit }}</td>
                  </tr>
                  <tr class="subtable-summary">
                    <td colspan="2" style="text-align: right;"><strong>Total Labels:</strong></td>
                    <td><strong>{{ data.LabelDetails.length }}</strong></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="footer">
    <table class="footer-table">
      <tr>
        <td width="25%">
          <p style="margin-bottom:5px;margin-top: 22px;"><b> PREPARED BY:</b></p>
        </td>
        <td width="25%">
          <p style="margin-bottom:5px;margin-top: 22px;"><b>APPROVED BY:</b></p>
        </td>
        <td width="25%">
          <p style="margin-bottom:5px;margin-top: 22px;"><b>ISSUED BY:</b></p>
        </td>
        <td width="25%">
          <p style="margin-bottom:5px;margin-top: 22px;"><b>RECEIVED BY:</b></p>
        </td>
      </tr>
      <tr>
        <td width="25%">
          <p style="margin-bottom: 20px;font-style: italic; font-weight: bolder;font-family:cursive;">
            <b>{{IssueSlipProducts[0].SlipGeneratedBy.toUpperCase()}}</b>
          </p>
        </td>
        <td width="25%">
          <p style="margin-bottom: 3px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <b>{{IssueSlipProducts[0]?.ActionBy?.toUpperCase()}}</b>
          </p>
          <p *ngIf="IssueSlipProducts[0]?.ActionBy != null"
            style="margin-bottom: 5px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <b>On - {{IssueSlipProducts[0]?.ActionDate | DatetimeConverter}}</b>
          </p>
        </td>
        <td width="25%">
          <p style="margin-bottom: 20px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <!-- <b>{{PurchaseOrder.ApprovedBy?.Name.toUpperCase()}}</b> -->
          </p>
        </td>
        <td width="25%">
          <p style="margin-bottom: 20px;font-style: italic; font-weight:bolder;font-family:cursive;">
            <!-- <b>{{PurchaseOrder.ApprovedBy?.Name.toUpperCase()}}</b> -->
          </p>
        </td>
      </tr>
    </table>
    <p>Powered By: KanzenFlow</p>
  </div>
</div>