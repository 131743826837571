import { InvoiceModel } from "./InvoiceModel";
import { StockProductModel } from "./StockProductModel";
import { FileUploadModel } from "./UploadModel";
import { UserModel } from "./UserModel";

export class StockModel {
  StockId: number = 0;
  StockDate: string = '';
  Grn: string = '';

  InvoiceId: number = 0;
  InspectionCompleted: boolean = false;
  IsQualityInspectionCompleted: boolean = false;
  QualityInspectionCompletedBy: UserModel = new UserModel();
  QualityInspectionCompletedDate: string = '';
  ManageRejectedItemsCompleted: boolean = false;
  AllocationCompleted: boolean = false;
  IsOpeningStock: boolean = false;
  Products: string = '';
  Invoice: InvoiceModel = new InvoiceModel();
  StockProduct: StockProductModel[] = [];
  AddedBy: string = '';
  AddedDate: string;
  IsTransferedStock: boolean = false;
  ProductQuality: string = '';
}

export interface IUploadProgress {
  filename: string;
  progress: number;
}
export class StoreWiseStockModel {
  "SNO": number = 0;
  "ProductType": string;
  "ProductName": string;
  "Quantity": number = 0;
  "Unit": string = '';
  "ProductId": number = 0;
  "ProductCode": string;
  "StoreId": number = 0;
  "StoreName": string;
  "StoreCode": string;
  "RackId": number = 0;
  "RackName": string;
  "RackCode": string;
  "QUANTITY PER RACK": number = 0
  "StoreDetails"?: any = []
  "StoreDetailsCopy"?: any = []
  "calculatedQuantity"?: any = null;
  "ProductCategoryId": number = 0;
  "ProductFirstSubCategoryId": number = 0;
  "ProductSecSubCategoryId": number = 0;
}

export class StockPrintModel {

  StockDate: string = '';
  InvoiceNumber: string = '';
  InvoiceDate: string = '';
  GRN: string = '';
  InvoiceTotalPrice: string = '';
  Products: string = '';
  Count: number = 0;
  Inspection: string = '';
  Allocation: string = '';



}

export class StoreWiseStockViewModel {
  ProductId: number = 0;
  ProductCode: string = '';
  ProductType: string = '';
  ProductName: string = '';
  Quantity: number = 0;
  Unit: string = '';
  ProductCategoryId: number = 0;
  ProductFirstSubCategoryId: number = 0;
  ProductSecSubCategoryId: number = 0;
  StoreDetails: StoreWiseStockStoreViewModel[] = [];
}
export class StoreWiseStockStoreViewModel {
  StoreName: string = '';
  RackName: string = '';
  Quantity: number = 0;
  Unit: string = '';
}
