import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyInfo } from '../../../../Authentication/UserInfo';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { StockLabelModel, StockLabelResponseModel } from 'src/PmsUIApp/Models/BarcodeLabelModel';
import JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-barcodelabelprint',
  templateUrl: './barcodelabelprint.component.html',
  styleUrls: ['./barcodelabelprint.component.css']
})
export class BarcodeLabelPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription();
  StockLabel: StockLabelResponseModel[] = [];
  StockLabelRequest: StockLabelModel = new StockLabelModel;
  requestAction: number = 0;
  Company = {
    CompanyName: '',
    Contact: '',
    Email: '',
    Address: '',
    Address2ndLine: '',
    GST: '',
  };
  isLoaded = false;
  permission = {
    View: false
  }
  selectedLabelsIdList: number[] = [];
  @ViewChild('barcodeContainer', { static: false }) barcodeContainer: ElementRef;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.permission.View = this.auth.CheckResponsibility(Modules.FinalInspection, Responsibility.View);
    // if (this.permission.View != true) {
    //   this.router.navigate(['/home/unauthorized']);
    // }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.StockLabelRequest.StockProductId = parseInt(params['StockProductId']);
      this.StockLabelRequest.StockId = parseInt(params['StockId']);
      this.StockLabelRequest.ProductId = parseInt(params['ProductId']);
      this.StockLabelRequest.NumberOfLabels = parseInt(params['NumberOfLabels']);
      this.requestAction = parseInt(params['action']);
    });
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.Contact = CompanyInfo.Contact;
    this.Company.Email = CompanyInfo.Email;
    this.Company.Address = CompanyInfo.ShortAddress;
    this.Company.Address2ndLine = CompanyInfo.ShortAddress2ndLine;

  }
  OpenViewPop() {
    let url = '';
    var generateNewBarcode = sessionStorage.getItem('generatenewbarcode');
    if (this.requestAction == 1 && generateNewBarcode == 'true') {
      url = this.ApiUrl + 'stock/generateproductstocklabel';
      sessionStorage.removeItem('generatenewbarcode');
    }
    else if (this.requestAction == 1 && generateNewBarcode != 'true') {
      url = this.ApiUrl + '';
      return;
    }
    else if (this.requestAction == 2) {
      url = this.ApiUrl + 'stock/getstocklabelsbyproductstock';
    }

    if (this.requestAction == 1 || this.requestAction == 2) {
      this.http.post<StockLabelResponseModel[]>(url, this.StockLabelRequest).subscribe((res) => {
        this.StockLabel = res;
        setTimeout(() => {
          this.generateBarcodes();
          window.print();
          window.onafterprint = () => window.close();
        }, 100);
      });
    }

    if (this.requestAction == 3) {
      const labelsJson = sessionStorage.getItem('selectedLabelsIdList');
      if (labelsJson) {
        this.selectedLabelsIdList = JSON.parse(labelsJson);
        sessionStorage.removeItem('selectedLabelsIdList'); // Clear the session storage after retrieving the data

        url = this.ApiUrl + 'stock/getstocklabelsbystocklabelid';
        this.http.post<StockLabelResponseModel[]>(url, this.selectedLabelsIdList).subscribe((res) => {
          this.StockLabel = res;
          setTimeout(() => {
            this.generateBarcodes();
            window.print();
            window.onafterprint = () => window.close();
          }, 100);
        });
      }
    }
  }



  generateBarcodes() {
    if (this.barcodeContainer) {
      const barcodeElements = this.barcodeContainer.nativeElement.querySelectorAll('.barcode');
      barcodeElements.forEach((element: SVGElement, index: number) => {
        JsBarcode(element, this.StockLabel[index].SerialNo, {
          format: 'CODE128',
          width: 3,  // Adjust this value to change barcode width
          height: 30,  // Adjust this value to change barcode height
          displayValue: true,
          fontSize: 18,  // Adjust this value to change the font size of the barcode text
          margin: 5,     // Adjust this value to change the margin around the barcode
          fontOptions: 'bolder',  // Adjust this value to change the font weight of the barcode text
        });
      });
    }
  }

  CheckOnload() {
    this.OpenViewPop();
  }
}





