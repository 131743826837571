import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { OutPassModel } from "../../Models/OutPassModel";
import { CompanyInfo } from "src/PmsUIApp/Authentication/UserInfo";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import { AuthService } from "src/PmsUIApp/Services/auth.service";

@Component({
  selector: "app-outpassprint",
  templateUrl: "./outpassprint.component.html",
  styleUrls: ["./outpassprint.component.css"],
})
export class OutPassPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  OutPass!: OutPassModel;
  OutpassId = 0;
  private route$: Subscription = new Subscription();
  count: number;
  PrintAllowed: boolean = false;
  Company = {
    CompanyName: '',
    HeadOfficeAddress: '',
    FactoryAddress: ''
  };
  constructor(public http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.OutpassId = params['id'];
    });
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.HeadOfficeAddress = CompanyInfo.HeadOfficAddress;
    this.Company.FactoryAddress = CompanyInfo.FactoryAddress;
    this.GetOutPassById(this.OutpassId)
  }
  GetOutPassById(outpassId: number) {
    let url = this.ApiUrl + 'Outpass/getoutpassbyid?outpassid=' + outpassId;
    this.http.get<OutPassModel>(url).subscribe(
      (res) => {
        this.OutPass = res;
        this.PrintAllowed = this.OutPass.Status == 'Approved' || this.OutPass.Status == 'New'
        this.OutPass.OutpassItems.forEach(y => {
          y.Total = (y.Quantity ?? 0) * (y.Amount ?? 0);
        })
        setTimeout(function () {
          window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetOutPassById(outpassId);
        }
      }
    );
  }

}
