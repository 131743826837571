import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BankMasterModel } from '../../Models/BankModel';
import { ProformaInvoiceModel } from '../../Models/ProformaModel';
import { environment } from '../../../environments/environment';
import { PIDrawerService } from '../../Services/PIDrawerService';

@Component({
    selector: 'app-PIDrawerView',
    templateUrl: './PIDrawerView.component.html',
})
export class PIDrawerViewComponent implements OnInit {
    ApiUrl = environment.Api_Url;
    IsPopupOpen = this.loader.loading$;
    ProformaInvoiceList: ProformaInvoiceModel[] = [];
    ProformaInvoice!: ProformaInvoiceModel;
    isDrawerVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    searchValue = '';
    private route$: Subscription = new Subscription();
    BankList: BankMasterModel[] = [];
    Bank: BankMasterModel = new BankMasterModel();
    ProformaInvoiceId = 0;
    count: number;
    constructor(
        private loader: PIDrawerService,
        public http: HttpClient,

    ) {
        this.loader.event_callback.subscribe(x => {
            this.GetAllProformaInvoice(x);
        })
    }
    ngOnInit() {
        this.GetAllBank();
    }
    GetAllBank() {
        this.isTableLoading = true;
        let url = this.ApiUrl + 'bank/getallbanks';
        this.http.get<BankMasterModel[]>(url).subscribe(
            (res) => {
                this.BankList = res;
                this.isTableLoading = false;
            },
            (res) => {
                this.count++;
                if (this.count < 2) {
                    this.GetAllBank();
                }
            }
        );
    }
    handleCancel(): void {
        this.isDrawerVisible = false;
    }
    GetAllProformaInvoice(id: any) {
        let url = this.ApiUrl + `proformainvoice/getproformainvoicebyid/${id}`;
        this.http.get<ProformaInvoiceModel>(url).subscribe(
            (res) => {
                this.ProformaInvoice = res;
                this.isDrawerVisible = true;
                this.ProformaInvoice.TermsCondition = this.ProformaInvoice.TermsCondition.replaceAll('\n', '<br>');
                this.Bank = this.BankList.filter(x => x.BankId == this.ProformaInvoice.BankId)[0];
            },
            (error) => {
                this.count++;
                if (this.count < 2) {
                    this.GetAllProformaInvoice(id);
                }
            }
        );
    }
    CalculateGrandTotal(data: any[], column: string) {
        var total = 0;
        switch (column) {
            case column = "Quantity": {
                total = parseFloat((data.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0))?.toFixed()) ?? 0
                break;
            }

            case column = "Price": {
                total = parseFloat((data.reduce((sum, current) => sum + parseFloat(current.Price?.toString()), 0))?.toFixed(2)) ?? 0
                break;
            }
            case column = "Total Price": {
                var Quantity = data.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
                var Price = data.reduce((sum, current) => sum + parseFloat(current.Price?.toString()), 0) ?? 0
                total = parseFloat((Quantity * Price)?.toFixed(2));
                break;
            }
        }
        return parseFloat(total.toFixed(2));
    }
}  