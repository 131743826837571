import { ESalesOrderStatus } from "./Enums";
import { FormulationCodeMixingRawMaterialModel } from "./FormulationCodeModel";
import { LacquerRawMaterial } from "./ProductionModel";
import { IssueSaleOrderProductsStockModel } from "./StockProductModel";
import { JumboActualListModel } from "./WorkPlanModel";
type Nullable<T> = T | null;

export class SaleOrderModel {
  SerialNo: number = 0;
  IsChecked: boolean = false;
  WorkPlanStatus: boolean = false;
  WorkPlanNumber: string = '';
  SaleOrderId: number = 0;
  ProformaInvoiceId: number = 0;
  SaleOrderType: string = '';

  BORNumber: string = '';
  CustomerId: number = 0;
  CustomerName: string = '';
  CategoryId: number = 0;
  Category: string = '';
  FormulationFabricProductName: string = '';
  FormulationFabricProductId: number = 0;
  FormulationFabricProductPrice: number = 0;
  FormulationFabricProductQty: number = 0;
  FormulationFabricProducWidthInMeter: number = 0;
  FormulationFabricProductUnit: string = '';
  SaleOrderNumber: string = '';
  LinkedSaleOrder: LinkedSaleOrderModel[] = [];
  ParentSaleOrder: LinkedSaleOrderModel[] = [];
  OrderIdForLinking: number = 0;
  SaleOrderDate: string = '';
  DeliveryDate: string = '';
  IsRawMaterialIssued: boolean = false;
  AddedBy: string = '';
  AddedDate: string = '';
  Remarks: string = '';
  ProductionCompletionRemarks: string = '';
  CostingAdded: boolean = false;
  GrandTotal: number = 0;
  Status: ESalesOrderStatus = 0;
  SaleOrderStatus: string = '';
  SaleFormulationCode: string = '';
  SaleFormulationCodeId: number = 0;
  SaleOrderPostProcessOrder: SaleOrderPostProcessOrderModel[] = [];
  SaleOrderProduction!: SaleOrderProductionModel;
  SaleOrderProductionPostProcess!: SaleOrderProductionPostProcessModel;
  FormulationCode!: SaleFormulationCodeMasterModel;
  SendToConsumption: boolean = false;
  ConsumptionStoreId: number = 0;
  IsJumboRequired: boolean = false;
  IsPrintRequired: boolean = false;
  IsEmbossingRequired: boolean = false;
  IsLacquerRequired: boolean = false;
  IsVacuumRequired: boolean = false;
  IsTumblingRequired: boolean = false;
  SaleOrderDispatchReady: SaleOrderDispatchReadyModel = new SaleOrderDispatchReadyModel();
  SaleOrderDispatch: SaleOrderDispatchModel[] = [];
  SaleOrderCosting: SaleOrderCostingModel = new SaleOrderCostingModel;
  SaleOrderProductionComplete: SaleOrderProductionCompleteModel = new SaleOrderProductionCompleteModel;
  PreInspectionCompletedBy: string = '';
  PreInspectionCompletedOn: string = '';
  IsPrimaryChecked: boolean = false;
  IsLinkedChecked: boolean = false;
  OrderStatusActionBy: string = '';
  OrderStatusActionDate: string = '';
  ShiftSupervisorWorkerId: number = 0;
}

export class SaleOrderProductionModel {
  SaleOrderProductionId: number = 0;
  SaleOrderId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategoryId: number = 0;
  ProductFirstSubCategory: string = '';
  ManufacturingProductCode: string = '';
  ManufacturingProductName: string = '';
  Lot: number = 0;
  Batch: number = 0;
  OrderQuantity!: number;
  LMConstant: number = 1.5;
  ExtraProduction!: number;
  ManufacturingQuantity!: number;
  Unit: string = '';
  ColorId: number = 0;
  ColorName: string = '';
  ColorCode: string = '';
  FabricColorId: number = 0;
  FabricProductId: number = 0
  FabricProductUnit: string = '';
  FabricProductName: string = '';
  FabricProductWidthInMeter: number = 0;
  FabricColorName: string = '';
  ColorPrice: number = 0;
  Barcode: string = '';
  GrainId: number = 0;
  GrainName: string = '';
  GrainCode: string = '';
  GrainPrice: number = 0;
  Thick: number = 0;
  ThicknessValue: string = ''
  ThickPrice: number = 0;
  Width: number = 0;
  WidthNumber: string = '';
  WidthPrice: number = 0;
  ProductionStatus: string = '';
  CostingStatus: string = '';
  SlippagePercent: number = 0;
  TotalCost: number = 0;
  MixingTotalCost: number = 0;
  MixingGSMTotal: number = 0;
  FinalMixingGSMTotal: number = 0;
  RawGSMTotal: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  LacquerMasterId: number[] = [];
  ProcessFormulationCode: string = '';
  MixingFormulationCode: string = '';
  SalePrice: number = 0;
  TotalSalePrice: string = '0';
  PreSkinGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  AdhesiveGsm: number = 0;
  FabricGsm: number = 0;
  TotalGsm: number = 0;
  RejectionPercentAutoCalculated: number = 0;
  Mixing: SaleOrderProductionMixingModel[] = [];
  FormulationMixing: SaleOrderProductionMixingModel[] = [];
  InspectionFormulationMixing: SaleOrderProductionMixingModel[] = [];
  InspectionSaleFormulationCode: SaleFormulationCodeMasterModel;
  SaleOrderProductionEmbossing: SaleOrderProductionEmbossingModel[] = [];
  SaleOrderProductionPrint: SaleOrderProductionPrintModel[] = [];
  SaleOrderProductionTumbling: SaleOrderProductionTumblingModel[] = [];
  SaleOrderProductionVacuum: SaleOrderProductionVacuumModel[] = [];
  LacquerRawMaterial: SaleOrderProductionLacquerRawMaterialModel[] = [];
  SaleOrderProductionRawMaterial: SaleOrderProductionRawMaterialModel[] = [];
  SaleOrderProductionElement: SaleOrderProductionElementModel[] = [];
  SaleOrderPostProcessPrint!: SaleOrderPostProcessPrintModel;
  SaleOrderPostProcessEmbossing!: SaleOrderPostProcessEmbossingModel;
  SaleOrderPostProcessLacquer!: SaleOrderPostProcessLacquerModel;
  SaleOrderPostProcessTumbling!: SaleOrderPostProcessTumblingModel;
  SaleOrderPostProcessVacuum!: SaleOrderPostProcessVacuumModel;
  Lacquer: SaleOrderProductionLacquerModel[] = [];
  SaleOrderProductionMiscellaneousRawMaterial: SaleOrderProductionMiscellaneousRawMaterialModel[] = [];
}
export class SaleOrderProductionPostProcessModel {
  SaleOrderProductionId: number = 0;
  ManufacturingProductName: string = '';
  SaleOrderPostProcessPrint!: SaleOrderPostProcessPrintModel[];
  SaleOrderPostProcessEmbossing!: SaleOrderPostProcessEmbossingModel[];
  SaleOrderPostProcessLacquer!: SaleOrderPostProcessLacquerModel[];
  SaleOrderPostProcessTumbling!: SaleOrderPostProcessTumblingModel[];
  SaleOrderPostProcessVacuum!: SaleOrderPostProcessVacuumModel[];
  SaleOrderProductionPrint: SaleOrderProductionPrintModel[];
  ThicknessValue: string;
  WidthNumber: string;
  ManufacturingQuantity: number;
  OrderQuantity: number;
  GrainCode: string;
  ColorName: string;
  FabricColorName: string;
  SaleOrderProductionEmbossing: SaleOrderProductionEmbossingModel[] = [];
  SaleOrderProductionVacuum: SaleOrderProductionVacuumModel[] = [];
  Lacquer: SaleOrderProductionLacquerModel[] = [];
  SaleOrderProductionTumbling: SaleOrderProductionTumblingModel[] = [];
}

export class SaleOrderProductionMixingModel {
  SaleOrderProductionMixingId: number = 0;
  ProductionMixingName: string = '';
  MixingName: string = '';
  SaleOrderProductionId: number = 0;
  Wastage: number = 0;
  WastageType: string = '';
  Total: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  WeightGsm: number = 0;
  CostPerKg: number = 0;
  CostGsm: number = 0;
  CostPerLm: number = 0;
  GSM: number = 0;
  ExtraGSM: number = 0;
  LessGSM: number = 0;
  FinalGSM: number = 0;
  AdhesiveGsmPasteReq: number = 0;
  FoamGsmPasteReq: number = 0;
  PreSkinGsmPasteReq: number = 0;
  SkinGsmPasteReq: number = 0;
  AdhesiveGsmSCPasteReq: number = 0;
  FoamGsmSCPasteReq: number = 0;
  PreSkinGsmSCPasteReq: number = 0;
  SkinGsmSCPasteReq: number = 0;
  TotalQuantity: number = 0;
  TotalScQuantity; number = 0;
  TotalCost: number = 0;
  FabricGSM: number = 0;
  TotalQtyInMtrs: number = 0;
  TotalQtyInKgs: number = 0;
  PricePerMtr: number = 0;
  StdPasteRequirementQuantity: number = 0;
  StdPasteRequirementScquantity: number = 0;
  MixingRawMaterial: SaleOrderProductionMixingRawMaterialModel[] = [];
}

export class SaleOrderProductionMixingRawMaterialModel {
  SaleOrderProductionMixingRawMaterialId: number = 0;
  SaleOrderProductionMixingId: number = 0;
  ProductId: number = 0;
  Quantity: number = 0;
  WidthInMeter: number = 0;
  ConvertedQtyKgsToMtrs: number = 0;
  CalculatedQuantity: number = 0;
  CalculatedSCQuantity: number = 0;
  Scquantity: number = 0;
  BaseQuantity: number = 0;
  IsBaseMaterial: boolean = false;
  Unit: string = '';
  Price: number = 0;
  BatchNo: string = '';
  ProductName: string = '';
  ProductCode: string = '';
  AvgGsm: number = 0;
  Perishable: boolean = false;
}

export class SaleOrderCompleteProductionModel {
  WorkPlanJumboMasterId: number = 0;
  SaleOrderId: number = 0;
  ProductionCompletionRemarks: string = '';
  PreSkinGsm: number = 0;
  PreSkinScGsm: number = 0;
  PreSkinRemainingPasteQty: number = 0;
  PreSkinActualPasteQty: number = 0;
  SkinGsm: number = 0;
  SkinScGsm: number = 0;
  SkinRemainingPasteQty: number = 0;
  SkinActualPasteQty: number = 0;
  FoamGsm: number = 0;
  FoamScGsm: number = 0;
  FoamRemainingPasteQty: number = 0;
  FoamActualPasteQty: number = 0;
  AdhesiveGsm: number = 0;
  AdhesiveScGsm: number = 0;
  AdhesiveRemainingPasteQty: number = 0;
  AdhesiveActualPasteQty: number = 0;
  SaleOrderProduction!: SaleOrderProductionModel;
}

export class LinkedSaleOrderModel {
  ParentSaleOrder: number = 0;
  ParentSaleOrderNumber: string = '';
  LinkedSaleOrder: number = 0;
  LinkedSaleOrderNumber: string = '';
  LinkedId: number = 0;
};

export class SaleOrderProductionEmbossingModel {
  SaleOrderProductionEmbossingMasterId: number = 0;
  SaleOrderProductionId: number = 0;
  EmbossingMasterId: number = 0;
  Code: string = '';
  Name: string = '';
  ImageName: string = '';
  Description: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Total: number = 0;
  Removed: boolean = false;
  RemovedBy: string = '';
  RemovedDate: string = '';
  Rank: number = 0;
}
export class SaleOrderProductionPrintModel {
  SaleOrderProductionPrintMasterId: number = 0;
  SaleOrderProductionId: number = 0;
  PrintMasterId: number = 0;
  Code: string = '';
  Name: string = '';
  ImageName: string = '';
  Description: string = '';
  Quantity: number = 0;
  Price: number = 0;
  Total: number = 0;
  Removed: boolean = false;
  RemovedBy: string = '';
  RemovedDate: string = '';
  Rank: number = 0;
}

export class SaleOrderProductionTumblingModel {
  SaleOrderProductionTumblingMasterId: number = 0;
  TumblingMasterId: number = 0;
  SaleOrderProductionId: number = 0;
  Code: string = '';
  Name: string = '';
  Description: string = '';
  Quantity: number = 1;
  Price: number = 0;
  Total: number = 0;
  Removed: boolean = false;
  RemovedBy: string = '';
  RemovedDate: string = '';
  Rank: number = 0;
}

export class SaleOrderProductionVacuumModel {
  SaleOrderProductionVacuumMasterId: number = 0;
  VacuumMasterId: number = 0;
  SaleOrderProductionId: number = 0;
  Code: string = '';
  Name: string = '';
  Description: string = '';
  Quantity: number = 1;
  Price: number = 0;
  Total: number = 0;
  Removed: boolean = false;
  RemovedBy: string = '';
  RemovedDate: string = '';
  Rank: number = 0;
}
export class SaleOrderProductionLacquerModel {
  Name: string = ''
  Code: string = '';
  Description: string = '';
  LacquerMasterId: number = 0;
  Removed: boolean = false;
  RemovedBy: string = '';
  RemovedDate: string = '';
  TotalQuantity: number = 0;
  TotalPerUnitPrice: number = 0;
  LacquerRawMaterial: LacquerRawMaterial[] = [];
  Rank: number = 0;
}

export class SaleOrderProductionLacquerRawMaterialModel {
  SaleOrderProductionLacquerRawMaterialId: number = 0;
  SaleOrderProductionId: number = 0;
  LacquerMasterId: number = 0;
  ProductId: number = 0;
  Quantity: number = 0;
  Unit: string = '';
  Price: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  AvgGsm: number = 0;
  Total: number = 0;
}

export class SaleOrderProductionRawMaterialModel {
  SaleOrderProductionRawMaterialId: number = 0;
  SaleOrderProductionId: number = 0;
  ProductId: number = 0;
  Unit: string = '';
  Quantity: number = 0;
  AcceptQTY: number = 0;
  PerUnitCost: number = 0;
  TotalCost: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  AvgGsm: number = 0;
  AvailableQTY: number = 0;
  ProductStock: IssueSaleOrderProductsStockModel[] = [];
  MixingName: string = '';
}

export class SaleOrderProductionMiscellaneousRawMaterialModel {
  SaleOrderProductionMiscellaneousRawMaterialId: number = 0;
  SaleOrderProductionId: number = 0;
  ProductId: number = 0;
  Unit: string = '';
  Quantity: number = 0;
  Price: number = 0;
  BatchNo: string = '';
  ProductName: string = '';
  ProductCode: string = '';
  MaterialCategory: string = '';
}

export class SaleOrderProductionElementModel {
  SaleOrderProductionElementId: number = 0;
  SaleOrderProductionId: number = 0;
  ElementId: number = 0;
  ElementName: string = '';
  Value: string = '';
  PerUnitCost: number = 0;
  TotalCost: number = 0;
  Total: number = 0;
}

export class ProductStockStoreModel {
  IsDisabled: boolean = false;
  IsChecked: boolean = false;
  StoreId: number = 0;
  StoreName: string = '';
  Quantity: number = 0;
  AcceptQTY: number = 0;
  Supplier: string = '';
  StockDate: string = '';
  Barcode: string = '';
}


export class SaleOrderFormulationMixingModel {
  FormulationCodeMixingId: number = 0;
  MixingName: string = '';
  SaleFormulationCodeId: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  MixingRawMaterial: SaleOrderProductionMixingRawMaterialModel[] = [];
}


export class SaleFormulationCodeMasterModel {
  SaleFormulationCodeId: number = 0;
  SaleFormulationCode: string = '';
  AddedBy: string = '';
  AddedDate?: string;
  CategoryId?: number = 0;
  ThicknessId?: number = 0;
  PreSkinGsm?: number = 0;
  SkinGsm?: number = 0;
  FoamGsm?: number = 0;
  AdhesiveGsm?: number = 0;
  FabricGsm?: number = 0;
  TotalGsm?: number = 0;
  FabricProductId?: number = 0;
  FabricProductName: string = '';
  CategoryName: string = '';
  FabricProductQty?: number = 0;
  FabricProductUnit?: string = '';
  FabricWidthInMeter?: number = 0;
  MixingRawMaterial!: FormulationCodeMixingRawMaterialModel[];
  ShiftSupervisorWorkerId: number = 0;
  ShiftSupervisorWorkerName: string = '';
}


export class SaleOrderPostProcessPrintModel {
  SaleOrderPostProcessPrintId: number = 0;
  SaleOrderId: number = 0;
  PrintRack: number = 0;
  StoreId: number = 0;
  PrintCompletedQuantity?: number = 0;
  ReceivedQuantity: number = 0;
  PrintWastageQuantity?: number = 0;
  PrintMeasurementUnit: string = '';
  PrintStatus: string = '';
  PrintCost: number = 0;
  AddedBy: string = '';
  AddedDate?: string = '';
  JumboActualList: JumboActualListModel[] = [];
  Remark: string = '';
  Rank: number = 0;
}

export class SaleOrderPostProcessEmbossingModel {
  SaleOrderPostProcessEmbossingId: number = 0;
  SaleOrderId: number = 0;
  EmbossingRack: number = 0;
  StoreId: number = 0;
  EmbossingCompletedQuantity?: number = 0;
  ReceivedQuantity: number = 0;
  EmbossingWastageQuantity?: number = 0;
  EmbossingMeasurementUnit: string = '';
  EmbossingCost: number = 0;
  EmbossingStatus: string = '';
  AddedBy: string = '';
  AddedDate?: string = '';
  JumboActualList: JumboActualListModel[] = [];
  Remark: string = '';
  Rank: number = 0;
}

export class SaleOrderPostProcessVacuumModel {
  SaleOrderPostProcessVacuumId: number = 0;
  SaleOrderId: number = 0;
  VacuumRack: number = 0;
  StoreId: number = 0;
  ReceivedQuantity: number = 0;
  VacuumCompletedQuantity?: number = 0;
  VacuumWastageQuantity?: number = 0;
  VacuumMeasurementUnit: string = '';
  VacuumStatus: string = '';
  VacuumCost: number = 0;
  AddedBy: string = '';
  AddedDate?: string = '';
  JumboActualList: JumboActualListModel[] = [];
  Remark: string = '';
  Rank: number = 0;
}

export class SaleOrderPostProcessLacquerModel {
  SaleOrderPostProcessLacquerId: number = 0;
  SaleOrderId: number = 0;
  LacquerRack: number = 0;
  StoreId: number = 0;
  ReceivedQuantity: number = 0;
  LacquerCompletedQuantity?: number = 0;
  LacquerWastageQuantity?: number = 0;
  LacquerMeasurementUnit: string = '';
  LacquerCost: number = 0;
  LacquerStatus: string = '';
  AddedBy: string = '';
  AddedDate?: string = '';
  JumboActualList: JumboActualListModel[] = [];
  Remark: string = '';
  Rank: number = 0;
}

export class SaleOrderPostProcessTumblingModel {
  SaleOrderPostProcessTumblingId: number = 0;
  SaleOrderId: number = 0;
  TumblingRack: number = 0;
  StoreId: number = 0;
  ReceivedQuantity: number = 0;
  TumblingCompletedQuantity?: number = 0;
  TumblingWastageQuantity?: number = 0;
  TumblingMeasurementUnit: string = '';
  TumblingCost: number = 0;
  TumblingStatus: string = '';
  AddedBy: string = '';
  AddedDate?: string = '';
  JumboActualList: JumboActualListModel[] = [];
  Remark: string = '';
  Rank: number = 0;
}

export class SaleOrderDispatchReadyModel {
  SaleOrderDispatchReadyId: number = 0;
  SaleOrderId: number = 0;
  DispatchReadyRack?: number = 0;
  DispatchReadyQuantity?: number = 0;
  DispatchReadyBarcode: string = '';
  DispatchReadyStatus: string = '';
  AddedBy: string = '';
  AddedDate?: string = '';
  RackName: string = '';
  StoreName: string = '';
  JumboActualList: JumboActualListModel[] = [];
}

export class SaleOrderPostProcessOrderModel {
  SaleOrderId: number = 0;
  Rank: number = 0;
  PostProcessName: string = '';
  DisplayProcessName: string = '';
  Status: string = '';
  ProcessMasterId: number = 0;
}

export class SaleOrderDispatchModel {
  SaleOrderDispatchId = 0;
  SaleOrderId = 0;
  TransportId = 0;
  TransportName = '';
  VehicleNumber = '';
  VehicleId = ''
  DispatchDate = ''
  DispatchNumber = ''
  AddedBy = ''
  AddedDate = ''
  PacketNumber = ''
  PacketWeight = ''
  IsGateIn = true
  IsGateOut = true
  IsDispatchCompleted = true
}

export class SaleOrderCostingModel {
  SaleOrderId: number = 0;
  FabricCost: number = 0;
  CoatingCost: number = 0;
  PasteCostLm: number = 0;
  GrainCostLm: number = 0;
  FabricCostLm: number = 0;
  FinishingCostLm: number = 0;
  RmcostLm: number = 0;
  Rejection: number = 0;
  ProductionCostLm: number = 0;
  PerLmconstant: number = 0;
  OverheadCost: number = 0;
  PrintCostPerUnit: number = 0;
  EmbossingCostPerUnit: number = 0;
  TumblingCostPerUnit: number = 0;
  VacuumCostPerUnit: number = 0;
  LacquerCostPerUnit: number = 0;
  InlineScraping: number = 0;
  SaleOrderMaterialType: string = '';
  PackagingCostPerUnit: number = 0;
  MiscellaneousCostPerUnit: number = 0;
  AddedBy: string = '';
  AddedDate?: string;
}

export class SaleOrderProductionCompleteModel {
  SaleOrderProductionCompleteId: number = 0;
  SaleOrderId: number = 0;
  ManufacturedQuantity: number = 0;
  PreSkinGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  AdhesiveGsm: number = 0;
  FabricGsm: number = 0;
  PreSkinActualPasteQty: number = 0;
  PreSkinRemainingPasteQty: number = 0;
  SkinActualPasteQty: number = 0;
  SkinRemainingPasteQty: number = 0;
  FoamActualPasteQty: number = 0;
  FoamRemainingPasteQty: number = 0;
  AdhesiveActualPasteQty: number = 0;
  AdhesiveRemainingPasteQty: number = 0;
  AddedBy: string = '';
  AddedDate?: string = '';
  PrdLineSpeed: number = 0;
  Overhead: number = 0;
}

export class SaleOrderCostingParamsModel {
  SaleOrderId: number = 0;
  GSMType: string = '';
  IsDataOnly: boolean = false;
}

export class SaleOrderCostingViewModel {
  SerialNo: number = 0;
  IsChecked: boolean = false;
  WorkPlanStatus: boolean = false;
  WorkPlanNumber: string = '';
  SaleOrderId: number = 0;
  SaleOrderType: string = '';

  BORNumber: string = '';
  CustomerId: number = 0;
  CustomerName: string = '';
  CategoryId: number = 0;
  Category: string = '';
  FormulationFabricProductName: string = '';
  FormulationFabricProductId: number = 0;
  FormulationFabricProductPrice: number = 0;
  FormulationFabricProductQty: number = 0;
  FormulationFabricProducWidthInMeter: number = 0;
  FormulationFabricProductUnit: string = '';
  SaleOrderNumber: string = '';
  LinkedSaleOrder: LinkedSaleOrderModel[] = [];
  SaleOrderDate: string = '';
  DeliveryDate: string = '';
  IsRawMaterialIssued: boolean = false;
  AddedBy: string = '';
  AddedDate: string = '';
  Remarks: string = '';
  ProductionCompletionRemarks: string = '';
  CostingAdded: boolean = false;
  GrandTotal: number = 0;
  Status: ESalesOrderStatus = 0;
  SaleOrderStatus: string = '';
  SaleFormulationCode: string = '';
  SaleFormulationCodeId: number = 0;
  SaleOrderPostProcessOrder: SaleOrderPostProcessOrderModel[] = [];
  SaleOrderProduction!: SaleOrderProductionCostingModel;
  FormulationCode!: SaleFormulationCodeMasterModel;
  SaleOrderCosting: SaleOrderCostingModel = new SaleOrderCostingModel;
  SaleOrderProductionComplete: SaleOrderProductionCompleteModel = new SaleOrderProductionCompleteModel;
  PreInspectionCompletedBy: string = '';
  PreInspectionCompletedOn: string = '';
  IsPrimaryChecked: boolean = false;
  IsLinkedChecked: boolean = false;
  OrderStatusActionBy: string = '';
  OrderStatusActionDate: string = '';
}
export class SaleOrderProductionCostingModel {
  SaleOrderProductionId: number = 0;
  SaleOrderId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategoryId: number = 0;
  ProductFirstSubCategory: string = '';
  ManufacturingProductCode: string = '';
  ManufacturingProductName: string = '';
  Lot: number = 0;
  Batch: number = 0;
  OrderQuantity!: number;
  LMConstant: number = 1.5;
  ExtraProduction!: number;
  ManufacturingQuantity!: number;
  Unit: string = '';
  ColorId: number = 0;
  ColorName: string = '';
  ColorCode: string = '';
  FabricColorId: number = 0;
  FabricProductId: number = 0
  FabricProductUnit: string = '';
  FabricProductName: string = '';
  FabricProductWidthInMeter: number = 0;
  FabricColorName: string = '';
  ColorPrice: number = 0;
  Barcode: string = '';
  GrainId: number = 0;
  GrainName: string = '';
  GrainCode: string = '';
  GrainPrice: number = 0;
  Thick: number = 0;
  ThicknessValue: string = ''
  ThickPrice: number = 0;
  Width: number = 0;
  WidthNumber: string = '';
  WidthPrice: number = 0;
  ProductionStatus: string = '';
  CostingStatus: string = '';
  SlippagePercent: number = 0;
  TotalCost: number = 0;
  MixingTotalCost: number = 0;
  MixingGSMTotal: number = 0;
  FinalMixingGSMTotal: number = 0;
  RawGSMTotal: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  LacquerMasterId: number[] = [];
  ProcessFormulationCode: string = '';
  MixingFormulationCode: string = '';
  SalePrice: number = 0;
  TotalSalePrice: string = '0';
  PreSkinGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  AdhesiveGsm: number = 0;
  FabricGsm: number = 0;
  TotalGsm: number = 0;
  RejectionPercentAutoCalculated: number = 0;
  Mixing: SaleOrderProductionMixingModel[] = [];
  FormulationMixing: SaleOrderProductionMixingModel[] = [];
  FinalFormulationMixing: SaleOrderProductionMixingModel[] = [];
  FinalFabricData: SaleOrderProductionMixingModel[] = [];
  InspectionSaleFormulationCode: SaleFormulationCodeMasterModel;
  SaleOrderProductionRawMaterial: SaleOrderProductionRawMaterialModel[] = [];
  SaleOrderPostProcessPrint!: SaleOrderPostProcessPrintModel;
  SaleOrderPostProcessEmbossing!: SaleOrderPostProcessEmbossingModel;
  SaleOrderPostProcessLacquer!: SaleOrderPostProcessLacquerModel;
  SaleOrderPostProcessTumbling!: SaleOrderPostProcessTumblingModel;
  SaleOrderPostProcessVacuum!: SaleOrderPostProcessVacuumModel;
  Lacquer: SaleOrderProductionLacquerModel[] = [];
  SaleOrderProductionMiscellaneousRawMaterial: SaleOrderProductionMiscellaneousRawMaterialModel[] = [];
  PackagingRawMaterial: SaleOrderProductionMiscellaneousRawMaterialModel[] = [];
}