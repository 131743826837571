import { StockLabelModel } from "./BarcodeLabelModel";

export class IssueProductModel {
  IssueType: string = '';
  SaleOrderId: number = 0;
  SaleOrderNumber: string = '';
  ProductName: string = '';
  Quantity: number = 0;
  DemandQuantity: number = 0;
  Batch: string = '';
  FromStoreName: string = '';
  ToStoreName: string = '';
  ActionDate: string = '';
  Status: string = '';
  IsChecked: boolean = false;
  IsDisabled: boolean = false;
  IssueId: number = 0;
  FromDept: number = 0;
  FromRack: number = 0;

  FromDeptName: string = '';
  ToDept: number = 0;
  ToDeptName: string = '';
  ProductId: number = 0;
  ProductCode: string = '';
  FromStore: number = 0;
  QuantityValue: number = 0;
  CreatedBy: string = '';
  CreatedDate: string = '';
  AuthorizedAction: string = '';
  isView: boolean = false;
  isAction: boolean = false;
  ActionBy: string = '';
  ToStore: number = 0;

  StockId: number = 0;
  StockProductId: number = 0;
  Remark: string = '';
  IssueSlipNumber: string = '';
  IssueSlipId: number = 0;
  RequestType: string = '';
  ToRackId: number = 0;
}

export class IssueProductViewModel {
  IssueId: number = 0;
  IssueSlipNumber: string = '';
  IssueType: string = '';
  ProductName: string = '';
  SaleOrderNumber: string = '';
  Quantity: number = 0;
  Batch: string = '';
  FromStoreName: string = '';
  ToStoreName: string = '';
  Remark: string = '';
  CreatedDate: string = '';
  CreatedBy: string = '';
  ActionDate: string = '';
  ActionBy: string = '';
  Status: string = '';
}

export class IssueProductActionModel {
  IssueId: number = 0;
  RackId: number = 0;
  FromRackId: number = 0;
  Status: string = '';
  IssueQuantity: number = 0;
  DemandQuantity: number = 0;
  ActionBy: string = '';
  ActionDate: string = '';
  StockId: number = 0;
  StockProductId: number = 0;
  StockLabelIds: number[] = [];
  ApprovalMode: string = '';
}


export class IssueProductSlipModel {
  IssueSlipId: number = 0;
  IssueId: number = 0;
  IssueIdList: string = '';
  SaleOrderId: number = 0
  SaleOrderNumber: string = '';
  IssueSlipNumber: string = '';
  Quantity: number = 0;
  DemandQuantity: number = 0;
  FromStore: number = 0;
  FromStoreName: string = '';
  FromRackName: string = '';
  ToStore: number = 0;
  ToStoreName: string = '';
  ToRackName: string = '';
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  Unit: string = '';
  CreatedBy: string = '';
  CreatedDate: string = '';
  SlipGeneratedBy: string = '';
  SlipGeneratedDate: string = '';
  Remark: string = '';
  ActionBy: string = '';
  ActionDate: string = '';
  RequestedDeptName: string = '';
  Status: string = '';
  LabelDetails: StockLabelModel[] = [];
}
export class SearchParamsProductIssueModel {
  DateFrom: Date = new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7));
  DateTo: Date = new Date(new Date().setHours(23, 59, 59));
  SaleOrderNumber: string = '';
  Status: string = '';
  IssueSlipNumber: string = '';
  IssueId: number = 0;
  FromStoreId: number = 0;
  ToStoreId: number = 0;
  RequestedBy: string = '';
  ActionBy: string = '';
  ProductType: string = '';
  Unit: string = null;
  ProductCategoryId: number = 0;
  ProductFirstSubCategoryId: number = 0;
  ProductSecSubCategoryId: number = 0;
  ProductId: number = 0;
  BatchNo: string = '';
}
