<app-SoDrawer></app-SoDrawer>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Post Process</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <nz-collapse>
      <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                  <label>Customer</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                    [(ngModel)]="request.CustomerId" (ngModelChange)="onFilterChange()" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                      [nzLabel]="s.CustomerName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                  <label>Formulation Code</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                    nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" (ngModelChange)="onFilterChange()"
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                      [nzLabel]="s.SaleFormulationCode"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                  <label>Article Name</label>
                  <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30" [(ngModel)]="request.ArticleName"
                    (ngModelChange)="onFilterChange()" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Color</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                    (ngModelChange)="onFilterChange()" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                      [nzLabel]="s.ColorName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Grain Name</label>
                  <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" (ngModelChange)="onFilterChange()"
                    nzSize="default" nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                      [nzLabel]="s.GrainName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Production Status</label>
                  <nz-select name="select-error" [(ngModel)]="request.Status" (ngModelChange)="onFilterChange()"
                    nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of status" [nzValue]="data.Value" [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Sale Order No.</label>

                  <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Number" maxlength="30"
                    [(ngModel)]="request.SaleOrderNumber" (ngModelChange)="onFilterChange()"
                    (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Date Type</label>
                  <nz-select name="select-error" [(ngModel)]="request.DateType" (ngModelChange)="onFilterChange()">
                    <nz-option *ngFor="let data of dateTypeList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>From Date</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromAddedDate" (ngModelChange)="onFilterChange()"
                    nzAllowClear></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>To Date</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToAddedDate" (ngModelChange)="onFilterChange()" nzAllowClear></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Order Type</label>
                  <nz-select name="select-error" [(ngModel)]="request.OrderType" (ngModelChange)="onFilterChange()"
                    nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of orderTypeList" [nzValue]="data" [nzLabel]="data"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Product Type</label>
                  <nz-select [(ngModel)]="request.ProductType" (ngModelChange)="onFilterChange()" nzAllowClear
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let data of PUPVCTypeList" [nzValue]="data.Value"
                      [nzLabel]="data.Text"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                (click)="GetPostProcessList()">
                Apply Filter
              </button>
              <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24">
      <!-- <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Select Product Category">
            <label>WorkPlan No </label>
            <nz-select class="form-select" nzShowSearch name="WorkPlanNo" [(ngModel)]="SelectedWorkPlan"
              nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanChange()">
              <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div> -->
      <nz-table style="width: 100%" nzSize="small" [nzScroll]="{ x: '1200px', y: '515px' }" #basicTable [nzData]="['']"
        [nzShowPagination]="false" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr>

            <th nzWidth="160px">Order No</th>
            <th nzWidth="200px">Customer</th>
            <th nzWidth="200px">Article Name</th>
            <th>
              Post Process
            </th>
            <!--<th nzWidth="60px">Print</th>
            <th nzWidth="60px">Embossing</th>
            <th nzWidth="60px">Vacuum</th>
            <th nzWidth="60px">Lacquer</th>
            <th nzWidth="60px">Tumbling</th>-->


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of SaleOrderList">


            <td>
              <span>
                <nz-tag [nzColor]="'blue'"><a (click)="OpenViewPop(data.SaleOrderId)">
                    {{data.SaleOrderNumber}}
                  </a></nz-tag>
              </span>
            </td>
            <td>{{ data.CustomerName }}</td>
            <td>{{ data.SaleOrderProductionPostProcess.ManufacturingProductName }}</td>
            <td>
              <div *ngFor="let p of data.SaleOrderPostProcessOrder" style="display:inline">
                <a *ngIf="p.Status==null" (click)="OpenDrawer(p,data)">
                  <nz-tag [nzColor]="'#108ee9'">{{p.DisplayProcessName}}</nz-tag>
                </a>


                <a *ngIf="p.Status!='NotStarted'" (click)="OpenDrawer(p,data)">
                  <nz-tag *ngIf="p.Status=='Completed'" [nzColor]="'#87d068'">{{p.DisplayProcessName}}</nz-tag>
                  <nz-tag *ngIf="p.Status=='InProcess'" [nzColor]="'#f50'">{{p.DisplayProcessName}}</nz-tag>
                  <nz-tag *ngIf="p.Status=='Assigned'" [nzColor]="'#2db7f5'">{{p.DisplayProcessName}}</nz-tag>
                </a>
                <nz-tag *ngIf="p.Status=='NotStarted'" [nzColor]="'#b5b5c3'">{{p.DisplayProcessName}}</nz-tag>
              </div>
            </td>
            <!--<td *ngIf="!data.SaleOrder.IsPrintRequired"> N/A </td>
            <td *ngIf="data.SaleOrder.IsPrintRequired">
              <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus=='Completed'"
                [nzColor]="'#87d068'">Completed</nz-tag>
              <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus!='Completed'"
                (click)="OpenDrawer('Print',data)">
                <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus=='InProcess'"
                  [nzColor]="'#f50'">InProcess</nz-tag>
                <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus=='Assigned'"
                  [nzColor]="'#2db7f5'">Assigned</nz-tag>
              </a>
              <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus==null"
                (click)="OpenDrawer('Print',data)">
                <nz-tag [nzColor]="'#108ee9'">Post Process</nz-tag>
              </a>

            </td>
            <td *ngIf="!data.SaleOrder.IsEmbossingRequired"> N/A </td>
            <td *ngIf="data.SaleOrder.IsEmbossingRequired">
              <nz-tag
                *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus=='Completed'"
                [nzColor]="'#87d068'">Completed</nz-tag>
              <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus!='Completed'"
                (click)="OpenDrawer('Embossing',data)">
                <nz-tag
                  *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus=='InProcess'"
                  [nzColor]="'#f50'">InProcess</nz-tag>
                <nz-tag
                  *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus=='Assigned'"
                  [nzColor]="'#2db7f5'">Assigned</nz-tag>
              </a>-->
            <!-- <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus==null"
                (click)="OpenDrawer('Embossing',data)"  >
                <nz-tag [nzColor]="'#108ee9'">Post Process</nz-tag>
              </a> -->

            <!--<button nz-button nzType="primary" nzSize="small"
                [disabled]="!(data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus =='Completed')"
                *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus==null"
                (click)="OpenDrawer('Embossing',data)">Post Process</button>
            </td>

            <td *ngIf="!data.SaleOrder.IsVacuumRequired"> N/A </td>
            <td *ngIf="data.SaleOrder.IsVacuumRequired">
              <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus=='Completed'"
                [nzColor]="'#87d068'">Completed</nz-tag>
              <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus!='Completed'"
                (click)="OpenDrawer('Vacuum',data)">
                <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus=='InProcess'"
                  [nzColor]="'#f50'">InProcess</nz-tag>
                <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus=='Assigned'"
                  [nzColor]="'#2db7f5'">Assigned</nz-tag>
              </a>-->
            <!-- <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus==null"
                (click)="OpenDrawer('Vacuum',data)">
                <nz-tag [nzColor]="'#108ee9'">Post Process</nz-tag>
              </a> -->
            <!--<button nz-button nzType="primary" nzSize="small" [disabled]="!(data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus =='Completed'
              && data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus =='Completed')"
                *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus==null"
                (click)="OpenDrawer('Vacuum',data)">Post Process</button>
            </td>

            <td *ngIf="!data.SaleOrder.IsLacquerRequired"> N/A </td>
            <td *ngIf="data.SaleOrder.IsLacquerRequired">
              <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus=='Completed'"
                [nzColor]="'#87d068'">Completed</nz-tag>
              <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus!='Completed'"
                (click)="OpenDrawer('Lacquer',data)">
                <nz-tag
                  *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus=='InProcess'"
                  [nzColor]="'#f50'">InProcess</nz-tag>
                <nz-tag *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus=='Assigned'"
                  [nzColor]="'#2db7f5'">Assigned</nz-tag>
              </a>-->
            <!-- <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus==null"
                (click)="OpenDrawer('Lacquer',data)">
                <nz-tag [nzColor]="'#108ee9'">Post Process</nz-tag>
              </a> -->
            <!--<button nz-button nzType="primary" nzSize="small" [disabled]="!(data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus =='Completed'
              && data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus =='Completed'
              && data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus =='Completed')"
                *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus==null"
                (click)="OpenDrawer('Lacquer',data)">Post Process</button>
            </td>

            <td *ngIf="!data.SaleOrder.IsTumblingRequired"> N/A </td>
            <td *ngIf="data.SaleOrder.IsTumblingRequired">
              <nz-tag
                *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus=='Completed'"
                [nzColor]="'#87d068'">Completed</nz-tag>
              <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus!='Completed'"
                (click)="OpenDrawer('Tumbling',data)">
                <nz-tag
                  *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus=='InProcess'"
                  [nzColor]="'#f50'">InProcess</nz-tag>
                <nz-tag
                  *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus=='Assigned'"
                  [nzColor]="'#2db7f5'">Assigned</nz-tag>
              </a>-->
            <!-- <a *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus==null"
                (click)="OpenDrawer('Tumbling',data)">
                <nz-tag [nzColor]="'#108ee9'">Post Process</nz-tag>
              </a> -->
            <!--<button nz-button nzType="primary" nzSize="small" [disabled]="!(data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus =='Completed'
              && data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus =='Completed'
              && data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus =='Completed'
              && data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus =='Completed')"
                *ngIf="data.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus==null"
                (click)="OpenDrawer('Tumbling',data)">Post Process</button>
            </td>-->

          </tr>
        </tbody>
      </nz-table>


    </div>
  </div>
</div>


<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1200" nzTitle="{{Type}} "
  (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div *ngIf="IsDataAvailable">
      <nz-divider
        nzText="Order No:{{this.Production?.SaleOrderNumber}} - [{{RequestModel.Status==''?'Pending':RequestModel.Status}}]">
      </nz-divider>
      <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Production?.SaleOrderProductionPostProcess" nzSize="small"
        [nzColumn]="5">

        <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
            Production?.SaleFormulationCode }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Customer Name"><b>{{Production?.CustomerName}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Category" [nzSpan]="1"><b>{{ Production?.Category }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Thick"><b> {{Production?.SaleOrderProductionPostProcess.ThicknessValue
            }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Width"><b> {{Production?.SaleOrderProductionPostProcess.WidthNumber
            }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Article Name" [nzSpan]="2"><b>
            {{Production?.SaleOrderProductionPostProcess.ManufacturingProductName }}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="MFG.Quantity"><b>
            {{Production?.SaleOrderProductionPostProcess.ManufacturingQuantity}}</b> </nz-descriptions-item>
        <nz-descriptions-item
          nzTitle="Order Quantity"><b>{{Production?.SaleOrderProductionPostProcess.OrderQuantity}}</b>
        </nz-descriptions-item>
        <!-- <nz-descriptions-item nzTitle="Sale Price"> <b> {{ Production?.SaleOrderProductionPostProcess.SalePrice }}</b> </nz-descriptions-item> -->
        <nz-descriptions-item nzTitle="Grain Code"> <b> {{ Production?.SaleOrderProductionPostProcess.GrainCode }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Color"> <b>{{Production?.SaleOrderProductionPostProcess.ColorName }}</b>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric Color"> <b>
            {{Production?.SaleOrderProductionPostProcess.FabricColorName}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Fabric Name"> <b> {{Production?.FormulationFabricProductName}}</b>
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-table *ngIf="Type=='Print'" nzSize="small" style="width: 100%;" #basicTable
        [nzData]="this.Production.SaleOrderProductionPostProcess.SaleOrderProductionPrint" [nzLoading]="isTableLoading"
        nzBordered nzShowPagination="false">
        <thead>
          <tr>
            <th nzWidth="200px">Name</th>
            <th nzWidth="100px">Code</th>
            <th nzWidth="200px">Description</th>
            <th nzWidth="60px">Image</th>


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">

            <td>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
            <td> <button class="btn btn-sm btn-light-primary" (click)="GetImages(data.PrintMasterId)">View</button></td>
          </tr>

        </tbody>
      </nz-table>
      <nz-modal [nzWidth]="600" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isImageVisible" [nzTitle]="modalTitle1"
        [nzContent]="modalContent1" [nzFooter]="null" (nzOnCancel)="handleCancelImage()">
        <ng-template #modalTitle1>Image Viewer</ng-template>
        <ng-template #modalContent1>
          <div><b style="color: red;"> Verify the image with physical copy also.</b></div>
          <nz-divider></nz-divider>
          <div class="row" *ngIf="FilteredImageArray.length > 0">
            <div class="column" *ngFor="let index of FilteredImageArray">
              <a href="{{ index.URL }}" target="_blank">
                <img src="{{ index.URL }}" alt="Snow" style="width: 100%" />
              </a>
            </div>
          </div>
          <div *ngIf="FilteredImageArray.length == 0">No image found</div>
        </ng-template>
      </nz-modal>
      <nz-table *ngIf="Type=='Embossing'" nzSize="small" style="width: 100%;" #basicTable
        [nzData]="this.Production.SaleOrderProductionPostProcess.SaleOrderProductionEmbossing"
        [nzLoading]="isTableLoading" nzBordered nzShowPagination="false">
        <thead>
          <tr>
            <th nzWidth="200px">Name</th>
            <th nzWidth="100px">Code</th>
            <th nzWidth="200px">Description</th>
            <th nzWidth="60px">Image</th>


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">

            <td>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
            <td>
              <button class="btn btn-sm btn-light-primary" (click)="GetEmbossingImages(data.EmbossingMasterId)">
                View
              </button>
            </td>


          </tr>

        </tbody>
      </nz-table>
      <nz-table *ngIf="Type=='Vacuum'" nzSize="small" style="width: 100%;" #basicTable
        [nzData]="this.Production.SaleOrderProductionPostProcess.SaleOrderProductionVacuum" [nzLoading]="isTableLoading"
        nzBordered nzShowPagination="false">
        <thead>
          <tr>
            <th nzWidth="200px">Name</th>
            <th nzWidth="100px">Code</th>
            <th nzWidth="200px">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
          </tr>
        </tbody>
      </nz-table>
      <nz-table *ngIf="Type=='Lacquar'" nzSize="small" style="width: 100%;" #basicTable
        [nzData]="this.Production.SaleOrderProductionPostProcess.Lacquer" [nzLoading]="isTableLoading" nzBordered
        nzShowPagination="false">
        <thead>
          <tr>
            <th nzWidth="200px">Name</th>
            <th nzWidth="100px">Code</th>
            <th nzWidth="200px">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
          </tr>
        </tbody>
      </nz-table>
      <nz-table *ngIf="Type=='Tumbling'" nzSize="small" style="width: 100%;" #basicTable
        [nzData]="this.Production.SaleOrderProductionPostProcess.SaleOrderProductionTumbling"
        [nzLoading]="isTableLoading" nzBordered nzShowPagination="false">
        <thead>
          <tr>
            <th nzWidth="200px">Name</th>
            <th nzWidth="100px">Code</th>
            <th nzWidth="200px">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.Name }}</td>
            <td>{{ data.Code }}</td>
            <td>{{ data.Description }}</td>
          </tr>

        </tbody>
      </nz-table>
      <nz-table nzSize="small" *ngIf="RequestModel.Status==''" style="width: 100%;" [nzData]="this.jumboTableData"
        #basicTable1 nzBordered [nzShowPagination]="false">
        <thead>
          <tr>
            <th>Jumbo No.</th>
            <th>Accepted Roll QTY</th>
            <th>Received QTY</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of jumboTableData;let i=index">
            <td>{{ data.JumboNo }}</td>
            <td *ngIf="data.ActualQuantity==null">{{ data.JumboRolQty }}</td>
            <td *ngIf="data.ActualQuantity!=null">{{ data.ActualQuantity }}</td>
            <td>
              <input type="text" style="color: #000;" nz-input [(ngModel)]="data.Yield"
                (ngModelChange)="calculateReceivedTotal()" />
            </td>

          </tr>

        </tbody>

      </nz-table>
      <nz-descriptions nzBordered [nzColumn]="6" nzLayout="vertical">
        <nz-descriptions-item nzTitle="Order QTY">
          {{ this.Production.SaleOrderProductionPostProcess.OrderQuantity }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Unit">
          mt
          <!-- {{ this.Production.SaleOrderProduction.Unit }} -->
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="MFG QTY">
          {{ this.Production.SaleOrderProductionPostProcess.ManufacturingQuantity }}
        </nz-descriptions-item>
        <!--<nz-descriptions-item nzTitle="Jumbo QTY">
      {{ totalJumboQTY }}
    </nz-descriptions-item>-->
        <nz-descriptions-item nzTitle="Received QTY">
          <input type="text" nz-input [(ngModel)]="RequestModel.ReceivedQuantity" [disabled]="RequestModel.Status!=''"
            style="color: #000;" />
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Received Unit">
          <nz-select style="width: 100%; color: #000;" name="select-error1" [(ngModel)]="RequestModel.Unit"
            [disabled]="RequestModel.Status!=''">
            <nz-option nzValue="0" nzLabel="Select Unit"></nz-option>
            <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
          </nz-select>

        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Remark">
          <input type="text" nz-input [(ngModel)]="RequestModel.Remark" [disabled]="RequestModel.Status!=''"
            style="color: #000;" />
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-divider nzText="Allocation"></nz-divider>
      <nz-descriptions nzBordered>
        <nz-descriptions-item nzTitle="Store">
          <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()"
            [disabled]="RequestModel.Status!=''" [(ngModel)]="StoreId" nzSize="default" nzAllowClear
            nzPlaceHolder="Choose">
            <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName"></nz-option>
          </nz-select>
        </nz-descriptions-item>

        <nz-descriptions-item nzTitle="Rack">
          <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default" [disabled]="RequestModel.Status!=''"
            [(ngModel)]="RequestModel.RackId" nzAllowClear nzPlaceHolder="Choose">
            <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
          </nz-select>
        </nz-descriptions-item>

      </nz-descriptions>
      <div class="text-center">
        <span nz-tooltip nzTooltipTitle="{{this.acceptProcessBtnToolTipText}}"><button nz-button nzType="primary"
            [nzLoading]="isLoading" style="margin: 30px;" [disabled]="enableAcceptProcessBtn()"
            (click)="Accept(false,false)" *ngIf="RequestModel.Status==''">
            Accept
          </button></span>
        <span nz-tooltip nzTooltipTitle="{{this.acceptProcessBtnToolTipText}}"><button nz-button nzType="primary"
            [nzLoading]="isLoading" [disabled]="enableAcceptProcessBtn()" (click)="Accept(true,false)"
            *ngIf="RequestModel.Status==''">
            Accept and Process
          </button></span>
        <span nz-tooltip nzTooltipTitle="{{this.acceptProcessBtnToolTipText}}"><button nz-button nzType="primary"
            [nzLoading]="isLoading" style="margin: 30px;" [disabled]="enableAcceptProcessBtn()"
            (click)="Accept(true,false)" *ngIf="RequestModel.Status=='Assigned'">
            Process
          </button></span>
        <span nz-tooltip nzTooltipTitle="{{this.removeBtnToolTipText}}"><button nz-button nzType="primary"
            [nzLoading]="isLoading" style="margin: 30px;" [disabled]="enableSkipRemoveBtn()"
            *ngIf="RequestModel.Status==''" (click)="SkipFn()">
            Skip and Remove this Step
          </button></span>
      </div>
      <div *ngIf="RequestModel.Status=='InProcess'">
        <nz-divider></nz-divider>
        <nz-table nzSize="small" style="width: 100%;" [nzData]="this.jumboTableData" #basicTable1 nzBordered
          [nzShowPagination]="false">
          <thead>
            <tr>
              <th>Jumbo No.</th>
              <th>Received QTY</th>
              <th>Complete QTY</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of jumboTableData;let i=index">
              <td>{{ data.JumboNo }}</td>
              <td *ngIf="data.ActualQuantity==null">{{ data.JumboRolQty }}</td>
              <td *ngIf="data.ActualQuantity!=null">{{ data.ActualQuantity }}</td>
              <td>
                <input type="text" style="color: #000;" nz-input [(ngModel)]="data.Yield"
                  (ngModelChange)="calculateJumboTotal()" />
              </td>

            </tr>

          </tbody>

        </nz-table>

        <nz-descriptions nzBordered>
          <nz-descriptions-item nzTitle="Complete QTY">
            <input type="text" nz-input (change)="CalculateWastage()" [(ngModel)]="RequestModel.CompletedQuantity" />
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Measurement Unit">
            {{RequestModel.Unit}}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Wastage QTY">
            <span [ngStyle]="getWastageStyle()">
              {{RequestModel.WastageQuantity}}</span>
          </nz-descriptions-item>

        </nz-descriptions>
        <div class="text-center">



          <button nz-button nzType="primary" [nzLoading]="isLoading" style="margin: 30px;" (click)="Accept(false,true)">
            SAVE AND SEND TO NEXT STAGE
          </button>

        </div>
      </div>

    </div>
    <div *ngIf="!IsDataAvailable">
      {{Type}} not available
    </div>
  </ng-container>
</nz-drawer>