export class StockLabelResponseModel {
    StockLabelId: number = 0;
    StockProductId: number = 0;
    ProductId: number = 0;
    SerialNo: string;
    BatchNo: string;
    ProductName: string;
    IsChecked: boolean = false;
    IsActive: boolean = false;
    Quantity: number = 0;
    InspectionStatus: string = '';
    CurrentRackId: number = 0;
    CurrentStoreId: number = 0;
    LabelStatus: string = '';
}
export class StockLabelBasicDetailsModel {
    StockLabelId: number = 0;
    SerialNo: string;
    BatchNo: string;
    ProductName: string;
    IsChecked: boolean = false;
    IsActive: boolean = false;
    Quantity: number = 0;
    InspectionStatus: string = '';
    PackagingUnit: string = '';
    MfgDate!: Date;
    ExpiryDate!: Date;
}
export class StockLabelModel {
    StockLabelId: number = 0;
    SerialNo: string = '';
    StockProductId: number = 0;
    StockId: number = 0;
    ProductId: number = 0;
    NumberOfLabels: number = 0;
    Quantity: number = 0;
    PackagingUnit: string = '';
    OriginalLabelId: number = 0;
    MfgDate!: Date;
    ExpiryDate!: Date;
    IsActive: boolean = false;
    AddedBy: string = '';
    AddedDate!: Date;
    UpdatedBy: string = '';
    UpdatedDate!: Date;
    InspectionStatus: string = '';
    StoreName: string = '';
    RackName: string = '';
    LabelStatus: string = '';
    CurrentRackId: number = 0;
    CurrentStoreId: number = 0;
}
export class LabelFullDetailsModel {
    BatchNo: string = '';
    ProductName: string = '';
    SupplierName: string = '';
    InvoiceNo: string = '';
    PurchaseOrderNo: string = '';
    ReceivedDate: string = '';
    ReceivedBy: string = '';
    InspectionCompletedBy: string = '';
    InspectedCompletedDate: string = '';
    Grade: string = '';
    StockUnit: string = '';
    ProductQuality: string = '';
    PricePerUnit: number = 0;
    StoreName: string = '';
    RackName: string = '';
    StockLabel: StockLabelModel;
}
export class BarcodeLabelUpdateModalState {
    isVisible: boolean = false;
    serialNo: string | null = null;
    action: string | null = null;
    currentRackId: number | null = null;
    stockProductId: number | null = null;
}