import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ActiveX, environment } from '../../environments/environment';
import { UserInfo } from '../Authentication/UserInfo';
import { UserRoleModel } from '../Models/UserModel';
import { MoudleResponsibilityModel, ResponsibilityMasterModel, UserRoleMasterModel } from '../Models/UserRoleModel';
import { AuthService } from '../Services/auth.service';
import { LoadingService } from '../Services/loadingService';
import { PublicClientApplication } from '@azure/msal-browser';
import { BarcodeScannerService } from '../Features/BarcodeLabelManagement/services/BarcodeScannerService';
import { Subscription } from 'rxjs';
import { ScannerMode } from '../Models/Enums';
@Component({
  selector: 'app-home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  ApiUrl = environment.Api_Url;
  isCollapsed = false;
  isUserLoggedIn = false;
  ForceLogoutSession = false;
  AzureUserName = "";
  UserID = "";
  aquireTokenInterval: any;
  DivisionName = environment.DivisionName;
  UserRoles: UserRoleModel = new UserRoleModel();
  UserRoleList: UserRoleMasterModel[] = [];
  MoudleResponsibilityList: MoudleResponsibilityModel[] = []
  count = 0;

  openMap: { [name: string]: boolean } = {
    sub1: false,
    sub2: false,
    sub3: false,
    sub4: false,
    sub5: false,
    sub6: false,
    sub7: false,
    sub8: false,
    sub9: false,
    sub10: false,
    sub11: false,
    sub12: false,
    sub13: false,
    sub14: false,
    sub15: false,
    sub16: false,
    sub17: false,
    sub18: false,
    sub19: false,
    sub20: false,
    sub21: false,
    sub22: false,
    sub23: false,
  };
  openReportsMap: { [name: string]: boolean } = {
    sub1: false,
    sub2: false,
    sub3: false,
    sub4: false,
    sub5: false,
  };
  IsMenuLoaded = false;
  currentRoute: string;
  isIframe = false;
  public innerWidth: any;
  private subscription: Subscription = new Subscription();
  private isInitialized: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  constructor(private authService: AuthService, private router: Router, private msalService: MsalService, public http: HttpClient, private loader: LoadingService,
    private barcodeService: BarcodeScannerService
  ) {
    this.currentRoute = "";
    this.acquireTokenSilently();
    this.acquireTokenSilentlyOnInterval();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        console.log('Route change detected');
        this.acquireTokenSilently();
        this.acquireTokenSilentlyOnInterval();
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
        this.changeOfRoutes();
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        console.log(event.error);
      }
    });
  }

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }
  openReportsHandler(value: string): void {
    for (const key in this.openReportsMap) {
      if (key !== value) {
        this.openReportsMap[key] = false;
      }
    }
  }

  GetUserRoles(Username: string) {
    this.loader.show();
    this.UserRoles = new UserRoleModel();
    this.UserRoles.Username = Username;
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "userrolesresponsibility/getalluserrolesbyusername/" + Username;
    this.http.get<UserRoleMasterModel[]>(url).subscribe(
      (res) => {
        this.UserRoleList = res;
        UserInfo.UserRolesMaster = res;
        var myResponsibilityList: number[] = [];
        res.forEach(el => {
          var m = el.Responsibilities.map(x => x.ResponsibilityId);
          m.forEach(x => {
            myResponsibilityList.push(x);
          })

        })
        this.MoudleResponsibilityList.forEach(el => {

          el.Responsibilities.forEach(x => {

            if (myResponsibilityList.includes(x.ResponsibilityId)) {
              x.IsChecked = true;
              el.IsChecked = true;
            } else {
              x.IsChecked = false
            }
          })
        })
        UserInfo.MyRoles = this.MoudleResponsibilityList;
        this.IsMenuLoaded = true;
        this.loader.hide();
      },
      (res) => {
        this.count++
        this.loader.hide();
        if (this.count < 2) {
          this.GetUserRoles(UserInfo.EmailID);
        }
      }
    );
  }
  GetAllResponsibility() {
    this.loader.show();
    let url = this.ApiUrl + "userrolesresponsibility/getallresponsibility";
    this.http.get<ResponsibilityMasterModel[]>(url).subscribe(
      (res) => {
        let uniqueModule = [...new Set(res.map(item => item.Module))];

        uniqueModule.forEach(el => {
          var item = new MoudleResponsibilityModel;
          item.Module = el;
          item.Responsibilities = res.filter(x => x.Module == el);
          this.MoudleResponsibilityList.push(item);
        })
        this.GetUserRoles(UserInfo.EmailID)
        this.loader.hide();
      },
      (res) => {
        this.count++
        this.loader.hide();
        if (this.count < 2) {
          this.GetAllResponsibility();
        }
      }
    );
  }

  CheckModule(Module: string) {
    return this.authService.CheckModule(Module);

  }
  CheckResponsibility(Module: string, Responsibility: string) {
    return this.authService.CheckResponsibility(Module, Responsibility);
  }
  // getWithExpiry(key: string) {
  //   const itemStr = localStorage.getItem(key)

  //   // if the item doesn't exist, return null
  //   if (!itemStr) {
  //     return null
  //   }

  //   const item = JSON.parse(itemStr)
  //   const now = new Date()

  //   // compare the expiry time of the item with the current time
  //   if (now.getTime() > item.expiry) {
  //     // If the item is expired, delete the item from storage
  //     // and return null
  //     localStorage.removeItem(key);
  //     this.ForceLogoutSession = true;
  //     return null;
  //   }
  //   return item.value
  // }
  changeOfRoutes() {

    if (this.authService.getAppSessionTime("appSessionExpiry") == null || this.authService.getAppSessionTime("token") == null) {
      this.isUserLoggedIn = false;
    }
    if (this.isUserLoggedIn && (!localStorage.getItem('expiresOn') || !localStorage.getItem('userName') || !localStorage.getItem('homeAccountId'))) {
      this.authService.setSessionData()
    }
    if (this.authService.ForceLogoutSession) {
      this.logout();
    }
  }
  async ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener; this.onResize("size");
    if (localStorage.getItem('token') == undefined || localStorage.getItem('expiresOn') == undefined || localStorage.getItem('userName') || localStorage.getItem('homeAccountId')) {
      localStorage.removeItem('token');
      localStorage.removeItem('homeAccountId');
      localStorage.removeItem('expiresOn');
      localStorage.removeItem('userName');
    }
    if (this.authService.getAppSessionTime("appSessionExpiry") == null || this.authService.getAppSessionTime("token") == null) {
      this.isUserLoggedIn = false;
    }
    var userinfo = this.msalService.instance.getActiveAccount();

    if (userinfo != null) {

      this.AzureUserName = userinfo?.name ?? "";
      this.UserID = userinfo?.username ?? "";
      UserInfo.EmailID = userinfo?.username;
      UserInfo.UserName = userinfo?.name ?? "";
      this.isUserLoggedIn = true;
      //this.SaveRole();
      if (!localStorage.getItem('expiresOn') || !localStorage.getItem('userName') || !localStorage.getItem('homeAccountId') || !localStorage.getItem('token')) {
        // this.authService.setSessionData();
        await this.authService.setSessionData();
      }
      this.GetAllResponsibility();

      //this.GetRole(UserInfo.EmailID);
    }
    else {

      this.isUserLoggedIn = false;
      //window.close()
      this.router.navigate(['/login']);
    }

    if (this.authService.ForceLogoutSession) {
      this.logout();
    }

    this.subscription.add(
      this.barcodeService.scannerMode$.subscribe(scannerMode => {
        if (scannerMode === ScannerMode.Closed && this.isInitialized) {
          setTimeout(() => {
            this.openBarcodeScanner();
          }, 100);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  acquireTokenSilentlyOnInterval() {
    let timeIntervalInMs = 3300 * 1000; // renews the token for every 55 minutes
    //let timeIntervalInMs = 30 * 1000; // renews the token for every 30 seconds
    const now = new Date();
    var expiresOn = new Date(localStorage.getItem("expiresOn"));
    const refreshTime = new Date(expiresOn.setMinutes(expiresOn.getMinutes() - 5));
    console.log('token will renew at: ' + new Date(new Date().setMilliseconds(timeIntervalInMs)).toString() + ' for idle session.');

    this.aquireTokenInterval = setInterval(() => {
      if (now > refreshTime) {
        const accessTokenRequest = {
          scopes: ["openid"],
          loginHint: localStorage.getItem('userName') || "",
          //forceRefresh: true
        }
        console.log('acquireTokenSilent started.');
        this.msalService.acquireTokenSilent(accessTokenRequest).toPromise().then(function (accessTokenResponse) {
          // call API
          let expiryTime = accessTokenResponse.expiresOn.toString();
          localStorage.setItem('expiresOn', expiryTime);
          localStorage.setItem('token', accessTokenResponse.accessToken);
          console.log('acquireTokenSilent ended.');
        }).catch((error) => {
          console.log("Error in login code  acquireTokenSilent " + error);
        });
      }
    }, timeIntervalInMs);
  }

  acquireTokenSilently() {
    const now = new Date();
    var expiresOn = new Date(this.msalService.instance.getActiveAccount()?.idTokenClaims?.exp * 1000)
    const refreshTime = new Date(expiresOn.setMinutes(expiresOn.getMinutes() - 5));

    if (now > refreshTime) {
      const accessTokenRequest = {
        scopes: ["openid", `${ActiveX.clientId}/.default`],
        loginHint: localStorage.getItem('userName') || "",
        //forceRefresh: true
      }
      console.log('acquireTokenSilent started.');
      this.msalService.acquireTokenSilent(accessTokenRequest).toPromise().then(function (accessTokenResponse) {
        // call API
        let expiryTime = accessTokenResponse.expiresOn.toString();
        localStorage.setItem('expiresOn', expiryTime);
        localStorage.setItem('token', accessTokenResponse.accessToken);
        console.log('acquireTokenSilent ended.');
      }).catch((error) => {
        console.log("Error in login code  acquireTokenSilent " + error);
        if (error.errorCode === 'interaction_required' || error.errorCode === 'login_required') {
          // Redirect user for interactive login
          let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
          msalInstance["browserStorage"].clear();
          this.router.navigate(['/login']);
        }
      });
    }
  }

  // logout() {
  //   const homeAccountId = localStorage.getItem('homeAccountId');
  //   const currentAccount = this.msalService.instance.getAccountByHomeId(homeAccountId);
  //   const logoutHint = currentAccount?.idTokenClaims?.login_hint;
  //   this.msalService.logoutPopup(
  //     {
  //       logoutHint: logoutHint,
  //       mainWindowRedirectUri: "/welcome",
  //     }
  //   );
  //   this.authService.clearLocalDataStorage();
  // }

  logout() {
    this.authService.clearLocalDataStorage();
    const currentAccount = this.msalService.instance.getActiveAccount();
    this.msalService.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: "/welcome"
    });
  }
  CheckSalesReportSubMenuPermission() {
    if (this.CheckModule('Reports - Sales')) {
      return true;
    }
    else
      return false;
  }
  CheckStockReportSubMenuPermission() {
    if (this.CheckModule('Reports - Stock Availability') || this.CheckModule('Reports - Stock') || this.CheckModule('Reports - Store Wise Stock') ||
      this.CheckModule('Reports - Product Wise Stock') || this.CheckModule('Reports - Product Stock History') || this.CheckModule('Reports - Category Wise Stock')) {
      return true;
    }
    else
      return false;
  }
  CheckProductStockReportSubMenuPermission() {
    if (this.CheckModule('Reports - Product Stock Summary')) {
      return true;
    }
    else
      return false;
  }
  CheckPurchaseReportSubMenuPermission() {
    if (this.CheckModule('Reports - Purchase')) {
      return true;
    }
    else
      return false;
  }
  CheckProductionReportSubMenuPermission() {
    if (this.CheckModule('Reports - Production Planning') || this.CheckModule('Reports - Production Status') || this.CheckModule('Reports - Post Process')
      || this.CheckModule('Reports - Paste Consumption') || this.CheckModule('Reports - Yield') || this.CheckModule('Reports - Wastage')) {
      return true;
    }
    else
      return false;
  }
  openBarcodeScanner() {
    this.barcodeService.setScannerMode(ScannerMode.OverlayScanner);
  }

  openManualInput() {
    this.barcodeService.setScannerMode(ScannerMode.ManualEntry);
  }
}