<app-SoDrawer></app-SoDrawer>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Start Jumbo Roll Production</nz-page-header-title>

      <nz-page-header-extra>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="4">

            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Customer</label>
                <nz-select name="select-error" [(ngModel)]="request.CustomerId" nzShowSearch nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of CustomerList" [nzValue]="data.CustomerId"
                    [nzLabel]="data.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="3">

            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Formulation Code</label>
                <nz-select name="select-error" [(ngModel)]="request.FormulationCodeId" nzShowSearch nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of FormulationCodeList" [nzValue]="data.SaleFormulationCodeId"
                    [nzLabel]="data.SaleFormulationCode"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Sale Order Status</label>
                <nz-select name="select-error" [(ngModel)]="request.Status" nzShowSearch nzAllowClear
                  nzPlaceHolder="Choose">
                  <nz-option *ngFor="let data of status" [nzValue]="data.Value" [nzLabel]="data.Text"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">

            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date (From)</label>
                <nz-date-picker [nzShowTime]="true" [nzFormat]="'MM-dd-yyyy hh:mm a'" nzPlaceHolder="From Date"
                  style="width: 200px;" name="orderDateFrom" [(ngModel)]="request.FromDate"
                  nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Date (To)</label>
                <nz-date-picker [nzShowTime]="true" [nzFormat]="'MM-dd-yyyy hh:mm a'" nzPlaceHolder="To Date"
                  style="width: 200px;" name="orderDateTo" [(ngModel)]="request.ToDate" nzAllowClear></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="1">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetAllWorkPlanList()">
              Search
            </button>
          </div>

        </div>


      </div>
    </div>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:left">
      <p><nz-tag [nzColor]="'lime'">Yellowish</nz-tag><b>= Inspection Not completed</b></p>
      <p><nz-tag [nzColor]="'cyan'">Greenish</nz-tag><b>= Inspection Completed</b></p>
    </div>
    <div style="padding: 8px;float:left">
      <p><nz-tag [nzColor]="'#87d068'">Green</nz-tag><b>= In Production</b></p>
      <p><nz-tag [nzColor]="'#f50'">Orange</nz-tag><b>= Production Completed and Sent to Final Inspection</b></p>
    </div>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search  All WorkPlan"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="100" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable
      [nzData]="this.WorkPlanList" [nzLoading]="isTableLoading" nzBordered>
      <thead>
        <tr>
          <th nzWidth="120px">WorkPlan No</th>
          <th nzWidth="120px">Sales Orders</th>
          <th nzWidth="130px">Production Details</th>
          <th nzWidth="60px">Lot No.</th>
          <th nzWidth="90px">Batch No.</th>
          <th nzWidth="95px">WorkPlan Date</th>
          <!-- <th nzWidth="150px">WorkPlan Order</th> -->
          <th nzWidth="200px">Added By</th>
          <th nzWidth="150px" style="text-align:center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.WorkPlanNo }}</td>
          <td>
            <nz-tag *ngFor="let so of data.WorkPlanOrder" [nzColor]="GetSaleOrderTagColor(so.SaleOrder)"><a
                (click)="OpenSaleOrderViewPop(so.SaleOrder)">
                {{so?.SaleOrder?.SaleOrderNumber}}
              </a></nz-tag>


          </td>
          <td>{{ data.ProductionDetails }}</td>
          <td>{{ data.LotNo }}</td>
          <td>{{ data.BatchNo }}</td>
          <td>{{ data.WorkPlanDate | DatetimeConverter}}</td>
          <!-- <td>{{ data.WorkPlanOrder }}</td> -->
          <td>{{ data.AddedBy }}</td>
          <td nzRight style="text-align:center">
            <!--<div>
    <button nz-button nz-dropdown  nzTrigger="click" [nzDropdownMenu]="menu" class="btn btn-sm btn-light-primary" style="line-height:0">
      Action
      <span nz-icon nzType="down"></span>
    </button>
      <nz-dropdown-menu #menu="nzDropdownMenu" >
      <ul nz-menu nzSelectable>
        <li style="width: 120px;" nz-menu-item (click)="OpenViewPop(data)">View</li>
        <li style="width: 120px;" nz-menu-item>Edit</li>

      </ul>
    </nz-dropdown-menu>
  </div>-->
            <button class="btn btn-sm btn-light-primary" (click)="OpenViewPop(data)">View</button> &nbsp;
            <button class="btn btn-sm btn-light-primary" (click)="OpenInspectionPop(data)">Choose</button> &nbsp;
          </td>


        </tr>
      </tbody>
    </nz-table>




  </div>
</div>

<!-- To view open drawer  -->
<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="1100" nzTitle="WorkPlan details"
  (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div *ngIf="WorkPlanModel">

      <nz-descriptions nzBordered>
        <nz-descriptions-item nzTitle="WORKPLAN NO">{{WorkPlanModel.WorkPlanNo}}</nz-descriptions-item>

        <nz-descriptions-item nzTitle="LOT NO.">{{WorkPlanModel.LotNo}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="BATCH NO.">{{WorkPlanModel.BatchNo}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="WORKPLAN DATE">{{WorkPlanModel.WorkPlanDate | date:
          "dd-MMM-yyyy"}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="ADDED BY">{{WorkPlanModel.AddedBy}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="PRODUCTION DETAILS" [nzSpan]="3">
          {{WorkPlanModel.ProductionDetails}}</nz-descriptions-item>
      </nz-descriptions>
      <nz-divider nzText="Sale Orders"></nz-divider>
      <nz-table #nzTable [nzData]="WorkPlanModel.WorkPlanOrder" nzTableLayout="fixed" [nzPageSize]="25" nzShowPagination="true">
        <thead>
          <tr>
            <!--<th nzWidth="60px"></th>-->
            <th>SALES ORDER NO</th>
            <th>BUYER</th>
            <!--<th>SUBCATEGORY</th>
      <th>CODE</th>-->
            <th>QTY</th>
            <!--<th>UNIT</th>-->
            <th nzWidth="100px" style="text-align: center" nzRight>Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of nzTable.data">
            <tr>
              <!--<td [nzExpand]="expandSet.has(data.OrderId)" (nzExpandChange)="onExpandChange(data.OrderId, $event)"></td>-->
              <td>{{ data.SaleOrder.SaleOrderNumber }}</td>
              <td>{{ data.SaleOrder.CustomerName }}</td>
              <!--<td>-</td>
        <td>{{ data.SaleOrder.SaleOrderProduction.ProductCode }}</td>-->
              <td>{{ data.SaleOrder.SaleOrderProduction.OrderQuantity }}</td>
              <!--<td>-</td>-->
              <td nzRight style="text-align: center">
                <button class="btn btn-sm btn-light-danger" (click)="printMixing(data)">Mixing</button>
              </td>
            </tr>
            <!--<tr [nzExpand]="expandSet.has(data.OrderId)">
          <nz-table #nzTable [nzData]="data.WorkPlanJumbo" nzTableLayout="fixed" nzShowPagination="false">
          <thead>
            <tr>-->
            <!-- <th nzWidth="60px"></th> -->
            <!--<th>JUMBO NO.</th>
              <th>DATE</th>
              <th>START TIME </th>
              <th>END TIME</th>
              <th>QTY</th>
              <th>RATE</th>
              <th>AMOUNT</th>
              <th>WEIGHT</th>
              <th>RACK</th>
              <th>REMARK</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of nzTable.data">
              <tr>-->
            <!-- <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td> -->
            <!--<td>{{ data.JumboNo }}</td>
                <td>{{ data.JumboRollDate|date:"dd-MMM-yyyy" }}</td>
                <td>{{ data.JumboRollStartTime | date:"hh:mm:ss" }}</td>
                <td>{{ data.JumboRollEndTime | date:"hh:mm:ss" }}</td>
                <td>{{ data.JumboRolQty }}</td>
                <td>{{ data.Rate }}</td>
                <td>{{ data.Amount }}</td>
               
                <td>{{ data.Weight }}</td>
                <td>{{ data.RackName }}</td>
                <td>{{ data.Remark }}</td>
              </tr>
             </ng-container>
          </tbody>
        </nz-table>
        <div  nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px;">
          <div nz-col [nzSpan]="12"></div>
          <div nz-col [nzSpan]="12">
            <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="3" style="border: 1px dotted rgb(181, 181, 195);">
              
              <nz-descriptions-item nzTitle="Total Jumbo Roll QTY" nzColon="true"><b>{{data.TotalJumboQty}}</b> </nz-descriptions-item>
              
              <nz-descriptions-item nzTitle="Total Jumbo Roll Weight " [nzSpan]="1" nzColon="true"><b>{{data.TotalWeight}}</b> </nz-descriptions-item>
            </nz-descriptions>
        
          </div>
        
        
        </div>
      </tr>-->
          </ng-container>
        </tbody>
      </nz-table>

    </div>
  </ng-container>
</nz-drawer>


<nz-drawer [nzClosable]="true" [nzVisible]="isVisibleInspection" nzPlacement="right" [nzWidth]="800"
  nzTitle="Production Start" (nzOnClose)="handleCancelInspection()">
  <ng-container *nzDrawerContent>
    <div *ngIf="IsSalesOrderWorkplanLoaded">
      <div *ngIf="SelectedWorkPlanOrder.length<=0"
        style="font-size: 16px;text-align: center;border: 1px solid #242f4c;padding: 15px;border-radius: 4px;background: #222f4e;color: #fff;text-transform: uppercase;">
        All sales order has been inspected.
      </div>
      <nz-form-item *ngIf="SelectedWorkPlanOrder.length>0">
        <nz-table nzSize="small" style="width: 100%;" #basicTable [nzData]="this.SelectedWorkPlanOrder"
          [nzLoading]="isTableLoading" [nzPageSize]="25" nzBordered nzShowPagination="true">
          <thead>
            <tr>

              <th nzWidth="180px"></th>
              <th nzWidth="150px">Sales Order No</th>
              <th nzWidth="150px">Order Type</th>
              <th nzWidth="150px">Article Name</th>
              <th nzWidth="100px">Order QTY</th>
              <!-- <th nzWidth="100px">MFG QTY</th> -->
              <!-- <th nzWidth="200px" style="text-align:center" nzRight>Complete Workplan</th> -->
              <!-- <th nzWidth="200px" style="text-align:center" nzRight>Jumbo</th> -->
              <!-- <th>Remark</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data">
              <td> <input style="margin-right: 5px" type="checkbox" [disabled]="EnableSaveBtn()" [checked]="data.SaleOrder.IsChecked"
                  (change)="data.SaleOrder.IsChecked = !data.SaleOrder.IsChecked;" /><b>Select to Start</b></td>
              <td>
                <nz-tag nzColor="blue"><a
                (click)="OpenSaleOrderViewPop(data.SaleOrder)">
                {{ data.SaleOrder.SaleOrderNumber }}
              </a></nz-tag></td>
              <td>{{ data.SaleOrder.SaleOrderType }}</td>
              <td>{{ data.SaleOrder.SaleOrderProduction.ManufacturingProductName }}</td>
              <td>{{ data.SaleOrder.SaleOrderProduction.OrderQuantity}} </td>
              <!-- <td><input type="text" nz-input [(ngModel)]="data.SaleOrder.SaleOrderProduction.ManufacturingQuantity" />
              </td> -->
              <!-- <td>
        <label style="margin-right: 5px" nz-checkbox [(ngModel)]="data.SaleOrder.IsChecked"><b> Is Complete </b></label>
        </td> -->
              <!--<td>
                <label style="margin-right: 5px" nz-checkbox [(ngModel)]="data.SaleOrder.CostingAdded"><b> Send to Jumbo </b></label>
              </td>-->
              <!-- <td>
                <input type="text" nz-input [(ngModel)]="data.SaleOrder.ProductionCompletionRemarks" />
              </td> -->

            </tr>

          </tbody>
        </nz-table>
        <nz-divider></nz-divider>
        <div class="text-center" style="width: 95%;">
          <button nz-button nzType="primary" [nzLoading]="isLoading" [hidden]="EnableSaveBtn()" (click)="SaveComplete()">Save</button>
        </div>
      </nz-form-item>
    </div>
  </ng-container>
</nz-drawer>