<div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
  <p style="font-size: 15px;"><b>{{this.Company.CompanyName}}</b></p>
  <p style="font-size: 10px;"><b>Head Office: </b>{{this.Company.HeadOfficeAddress}}</p>
  <p style="font-size: 10px;"><b>Factory Address: </b>{{this.Company.FactoryAddress}}</p>
</div>
<div style="text-transform: uppercase; font-size: 13px; text-align: center; color: black; border: 1px solid black;">
  <b>Software Generated Gate Pass No.: {{Production.GateInId}}</b>
</div>
<div style="text-transform: uppercase; color: black; border: 1px solid black;">
  <nz-descriptions nzBordered nzLayout="vertical" *ngIf="Production" nzSize="small" [nzColumn]="4"
    style="border: black;">
    <nz-descriptions-item nzTitle="Transport Name" [nzSpan]="2"
      nzColon="true"><b>{{Production.TransportCompanyName}}</b>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Vehicle Number" [nzSpan]="2"><b>{{Production.VehicleNumber }} (
        {{Production.VehicleType}})</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Type" [nzSpan]="2"><b>{{Production.Type}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Outpass Required"
      [nzSpan]="2"><b>{{Production.IsOutpassRequired}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Gate In Date" [nzSpan]="2"><b>{{Production.GateInDate|
        DatetimeConverter}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Driver Name" [nzSpan]="2"><b>{{Production.GateInPerson}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Driver Number" [nzSpan]="2">
      <b>{{Production.GateInPersonContact}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="In Weight" [nzSpan]="2">
      <b>{{Production.InWeight}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Out Weight" [nzSpan]="2">
      <b>{{Production.OutWeight}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Gate Pass Issue Date" [nzSpan]="2"><b>{{Production.GatePassIssueDate |
        DatetimeConverter}}</b></nz-descriptions-item>
    <nz-descriptions-item nzTitle="Gate Pass Issued By"
      [nzSpan]="2"><b>{{Production.GatePassAddedByName}}</b></nz-descriptions-item>
  </nz-descriptions>
</div>