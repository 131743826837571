<app-SaleOrderCostingView></app-SaleOrderCostingView>
<div style="border:1px solid;background-color: white;" id="jobcardprint" *ngIf="costingService.Orderlist">
    <div style="text-transform: uppercase; text-align: center; color: black; border: 1px solid black;">
        <p style="font-size: 15px;"><b>{{this.Company.CompanyName}}</b></p>
        <p style="font-size: 10px;"><b>Head Office: </b>{{this.Company.HeadOfficeAddress}}</p>
        <p style="font-size: 10px;"><b>Factory Address: </b>{{this.Company.FactoryAddress}}</p>
    </div>
    <div style="text-transform: uppercase; font-size: 13px; text-align: center; color: black; border: 1px solid black;">
        <b>Software Generated After Production Costing Details for Order No:
            {{costingService.Orderlist.SaleOrderNumber}}</b>
    </div>
    <div style="text-transform: uppercase; font-size: 11px; text-align: center; color: black; border: 1px solid black;">
        <b>The calculations are based on: {{GSMTypePrintText}}</b>
    </div>
    <nz-divider nzText="Sale Order Details"></nz-divider>
    <nz-descriptions *ngIf="costingService.Orderlist" nzBordered nzSize="small" [nzColumn]="3"
        style="border: 1px solid black;">
        <nz-descriptions-item
            nzTitle="Order Type"><b>{{costingService.Orderlist.SaleOrderType}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Customer"><b>{{costingService.Orderlist?.CustomerName}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Sale Order No."><b>{{costingService.Orderlist.SaleOrderNumber}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Product Type"><b>{{costingService.GetProductType(costingService.Orderlist.SaleFormulationCode)}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Date"><b>{{costingService.Orderlist.SaleOrderDate |
                date:'dd-MMM-yyyy'}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Delivery Date"><b>{{costingService.Orderlist.DeliveryDate |
                date:'dd-MMM-yyyy'}}</b></nz-descriptions-item>
    </nz-descriptions>
    <nz-divider nzText="Production Details"></nz-divider>
    <nz-descriptions *ngIf="costingService.Orderlist" nzBordered nzSize="small" [nzColumn]="4"
        style="border: 1px solid black;">
        <nz-descriptions-item
            nzTitle="Formulation Code"><b>{{costingService.Orderlist?.SaleFormulationCode}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Category"><b>{{costingService.Orderlist?.Category}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Thick"><b>{{costingService.Orderlist?.SaleOrderProduction.ThicknessValue}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Width"><b>{{costingService.Orderlist?.SaleOrderProduction.WidthNumber}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Article Name"
            [nzSpan]="2"><b>{{costingService.Orderlist?.SaleOrderProduction.ManufacturingProductName}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Order Quantity"><b>{{costingService.Orderlist?.SaleOrderProduction.OrderQuantity}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Sale Price"><b>&#8377;
                {{costingService.Orderlist?.SaleOrderProduction.SalePrice}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Total Sale Price"><b>&#8377;
                {{costingService.Orderlist?.SaleOrderProduction.TotalSalePrice}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Grain Name/Code"><b>{{costingService.Orderlist?.SaleOrderProduction?.GrainName}}
                /
                {{costingService.Orderlist?.SaleOrderProduction?.GrainCode}}</b></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Colour Name/Code"><b>{{costingService.Orderlist?.SaleOrderProduction?.ColorName}}
                /
                {{costingService.Orderlist?.SaleOrderProduction?.ColorCode}}</b></nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Fabric Color"><b>{{costingService.Orderlist?.SaleOrderProduction.FabricColorName}}</b></nz-descriptions-item>
    </nz-descriptions>
    <nz-divider nzText="Raw Material Details"></nz-divider>
    <div *ngIf="costingService.Orderlist" nz-col [nzSpan]="24" style="margin: 0px 0px;">
        <div nz-col [nzSpan]="24" *ngFor="let i of costingService.Orderlist.SaleOrderProduction.FinalFabricData">

            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false"
                style="margin-left: 0; margin-right:0;font-weight: bolder;">
                <thead>
                    <tr style="text-transform: uppercase; font-size: 13px; text-align: center; color: black;">
                        <td colspan="7" style="border: 1px solid black;">
                            <b>{{i.MixingName}}</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="80px" style="text-align:left; border: 1px solid;font-size: small;"><b>S.No.</b>
                        </td>
                        <td width="200px" style="text-align:left; border: 1px solid;font-size: small;"><b>Product
                                Name</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Unit</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Price</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Batch
                                No.</b>
                        </td>
                        <td width="90px" style="text-align:center; border: 1px solid;font-size: small;"><b>QTY</b>
                        </td>
                        <td width="140px" style="text-align:center; border: 1px solid;font-size: small;"><b>Cost</b>
                        </td>
                    </tr>
                    <tr *ngFor="let data of i.MixingRawMaterial;let i=index">
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ i + 1 }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{
                            data.ProductName }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Unit
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">&#8377; {{ data.Price
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.BatchNo
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Quantity
                            }}
                            <p *ngIf="data.Unit == 'Kgs'">QTY in MTR - {{data.ConvertedQtyKgsToMtrs}}</p>
                        </td>
                        <td style="text-align:center; border:1px solid;font-size: small;">&#8377; {{(data.Price *
                            data.Quantity).toFixed(2)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:center; border: 1px solid;font-size: small;">
                            <b> Total Fabric QTY (MTR)</b>
                        </td>
                        <td style="text-align:center; border: 1px solid"><b>{{i.TotalQuantity}}</b></td>
                    </tr>
                    <tr>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:center; border: 1px solid;font-size: small;">
                            <b>Total Cost</b>
                        </td>
                        <td style="text-align:center; border: 1px solid"><b> &#8377; {{i.TotalCost}}</b></td>
                    </tr>
                </tfoot>
            </nz-table>
        </div>
    </div>
    <nz-divider nzText="Mixing Details"></nz-divider>
    <div *ngIf="costingService.Orderlist" nz-col [nzSpan]="24" style="margin: 0px 0px;">
        <div nz-col [nzSpan]="24" *ngFor="let i of costingService.Orderlist.SaleOrderProduction.FinalFormulationMixing">

            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false"
                style="margin-left: 0; margin-right:0;font-weight: bolder;">
                <thead>
                    <tr style="text-transform: uppercase; font-size: 13px; text-align: center; color: black;">
                        <td colspan="8" style="border: 1px solid black;">
                            <b>{{i.MixingName}}</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="80px" style="text-align:left; border: 1px solid;font-size: small;"><b>S.No.</b>
                        </td>
                        <td width="200px" style="text-align:left; border: 1px solid;font-size: small;"><b>Product
                                Name</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Unit</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Price</b>
                        </td>
                        <td width="100px" style="text-align:center; border: 1px solid;font-size: small;"><b>Batch
                                No.</b>
                        </td>
                        <td width="90px" style="text-align:center; border: 1px solid;font-size: small;"><b>QTY</b>
                        </td>
                        <td width="90px" style="text-align:center; border: 1px solid;font-size: small;"><b>SC QTY</b>
                        </td>
                        <td width="140px" style="text-align:center; border: 1px solid;font-size: small;"><b>Cost</b>
                        </td>
                    </tr>
                    <tr *ngFor="let data of i.MixingRawMaterial;let i=index">
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ i + 1 }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{
                            data.ProductName }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Unit
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">&#8377; {{ data.Price
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.BatchNo
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{ data.Quantity
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">{{
                            data.Scquantity
                            }}</td>
                        <td style="text-align:center; border:1px solid;font-size: small;">&#8377; {{(data.Price *
                            data.Quantity).toFixed(2)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"><b> Total Weight
                            </b></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:left; border: 1px solid;font-size: small;"></td>
                        <td style="text-align:center; border: 1px solid;font-size: small;">
                            <b>{{i.TotalQuantity}} Kgs</b>
                        </td>
                        <td style="text-align:center; border: 1px solid;font-size: small;">
                            <b>{{i.TotalScQuantity}} Kgs</b>
                        </td>
                        <td style="text-align:center; border: 1px solid"><b>&#8377; {{i.TotalCost}}</b></td>
                    </tr>
                </tfoot>
            </nz-table>
        </div>
        <div nz-col [nzSpan]="24" *ngIf="costingService.Orderlist.SaleOrderProduction.PackagingRawMaterial.length > 0">

            <nz-divider nzText="Packaging"></nz-divider>
            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <tbody>
                    <tr>
                        <th nzWidth="50px" style="text-align:left; border: 1px solid;font-size: small;">S.No</th>
                        <th style="text-align:left; border: 1px solid;font-size: small;">Product</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Unit</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Price</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Batch No.</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">QTY</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Cost</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        *ngFor="let data of costingService.Orderlist.SaleOrderProduction.PackagingRawMaterial; let i = index">
                        <td nzWidth="20px" style="border: 1px solid ;text-align: left;font-size: small;">{{ i + 1 }}
                        </td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.ProductName }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.Unit }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">&#8377; {{ data.Price }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.BatchNo }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{data.Quantity}}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">&#8377; {{data.Price *
                            data.Quantity}}</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"><b>Total</b></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"><b>{{
                                costingService.PackagingTotalQuantity }}</b> </td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"><b>&#8377; {{
                                costingService.PackagingTotalCost }}</b> </td>
                    </tr>
                </tbody>
            </nz-table>
            <br>
        </div>
        <div nz-col [nzSpan]="24"
            *ngIf="costingService.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.length > 0">

            <nz-divider nzText="Miscellaneous"></nz-divider>
            <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <tbody>
                    <tr>
                        <th nzWidth="50px" style="text-align:left; border: 1px solid;font-size: small;">S.No</th>
                        <th style="text-align:left; border: 1px solid;font-size: small;">Product</th>
                        <th style="text-align:left; border: 1px solid;font-size: small;">Material Category</th>

                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Unit</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Price</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Batch No.</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">QTY</th>
                        <th nzWidth="100px" style="text-align:left; border: 1px solid;font-size: small;">Cost</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        *ngFor="let data of costingService.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial; let i = index">
                        <td nzWidth="20px" style="border: 1px solid ;text-align: left;font-size: small;">{{ i + 1 }}
                        </td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.ProductName }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.MaterialCategory }}
                        </td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.Unit }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">&#8377; {{ data.Price }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{ data.BatchNo }}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">{{data.Quantity}}</td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;">&#8377; {{data.Price *
                            data.Quantity}}</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"><b>Total</b></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"></td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"><b>{{
                                costingService.MiscTotalQuantity }}</b> </td>
                        <td style="border: 1px solid ;text-align: left;font-size: small;"><b>&#8377; {{
                                costingService.MiscTotalCost }}</b> </td>
                    </tr>
                </tbody>
            </nz-table>
            <br>
        </div>
        <nz-divider></nz-divider>
        <div *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null"><b
                style="margin-left: 15px;">Inline Scraping(Optional) : </b> {{this.costingService.InlineScraping}}
        </div>
        <nz-divider nzText="Costing Details"></nz-divider>
        <p>
            Note: Costing Details are calculated after the consumption for raw material is completed and if there are
            Post Process included in this order then that also needs to be processed before to show the actual cost.
        </p>
        <nz-divider></nz-divider>
        <nz-descriptions *ngIf="costingService.havePostProcess" nzBordered nzLayout="vertical" nzSize="small"
            [nzColumn]="6">
            <nz-descriptions-item nzTitle="Material Type">
                {{this.costingService.MaterialType}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Printing"
                *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null">
                <b>
                    &#8377; {{
                    this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Embossing"
                *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null">
                <b>
                    &#8377; {{
                    this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Tumbling"
                *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null">
                <b>
                    &#8377; {{
                    this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Vacuum"
                *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null">
                <b>
                    &#8377; {{
                    this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Lacquer"
                *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null">
                <b>
                    &#8377; {{
                    this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost}}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
        <br>
        <div>
            <nz-table [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                    <tr>
                        <th></th>
                        <th>Per Kg Cost</th>
                        <th>Cost Per Lm</th>
                        <th>Final GSM</th>
                        <th>STD. GSM</th>
                        <th>Less GSM</th>
                        <th>Extra GSM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let i of costingService.Orderlist.SaleOrderProduction.FinalFormulationMixing">
                        <td>{{ i.MixingName }}</td>
                        <td>&#8377; {{(i.TotalCost / i.TotalQuantity).toFixed(2)}}</td>
                        <td>&#8377; {{i.CostPerLm}}</td>
                        <td>{{i.FinalGSM}}</td>
                        <td>{{i.GSM}}</td>
                        <td>{{i.LessGSM}}</td>
                        <td>{{i.ExtraGSM}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <div>
            <br>
            <nz-table [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                <thead>
                    <tr>
                        <th></th>
                        <th>GSM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="costingService.Orderlist.SaleOrderProduction.FinalFabricData?.length > 0">
                        <td>Fabric</td>
                        <td>{{costingService.FabricFinalAvgGsm}}
                        </td>
                    </tr>
                    <tr>
                        <td>Coating</td>
                        <td> {{this.costingService.TotalCoating}} </td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>{{costingService.FabricFinalAvgGsm +
                            this.costingService.TotalCoating}} </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <br>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="5">
            <nz-descriptions-item nzTitle="Per LM Constant" [nzSpan]="1">
                <b>{{this.costingService.PerLMConstant}}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PASTE COST / LM" [nzSpan]="1" nzColon="true">
                <b>&#8377; {{ this.costingService.TotalCostLm }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="GRAIN COST / LM" [nzSpan]="1"><b>&#8377; {{
                    costingService.Orderlist.SaleOrderProduction.GrainPrice
                    }}</b></nz-descriptions-item>

            <nz-descriptions-item nzTitle="FABRIC COST/LM"
                *ngIf="costingService.Orderlist.SaleOrderProduction.FinalFabricData?.length > 0" [nzSpan]="1"><b>&#8377;
                    {{
                    this.costingService.FabricProductCostPerLm}}</b></nz-descriptions-item>

            <nz-descriptions-item nzTitle="FINISHING COST/ LM">
                <b>
                    &#8377; {{costingService.calculatefinishingcost()}}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="4">
            <nz-descriptions-item nzTitle="R\M COST / LM">
                <b>
                    {{ costingService.calculateRMCost()}}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="REJECTION %">
                {{this.costingService.RejectionConstant}}%
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="REJECTION COST /LM">
                <b>{{ this.costingService.Rejection }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PRODUCTION COST / LM">
                <b>
                    {{ this.costingService?.ProductionCostLm }}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="6">

            <nz-descriptions-item nzTitle="*** Overhead">
                {{this.costingService.OverheadCost}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="PACKAGING COST / LM" [nzSpan]="1">
                <b>{{ this.costingService.PackagingCostPerLm }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="MISC. COST / LM" [nzSpan]="1" nzColon="true"
                *ngIf="costingService.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.length > 0">
                <b>{{ this.costingService.MiscCostPerLm }}</b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Cost">
                <b>
                    {{ this.costingService.TotalCostPerLm }}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
        <p style="margin: 10px;">** Rejection Percentage shown above is auto calculated by
            taking sum quantity of
            these Graded Mini Rolls - LOT, NS, CUT-PC, FILM, Waste. Each Roll Details can be
            seen on Yield Report.
        </p>
        <p style="margin: 10px;">*** Overhead is auto calculated based on Line Speed for this
            order -
            {{this.costingService.Orderlist.SaleOrderProductionComplete?.PrdLineSpeed}}
            MTRs/Minute and Per Hour Avg Overhead provided in Overhead Details under Costing Menu.
        </p>
        <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3">
            <nz-descriptions-item nzTitle="Order QTY">
                <div>
                    {{this.costingService.Orderlist.SaleOrderProduction.OrderQuantity}}
                </div>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Manufactured QTY">
                <div>
                    {{this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity}}
                </div>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Production Line Speed">
                <div>
                    {{this.costingService.Orderlist.SaleOrderProductionComplete?.PrdLineSpeed}}
                    MTRs/Minute
                </div>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                    costingService.Orderlist.SaleFormulationCode }}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Sale Price"><b>{{
                    costingService.Orderlist.SaleOrderProduction.SalePrice
                    }}</b></nz-descriptions-item>
            <nz-descriptions-item
                nzTitle="Thick"><b>{{costingService.Orderlist.SaleOrderProduction.ThicknessValue}}</b></nz-descriptions-item>
            <nz-descriptions-item nzTitle="Final Cost">
                <b>
                    {{ (this.costingService.TotalCostPerLm *
                    this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)
                    }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Profit/Loss Per LM">
                <b>
                    {{ (costingService.Orderlist.SaleOrderProduction.SalePrice -
                    this.costingService.TotalCostPerLm).toFixed(2) }}
                </b>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Total Profit/Loss">
                <b>
                    {{ this.costingService.calculateTotalProfitLoss() }}
                </b>
            </nz-descriptions-item>
        </nz-descriptions>
    </div>



</div>
<app-loader></app-loader>