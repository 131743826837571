<app-SoDrawer></app-SoDrawer>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Product Stock Summary Report</nz-page-header-title>
      <nz-page-header-subtitle>Manage your product stock summary here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">

          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                <label>Status</label>
                <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                  [(ngModel)]="request.RecordStatus" name="Status" nzAllowClear nzPlaceHolder="">
                  <nz-option nzValue="null" nzLabel="All"></nz-option>
                  <nz-option nzValue="Available" nzLabel="Available"></nz-option>
                  <nz-option nzValue="Dispatched" nzLabel="Dispatched"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                <label>Formulation Code</label>
                <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                  nzAllowClear [(ngModel)]="request.SaleFormulationCodeId" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                    [nzLabel]="s.SaleFormulationCode"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label>Customer</label>
                <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                  [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                    [nzLabel]="s.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Color</label>
                <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId" nzShowSearch
                  nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                    [nzLabel]="s.ColorName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Grain Name</label>
                <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default" nzShowSearch
                  nzAllowClear nzPlaceHolder="Choose">
                  <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                    [nzLabel]="s.GrainName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                <label>Article Name</label>
                <input nz-input nzAllowClear placeholder="Enter Alias to Search" maxlength="30"
                  [(ngModel)]="request.ArticleName" (keydown)="space($event)" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Embossing</label>
                <nz-select #microwaveRef class="form-select" [(ngModel)]="request.EmbossingMasterId" nzAllowClear
                  nzShowSearch nzSize="default" ame="EmbossingMasterId" nzPlaceHolder="Select">
                  <nz-option *ngFor="let s of this.EmbossingList;" [nzValue]="s.EmbossingMasterId"
                    [nzLabel]="s.Name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Print</label>
                <nz-select class="form-select" nzShowSearch [(ngModel)]="request.PrintMasterId" nzAllowClear
                  nzSize="default" name="PrintMasterId" nzPlaceHolder="Select">
                  <nz-option *ngFor="let s of this.PrintMasterList;" [nzValue]="s.PrintMasterId"
                    [nzLabel]="s.Name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Vaccum</label>
                <nz-select #microwaveRef class="form-select " [(ngModel)]="request.VacuumMasterId" nzShowSearch
                  nzSize="default" name="Category" nzAllowClear nzPlaceHolder="Vaccum">
                  <nz-option *ngFor="let s of this.VaccumList;" [nzValue]="s.VacuumMasterId"
                    [nzLabel]="s.Name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetProductStockSummaryReport()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 8px;float:right">
      <button nz-button nzSize="small" (click)="export()"><i style="top: 4px;right: 51px;position: absolute;" nz-icon
          nzType="export" nzTheme="outline"></i>
        Export</button>
      <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
        style="margin-left:5px"><i style="top: 4px;right: 38px;position: absolute;" nz-icon nzType="printer"
          nzTheme="outline"></i>Print</button>
    </div>
    <nz-divider></nz-divider>
    <div style="max-width:100%; overflow:scroll">
      <nz-table id="print-section" nzSize="small" [nzPageSize]="10" style="width: 100%" #basicTable
        [nzData]="this.DataList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger
        [nzShowTotal]="totalTemplate" nzShowQuickJumper="true">
        <thead>
          <tr style="font-size: small;">

            <th nzWidth="50px" style="font-size: x-small;">S. No.</th>
            <th nzWidth="65px">Sale Order No.</th>
            <th nzWidth="150px">Sale Order Code</th>
            <th nzWidth="95px">Grain Code</th>
            <th nzWidth="100px">Color Name</th>
            <th nzWidth="100px">Alias</th>
            <th nzWidth="130px">Customer</th>
            <th nzWidth="60px">Grade</th>
            <th nzWidth="55px">Roll</th>
            <th nzWidth="65px">MTR</th>
            <th nzWidth="100px">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data" style="font-size: small;">

            <td nzWidth="45px">{{ data.SerialNo }}</td>
            <td nzWidth="65px">
              <nz-tag nzColor="blue"><a (click)="OpenSaleOrderViewPop(data.SaleOrderId)">
                  {{data.SaleOrderNumber}}
                </a></nz-tag>
            </td>
            <td nzWidth="150px" nzBreakWord>{{ data.SaleOrderCode }}</td>
            <td nzWidth="100px" title="Name: {{data.GrainName}}" nzBreakWord>{{ data.GrainCode }}</td>
            <td nzWidth="120px" nzBreakWord>{{ data.ColorName }}</td>
            <td nzWidth="120px" nzBreakWord>{{ data.ArticleName }}</td>
            <td nzWidth="160px" nzBreakWord>{{ data.CustomerName }}</td>
            <td nzWidth="80px">{{ data.Grade }}</td>
            <td nzWidth="80px">{{ data.Roll }}</td>
            <td nzWidth="80px">{{ data.Quantity }}</td>
            <td nzWidth="100px">{{ data.SaleOrderStatus == 'DispatchCompleted' ? 'Dispatched' : 'Available' }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>

  </div>
</div>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>