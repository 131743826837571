import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';

import { ProductionModel, ProductionElementModel, ProductionOrderModel, ProductionRawMaterialModel } from '../../Models/ProductionModel';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { GateInModel } from '../../Models/GateInModel';
import { CompanyInfo } from 'src/PmsUIApp/Authentication/UserInfo';


@Component({
  selector: 'app-gatepassprint',
  templateUrl: './gatepassprint.component.html',
  styleUrls: ['./gatepassprint.component.css']
})
export class GatepassprintComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  GateInRecord: GateInModel = new GateInModel() ;
  Production!: GateInModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production";
  searchValue = '';
  visible = false;
  private route$: Subscription = new Subscription;
  ProductionId = 0;
  count: 0;
  Company = {
    CompanyName: '',
    HeadOfficeAddress: '',
    FactoryAddress: ''
  };
  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute) { }


  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.ProductionId = params["id"];
    })
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.HeadOfficeAddress = CompanyInfo.HeadOfficAddress;
    this.Company.FactoryAddress = CompanyInfo.FactoryAddress;
    this.GetAllGateInRecords();
  }
  GetAllGateInRecords() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "gate/getgateinrecordbyid?gateinid=" + this.ProductionId;
    this.http.get<GateInModel>(url).subscribe(res => {
      this.GateInRecord = res;
      this.Production = this.GateInRecord;
      setTimeout(function () {
        window.print();
        window.onafterprint = close;
        function close() {
          window.close();
        }
      }, 1)
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllGateInRecords();
      }
    });
  }



}
