<div *ngIf="isOverlayOpen" class="barcode-scanner-container">
    <barcode-scanner-livestream-overlay #barcodeScanner [type]="['code_128']" (valueChanges)="onValueChanges($event)"
        (started)="onStarted($event)"></barcode-scanner-livestream-overlay>
</div>

<nz-modal [(nzVisible)]="isManualInputOpen" 
    [nzTitle]="manualInputModalTitle" 
    [nzFooter]="manualInputModalFooter"
    [nzContent]="manualInputModalContent"
    (nzOnCancel)="handleManualInputCancel()"
    (nzAfterOpen)="onManualInputModalOpen()">
    <ng-template #manualInputModalTitle>External Scanner Device/Manual Entry</ng-template>
    <ng-template #manualInputModalContent>
        <form [formGroup]="manualInputForm" (ngSubmit)="fetchLabelDetailsManually()">
            <nz-form-item>
                <nz-form-label nzFor="serialNo">Serial Number</nz-form-label>
                <nz-form-control>
                    <input nz-input id="serialNo" formControlName="serialNo" 
                           placeholder="Enter Serial Number"
                           (keyup.enter)="fetchLabelDetailsManually()"
                           #serialNoInput>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-template>
    <ng-template #manualInputModalFooter>
        <button nz-button nzType="primary" (click)="fetchLabelDetailsManually()">Fetch Details</button>
        <button nz-button nzType="default" (click)="handleManualInputCancel()">Cancel</button>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsLabelDetailsVisible" [nzStyle]="{ top: '20px'}" [nzWidth]="isMobile ? '95%' : '700px'"
    [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleLabelDetailsCancel()">
    <ng-template #modalTitle>Label Details</ng-template>

    <ng-template #modalContent>
        <div>
            <p>Label Status: <b [ngStyle]="{'background-color': LabelStatusColor}">{{LabelDetails.StockLabel.LabelStatus}}</b></p>
            <p>Serial No: <b>{{LabelDetails.StockLabel.SerialNo}}</b></p>

            <nz-collapse>
                <nz-collapse-panel nzHeader="Product Information" [nzActive]="true">
                    <table class="info-table">
                        <tr>
                            <td>Product Name:</td>
                            <td><b>{{ LabelDetails.ProductName }}</b></td>
                        </tr>
                        <tr>
                            <td>Batch No.:</td>
                            <td><b>{{ LabelDetails.BatchNo }}</b></td>
                        </tr>
                        <tr>
                            <td>Quantity:</td>
                            <td><b>{{ LabelDetails.StockLabel.Quantity }}</b></td>
                        </tr>
                        <tr>
                            <td>Packaging Unit:</td>
                            <td><b>{{ LabelDetails.StockLabel.PackagingUnit }}</b></td>
                        </tr>
                    </table>
                </nz-collapse-panel>
                <nz-collapse-panel nzHeader="Purchase Information">
                    <table class="info-table">
                        <tr>
                            <td>Supplier Name:</td>
                            <td><b>{{ LabelDetails.SupplierName }}</b></td>
                        </tr>
                        <tr>
                            <td>Stock Unit:</td>
                            <td><b>{{ LabelDetails.StockUnit }}</b></td>
                        </tr>
                        <tr>
                            <td>Price Per Unit:</td>
                            <td><b>{{ LabelDetails.PricePerUnit }}</b></td>
                        </tr>
                        <tr>
                            <td>Invoice No.:</td>
                            <td><b>{{ LabelDetails.InvoiceNo }}</b></td>
                        </tr>
                        <tr>
                            <td>Purchase Order No.:</td>
                            <td><b>{{ LabelDetails.PurchaseOrderNo }}</b></td>
                        </tr>
                        <tr>
                            <td>Product Quality:</td>
                            <td><b>{{ LabelDetails.ProductQuality }}</b></td>
                        </tr>
                        <tr>
                            <td>Grade:</td>
                            <td><b>{{ LabelDetails.Grade }}</b></td>
                        </tr>
                    </table>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Date Information">
                    <table class="info-table">
                        <tr>
                            <td>MFG Date:</td>
                            <td><b>{{ LabelDetails.StockLabel.MfgDate | date:'dd-MM-yyyy': 'IST' }}</b></td>
                        </tr>
                        <tr>
                            <td>Expiry Date:</td>
                            <td><b>{{ LabelDetails.StockLabel.ExpiryDate | date:'dd-MM-yyyy': 'IST' }}</b></td>
                        </tr>
                        <tr>
                            <td>Received Date:</td>
                            <td><b>{{ LabelDetails.ReceivedDate | DatetimeConverter }}</b></td>
                        </tr>
                        <tr>
                            <td>Inspected Completed Date:</td>
                            <td><b>{{ LabelDetails.InspectedCompletedDate | DatetimeConverter }}</b></td>
                        </tr>
                    </table>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Location">
                    <table class="info-table">
                        <tr>
                            <td>Store Name:</td>
                            <td><b>{{ LabelDetails.StockLabel.StoreName }}</b></td>
                        </tr>
                        <tr>
                            <td>Rack Name:</td>
                            <td><b>{{ LabelDetails.StockLabel.RackName }}</b></td>
                        </tr>
                    </table>
                </nz-collapse-panel>
                <nz-collapse-panel nzHeader="Additional Information">
                    <table class="info-table">
                        <tr>
                            <td>Received By:</td>
                            <td><b>{{ LabelDetails.ReceivedBy }}</b></td>
                        </tr>
                        <tr>
                            <td>Inspection Completed By:</td>
                            <td><b>{{ LabelDetails.InspectionCompletedBy }}</b></td>
                        </tr>
                    </table>
                </nz-collapse-panel>
            </nz-collapse>
        </div>
    </ng-template>

    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleLabelDetailsCancel()">Close</button>
        <button nz-button nzType="primary" *ngIf="permission.Edit" (click)="openUpdateModal(LabelDetails.StockLabel.SerialNo)">Update</button>
        <button nz-button nzType="primary" (click)="openSplitModal(LabelDetails.StockLabel)">Split Label</button>
    </ng-template>
</nz-modal>
