import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { ProductModel, ProductModelExport } from 'src/PmsUIApp/Models/ProductModel';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { FormBuilder, UntypedFormControl, FormGroup, ValidationErrors, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel } from '../../Models/MasterModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-product-master',
  templateUrl: './ProductList.component.html',
  styleUrls: ['./ProductList.component.css']
})
export class ProductListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  ProductList: ProductModel[] = [];
  ProductListExport: ProductModelExport[] = [];
  ProductListOriginal: ProductModel[] = [];
  NewProduct: ProductModel = new ProductModel;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  MeasureUnits: MeasureUnitModel[] = [];
  typeList: any[] = [];
  PopUpTitle: string = "Add New Product";
  searchValue = '';
  visible = false;
  IsSearching = false;
  NameError = 'Enter Product name';
  CodeError = 'Enter Product code';
  exportfields: ProductModelExport[] = [];
  exportoptions = {
    headers: [

      "Product Name",
      "Product Code",
      "Product Type",
      "Unit",
      "AvgGsm",
      "Width",
      "MinQty",
      "Category",
      "First Sub-Category",
      "2nd Sub-Category",
    ]
  };
  fields: ProductModelExport = new ProductModelExport();
  count: number;
  AddProductDescription!: UntypedFormGroup;
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  isNew: boolean = true;
  constructor(public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private auth: AuthService, private router: Router, private fb: UntypedFormBuilder,) { }

  GetAllProducts() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.ProductListOriginal = res;
      this.isTableLoading = false;
      this.ProductListExport = res
      this.exportfields = [];
      this.ProductListExport.forEach(x => {
        this.fields = new ProductModelExport();
        this.fields.ProductName = x.ProductName;
        this.fields.ProductCode = x.ProductCode;
        this.fields.Unit = x.Unit
        this.fields.AvgGsm = x.AvgGsm
        this.fields.WidthInMeter = x.WidthInMeter
        this.fields.MinimumQuantity = x.MinimumQuantity
        this.fields.ProductType = x.ProductType
        this.fields.ProductCategory = x.ProductCategory;

        this.fields.ProductFirstSubCategory = x.ProductFirstSubCategory;
        this.fields.ProductSecSubCategory = x.ProductSecSubCategory;
        this.exportfields.push(this.fields);

      })
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProducts(); }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res.filter(x => x.ProductType == this.NewProduct.ProductType);
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(); }
    });

  }
  GetAllFirstCategory(data: any) {

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);

    }, res => { });
  }
  GetAllSecondCategory(data: any) {

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);

    }, res => { });
  }
  SaveProduct() {
    if (!this.Validate(this.NewProduct)) {
      this.isLoading = false
      return;
    }
    // this.NewProduct.ProductType = this.ProductCategoryList.filter(x => x.ProductCategoryId == this.NewProduct.ProductCategoryId)[0].ProductType;
    let url = this.ApiUrl + "product/addupdateproduct";
    this.http.post<ProductModel>(url, this.NewProduct).subscribe({

      next: res => {
        this.alertService.success("Product Saved Successfully");
        this.isLoading = this.isVisible = false;
        this.AddProductDescription.reset();
        this.GetAllProducts();
      },
      error: res => {
        if (res.error.StatusCode == 'BadRequest') {
          this.alertService.warning(res.error.ResponseBody); this.isLoading = false;
        }
        else {
          this.alertService.error("An error has been occured. Please try again"); this.isLoading = this.isVisible = false;
        }
      },

    });

  }
  Validate(model: ProductModel) {
    var Isvalid: boolean = true;

    if (model.ProductName == '') {
      this.alertService.error("Enter Product Name"); Isvalid = false;
    }
    else if (model.ProductCode == '') {
      this.alertService.error("Enter Product Code"); Isvalid = false;
    }
    else if (model.ProductType == '') {
      this.alertService.error("Select Product Type"); Isvalid = false;
    }
    else if (model.Unit == '0') {
      this.alertService.error("Select Product Unit"); Isvalid = false;
    }
    else if (model.ProductCategoryId <= 0) {
      this.alertService.error("Select Product Category"); Isvalid = false;
    }
    else if (model.ProductDescription?.length > 800) {
      this.alertService.error("Product Description cannot be more than 800 characters"); Isvalid = false;
    }
    if (model.ProductName != '') {
      if (this.NewProduct.ProductId != 0) {
        var res = this.ProductList.filter(x => x.ProductId != this.NewProduct.ProductId);
        var nre = res.filter(x => x.ProductName.toLowerCase() == model.ProductName.toLowerCase());
        if (nre.length > 0) {
          this.alertService.error("Product already Exist"); Isvalid = false;
        }
      } else {
        var nre = this.ProductList.filter(x => x.ProductName.toLowerCase() == model.ProductName.toLowerCase());
        if (nre.length > 0) {
          this.alertService.error("Product already Exist"); Isvalid = false;
        }
      }
    }
    if (model.ProductCode != '') {

      if (this.NewProduct.ProductId != 0) {
        var res = this.ProductList.filter(x => x.ProductId != this.NewProduct.ProductId);

        var nre = res.filter(x => x.ProductCode?.toLowerCase() == model.ProductCode.toLowerCase());

        if (nre.length > 0) {
          this.alertService.error("Product Code already Exist"); Isvalid = false;
        }
      } else {
        var nre = this.ProductList.filter(x => x.ProductCode?.toLowerCase() == model.ProductCode.toLowerCase());

        if (nre.length > 0) {
          this.alertService.error("Product Code already Exist"); Isvalid = false;
        }
      }
    }
    //return false;
    return Isvalid;
  }
  OpenEditPop(data: ProductModel) {
    this.GetAllProductCategory();
    this.isTableLoading = true;
    this.isNew = false;
    this.PopUpTitle = "Modify Product";
    this.NewProduct = new ProductModel();
    this.NewProduct.ProductId = data.ProductId;
    this.NewProduct.ProductName = data.ProductName;
    this.NewProduct.ProductCode = data.ProductCode;
    this.NewProduct.ProductType = data.ProductType;
    this.NewProduct.Unit = data.Unit;
    this.NewProduct.AvgGsm = data.AvgGsm;
    this.NewProduct.ProductCategoryId = data.ProductCategoryId;
    this.NewProduct.MinimumQuantity = data.MinimumQuantity;
    this.NewProduct.WidthInMeter = data.WidthInMeter;
    this.NewProduct.ProductDescription = data.ProductDescription;
    //this.NewProduct=data;xfggg
    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.ProductCategoryId);
      this.NewProduct.ProductFirstSubCategoryId = data.ProductFirstSubCategoryId;
      let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
      this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
        this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId);
        this.NewProduct.ProductSecSubCategoryId = data.ProductSecSubCategoryId

        this.isVisible = true;
        this.isTableLoading = false;
      }, res => { });


    }, res => { });

  }
  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnits(); }
    });
  }
  showModal(): void {
    this.PopUpTitle = "Add New Product";
    this.isNew = true;
    //this.NewProduct.ProductName="";
    //this.NewProduct.ProductCode="";
    //this.NewProduct.ProductId = 0;
    this.NewProduct = new ProductModel;
    // this.NewProduct.ProductType = "Raw";
    this.NewProduct.Unit = "0";
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveProduct();

  }

  handleCancel(): void {
    this.AddProductDescription.reset();
    this.isVisible = false;
  }
  DeleteProduct(data: ProductModel) {
    let url = this.ApiUrl + "product/deleteproduct";
    this.http.post<any>(url, data).subscribe({

      next: res => {
        this.alertService.success(res); this.isLoading = false;
        this.GetAllProducts();
      },
      error: res => {
        if (res.StatusCode == 400) {
          this.alertService.warning(res.error.ResponseBody)
        }
        else {
          this.alertService.error(res.error.ResponseBody); this.isLoading = false;
        }
      },

    });

  }
  handleDelete(data: ProductModel) {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this product?',
      nzOkDanger: true,
      nzOkText: 'Yes',
      nzOnOk: () => this.DeleteProduct(data),
    });

    setTimeout(() => modal.destroy(), 5000);

  }
  enableEditDeleteBtn(data: any) {
    if (this.permission.Delete && data == "Delete") {
      return false;
    }
    if (this.permission.Edit && data == "Edit") {
      return false;
    }
    else {
      return true;
    }
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'product-export' + exportdate,
        this.exportoptions
      );
  }



  search() {

    var res = this.ProductListOriginal;
    this.ProductList = res.filter((item: ProductModel) => {

      if (
        item?.ProductCode?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else if (
        item?.ProductType?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      else if (
        item?.Unit?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item.MinimumQuantity == +this.searchValue
      ) {
        return true;
      }
      else if (
        item?.ProductCategory?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.ProductFirstSubCategory?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.ProductSecSubCategory?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }

      return false;
    });




  }

  NameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.ProductList.filter(x => x.ProductId != this.NewProduct.ProductId);
        var nre = res.filter(x => x.ProductName.toLowerCase() == control.value.toLowerCase());
        this.NameError = '';

        if (control.value == '') {
          this.NameError = "Enter Department name";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.NameError = "Department name already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.NameError = "Enter Department name";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  CodeAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.ProductList.filter(x => x.ProductId != this.NewProduct.ProductId);
        var nre = res.filter(x => x.ProductCode.toLowerCase() == control.value.toLowerCase());

        this.CodeError = '';
        if (control.value == '') {
          this.CodeError = "Enter Department code";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.CodeError = "Department code already exist";
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          this.CodeError = "Enter Department code";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Product, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Product, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.Product, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Product, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProducts();
    this.GetAllUnits();
    // this.GetAllProductCategory()
    this.typeList = [
      { name: "Raw" },
      { name: "Finished" },
      { name: "Internal-Use" }
    ];
    this.AddProductDescription = this.fb.group({
      description: [null, [Validators.required, Validators.maxLength(800)]]
    });
  }
  get f() { return this.AddProductDescription.controls; }
  onSelectedProductTypeChange() {

    this.GetAllProductCategory();
  }
}
