<app-OutpassTimeline></app-OutpassTimeline>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Out Pass List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your Out Pass here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" routerLink="/home/outpass/add">
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Show Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Order Date (From)</label>
                  <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                    [(ngModel)]="request.FromDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label> Order Date (To)</label>
                  <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                    [(ngModel)]="request.ToDate" nzAllowClear nzShowTime></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                  <label>OutPass To</label>
                  <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                    [(ngModel)]="request.OutpassToCustomerId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                      [nzLabel]="s.CustomerName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Outpass Type</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.OutpassType"
                    nzPlaceHolder="Choose" nzShowSearch nzAllowClear>
                    <nz-option nzValue="Returnable" nzLabel="Returnable"></nz-option>
                    <nz-option nzValue="Non-Returnable" nzLabel="Non-Returnable"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Returnable Status</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.IsOutpassIn"
                    nzPlaceHolder="Choose" nzShowSearch nzAllowClear>
                    <nz-option nzValue=true nzLabel="Received"></nz-option>
                    <nz-option nzValue=false nzLabel="Pending"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Product Type</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                    (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                    <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                    <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                    <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                  <label>Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID"
                    name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                    <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                      [nzLabel]="s.ProductCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="5">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>SubCategory</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category"
                    (ngModelChange)="GetAllSecondCategory($event)">
                    <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                      [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="5">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>2nd SubCategory</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="Second Category"
                    (ngModelChange)="GetSecondCategoryFilteredProduct()">
                    <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                      [nzLabel]="s.ProductSecSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="7">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label>Product Name</label>
                  <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="request.OutpassProductName"
                    name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear
                    nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                      [nzLabel]="s.ProductName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>

          <div style="float: right; margin-bottom: 5%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px" (click)="GetAllOutPass()">
                Apply Filter
              </button>
              <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search  "
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <div style="font-weight: bolder;">
      <p>* Yellow color cells are to show Non-Store items in Outpass. Admin should review them regularly.</p>
    </div>
    <nz-table nzSize="small" [nzScroll]="{ x: '1400px', y: '515px' }" style="width: 100%" #basicTable
      [nzData]="this.OutPassList" [nzLoading]="isTableLoading" nzBordered [nzPageSize]="20" nzShowPagination="true"
      nzShowSizeChanger [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true"
      nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="60px">S.No.</th>
          <th nzWidth="130px">Out Pass No.</th>
          <th nzWidth="190px">Out Pass To</th>
          <th nzWidth="150px">Out Pass Type</th>
          <th nzWidth="150px">Product Name</th>
          <th nzWidth="140px">Out Pass Date</th>
          <th nzWidth="150px">Purpose</th>
          <th nzWidth="150px">Status</th>
          <th nzWidth="150px">Transport</th>
          <th nzWidth="150px">Vehicle</th>
          <th nzWidth="200px">Remark </th>
          <th nzWidth="120px">Expected Return</th>
          <th nzWidth="120px">Added Date </th>
          <th nzWidth="150px">Added By </th>
          <th nzWidth="140px" style="text-align: center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{data.SerialNo}}</td>
          <td [ngStyle]="{'background-color': GetCellColorforNonStoreItems(data)}">{{ data.OutpassNumber }}</td>
          <td>{{ data.OutpassTo }}</td>
          <td>{{ data.OutpassType }}</td>
          <td [ngStyle]="{'background-color': GetCellColorforNonStoreItems(data)}"
            (click)="OpenProductList(data.OutpassItems)" innerHTML="{{ GetProductName(data)}}"> </td>
          <td>{{ data.OutpassDate | DatetimeConverter}}</td>
          <td>{{ data.Purpose }}</td>
          <td><nz-tag [nzColor]="'blue'" (click)="OpenTimeline(data.OutpassId)"> <a>{{ data.Status }}</a></nz-tag></td>
          <td>{{ data.TransportName }}</td>
          <td>{{ data.VehicleNumber }}</td>
          <td>{{ data.Remark }}</td>
          <td>{{ data.ExpectedReturnDate | DatetimeConverter }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td>{{ data.AddedBy }}</td>
          <td nzRight style="text-align: center">
            <!-- <button *ngIf="data.OutpassType=='Returnable' && data.IsOutpassIn!=true" class="btn btn-sm btn-light-primary"
              (click)="OpenInoutPass(data)" style="margin-right: 10px; ">In-OutPass</button>
            <button class="btn btn-sm btn-light-primary" (click)="OpenProductList(data.OutpassItems)">Show
              Products</button>
            <button class="btn btn-sm btn-light-primary" (click)="print(data)">Print</button> -->
            <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="btn btn-sm btn-light-primary"
              style="line-height:0">
              Action
              <span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li *ngIf="data.OutpassType=='Returnable' && data.IsOutpassIn!=true" style="width: 120px;" nz-menu-item
                  (click)="OpenInoutPass(data)">Accept Return OutPass</li>
                  <li *ngIf="data.OutpassType=='Returnable' && data.IsOutpassIn!=true" style="width: 120px;" nz-menu-item
                  (click)="ShowOutpassReturnExtendModel(data)">Extend Return Date</li>
                <li style="width: 120px;" nz-menu-item (click)="OpenProductList(data.OutpassItems)">Show
                  Products</li>
                  <li style="width: 120px;" nz-menu-item (click)="OpenTimeline(data.OutpassId)">Show
                    Timeline</li>
                <li style="width: 120px;" nz-menu-item (click)="print(data)" *ngIf="EnablePrintButton(data)">Print
                  OutPass</li>
                <li style="width: 120px;" nz-menu-item *ngIf="this.permission.AddTransport && !data.IsGateIn"
                  (click)="showTransportModal(data)">Add Transport</li>
                <li style="width: 120px;" nz-menu-item (click)="StatusChangeConfirmation(data.OutpassId, 'Approved')"
                  *ngIf="EnableApproveButton(data)">Approve</li>
                <li style="width: 120px;" nz-menu-item (click)="StatusChangeConfirmation(data.OutpassId, 'On Hold')"
                  *ngIf="EnableOnHoldButton(data)">On Hold</li>
                <li style="width: 120px;" nz-menu-item (click)="StatusChangeConfirmation(data.OutpassId, 'Rejected')"
                  *ngIf="EnableApproveButton(data)">Reject</li>
                <li style="width: 120px;" nz-menu-item (click)="StatusChangeConfirmation(data.OutpassId, 'Cancelled')"
                  *ngIf="EnableCancelButton(data)">Cancel</li>

              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>




<nz-modal [nzWidth]="1000" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>In-Out pass</ng-template>

  <ng-template #modalContent>

    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzData]="['']" #basicTable1 nzBordered>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Store</th>
          <th>Rack</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Returned Store</th>
          <th>Returned Rack</th>
          <th>Returned Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.OutPass.OutpassItems;let i=index">
          <td>{{i+1}}</td>
          <td>{{ data.StoreName }}</td>
          <td>{{ data.RackName }}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Quantity }}</td>
          <td>
            <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
              [(ngModel)]="data.ReturnedStoreId" (ngModelChange)="GetStorewiseStock(data)">
              <nz-option *ngFor="let s of this.AdminStoreList;" [nzValue]="s.StoreId"
                [nzLabel]="s.StoreName"></nz-option>
            </nz-select>
          </td>
          <td>
            <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
              [(ngModel)]="data.ReturnedRackId">
              <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
            </nz-select>

          </td>
          <td><input nz-input type="number" name="ReturnedQuantity" [(ngModel)]="data.ReturnedQuantity" /> </td>
        </tr>

      </tbody>

    </nz-table>


  </ng-template>
  <ng-template #modalFooter>
    <div class="text-center">
      <a nz-button nzType="primary" (click)="Save()">Save</a>
    </div>
  </ng-template>

</nz-modal>

<nz-modal [nzWidth]="1200" [nzStyle]="{ top: '20px' }" [(nzVisible)]="IsProductsVisible" [nzTitle]="modalTitle1"
  [nzContent]="modalContent1" [nzFooter]="modalFooter1" (nzOnCancel)="handleProductsViewCancel()">
  <ng-template #modalTitle1>Products Details</ng-template>

  <ng-template #modalContent1>

    <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzData]="['']" #basicTable1 nzBordered>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>From Store</th>
          <th>From Rack</th>
          <th>Amount</th>
          <th>Unit</th>
          <th>Total Amount</th>
          <th>Return Store</th>
          <th>Return Rack</th>
          <th>Return Completed By</th>
          <th>Return Completed Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of this.OutPassProducts;let i=index">
          <td>{{i+1}}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Quantity }}</td>
          <td>{{ data.StoreName }}</td>
          <td>{{ data.RackName }}</td>
          <td>{{ data.Amount }}</td>
          <td>{{ data.Unit }}</td>
          <td>{{ (data.Quantity * data.Amount).toFixed(2) }}</td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name != null">{{ data.ReturnedStoreName
            }}
          </td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name != null">{{
            data.ReturnedRackName}}</td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name != null">{{
            data.ReturnCompletedBy?.Name }}
          </td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name != null">{{
            data.ReturnCompletedDate |
            DatetimeConverter}}</td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name == null">Not Yet</td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name == null">Not Yet</td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name == null">Not Yet</td>
          <td *ngIf="data.OutpassType == 'Returnable' && data.ReturnCompletedBy?.Name == null">Not Yet</td>
          <td *ngIf="data.OutpassType == 'Non-Returnable'">N/A</td>
          <td *ngIf="data.OutpassType == 'Non-Returnable'">N/A</td>
          <td *ngIf="data.OutpassType == 'Non-Returnable'">N/A</td>
          <td *ngIf="data.OutpassType == 'Non-Returnable'">N/A</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
  <ng-template #modalFooter1>
    <div class="text-center">
      <a nz-button nzType="primary" (click)="handleProductsViewCancel()">Close</a>
    </div>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsActivitiesActionPopVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="400"
  [nzTitle]="modalTitleDispatch" [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch"
  (nzOnCancel)="handleActivitiesActionCancel()">
  <ng-template #modalTitleDispatch>Purchase Order Action</ng-template>

  <ng-template #modalContentDispatch>
    <label><b>Status Change for Outpass No.: {{this.selectedOutpass.OutpassNumber}}</b></label>
    <p></p>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
          <label>Status</label>
          <nz-select class="form-select " nzShowSearch nzSize="default" [(ngModel)]="OutpassStatusAction.Status"
            name="Category" nzAllowClear nzPlaceHolder="Select Status">
            <nz-option *ngFor="let s of this.outpassStatusList;" [nzValue]="s" [nzLabel]="s"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <label style="color: red;"><b>* Remark is
        required</b></label>
    <div>
      <nz-form-item>
        <nz-form-control nzErrorTip="Only 180 characters allowed.">
          <nz-textarea-count [nzMaxCharacterCount]="180">
            <textarea nz-input class="form-control mb-2" name="remark" [(ngModel)]="OutpassStatusAction.Remark"
              rows="3"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </ng-template>

  <ng-template #modalFooterDispatch>
    <button nz-button nzType="primary" (click)="SaveStatusChange()" [nzLoading]="isLoading">Submit</button>
    <button nz-button nzType="default" (click)="handleActivitiesActionCancel()" [nzLoading]="isLoading">Cancel</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsTransportVisible" [nzStyle]="{ top: '50px'}" [nzWidth]="750" [nzTitle]="modalTitle2"
  [nzContent]="modalContent2" [nzFooter]="modalFooter2" (nzOnCancel)="handletransportcancel()">
  <ng-template #modalTitle2>Add Transport for Outpass No: {{this.OutpassModify.OutpassNumber }}</ng-template>

  <ng-template #modalContent2>
    <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="2">

      <nz-descriptions-item nzTitle="Transport">
        <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedTransportChange($event)"
          [(ngModel)]="OutpassModify.TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
            [nzLabel]="s.TransportCompanyName"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Vehicle">
        <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default"
          [(ngModel)]="OutpassModify.VehicleId" (ngModelChange)="onSelectedVehicleChange($event)" nzAllowClear
          nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId"
            [nzLabel]="s.VehicleNumber"></nz-option>
          <nz-option nzValue="0" nzLabel="Others"></nz-option>
        </nz-select>

      </nz-descriptions-item>
    </nz-descriptions>
    <span style="color: crimson;"><b>{{this.VehicleStatus}}</b></span>
  </ng-template>

  <ng-template #modalFooter2>

    <button nz-button nzType="default" (click)="handletransportcancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="SaveModifyOutpass()" [nzLoading]="isLoading">Save</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsExtendExpectedReturnVisible" [nzStyle]="{ top: '50px'}" [nzWidth]="450" [nzTitle]="modalTitle3"
  [nzContent]="modalContent3" [nzFooter]="modalFooter3" (nzOnCancel)="handleExtendExpectedReturnCancel()">
  <ng-template #modalTitle3>Extend Expected Return</ng-template>

  <ng-template #modalContent3>
    
    <label><b>New Expected Return Date for Outpass No: {{this.selectedOutpass.OutpassNumber }}</b></label>
    <p></p>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-control [nzSpan]="24">
          <label>Expected Return Date</label>
          <nz-date-picker [nzDisabledDate]="disabledPastDate" nzPlaceHolder="Return Date" class="form-control"
                    [(ngModel)]="OutpassModify.ExpectedReturnDate" nzShowTime></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <label style="color: red;"><b>* Remark is
        required</b></label>
    <div>
      <nz-form-item>
        <nz-form-control nzErrorTip="Only 180 characters allowed.">
          <nz-textarea-count [nzMaxCharacterCount]="150">
            <textarea nz-input class="form-control mb-2" name="remark" [(ngModel)]="OutpassModify.Remark"
              rows="3"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </ng-template>

  <ng-template #modalFooter3>

    <button nz-button nzType="default" (click)="handleExtendExpectedReturnCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="SaveModifyOutpass()" [nzLoading]="isLoading">Save</button>
  </ng-template>
</nz-modal>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>