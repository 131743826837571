<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Purchase Order</nz-page-header-title>
      <nz-page-header-subtitle>{{PoId > 0 ? "Editing PO No. ":" Add new " }}
        {{this.NewPO.Ponumber}}</nz-page-header-subtitle>
      <nz-page-header-extra>

        <!--<button nz-button nzType="primary" (click)="showModal()">Add New</button>-->
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="8">


          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Supplier</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Select Supplier">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select" nzShowSearch name="TransportId" formControlName="supplierId"
                nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                  [nzLabel]="s.SupplierName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10">Shipment Preference</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Select Shipment Preference">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select " nzShowSearch name="VehicleId" formControlName="transportId"
                nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                  [nzLabel]="s.TransportCompanyName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Reference# </nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Reference cannot be more than 100 characters">
              <input nz-input name="GateInPersonContact" formControlName="reference" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="10">Department</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Select Department Name">
              <nz-select class="form-select " nzShowSearch name="DeptId" formControlName="departmentId" nzSize="default"
                nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.DeptList;" [nzValue]="s.DeptId" [nzLabel]="s.DeptName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Delivery Terms</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select Delivery Terms">
              <!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
              <nz-select class="form-select" nzShowSearch name="VehicleId" formControlName="deliveryTermId"
                nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.DeliveryTermList;" [nzValue]="s.DeliveryTermId"
                  [nzLabel]="s.DeliveryTerm"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Payment Terms</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select Payment Terms">
              <nz-select class="form-select" nzShowSearch name="VehicleId" formControlName="paymentTermId"
                nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.PaymentTermList;" [nzValue]="s.PaymentTermId"
                  [nzLabel]="s.PaymentTerm"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Currency</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Enter Rate number">

              <nz-select class="form-select" nzShowSearch nzSize="default" formControlName="Currency" name="Currency">
                <nz-option *ngFor="let s of this.CurrencyList;" [nzValue]="s" [nzLabel]="s"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>PO Type</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Select PO Type">
              <nz-select class="form-select" nzSize="default" nzPlaceHolder="Choose" formControlName="POType"
                name="POType">
                <nz-option nzValue="IMPORT" nzLabel="IMPORT"></nz-option>
                <nz-option nzValue="DOMESTIC" nzLabel="DOMESTIC"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <!--<nz-form-item>
    <nz-form-label [nzSpan]="10">Order No</nz-form-label>
    <nz-form-control [nzSpan]="12" nzErrorTip="Order No required">
      <input nz-input name="GateInPerson" formControlName="ponumber"/>
    </nz-form-control>
  </nz-form-item>-->
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>PO Date</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="PO Date required">

              <nz-date-picker class="form-select" formControlName="pocreationDate"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Delivery Date</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Delivery Date required">

              <nz-date-picker class="form-select" formControlName="podeliveryDate"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Contact Person</nz-form-label>
            <nz-form-control [nzSpan]="12">

              <nz-select class="form-select" nzShowSearch nzSize="default" formControlName="ContactPersonUserId"
                name="ContactPersonUserId">
                <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.UserId" [nzLabel]="s.Name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <nz-divider></nz-divider>

      <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="4">
          <nz-form-item>
            <label>Product Type</label>
            <nz-select class="form-select " nzShowSearch nzSize="default" [disabled]="IsDisabled"
              [(ngModel)]="SelectedProductType" [ngModelOptions]="{standalone: true}" name="Category"
              nzPlaceHolder="ProductType" (ngModelChange)="onSelectedProductTypeChange()">
              <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
              <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
              <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>


            <nz-form-control [nzSpan]="40" nzErrorTip="Select supplier Name">
              <label>Category</label>
              <!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
              <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [disabled]="IsDisabled"
                [(ngModel)]="productItem.CategoryID" [ngModelOptions]="{standalone: true}" name="Category" nzAllowClear
                nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                  [nzLabel]="s.ProductCategory"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>


            <nz-form-control [nzSpan]="40" nzErrorTip="Select supplier Name">
              <label>Sub-Category</label>
              <!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
              <nz-select #microwaveRef class="form-select" nzShowSearch nzSize="default" [disabled]="IsDisabled"
                [(ngModel)]="productItem.FirstCategoryID" [ngModelOptions]="{standalone: true}" name="Category"
                nzAllowClear nzPlaceHolder="First Category" (ngModelChange)="GetAllSecondCategory($event)">
                <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId"
                  [nzLabel]="s.ProductFirstSubCategory"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>


            <nz-form-control [nzSpan]="40" nzErrorTip="Select supplier Name">
              <label>2nd Sub-Category</label>
              <!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
              <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [disabled]="IsDisabled"
                [(ngModel)]="productItem.SecondCategoryID" [ngModelOptions]="{standalone: true}" name="Category"
                nzAllowClear nzPlaceHolder="Second Category" (ngModelChange)="FilterSecSubCategory($event)">
                <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                  [nzLabel]="s.ProductSecSubCategory"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-control [nzSpan]="40" nzErrorTip="Select product Name">
              <label class="required">Product</label>
              <!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
              <nz-select #microwaveRef class="form-select" nzShowSearch nzSize="default" [(ngModel)]="selectedproduct"
                [ngModelOptions]="{standalone: true}" name="Category" nzAllowClear nzPlaceHolder="Product"
                (ngModelChange)="UpdateProduct($event)">
                <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s"
                  [nzLabel]="s.ProductName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>

            <nz-form-control [nzSpan]="40" nzErrorTip="Enter Unit number">
              <label class="required">Unit</label>
              <nz-select #microwaveRef disabled class="form-select" nzShowSearch nzSize="default"
                [(ngModel)]="selectedunit" [ngModelOptions]="{standalone: true}" name="UnitID" nzAllowClear
                nzPlaceHolder="Unit" (ngModelChange)="UpdateUnit($event)">
                <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

        </div>

        <div nz-col [nzSpan]="6">
          <nz-form-item>

            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Quantity">
              <label class="required">Quantity</label>
              <input nz-input type="number" name="Quantity" placeholder="Quantity" value="productItem.Quantity"
                [(ngModel)]="productItem.Quantity" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="OnQtychange($event)" />
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>

            <nz-form-control [nzSpan]="24" nzErrorTip="Enter Rate number">
              <label class="required">Rate</label>
              <input nz-input type="number" name="Rate" placeholder="Rate" value="productItem.Rate"
                [(ngModel)]="productItem.Rate" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="OnRatechange($event)" />
            </nz-form-control>
          </nz-form-item>

        </div>

        <div nz-col [nzSpan]="6">
          <nz-form-item>

            <nz-form-control [nzSpan]="40" nzErrorTip="Enter Amount">
              <label>Amount</label>
              <input nz-input name="Amount" disabled placeholder="Amount" value="productItem.Amount"
                [(ngModel)]="productItem.Amount" [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>

            <nz-form-control [nzSpan]="40" nzErrorTip="Enter IGST">
              <label class="required">IGST/TAX</label>
              <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="productItem.IGST" nzPlaceHolder="Choose"
                [ngModelOptions]="{standalone: true}">
                <nz-option nzValue="0" nzLabel="N/A"></nz-option>
                <nz-option nzValue="5" nzLabel="5%"></nz-option>
                <nz-option nzValue="12" nzLabel="12%"></nz-option>
                <nz-option nzValue="18" nzLabel="18%"></nz-option>
                <nz-option nzValue="28" nzLabel="28%"></nz-option>

              </nz-select>
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="6">
          <nz-form-item>

            <nz-form-control [nzSpan]="40" nzErrorTip="Enter Amount">
              <label>Grade</label>
              <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="productItem.Grade" nzAllowClear
                nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                <nz-option nzValue="NA" nzLabel="NA"></nz-option>
                <!--<nz-option nzValue="0" nzLabel="0"></nz-option>-->
                <nz-option nzValue="1" nzLabel="1"></nz-option>
                <nz-option nzValue="2" nzLabel="2"></nz-option>
                <nz-option nzValue="3" nzLabel="3"></nz-option>
                <nz-option nzValue="4" nzLabel="4"></nz-option>
                <nz-option nzValue="5" nzLabel="5"></nz-option>
                <nz-option nzValue="6" nzLabel="6"></nz-option>
                <nz-option nzValue="7" nzLabel="7"></nz-option>
                <nz-option nzValue="8" nzLabel="8"></nz-option>
                <nz-option nzValue="9" nzLabel="9"></nz-option>
                <nz-option nzValue="A" nzLabel="A"></nz-option>
                <nz-option nzValue="B" nzLabel="B"></nz-option>
                <nz-option nzValue="c" nzLabel="C"></nz-option>
                <nz-option nzValue="D" nzLabel="D"></nz-option>
                <nz-option nzValue="E" nzLabel="E"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

        </div>
      </div>
      <div nz-row [nzGutter]="12" style="align-items: center; justify-content: flex-end;">
        <div nz-col [nzSpan]="2" style="padding: 15px;">
          <a class="btn btn-sm btn-primary" (click)="btnAddClick()">
            <i nz-icon nzType="plus-circle" nzTheme="fill" style="margin-bottom: 3px;"></i>Add
          </a>
        </div>
      </div>
              <!--<div nz-col [nzSpan]="8">
            <nz-form-item>
              <nz-form-label [nzSpan]="12">Invoice Date</nz-form-label>
              <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
                <nz-date-picker class="form-select mb-2" formControlName="InvoiceDate"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="8">
            <nz-form-item>
              <nz-form-label [nzSpan]="12">Invoice Total Price</nz-form-label>
              <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
                <input nz-input formControlName="InvoiceTotalPrice" name="GateInPersonContact" />
              </nz-form-control>
            </nz-form-item>
          </div>-->

      <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzData]="['']" #basicTable1 nzBordered>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Product</th>
            <th>Unit</th>
            <th>Grade</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Currency</th>
            <th>Amount</th>
            <th>IGST</th>
            <th></th>
            <!-- <th nzWidth="20%">Action</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of prolist;let i=index">
            <td>{{i+1}}</td>
            <td>{{ data.ProductName }}</td>
            <td>{{ data.Unit }}</td>
            <td>{{ data.Grade }}</td>
            <td>{{ data.Quantity }}</td>
            <td>{{ data.Rate }}</td>
            <td>{{ data.Currency }}</td>
            <td>{{ data.Amount }}</td>
            <td> <span *ngIf="data.IGST==0">N/A</span><span *ngIf="data.IGST!=0">{{ data.IGST }}</span></td>
            <td>

              <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)">
                <i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;">
                </i>Remove
              </a>
              <a class="btn btn-sm btn-light-warning" style="float:right  " (click)="editRow(data)">
                <i nz-icon nzType="edit" nzTheme="fill" style=" margin-bottom: 3px;">
                </i>Edit
              </a>
            </td>
          </tr>

        </tbody>
        <tfoot *ngIf="this.prolist.length>0">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Total</th>
            <th><input nz-input name="GrandTotal" readonly placeholder="Grand Total" [(ngModel)]="GrandTotal"
                [ngModelOptions]="{standalone: true}" /></th>
            <th></th>
            <!-- <th nzWidth="20%">Action</th> -->
          </tr>
        </tfoot>
      </nz-table>
      <nz-divider></nz-divider>
      <nz-descriptions [nzColumn]="1">
        <nz-descriptions-item nzTitle="Remark" nzColon="true">
          <textarea nz-input name="remark" placeholder="Remark" style="height:100px; width:250px; margin-left: -4px;"
            [(ngModel)]="NewPO.Remarks" [ngModelOptions]="{standalone: true}"></textarea>
        </nz-descriptions-item>

      </nz-descriptions>



      <div class="text-center">
        <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
      </div>

    </form>


  </div>
</div>