<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>PO List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your purchase order here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" *ngIf="this.permission.Add" routerLink="/home/po/add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <nz-collapse>
      <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Date Type</label>
                      <nz-select name="select-error" [(ngModel)]="request.DateType">
                        <nz-option *ngFor="let data of dateTypeList" [nzValue]="data.Value"
                          [nzLabel]="data.Text"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" *ngIf="request.DateType=='addeddate'">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">Added Date From</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.FromAddedDate"
                        nzPlaceHolder="Date"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" *ngIf="request.DateType=='addeddate'">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">Added Date To</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.ToAddedDate"
                        nzPlaceHolder="Date"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" *ngIf="request.DateType=='podate'">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">PO Created From</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.DateFrom"
                        nzPlaceHolder="Date"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" *ngIf="request.DateType=='podate'">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">PO Created To</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.DateTo"
                        nzPlaceHolder="Date"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" *ngIf="request.DateType=='approveddate'">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">Approved Date From</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.FromApprovedDate"
                        nzPlaceHolder="Date"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" *ngIf="request.DateType=='approveddate'">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">Approved Date To</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.ToApprovedDate"
                        nzPlaceHolder="Date"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>PO Number</label>
                      <input nz-input [(ngModel)]="request.PONumber" name="PONumber"
                        placeholder="Enter Full PO Number" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Supplier</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="request.SupplierId" name="SupplierId" nzAllowClear nzPlaceHolder="All">
                        <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                          [nzLabel]="s.SupplierName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Unit</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="request.Unit" name="Unit" nzAllowClear nzPlaceHolder="All">
                        <nz-option *ngFor="let s of this.UnitList;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row [nzGutter]="24">

                
                <!-- <div nz-col [nzSpan]="5">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Action By</label>
                      <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.ActionBy"
                        nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email" [nzLabel]="s.Name"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Product Type</label>
                      <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                        (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
                        <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                        <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                        <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Category</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category"
                        (ngModelChange)="GetAllFirstCategory($event)">
                        <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                          [nzLabel]="s.ProductCategory"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>SubCategory</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category"
                        (ngModelChange)="GetAllSecondCategory($event)">
                        <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                          [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>2nd SubCategory</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                        nzPlaceHolder="Second Category" (ngModelChange)="GetSecondCategoryFilteredProduct()">
                        <nz-option *ngFor="let s of this.ProductSecSubCategoryList;"
                          [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="7">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Product Name</label>
                      <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="request.ProductId"
                        name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear
                        nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                          [nzLabel]="s.ProductName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Purchase Order Type</label>
                      <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.POType"
                        nzPlaceHolder="Choose">
                        <nz-option nzValue="" nzLabel="All"></nz-option>
                        <nz-option nzValue="IMPORT" nzLabel="IMPORT"></nz-option>
                        <nz-option nzValue="DOMESTIC" nzLabel="DOMESTIC"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Delivery Term</label>
                      <nz-select class="form-select" nzShowSearch [(ngModel)]="request.DeliveryTermId"
                        nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.DeliveryTermList;" [nzValue]="s.DeliveryTermId"
                          [nzLabel]="s.DeliveryTerm"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Payment Term</label>
                      <nz-select class="form-select" nzShowSearch [(ngModel)]="request.PaymentTermId"
                        nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.PaymentTermList;" [nzValue]="s.PaymentTermId"
                          [nzLabel]="s.PaymentTerm"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Status</label>
                      <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.Status"
                        nzPlaceHolder="Choose" nzShowSearch nzAllowClear>
                        <nz-option nzValue="New" nzLabel="New"></nz-option>
                        <nz-option nzValue="Revised" nzLabel="Revised"></nz-option>
                        <nz-option nzValue="Active" nzLabel="Active"></nz-option>
                        <nz-option nzValue="Complete" nzLabel="Complete"></nz-option>
                        <nz-option nzValue="Cancelled" nzLabel="Cancelled"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div>
          <div style="float: right; margin-bottom: 2%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" (click)="GetAllPurchaseOrderWithFilters()">
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search Supplier / OrderNo / GRN"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-table nzSize="small" [nzPageSize]="20" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;" #basicTable
      [nzData]="this.PurchaseOrderList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
      nzShowSizeChanger [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate" nzShowQuickJumper="true"
      nzResponsive=true>
      <thead>
        <tr>
          <th nzWidth="60px" nzLeft>S. No.</th>
          <th nzWidth="200px" nzLeft>Supplier Name</th>
          <th nzWidth="150px">PO No</th>
          <th nzWidth="90px">PO Type</th>
          <th nzWidth="200px">Product Name</th>
          <th nzWidth="150px">Status</th>
          <th nzWidth="150px">Delivery Terms</th>
          <th nzWidth="150px">Payment Terms</th>

          <th nzWidth="150px">GRN</th>
          <th nzWidth="150px">PO Date</th>
          <th nzWidth="150px">Added Date</th>
          <th nzWidth="150px">Added By</th>

          <th nzWidth="150px">Approve</th>
          <th nzWidth="150px">Approved Date</th>
          <th nzWidth="150px" style="text-align:center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td nzLeft>{{ data.SerialNo }}</td>
          <td nzLeft>{{ data.SupplierName }}</td>
          <td *ngIf="data.Status == 'New' || data.Status == 'Revised'"></td>
          <td *ngIf="data.Status != 'New' && data.Status != 'Revised'">{{ data.Ponumber }}</td>
          <td>{{ data.POType }}</td>
          <td (click)="OpenViewPop(data)" innerHTML="{{ GetProductName(data)}}"> </td>
          <td [ngStyle]="{'background-color': GetColor(data.Status)}">
            <span>
              <nz-tag [nzColor]="'blue'"><a (click)="OpenTimelinePop(data)">
                  {{data.Status}}
                </a></nz-tag>
            </span>
            <span *ngIf="GetStatus(data.Status)" title={{data.ActionBy?.Email}}> <br>(By User :
              {{data.ActionBy?.Name}} )</span>
          </td>
          <td>{{ data.DeliveryTerm }}</td>
          <td>{{ data.PaymentTerm }}</td>
          <td>{{ data.Grn }}</td>
          <td>{{ data.PocreationDate | date: 'dd-MMM-yyyy'}}</td>
          <td>{{ data.AddedDate | date: 'dd-MMM-yyyy' }}</td>
          <td>{{ data.AddedBy?.Name }}</td>

          <td *ngIf="data.Status == 'New' || data.Status == 'Revised'">
            <button style="margin-top:10px" class="btn btn-sm btn-light-success"
              (click)="showPOActivitiesActionPopup(data, 'Approval')" *ngIf="!isApprovalDisabled(data)">
              Approve</button>
          </td>
          <td *ngIf="data.Status != 'New' && data.Status != 'Revised'"
            [ngStyle]="{'background-color': GetColor(data.Status)}">Approved
            <span *ngIf="data.ApprovedBy" title={{data.ApprovedBy?.Email}}> <br>(By User :
              {{data.ApprovedBy?.Name}} )</span>
          </td>
          <td>{{data.ApprovedDate | DatetimeConverter}}</td>
          <td nzRight style="text-align:center">
            <!--<button class="btn btn-sm btn-light-primary" (click)="print(data)">Print</button>-->
            <div>
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item (click)="OpenViewPop(data)">View</li>
                  <li style="width: 120px;" nz-menu-item (click)="OpenTimelinePop(data)">Timeline</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableEditButton(data)"
                    routerLink="/home/po/edit/{{data.Poid}}">Edit</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'Blocked')"
                    (click)="showPOActivitiesActionPopup(data, 'Blocked')">Block/Hold</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'Unblock')"
                    (click)="showPOActivitiesActionPopup(data, 'Unblock')">Unblock/Resume</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'Complete')"
                    (click)="showPOActivitiesActionPopup(data, 'Complete')">Mark Complete</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'ReOpen')"
                    (click)="showPOActivitiesActionPopup(data, 'ReOpen')">Re-Open</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'InTransit')"
                    (click)="showPOActivitiesActionPopup(data, 'InTransit')">Mark In Transit</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'PartialPayment')"
                    (click)="showPOActivitiesActionPopup(data, 'PartialPayment')">Mark Partial Payment Complete</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnablePOActivitiesButton(data, 'FullPayment')"
                    (click)="showPOActivitiesActionPopup(data, 'FullPayment')">Mark Full Payment Complete</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="data.Status != 'New' && data.Status != 'Approved'"
                    (click)="print(data)">Print</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="EnableEmailButton(data)" (click)="email(data)">Email
                  </li>
                  <li style="width: 120px;" nz-menu-item (click)="showPOActivitiesActionPopup(data, 'Cancelled')"
                    *ngIf="EnableCancelButton(data)">Cancel PO</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="this.permission.Add"
                    routerLink="/home/po/add/{{data.Poid}}">Create new</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>


          <!--<td>
  <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
  <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>
        </td>-->
        </tr>
      </tbody>
    </nz-table>




  </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>


<!--<nz-modal [nzWidth]="1000"
          [nzStyle]="{ top: '20px' }"
          [(nzVisible)]="isVisible"
          [nzTitle]="modalTitle"
          [nzContent]="modalContent"
          [nzFooter]=null
          (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

  <ng-template #modalContent>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">

        <nz-form-item>
          <nz-form-label [nzSpan]="10">Transport Company Name</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Select Company">-->
<!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
<!--<nz-select class="form-select mb-2" nzShowSearch formControlName="TransportId" name="TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllVehicle($event)">
              <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s" [nzLabel]="s.TransportCompanyName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10">Vehicle Number</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">-->
<!-- <input nz-input formControlName = "SupplierContactNumber" name="SupplierContactNumber" /> -->
<!--<nz-select class="form-select mb-2" nzShowSearch formControlName="VehicleId" name="VehicleId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.VehicleList;" [nzValue]="s.VehicleId" [nzLabel]="s.VehicleNumber"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">


        <nz-form-item>
          <nz-form-label [nzSpan]="10">GateIn Person Name</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Contact Person Name required">
            <input nz-input formControlName="GateInPerson" name="GateInPerson" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10">GateIn Person Number</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
            <input nz-input formControlName="GateInPersonContact" name="GateInPersonContact" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24">


        <nz-form-item>
          <nz-form-label [nzSpan]="5">Upload Image</nz-form-label>
          <nz-form-control [nzSpan]="18" nzErrorTip="Contact Person Name required">
            <input class="form-select mb-2" [(ngModel)]="uploadinvoice" [ngModelOptions]="{standalone: true}" type="file" multiple="multiple" (change)="onChange($event)">
            <div *ngIf="filesSelected" hidden="true">
              <h2>Upload Progress</h2>
              <pre>{{uploadProgress$ | async | json}}</pre>
            </div>
          </nz-form-control>
        </nz-form-item>

      </div>
    </div>

    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="10">
        <nz-form-item>

          <nz-form-label [nzSpan]="10">Supplier Name</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select supplier Name">-->
<!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
<!--<nz-select #microwaveRef class="form-select mb-2" nzShowSearch nzSize="default" (ngModelChange)="onSelectedSupplierChange($event)" [(ngModel)]="InvoiceModel.SupplierId" [ngModelOptions]="{standalone: true}" name="SupplierName" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId" [nzLabel]="s.SupplierName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="10">
        <nz-form-item>

          <nz-form-label [nzSpan]="10">Purchase Order</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select supplier Name">-->
<!-- <input nz-input formControlName = "SupplierName" name="SupplierName" /> -->
<!--<nz-select #microwaveRef class="form-select mb-2" nzShowSearch nzSize="default" (ngModelChange)="onSelectedPOChange($event)" [(ngModel)]="InvoiceModel.Poid" [ngModelOptions]="{standalone: true}" name="PO" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.FilteredPurchaseOrderList;" [nzValue]="s.Poid" [nzLabel]="s.Ponumber"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="10">
        <nz-form-item>
          <nz-form-label [nzSpan]="10">Invoice Number</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Enter Invoice number">
            <nz-select nzShowSearch #microwaveRef class="form-select " *ngIf="IsChecked" (ngModelChange)="onSelectedInvoiceChange($event)" nzSize="default" [(ngModel)]="InvoiceModel.InvoiceId" [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.InvoiceList;" [nzValue]="s.InvoiceId" [nzLabel]="s.InvoiceNumber"></nz-option>
            </nz-select>
            <input nz-input name="GateInPersonContact" *ngIf="!IsChecked" [(ngModel)]="InvoiceModel.InvoiceNumber" [ngModelOptions]="{standalone: true}" />
          </nz-form-control>
        </nz-form-item>

      </div>
      <div nz-col [nzSpan]="10">
        <nz-form-item>

          <label nz-checkbox [(ngModel)]="IsChecked" [ngModelOptions]="{standalone: true}">Existing Invoice</label>
        </nz-form-item>

      </div>
      <div nz-col [nzSpan]="4">
        <a class="btn btn-sm btn-light-primary" style="float:right  " (click)="handleAddInvoice()"><i nz-icon nzType="plus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>Add</a>
      </div>-->
<!--<div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label [nzSpan]="12">Invoice Date</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
            <nz-date-picker class="form-select mb-2" formControlName="InvoiceDate"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label [nzSpan]="12">Invoice Total Price</nz-form-label>
          <nz-form-control [nzSpan]="12" nzErrorTip="Enter valid number">
            <input nz-input formControlName="InvoiceTotalPrice" name="GateInPersonContact" />
          </nz-form-control>
        </nz-form-item>
      </div>-->

<!--</div>


    <nz-divider></nz-divider>






  </ng-template>



</nz-modal>-->

<nz-drawer [nzClosable]="true" [nzVisible]="isVisible" nzPlacement="right" [nzWidth]="800"
  nzTitle="Purchase Order details" (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <div style="display: flex; justify-content: space-between;">
      <div *ngIf="this.PurchaseOrder?.Status == 'New' || this.PurchaseOrder?.Status == 'Revised'">
        <button style="margin:15px" class="btn btn-lg btn-light-success"
          (click)="showPOActivitiesActionPopup(this.PurchaseOrder, 'Approval')"
          *ngIf="isApprovalDisabledOnProductView(this.PurchaseOrder)">
          Approve</button>
      </div>
      <div>
        <button style="margin:20px" nz-button nzType="primary" (click)="OpenTimelinePop(this.PurchaseOrder)">
          Show Timeline
        </button>
      </div>
    </div>
    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="PurchaseOrder">
      <nz-descriptions-item nzTitle="Order No" nzColon="true"
        *ngIf="PurchaseOrder.Status == 'Active' || PurchaseOrder.Status == 'Complete'"><b>{{PurchaseOrder.Ponumber}}</b>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Order Date"><b>{{PurchaseOrder.PocreationDate | date:
          'dd-MMM-yyyy'}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Department Name"><b>{{PurchaseOrder.DepartmentName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="PO Type"><b>{{PurchaseOrder.POType}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Supplier Name"
        [nzSpan]="2"><b>{{PurchaseOrder.SupplierName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="GRN"><b>{{PurchaseOrder.Grn}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Reference#"><b>{{PurchaseOrder.Reference}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Delivery Terms"> <b>{{PurchaseOrder.DeliveryTerm}}</b></nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="Shipment Preference"><b>{{PurchaseOrder.TransportCompanyName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Payment Terms"><b>{{PurchaseOrder.PaymentTerm}}</b></nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="Currency"><b>{{PurchaseOrder.PurchaseOrderProduct[0].Currency}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Prepared By"><b>{{PurchaseOrder.AddedBy?.Name}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Remark" [nzSpan]="3"><b>{{PurchaseOrder.Remarks}}</b></nz-descriptions-item>

      <!--<nz-descriptions-item nzTitle="View Invoice">
    <a class="btn btn-sm btn-light-info" *ngIf="this.NewStock.Invoice.InvoiceFile != null" [href]="NewStock.Invoice.InvoiceFile" target="_blank">Link to Invoice</a>
    <span *ngIf="this.NewStock.Invoice.InvoiceFile == null">No invoice file found</span>
  </nz-descriptions-item>-->

    </nz-descriptions>
    <nz-divider></nz-divider>
    <nz-table nzSize="small" *ngIf="PurchaseOrder" [nzData]="['']" #basicTable1 nzBordered style="width:100%">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Product</th>
          <th>Unit</th>
          <th>Grade</th>
          <th>Rate</th>
          <th>Order QTY</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">Received QTY</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">Pending QTY</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">Extra QTY</th>
          <th>Amount</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of PurchaseOrder.PurchaseOrderProduct;let i=index" nzShowPagination="false">
          <td>{{i+1}}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Unit }}</td>
          <td>{{ data.Grade }}</td>
          <td>{{ data.Rate }}</td>
          <td>{{ data.Quantity }}</td>
          <td *ngIf="PurchaseOrder.Status != 'New'">{{ data.RecievedQuantity }}</td>
          <td *ngIf="PurchaseOrder.Status != 'New'">{{ CalculatePendingQTY(data) }}</td>
          <td *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateExtraQTY(data) }}</td>
          <td>{{ data.Amount }}</td>

        </tr>

      </tbody>
      <tfoot *ngIf="this.PurchaseOrder.PurchaseOrderProduct.length>0">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct ,'OrderQuantity') }}</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct
            ,'ReceivedQuantity') }}</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct
            ,'PendingQuantity') }}</th>
          <th *ngIf="PurchaseOrder.Status != 'New'">{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct
            ,'ExtraQuantity') }}</th>
          <th>{{PurchaseOrder.PototalAmount}}</th>
          <!-- <th nzWidth="20%">Action</th> -->
        </tr>
      </tfoot>
    </nz-table>
    <div *ngIf="this.PurchaseOrder?.Status == 'New' || this.PurchaseOrder?.Status == 'Revised'">
      <button style="margin:10px" class="btn btn-lg btn-light-success"
        (click)="showPOActivitiesActionPopup(this.PurchaseOrder, 'Approval')"
        *ngIf="isApprovalDisabledOnProductView(this.PurchaseOrder)">
        Approve</button>
    </div>
  </ng-container>
</nz-drawer>

<nz-modal [(nzVisible)]="IsActivitiesActionPopVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="400"
  [nzTitle]="modalTitleDispatch" [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch"
  (nzOnCancel)="handlePOActivitiesActionCancel()">
  <ng-template #modalTitleDispatch>Purchase Order Action</ng-template>

  <ng-template #modalContentDispatch>
    <label><b>Purchase Order for Supplier Name: {{this.ActionPO.SupplierName}}</b></label>
    <p></p>
    <label style="color: red;" *ngIf="this.ActionPO.Status != 'New' && this.ActionPO.Status != 'Revised'"><b>* Remark is
        required</b></label>
    <label style="color: red;" *ngIf="this.ActionName == 'Blocked'"><b>* Remark is
        required</b></label>
    <label style="color: yellowgreen;"
      *ngIf="(this.ActionPO.Status == 'New' || this.ActionPO.Status == 'Revised') && (this.ActionName != 'Blocked' && this.ActionName != 'Unblock')"><b>*
        Remark is
        optional</b></label>
    <form nz-form [formGroup]="POActivitiesActionForm" nzLayout="vertical">
      <nz-form-item>
        <nz-form-control nzErrorTip="Only 180 characters allowed.">
          <nz-textarea-count [nzMaxCharacterCount]="180">
            <textarea nz-input class="form-control mb-2" formControlName="remark" value="" rows="3"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooterDispatch>
    <button nz-button nzType="primary" (click)="savePOAction()" [nzLoading]="isLoading">Submit</button>
    <button nz-button nzType="default" (click)="handlePOActivitiesActionCancel()"
      [nzLoading]="isLoading">Cancel</button>
  </ng-template>
</nz-modal>
<app-PoTimeline></app-PoTimeline>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.2/jspdf.min.js"></script>