import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { DatetimeConverter } from '../../../Services/DatetimeConverter.pipe';
import { environment } from '../../../../environments/environment';
import { ProductWiseStockWithSupplierReportChildModel, ProductWiseStockWithSupplierReportExportModel, ProductWiseStockWithSupplierReportModel, ProductWiseStockWithSupplierReportParentModel, SearchParamsProductCategoryReportModel } from '../../../Models/ReportModel';
import { AuthService } from '../../../Services/auth.service';
import { LoadingService } from '../../../Services/loadingService';
import * as moment from 'moment';
import { Modules, Responsibility } from '../../../Models/Enums';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, RackModel, StoreModel } from '../../../Models/MasterModel';
import { MeasureUnitModel } from '../../../Models/MeasureUnitModel';
import { ProductModel } from '../../../Models/ProductModel';
import { SupplierModel } from '../../../Models/SupplierModel';
import { UserModel } from '../../../Models/UserModel';
import { BarcodeLabelUpdateService } from '../../../Features/BarcodeLabelManagement/services/BarcodeLabelUpdateService';

@Component({
  selector: 'app-WithSupplierProductWiseStock',
  templateUrl: './WithSupplierProductWiseStock.component.html'
})

export class WithSupplierProductWiseStockReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  searchValue = '';
  isTableLoading: boolean = false;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any;
  dateTypeSelected = ''
  MDate = ["MFG Date", "Expiry Date", "Received Date"];

  ExpiryDate: any
  ManufacturedDate: any
  ExpDate: any;
  ReceivedDate: any;
  data: any
  ReportList: ProductWiseStockWithSupplierReportModel[] = [];
  ReportListOriginal: ProductWiseStockWithSupplierReportModel[] = [];
  FilteredReportList: ProductWiseStockWithSupplierReportParentModel[] = [];
  FilteredReportListOg: ProductWiseStockWithSupplierReportParentModel[] = [];
  exportfields: ProductWiseStockWithSupplierReportExportModel[] = [];
  fields: ProductWiseStockWithSupplierReportExportModel = new ProductWiseStockWithSupplierReportExportModel();
  exportoptions = {
    headers: [
      "Sr.No",
      "Product Type",
      "Product Category",
      "Product First Category",
      "Product Second Category",
      "Product Name",
      "Product Code",
      "Total Qty",
      "Minimum Quantity",
      "Recieved QTY",
      "Batch",
      "Product Quality",
      "Store Name",
      "Rack Name",
      "Supplier Name",
      "Available QTY",
      "Price",
      "Total Amount",
      "MFG Date",
      "Expiry Date",
      "ReceivedDate",
      "ReceivedBy",
    ]
  };
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  };
  request: SearchParamsProductCategoryReportModel = new SearchParamsProductCategoryReportModel();
  FilteredProductCategoryList: ProductCategoryModel[];
  FilteredProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  FilteredProductSecSubCategoryList: ProductSecSubCategoryModel[];
  ProductCategoryList: ProductCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[];
  MeasureUnits: MeasureUnitModel[];
  ProductList: ProductModel[];
  SupplierList: SupplierModel[];
  RackList: RackModel[];
  StoreID: number = 0;
  RackID: number = 0;
  StoreList: StoreModel[];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  SelectedProductType: string = '';
  SelectedProductId: number = 0;
  SelectedProduct: ProductModel;
  SelectedUnit: string = '';
  FilteredProductList: ProductModel[];
  UserList: UserModel[];
  IsMsgShow: boolean = false;
  filterPanelTxt: string = 'Show Filters'
  constructor(private authService: AuthService,
    private alertService: AlertMessageService, private auth: AuthService, private router: Router, public http: HttpClient, private loader: LoadingService,
    private barcodeLabelUpdateService: BarcodeLabelUpdateService) {
  }
  GetReport() {
    this.IsMsgShow = false;
    this.FilteredReportList = [];
    this.ReportList = [];
    this.request.ProductType = this.SelectedProductType ?? '';
    this.request.ProductCategoryId = this.CategoryID ?? 0;
    this.request.ProductFirstSubCategoryId = this.FirstCategoryID ?? 0;
    this.request.ProductSecSubCategoryId = this.SecondCategoryID ?? 0;
    this.request.ProductId = this.SelectedProductId ?? 0;
    this.request.Unit = this.SelectedUnit ?? '';
    this.request.StoreId = this.StoreID ?? 0;
    this.request.RackId = this.RackID ?? 0;
    if (this.request.IncludeWIPStore == "null") {
      this.request.IncludeWIPStore = null;
    }
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + "stock/getproductwisestockwithsupplier";
    this.http.post<ProductWiseStockWithSupplierReportModel[]>(url, this.request).subscribe(res => {
      console.log(res);
      if (this.request.IncludeWIPStore == null) {
        this.request.IncludeWIPStore = "null";
      }
      this.isTableLoading = false;
      this.ReportList = res;
      this.ReportListOriginal = res;
      this.exportfields = [];
      if (this.ReportList.length <= 0) {
        this.IsMsgShow = true;
        this.loader.hide();
        return;
      }
      console.log('before export', this.ReportList)
      // this.ReportList.filter(x => x.ReceivedBy != null).forEach(x => {
      //   this.fields = new ProductWiseStockWithSupplierReportExportModel();
      //   this.fields.ProductType = x.ProductType;
      //   this.fields.ProductCategory = x.ProductCategory;
      //   this.fields.ProductFirstSubCategory = x.ProductFirstSubCategory ?? "";
      //   this.fields.ProductSecSubCategory = x.ProductSecSubCategory ?? "";
      //   this.fields.ProductName = x.ProductName;
      //   this.fields.ProductCode = x.ProductCode;
      //   this.fields.Batch = x.Batch;
      //   this.fields.ProductQuality = x.ProductQuality == null ? '' : x.ProductQuality;
      //   this.fields.StoreName = x.StoreName;
      //   this.fields.RackName = x.RackName;
      //   this.fields.SupplierName = x.SupplierName
      //   this.fields.Quantity = parseFloat(x.Quantity).toFixed(3)
      //   this.fields.MinimumQuantity = x.MinimumQuantity;
      //   this.fields.RecievedQuantity = parseFloat(x.RecievedQuantity).toFixed(3)
      //   this.fields.Barcode = x.IsBarcodeLabelExist ? 'Present' : 'No Barcode';
      //   this.fields.ManufacturedDate = new DatetimeConverter().transform(x.ManufacturedDate) ?? "";
      //   this.fields.ExpiryDate = new DatetimeConverter().transform(x.ExpiryDate) ?? "";
      //   this.fields.PricePerUnit = parseFloat(x.PricePerUnit).toFixed(3);
      //   this.fields.TotalAmount = (parseFloat(x.Quantity) * parseFloat(x.PricePerUnit)).toFixed(3);
      //   this.fields.Unit = x.Unit;
      //   this.fields.ReceivedDate = new DatetimeConverter().transform(x.ReceivedDate) ?? "";
      //   this.fields.ReceivedBy = x.ReceivedBy;
      //   this.exportfields.push(this.fields);
      // })

      let uniqueProduct = [...new Set(res.map((item) => item.ProductId))];

      console.log(uniqueProduct);
      var fp = this.ReportList.filter((x) =>
        uniqueProduct.includes(x.ProductId)
      );
      debugger
      console.log(fp.length);
      uniqueProduct.forEach((x1) => {
        var it = new ProductWiseStockWithSupplierReportParentModel();
        var x = this.ReportList.filter(z => z.ProductId == x1)[0];
        it.ProductType = x.ProductType;
        it.ProductCode = x.ProductCode;
        it.ProductName = x.ProductName;
        it.ProductCategory = x.ProductCategory;
        it.ProductFirstCategory = x.ProductFirstSubCategory;
        it.ProductSecondCategory = x.ProductSecSubCategory;
        it.Unit = x.Unit;
        it.MinimumQuantity = x.MinimumQuantity;
        it.RecievedQuantity = parseInt(parseFloat(x.RecievedQuantity).toFixed(3));
        it.TotalQty = parseFloat(res
          .filter((y) => y.ProductId == x.ProductId)
          .reduce(
            (sum, current) => sum + parseFloat(current.Quantity?.toString()),
            0
          ).toFixed(2)) ?? 0;
        res
          .filter((y) => y.ProductId == x.ProductId)
          .forEach((iit) => {
            var st = new ProductWiseStockWithSupplierReportChildModel();


            st.SupplierName = iit.SupplierName;
            st.RecievedQuantity = iit.RecievedQuantity;
            st.Quantity = iit.Quantity;
            st.Barcode = iit.Barcode;
            st.StockProductId = iit.StockProductId;
            st.IsBarcodeLabelExist = iit.IsBarcodeLabelExist;
            st.ManufacturedDate = iit.ManufacturedDate;
            st.PricePerUnit = iit.PricePerUnit;
            st.TotalAmount = (parseFloat(iit.Quantity) * parseFloat(iit.PricePerUnit)).toFixed(3);
            st.ExpiryDate = iit.ExpiryDate;
            st.Batch = iit.Batch;
            st.ProductQuality = iit.ProductQuality;
            st.StoreName = iit.StoreName;
            st.RackName = iit.RackName;
            st.RackId = iit.RackId;
            st.ReceivedDate = iit.ReceivedDate;
            st.ReceivedBy = iit.ReceivedBy;
            it.ChildList.push(st);
          });
        this.FilteredReportListOg = this.FilteredReportList

        this.FilteredReportList.push(it);
      });
      this.structureExportFields();
      this.loader.hide();
      //console.log(this.FilteredReportList);
    }, res => {
      this.loader.hide();
      this.count++;
      if (this.count < 2) { this.GetReport(); }
    });
  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsCategoryWiseStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsCategoryWiseStock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsCategoryWiseStock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.dateTypeSelected = this.MDate[2];
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllUnits();
      this.GetAllProducts();
      this.GetAllProductCategory();
      this.GetAllStore();
      this.GetAllSupplier();
      this.GetAllUsers();
      this.filterPanelTxt = 'Hide Filters';
    }
    else {
      this.filterPanelTxt = 'Show Filters';
    }
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetReport()
    this.myDateValue = undefined;
    this.toDate = undefined;
    this.dateTypeSelected = this.MDate[2];
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'stock-report-export' + exportdate,
        this.exportoptions
      );
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }




  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  search() {

    var res = this.FilteredReportListOg;
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ProductWiseStockWithSupplierReportParentModel) => {
        if (this.dateTypeSelected == "Received Date") {
          return this.reverseAndTimeStamp(moment(m.ChildList[0].ReceivedDate).format('DD-MM-YYYY')) >= this.reverseAndTimeStamp(fromdate) && this.reverseAndTimeStamp(moment(m.ChildList[0].ReceivedDate).format('DD-MM-YYYY')) <= this.reverseAndTimeStamp(todate)

        }

        else if (this.dateTypeSelected == "Expiry Date") {
          return this.reverseAndTimeStamp(moment(m.ChildList[0].ExpiryDate).format('DD-MM-YYYY')) >= this.reverseAndTimeStamp(fromdate) && this.reverseAndTimeStamp(moment(m.ChildList[0].ExpiryDate).format('DD-MM-YYYY')) <= this.reverseAndTimeStamp(todate)

        }
        else if (this.dateTypeSelected == "MFG Date") {
          return this.reverseAndTimeStamp(moment(m.ChildList[0].ManufacturedDate).format('DD-MM-YYYY')) >= this.reverseAndTimeStamp(fromdate) && this.reverseAndTimeStamp(moment(m.ChildList[0].ManufacturedDate).format('DD-MM-YYYY')) <= this.reverseAndTimeStamp(todate)

        }
        else {
          return false
        }
      });

      this.FilteredReportList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.FilteredReportList = res.filter((item: ProductWiseStockWithSupplierReportParentModel) => {


        if (
          item?.ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {

          return true;
        }
        else if (
          item?.ProductType?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }

        else if (
          item?.ProductCode?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.ChildList[0].Batch?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.ChildList[0].StoreName?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.ChildList[0].RackName?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.ChildList[0].Barcode?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.ChildList[0]?.ReceivedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item?.ChildList[0].SupplierName?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item.ChildList[0].RecievedQuantity == this.searchValue
        ) {
          return true;
        }
        else if (
          item.ChildList[0].Quantity == this.searchValue
        ) {
          return true;
        }
        else if (
          item.ChildList[0].PricePerUnit == this.searchValue
        ) {
          return true;
        }
        else if (
          item?.Unit?.toLowerCase().includes(this.searchValue?.toLowerCase())
        ) {
          return true;
        }
        else if (
          item.TotalAmount == this.searchValue
        ) {
          return true;
        }
        else if (
          item.TotalQty == +this.searchValue
        ) {
          return true;
        }



        return false;
      });




    }

  }
  GetAllUnits() {
    this.loader.show();
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllUnits()
      }
    });
  }
  GetAllProducts() {
    this.loader.show();
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) { this.GetAllProducts() }
    });
  }
  UpdateProduct(data: ProductModel) {
    if (data != null) {
      this.SelectedUnit = data.Unit;
      this.SelectedProduct.ProductName = data.ProductName;
      this.SelectedProduct.ProductId = data.ProductId;
      this.SelectedProductId = data.ProductId;
      this.SelectedProduct.ProductCategoryId = data.ProductCategoryId;
      this.CategoryID = data.ProductCategoryId;
      let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
      this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
        this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data.ProductCategoryId);
        this.SelectedProduct.ProductFirstSubCategoryId = data.ProductFirstSubCategoryId;
        this.FirstCategoryID = data.ProductFirstSubCategoryId;
        let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
          this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data.ProductFirstSubCategoryId);
          this.SelectedProduct.ProductSecSubCategoryId = data.ProductSecSubCategoryId;
          this.SecondCategoryID = data.ProductSecSubCategoryId;
        }, res => { });
      }, res => { });
      this.GetAllFirstCategory(data.ProductCategoryId, data.ProductFirstSubCategoryId);
      this.GetAllSecondCategory(data.ProductFirstSubCategoryId, data.ProductSecSubCategoryId);
    } else {
      this.FilteredProductList = this.ProductList;
    }
  }

  GetAllProductCategory() {
    this.loader.show();
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllProductCategory()
      }
    });
  }
  onSelectedProductTypeChange() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
    this.FilteredProductCategoryList = this.ProductCategoryList.filter(x => x.ProductType == this.SelectedProductType);
    this.loader.hide();
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductCategoryId == this.CategoryID);

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductFirstSubCategoryId == this.FirstCategoryID);

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetSecondCategoryFilteredProduct() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductSecSubCategoryId == this.SecondCategoryID);
    this.loader.hide();
  }
  GetAllSupplier() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++;
      if (this.count < 2) {
        this.GetAllSupplier()
      }
    });
  }
  onSelectedStoreChange() {
    this.loader.show();
    let url = this.ApiUrl + 'rack/getallracks';
    this.http.get<RackModel[]>(url).subscribe(
      (res) => {
        this.RackList = res.filter((x) => x.StoreId == this.StoreID);
        this.RackID = 0;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.onSelectedStoreChange();
        }
      }
    );
  }

  GetAllStore() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
        this.isTableLoading = false;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  GetConditionalColorForLowStock(data: ProductWiseStockWithSupplierReportParentModel) {
    if (data.TotalQty < data.MinimumQuantity) {
      return 'red'
    }
    else
      return ''
  }
  openScannedBarcodeList(StockProductId: number, RackId: number) {
    console.log(StockProductId, RackId);
    this.barcodeLabelUpdateService.openModal(null, 'ShowStoreBarcodeList', RackId, StockProductId);
  }
  structureExportFields() {
    let resultArr = [];

    for (let j = 0; j < this.FilteredReportList.length; j++) {
      if (this.FilteredReportList.length > 0) {
        for (let i = 0; i < this.FilteredReportList[j].ChildList.length; i++) {
          if (i == 0) {
            // First row of each product includes all parent details
            const obj = {
              SNO: j + 1,
              ProductType: this.FilteredReportList[j].ProductType,
              ProductCategory: this.FilteredReportList[j].ProductCategory ?? "",
              ProductFirstCategory: this.FilteredReportList[j].ProductFirstCategory ?? "",
              ProductSecondCategory: this.FilteredReportList[j].ProductSecondCategory ?? "",
              ProductName: this.FilteredReportList[j].ProductName,
              ProductCode: this.FilteredReportList[j].ProductCode,
              TotalQty: this.FilteredReportList[j].TotalQty,
              MinimumQuantity: this.FilteredReportList[j].MinimumQuantity,
              RecievedQuantity: this.FilteredReportList[j].ChildList[i].RecievedQuantity ?? "",
              Batch: this.FilteredReportList[j].ChildList[i].Batch,
              ProductQuality: this.FilteredReportList[j].ChildList[i].ProductQuality ?? "",
              StoreName: this.FilteredReportList[j].ChildList[i].StoreName,
              RackName: this.FilteredReportList[j].ChildList[i].RackName,
              SupplierName: this.FilteredReportList[j].ChildList[i].SupplierName,
              Quantity: this.FilteredReportList[j].ChildList[i].Quantity,
              PricePerUnit: this.FilteredReportList[j].ChildList[i].PricePerUnit,
              TotalAmount: this.FilteredReportList[j].ChildList[i].TotalAmount,
              ManufacturedDate: new DatetimeConverter().transform(this.FilteredReportList[j].ChildList[i].ManufacturedDate),
              ExpiryDate: new DatetimeConverter().transform(this.FilteredReportList[j].ChildList[i].ExpiryDate),
              ReceivedDate: new DatetimeConverter().transform(this.FilteredReportList[j].ChildList[i].ReceivedDate),
              ReceivedBy: this.FilteredReportList[j].ChildList[i].ReceivedBy,
            };
            resultArr.push(obj);
          } else {
            // Subsequent rows only include child details
            let obj = {
              SNO: '',
              ProductType: '',
              ProductCategory: '',
              ProductFirstCategory: '',
              ProductSecondCategory: '',
              ProductName: '',
              ProductCode: '',
              TotalQty: '',
              MinimumQuantity: '',
              RecievedQuantity: '',
              Batch: this.FilteredReportList[j].ChildList[i].Batch,
              ProductQuality: this.FilteredReportList[j].ChildList[i].ProductQuality ?? "",
              StoreName: this.FilteredReportList[j].ChildList[i].StoreName,
              RackName: this.FilteredReportList[j].ChildList[i].RackName,
              SupplierName: this.FilteredReportList[j].ChildList[i].SupplierName,
              Quantity: this.FilteredReportList[j].ChildList[i].Quantity,
              PricePerUnit: this.FilteredReportList[j].ChildList[i].PricePerUnit,
              TotalAmount: this.FilteredReportList[j].ChildList[i].TotalAmount,
              ManufacturedDate: new DatetimeConverter().transform(this.FilteredReportList[j].ChildList[i].ManufacturedDate),
              ExpiryDate: new DatetimeConverter().transform(this.FilteredReportList[j].ChildList[i].ExpiryDate),
              ReceivedDate: new DatetimeConverter().transform(this.FilteredReportList[j].ChildList[i].ReceivedDate),
              ReceivedBy: this.FilteredReportList[j].ChildList[i].ReceivedBy,
            };
            resultArr.push(obj);
          }
        }
      }
    }

    this.exportfields = resultArr;
  }
}
