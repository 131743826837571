import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageBlobSasTokenModel } from '../Models/UploadModel';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  getToken: any;
  ApiUrl = environment.Api_Url;

  constructor(private http: HttpClient) { }

  getStorageSASToken(ContainerName: string): any {
    let url = this.ApiUrl + "storage/getstoragesastoken/" + ContainerName;
    this.getToken = this.http.get(url)
    return this.getToken
  }
  GetFileByFileUploadId(FileUploadId: number): Observable<StorageBlobSasTokenModel> {
    let url = this.ApiUrl + 'storageblob/getstorageblobsastokenbyuploadid/' + FileUploadId;
    return this.http.get<StorageBlobSasTokenModel>(url)
  }
}