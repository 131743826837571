import { HttpClient } from "@angular/common/http";
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { DatetimeConverter } from "../../Services/DatetimeConverter.pipe";
import { LoadingService } from "../../Services/loadingService";
import { PoTimelineService } from "../../Services/PoTimeLineService";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-PoTimeline',
    templateUrl: './PoTimeline.component.html'
})

export class PoTimelineComponent {
    ApiUrl = environment.Api_Url;
    IsPopupOpen = this.loader.loading$;

    IsDisabled: boolean = false;
    isTableLoading: boolean = false;
    count: 0;
    permission = {
        View: false,
        Add: false,
        Delete: false,
        Manage: false
    }
    TimeLineObject: any;
    ActivityLogList: any;
    isTimelineVisible = false;
    refreshBtnLoading: boolean;
    descriptionActivity: any;
    @ViewChild('descriptionTemplate') descriptionTemplate: TemplateRef<any>;
    constructor(
        private loader: PoTimelineService,
        public http: HttpClient,
        private datePipe: DatetimeConverter,
        private _loadingService: LoadingService,
        private sanitizer: DomSanitizer
    ) {
        this.loader.event_callback.subscribe(x => {
            this.GetAllActivityLog(x);
        })
    }
    ngOnInit() {
        console.log(this.TimeLineObject)

    }

    // OpenTimelinePop(data: any): void {

    //     this.TimeLineObject = data;
    //     this.GetAllActivityLog(data.Poid);
    // }
    handleTimelineCancel(): void {
        this.isTimelineVisible = false;
    }
    GetAllActivityLog(Poid: string) {
        this.isTableLoading = true;
        let url = this.ApiUrl + "PurchaseOrder/GetPurchaseOrderTimelineById/" + Poid;
        this.http.get<any>(url).subscribe(res => {
            this.ActivityLogList = res;
            this.isTimelineVisible = true;
            this.isTableLoading = false;
        }, res => {
            this.GetAllActivityLog(Poid);
        });
    }
    GetTimelineRefresh(Poid: string) {
        this.loader.show();
        this.refreshBtnLoading = true;
        let url = this.ApiUrl + "PurchaseOrder/GetPurchaseOrderTimelineById/" + Poid;
        this.http.get<any>(url).subscribe(res => {
            this.ActivityLogList = res;
            this.refreshBtnLoading = false;
            this.loader.hide();
        }, res => { });
    }
    GetTimeLineStatus(status: string) {
        if (status === '' || status === undefined) {
            return 'hide';
        } else {
            return 'finish';
        }
    }
    GetTimeLineSubtitle(activity: any) {
        return "On: " + this.datePipe.transform(activity.AddedDate);
    }
    getDescriptionTemplate(activity: any): TemplateRef<any> {
        this.descriptionActivity = activity;
        return this.descriptionTemplate;
    }
}
