<app-SoDrawer></app-SoDrawer>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Activity Log</nz-page-header-title>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div nz-row [nzGutter]="24" style="margin: 0px 0px">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="24">


          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>From</label>
                <nz-date-picker [(ngModel)]="request.DateFrom" nzPlaceHolder="Date"
                  style="width: 150px;margin-right: 8px;"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>To</label>
                <nz-date-picker [(ngModel)]="request.DateTo" nzPlaceHolder="Date"
                  style="width: 150px;margin-right: 8px;"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Sale Order Number</label>
                <input nz-input [(ngModel)]="request.SaleOrderNumber" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Status</label>
                <nz-select #microwaveRef nzShowSearch nzSize="default" [(ngModel)]="request.Status" name="Category"
                  nzAllowClear>
                  <nz-option *ngFor="let s of this.statusList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <label>Added By</label>
                <input nz-input [(ngModel)]="request.Addedby" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="2">
            <button nz-button nzType="primary" style="margin-top: 22px" (click)="GetAllActivityLog()">
              Search
            </button>

          </div>
        </div>
      </div>
    </div>
    <div style="padding: 8px;float:right">
      <!--<button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline" style="top: 2px;right: 48px;position: absolute;"></i> Export</button>-->
      <!--<button nz-button *ngIf="this.isEmailReportEnabled" [nzLoading]="isEmailButtonLoading" nzSize="small" (click)="sendEmail()"><i nz-icon nzType="mail" nzTheme="outline" style="top: 2px;right: 79px;position: absolute;"></i> Send Email</button>-->
      <button nz-button nzSize="small" printSectionId="print-section" ngxPrint [useExistingCss]="true"
        style="margin-left:5px"><i style="top: 2px;right: 37px;position: absolute;" nz-icon nzType="printer"
          nzTheme="outline"></i>Print</button>
    </div>
    <nz-divider></nz-divider>
    <div style="max-width:100%; overflow:scroll">
      <nz-table id="print-section" nzSize="small" style="width: 100%" #basicTable [nzShowPagination]="false"
        [nzPageSize]="1000" [nzData]="this.ActivityLogList" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr style="text-align: left;">

            <th>S. No.</th>
            <th>Sale Order No.</th>
            <th>Status</th>
            <th>Action by</th>
            <th>Action Date</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <td>{{ i + 1 }}</td>
            <td><nz-tag [nzColor]="'blue'"><a (click)="OpenViewPop(data)"> {{ data.SaleOrderNumber}}
                </a></nz-tag></td>
            <td>{{ data.Status }}</td>
            <td>{{ data.AddedBy }}</td>
            <td>{{ data.AddedDate | DatetimeConverter}}</td>
          </tr>

        </tbody>

      </nz-table>

    </div>
  </div>
</div>
