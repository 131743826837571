<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Production Planning Report</nz-page-header-title>
            <nz-page-header-subtitle>Manage your production planning here</nz-page-header-subtitle>
        </nz-page-header>
        <nz-divider></nz-divider>
        <nz-collapse>
            <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Product Type</label>
                                    <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Customer</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default"
                                        nzAllowClear [(ngModel)]="request.CustomerId" nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                                            [nzLabel]="s.CustomerName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Formulation/Product Code</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode"
                                        nzSize="default" nzAllowClear [(ngModel)]="request.SaleFormulationCodeId"
                                        nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.FormulationCodeList;"
                                            [nzValue]="s.SaleFormulationCodeId"
                                            [nzLabel]="s.SaleFormulationCode"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Color</label>
                                    <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="request.ColorId"
                                        nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                                            [nzLabel]="s.ColorName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Grain Name</label>
                                    <nz-select class="form-select mb-2" [(ngModel)]="request.GrainId" nzSize="default"
                                        nzShowSearch nzAllowClear nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                                            [nzLabel]="s.GrainName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Thickness</label>
                                    <nz-select class="form-select mb-2" nzSize="default"
                                        [(ngModel)]="request.ThicknessId" nzAllowClear nzShowSearch
                                        nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                                            [nzLabel]="s.ThicknessNumber"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="ArticleName">
                                    <label>Article Name</label>
                                    <input nz-input nzAllowClear placeholder="Enter Name" maxlength="30"
                                        [(ngModel)]="request.ArticleName" (keydown)="space($event)" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label>Sale Order No.</label>
                                    <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full No."
                                        maxlength="30" [(ngModel)]="request.SaleOrderNumber"
                                        (keydown)="space($event)" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                        <div style="display: flex">
                            <button nz-button nzSize="small" nzType="primary" (click)="ProductionPlanningReport()">
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Date Filter</label>
                                <nz-select [(ngModel)]="selecteddateFilter"
                                    (ngModelChange)="getDateRange(selecteddateFilter)" nzPlaceHolder="Choose"
                                    [ngModelOptions]="{standalone: true}">
                                    <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5" *ngIf="this.enableCustomDateRange">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label style="display:block">From Date</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.WorkPlanFromDate"
                                    nzPlaceHolder="Date" nzShowTime></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="5" *ngIf="this.enableCustomDateRange">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>To Date</label>
                                <nz-date-picker class="form-select " [(ngModel)]="request.WorkPlanToDate"
                                    nzPlaceHolder="Date" nzShowTime></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4" *ngIf="enableWorkShiftDropDown">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Work Shift</label>
                                <nz-select [(ngModel)]="request.WorkShift" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let data of WorkShiftList" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Production Line No.</label>
                                <nz-select [(ngModel)]="request.ProductionLineNo" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let data of ProductionLineList" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="4">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <label>Product Type</label>
                                <nz-select [(ngModel)]="request.ProductType" nzAllowClear nzPlaceHolder="Choose">
                                    <nz-option *ngFor="let data of ProductTypeList" [nzValue]="data.Value"
                                        [nzLabel]="data.Text"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="2">
                        <button nz-button nzType="primary" style="margin-top: 22px"
                            (click)="ProductionPlanningReport()">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 8px;float:right">
            <nz-button-group style="margin-left:5px">
                <button nz-button nzSize="small" (click)="setAutoRefreshAction()">{{this.autoRefreshBtnText}}</button>
                <button nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight" nzSize="small">
                    <span nz-icon nzType="ellipsis"></span>
                </button>
            </nz-button-group>
            <nz-dropdown-menu #menu1="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item (click)="setAutoRefreshTime(30)">Refresh Every 30 Seconds</li>
                    <li nz-menu-item (click)="setAutoRefreshTime(60)">Refresh Every 60 Seconds</li>
                    <li nz-menu-item (click)="setAutoRefreshTime(300)">Refresh Every 5 Minute</li>
                    <li nz-menu-item (click)="setAutoRefreshTime(900)">Refresh Every 15 Minute</li>
                </ul>
            </nz-dropdown-menu>
            <button nz-button nzSize="small" (click)="export()" style="margin-left:5px"><i nz-icon nzType="export"
                    nzTheme="outline" style="top: 2px;right: 49px;position: absolute;"></i> Export</button>
            <button nz-button *ngIf="this.isEmailReportEnabled" [nzLoading]="isEmailButtonLoading" nzSize="small"
                (click)="sendEmail()" style="margin-left:5px"><i nz-icon nzType="mail" nzTheme="outline"
                    style="top: 2px;right: 79px;position: absolute;"></i> Send Email</button>
            <button nz-button nzSize="small" printSectionId="print-section" ngxPrint
                printTitle="Production Planning Report - From: {{request.WorkPlanFromDate | date:'dd-MM-yyyy HH:mm:ss a'}} To: {{request.WorkPlanToDate | date:'dd-MM-yyyy HH:mm:ss a'}}"
                [useExistingCss]="true" [printStyle]="{ '@page': { size: 'landscape !important' } }"
                style="margin-left:5px"><i style="top: 2px;right: 39px;position: absolute;" nz-icon nzType="printer"
                    nzTheme="outline"></i>Print</button>
        </div>
        <nz-divider></nz-divider>

        <div style="max-width:100%; overflow:scroll;border-color: black;">
            <nz-table id="print-section" nzSize="small" nzShowPagination="false"
                style="width: 100%;page-break-inside: auto;" [nzData]="this.DemandList" [nzLoading]="isTableLoading"
                nzBordered>
                <thead>

                    <tr
                        style="text-align: left;font-size:x-small;font-weight: bolder;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                        <th nzLeft nzWidth="20px">S.No.</th>
                        <th nzLeft nzWidth="80px" rowspan="2">Order Date</th>
                        <th nzLeft nzWidth="80px" rowspan="2">Order No.</th>
                        <th nzWidth="90px">WorkPlan No.</th>
                        <th nzWidth="80px">Item Name</th>
                        <th nzWidth="70px">P.Code</th>
                        <th nzWidth="70px">Grain Name</th>
                        <th nzWidth="80px">Colors</th>
                        <th nzWidth="60px">Width</th>
                        <th nzWidth="80px">Fabric</th>
                        <th nzWidth="60px">Thick</th>
                        <th nzWidth="80px">Finish</th>
                        <th nzWidth="70px">Pending QTY (MTR)</th>
                        <!-- <th >Rate</th> -->
                        <th>Pre Skin (GSM)</th>
                        <th>Skin (GSM)</th>
                        <th>Foam (GSM)</th>
                        <th>Adhesive (GSM)</th>
                        <th>Fabric (GSM)</th>
                        <th>Final (GSM)</th>
                        <th nzWidth="100px">Party name</th>
                        <th nzWidth="100px">Remarks</th>

                    </tr>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of this.DemandList; let i = index"
                        style="text-align: left;font-size: x-small;font-weight: bolder; border-color: black;page-break-inside:avoid; page-break-after:auto">
                        <td style="border-color: black;" nzLeft>{{ i + 1 }}</td>
                        <td style="border-color: black;" nzLeft>{{ data.OrderDate }}</td>
                        <td style="border-color: black;" nzLeft
                            [ngStyle]="{'background-color': SetArticleNameCellForInProgressPRD(data)}">{{
                            data.SaleOrderNo }}</td>
                        <td style="border-color: black;" nzLeft>{{ data.WorkPlanNo }}</td>
                        <td style="border-color: black;" nzBreakWord
                            [ngStyle]="{'background-color': SetArticleNameCellForInProgressPRD(data)}">{{ data.ItemName
                            }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.PCode }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Grain }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Colours}}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Width }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Fabric }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Thick }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Finish }}</td>
                        <td style="border-color: black;">{{ data.PendingQty }}</td>
                        <!-- <td style="border-color: black;">{{ data.Rate }}</td> -->
                        <td style="border-color: black;">{{ data.PreSkin }}</td>
                        <td style="border-color: black;">{{ data.Skin }}</td>
                        <td style="border-color: black;">{{ data.Foam }}</td>
                        <td style="border-color: black;">{{ data.Adhesive }}</td>
                        <td style="border-color: black;">{{ data.FabricGsm }}</td>
                        <td style="border-color: black;">{{ data.PreSkin + data.Skin + data.Foam + data.Adhesive +
                            data.FabricGsm }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.PartyName }}</td>
                        <td style="border-color: black;" nzBreakWord>{{ data.Remarks }}</td>
                    </tr>
                    <tr style="page-break-inside:avoid; page-break-after:auto;font-weight: bolder;">
                        <td colspan="12" style="text-align:right">Total</td>
                        <td>{{CalculateTotal('SaleOrderQuantity')}}</td>
                        <td colspan="8"></td>
                    </tr>
                </tbody>
            </nz-table>
        </div>

    </div>
</div>