import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";
import { PurchaseOrderModel } from "../../Models/PurchaseOrderModel";
import { SaleOrderModel } from "../../Models/SalesOrderModel";
import { EstimationCodeFabricsModel, EstimationCodeMixingModel, EstimationCodeMixingRawMaterialModel } from "../../Models/CostingModel";
import { EODrawerService } from "../../Services/EODrawerService";

@Component({
  selector: 'app-EODrawer',
  templateUrl: './EODrawer.component.html'
})

export class EODrawerComponent {
  ApiUrl = environment.Api_Url;
  IsPopupOpen = this.loader.loading$;
  IsDisabled: boolean = false;
  isTableLoading: boolean = false;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false
  }
  Poid: any;
  PurchaseOrder: PurchaseOrderModel | undefined;
  EOList: any;
  MixingSelectedList: EstimationCodeMixingModel[] = [];
  isPurchaseOrderLoaded = false;
  refreshBtnLoading: boolean;
  IsTimelineOpen = false;
  postProcessArray: any = [];
  listOfSelectedmixing: number[] = [];
  Orderlist!: SaleOrderModel;
  isVisible = false;
  FabricName: string;
  Coating: number = 0;
  PreSkinGsm: number = 0;
  AdhesiveGsm: number = 0;
  SkinGsm: number = 0;
  FoamGsm: number = 0;
  FabricGsm: number = 0;
  TotalGsm: number = 0;
  FabricColorName: string;
  TotalCostPerLm: number = 0;
  GrandTotalFabricPrice: number = 0;
  TotalFabricGSM: number = 0;
  TotalFabricQuantity: number = 0;
  TotalFabricPrice: number = 0;
  TotalQuantity: number = 0;
  TotalPrice: number = 0;
  PerLMConstant: number = 0;
  constructor(
    private loader: EODrawerService,
    public http: HttpClient
  ) {
    this.loader.event_callback.subscribe(x => {
      this.showDrawerData(x);
    })
  }
  ngOnInit() {
    console.log(this.PurchaseOrder)
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  showDrawerData(EstimationOrderId: string) {
    this.isTableLoading = true;
    let url =
      this.ApiUrl + 'estimationorder/getestimationorderbyid/' + EstimationOrderId;
    this.postProcessArray = [];
    this.http.get<any>(url).subscribe((data) => {
      this.EOList = data;
      if (data.EstimationMixingTable.length > 0) {
        this.AdhesiveGsm = data.EstimationMixingTable[0].AdhesiveGsm || 0;
        this.PreSkinGsm = data.EstimationMixingTable[0].PreSkinGsm || 0;
        this.SkinGsm = data.EstimationMixingTable[0].SkinGsm || 0;
        this.FoamGsm = data.EstimationMixingTable[0].FoamGsm || 0;
        this.FabricGsm = data.EstimationMixingTable[0].FabricGsm || 0;
      } else {
        this.AdhesiveGsm = 0;
        this.PreSkinGsm = 0;
        this.SkinGsm = 0;
        this.FoamGsm = 0;
        this.FabricGsm = 0;
      }
      this.MixingSelectedList = data.EstimationMixingTable.map((mixing: any) => {
        const newMixing: EstimationCodeMixingModel = {
          MixingId: mixing.MixingId,
          MixingName: mixing.MixingName,
          AdhesiveGsm: this.AdhesiveGsm,
          PreSkinGsm: this.PreSkinGsm,
          SkinGsm: this.SkinGsm,
          FoamGsm: this.FoamGsm,
          FabricGsm: this.FabricGsm,
          MixingRawMaterial: mixing.EstimationCodeMixingRawMaterialTable
        };
        return newMixing;
      });
      this.Coating = this.PreSkinGsm + this.SkinGsm + this.AdhesiveGsm;
      this.EOList.FabricsData = data.EstimationFabricProductDetail;
      this.EOList.FinishingData = data.EstimationFinishingTable;
      this.calculateGrandTotals();
      this.calculateGSMGrandTotal();
      this.isVisible = true;
    });
  }
  calculateGrandTotals() {
    this.TotalFabricGSM = this.EOList.FabricsData.reduce((total, fabric) => {
      return total + fabric.FabricGsm;
    }, 0);
    this.TotalFabricQuantity = this.EOList.FabricsData.reduce((total, fabric) => {
      return total + fabric.FabricEstimationQuantity;
    }, 0);
    this.TotalFabricPrice = this.EOList.FabricsData.reduce((total, fabric) => {
      return total + fabric.FabricProductCostPerLm;
    }, 0);
    this.GrandTotalFabricPrice = this.EOList.FabricsData.reduce((total, fabric) => {
      return total + (fabric.FabricEstimationQuantity * fabric.FabricProductCostPerLm);
    }, 0);
    this.EOList.FabricGsm = this.TotalFabricGSM;
    this.EOList.FabricProductCostPerLm = this.GrandTotalFabricPrice;
  }
  calculateGSMGrandTotal() {
    if (!isNaN(this.PreSkinGsm)) {
      this.TotalGsm += this.PreSkinGsm;
    }
    if (!isNaN(this.SkinGsm)) {
      this.TotalGsm += this.SkinGsm;
    }
    if (!isNaN(this.FoamGsm)) {
      this.TotalGsm += this.FoamGsm;
    }
    if (!isNaN(this.AdhesiveGsm)) {
      this.TotalGsm += this.AdhesiveGsm;
    }
    if (!isNaN(this.FabricGsm)) {
      this.TotalGsm += this.FabricGsm;
    }
  }
  calculateTotalProfitLoss() {
    return (parseFloat(this.EOList.TotalSalePrice) - this.TotalCostPerLm * this.EOList.OrderQuantity).toFixed(2)
  }
  calculateCostPerLmForItem(item: any): number {
    let totalCost = item.MixingRawMaterial.reduce((sum, current) => sum + (current.Price) == 0 ? 0 : current.Price * parseFloat(current.Quantity?.toFixed(2)), 0);
    let totalQuantity = item.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0);
    let GSM = item.MixingName === 'PRE SKIN' ? this.PreSkinGsm : (item.MixingName === 'SKIN' ? this.SkinGsm :
      (item.MixingName === 'ADHESIVE' ? this.AdhesiveGsm : 0));
    let clm = ((totalCost / totalQuantity / 1000) * this.PerLMConstant) * GSM;
    this.TotalQuantity = this.TotalQuantity + totalQuantity
    this.TotalPrice = this.TotalPrice + totalCost
    this.EOList.TotalCostPerLm = parseFloat((this.TotalCostPerLm + clm).toFixed(2));
    return isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
  }
  calculateTotalPriceForItem(item: any): String {
    let totalCost = item.MixingRawMaterial.reduce((sum, current) => sum + (current.Price) == 0 ? 0 : current.Price * parseFloat(current.Quantity?.toFixed(2)), 0);
    let totalQuantity = item.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0);
    return (totalCost / totalQuantity).toFixed(2);
  }
  calculateTotalEstimatedPrice() {
    return parseFloat(((isNaN(this.EOList.OrderQuantity) ? 0 : this.EOList.OrderQuantity) * (isNaN(this.EOList.EstimationPrice) ? 0 : this.EOList.EstimationPrice)).toString()).toFixed(3)
  }
}