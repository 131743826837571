import { OutpassStatus } from "./Enums";
import { UserModel } from "./UserModel";

export class OutPassModel {
  SerialNo: number = 0;
  OutpassTo: string = '';
  OutpassToCustomerId: number = 0;
  OutpassNumber: string = '';
  OutpassDate: any = '';
  ExpectedReturnDate!: string;
  OutpassType: string = '';
  Purpose: string = '';
  PurposeId: number = 0;
  Remark: string = '';
  OutpassId: number = 0;
  IsOutpassIn?: boolean;
  AddedBy: string = '';
  AddedDate?: string = '';
  Status: string = '';
  TransportId: number = 0;
  VehicleId: number = 0;
  TransportName: string = '';
  VehicleNumber: string = '';
  IsGateIn: boolean = false;
  ApprovedByName: string = '';
  OutpassItems: OutpassItemModel[] = [];
}




export class OutpassItemModel {
  OutpassItemId: number = 0;
  OutpassId?: number = 0;
  StockProductId?: number = 0;
  ProductName: string = '';
  RackName: string = '';
  StoreName: string = '';
  Quantity: number = 0;
  Amount: number = 0;
  Total: number = 0;
  RackId?: number = 0;
  Unit: string = '';
  ReturnedQuantity: number = 0;
  ReturnedStoreId: number = 0;
  ReturnedRackId: number = 0;
  ReturnCompletedBy: UserModel = null;
  ReturnCompletedDate: string = '';
  OutpassType: string = '';
  ReturnedRackName: string = '';
  ReturnedStoreName: string = '';
}

export class OutpassStatusActionModel {
  OutpassId: number = 0;
  Status: string = '';
  Remark: string = '';
}