import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { PurchaseOrderModel } from "src/PmsUIApp/Models/PurchaseOrderModel";
import { SaleOrderModel, SaleOrderProductionMixingRawMaterialModel } from "src/PmsUIApp/Models/SalesOrderModel";
import { environment } from "src/environments/environment";
import { JumboDispatchModel, JumboInspectionFilterModel, JumboInspectionModel, WorkPlanJumboMasterModel, WorkPlanMasterModel, WorkPlanOrderModel } from "../../../Models/WorkPlanModel";
import { FormulationCodeMixingModel } from "../../../Models/FormulationCodeModel";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate } from "@angular/common";
import { DatetimeConverter } from "../../../Services/DatetimeConverter.pipe";
import { AlertMessageService } from "../../../Services/AlertMessageService";
import { ActivatedRoute, Params } from "@angular/router";
import { LoadingService } from "../../../Services/loadingService";
import { Subject, Subscription } from "rxjs";
import { TimelineService } from "../../../Services/TimelineService";
import { ESalesOrderStatus } from "../../../Models/Enums";
import { IssueProductModel, SearchParamsProductIssueModel } from "../../../Models/IssueModel";
import { IssueSaleOrderProductsStockModel } from "../../../Models/StockProductModel";
import { ConsumedSaleOrderProductsStockModel } from "../../../Models/ConsumptionModel";

@Component({
    selector: 'app-SOTrailsReport',
    templateUrl: './SalesOrderTrailsReport.component.html'
})

export class SalesOrderTrailsReportComponent {
    ApiUrl = environment.Api_Url;
    IsPopupOpen = this.loader.loading$;
    IsDisabled: boolean = false;
    isTableLoading: boolean = false;
    isConsumptionTableLoading: boolean = false;
    NoConsumptionMsg: boolean = false;
    count: 0;
    permission = {
        View: false,
        Add: false,
        Delete: false,
        Manage: false
    }
    Poid: any;
    PurchaseOrder: PurchaseOrderModel | undefined;
    SaleOrderId: number;
    SaleOrderNumber: string;
    ActivityLogList: any;
    WorkPlanId: number;
    expanded: boolean = true;
    isPurchaseOrderLoaded = false;
    refreshBtnLoading: boolean;
    IsTimelineOpen = false;
    postProcessArray: any = [];
    Orderlist!: SaleOrderModel;
    WorkPlanModel: any;
    SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
    ProductionList: IssueProductModel[] = [];
    IssueListOriginal: IssueProductModel[] = [];
    StoreWiseStock: IssueSaleOrderProductsStockModel[] = [];
    OutofStockList: IssueSaleOrderProductsStockModel[] = [];
    SelectedOrder!: WorkPlanOrderModel | null;
    isVisible = false;
    FabricName: string;
    FabricColorName: string;
    SelectedFabricId: number = 0;
    TotalCostPerLm: number = 0;
    WorkPlanOrdersId: number = 0
    SelectedOrderId: number = 0;
    IsSalesOrderWorkplanLoaded = false;
    IsInspectionCompleted = false;
    ProductName: string;
    isUnitConversionNotDone = false;
    FabricGsm: number = 0;
    FabricQTY!: number;
    TotalJumboQty: number = 0;
    TotalWeight: number = 0;
    RemainingQty: number = 0;
    totalRollSize: number = 0;
    totalRollWeight: number = 0;
    totalItemsCount: number = 0;
    isQtyFull: boolean = false;
    jumboTableData: WorkPlanJumboMasterModel[] = [];
    SelectedMixing!: FormulationCodeMixingModel;
    NewWorkPlan = new WorkPlanJumboMasterModel;
    JumboInspection: JumboInspectionModel[] = [];
    jumboDataLoaded: Subject<boolean> = new Subject<boolean>();
    NewFilter: JumboInspectionFilterModel = new JumboInspectionFilterModel;
    NewDispatch: JumboDispatchModel = new JumboDispatchModel;
    SaleOrder: SaleOrderModel = new SaleOrderModel;
    request: SearchParamsProductIssueModel = new SearchParamsProductIssueModel();
    private route$: Subscription = new Subscription;
    RequestModel = {
        Id: 0,
        SaleOrderId: 0,
        RackId: 0,
        ReceivedQuantity: 0,
        Remark: '',
        CompletedQuantity: 0,
        WastageQuantity: 0,
        Unit: '0',
        Status: '',
    }
    JumboNumber = '';
    checkedStatus: { [key: string]: boolean } = {};
    ConsumedStockList: ConsumedSaleOrderProductsStockModel[];
    ConsumedtotalItemsCount: number;
    constructor(public http: HttpClient, private alertService: AlertMessageService, private route: ActivatedRoute, private loader: LoadingService, private timeLine: TimelineService, private datePipe: DatetimeConverter, private location: Location
    ) { }

    ngOnInit() {
        // console.log(this.PurchaseOrder)
        this.route$ = this.route.params.subscribe((params: Params) => {
            this.SaleOrderId = params["id"];
        })
        if (this.SaleOrderId > 0) {
            setTimeout(() => {
                this.showSOTrailsReport(this.SaleOrderId)
            }, 3000);
        }
    }
    goBack(): void {
        this.location.back();
    }
    onSalesFilterPanelOpen(data: any) {
        if (data == true) {
            this.showSOTrailsReport(this.SaleOrderId)
        }
    }
    onWorkplanFilterPanelOpen(data: any) {
        if (data == true) {
            this.ShowAllWorkPlanDetails(this.SaleOrderId)
        }
    }
    onInspectionFilterPanelOpen(data: any) {
        if (data == true) {
            this.GetSaleOrderDetails(this.SaleOrderId)
        }
    }
    onRawMaterialPanelOpen(data: any) {
        if (data == true) {
            this.GetAllProduction();
        }
    }
    onProductionPanelOpen(data: any) {
        if (data == true) {
            this.GetAllActivityLog(this.SaleOrderNumber);
        }
    }
    onJumboFilterPanelOpen(data: any) {
        if (data == true) {
            this.getJumboRollsById(this.SaleOrderId);
        }
    }
    onConsumptionFilterPanelOpen(data: any) {
        if (data == true) {
            this.GetAllSaleOrderConsumedProducts(this.SaleOrderId);
        }
    }
    onPostProcessFilterPanelOpen(data: any) {
        if (data == true) {
            this.getJumboRollsById(this.SaleOrderId)
        }
    }
    onGradingFilterPanelOpen(data: any) {
        if (data == true) {
            this.getJumboRollsById(this.SaleOrderId)
        }
    }
    onPackagingFilterPanelOpen(data: any) {
        if (data == true) {
            this.GetJumboInspectionList();
        }
    }
    GetTimeLineStatus(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);
            var last = ESalesOrderStatus[this.ActivityLogList[0]?.Status as keyof typeof ESalesOrderStatus];
            console.log("last", last, "st", st);
            if (stt != null) {
                if (st > last) {
                    return false;
                }
                else if (stt[0] === undefined) {
                    return false;
                }
                else if (stt.length > 0) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        } else {
            return false;
        }
    }
    WorkplanNo(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);

            if (stt != null) {
                if (stt[0] === undefined) {
                    return "";
                }
                else if (stt[0].WorkPlanNo != null)
                    return stt[0]?.WorkPlanNo;
                else
                    return 0;
            }
            else
                return "";
        } else { return ""; }
    }
    ProductionDate(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);

            if (stt != null) {
                if (stt[0] === undefined) {
                    return "";
                }
                else if (stt[0].WorkPlanNo != null)
                    return this.datePipe.transform(stt[0]?.AddedDate)
                else
                    return this.datePipe.transform(stt[0]?.AddedDate);
            }
            else
                return "";
        } else { return ""; }
    }
    AddedBy(st: number) {
        if (this.ActivityLogList) {
            var status = ESalesOrderStatus[st];
            var stt = this.ActivityLogList?.filter(x => x.Status == status);
            var last = ESalesOrderStatus[this.ActivityLogList[0]?.Status as keyof typeof ESalesOrderStatus];
            if (stt != null) {
                if (st > last) {
                    return "";
                }
                else
                    if (stt[0] === undefined) {
                        return "Skipped";
                    }
                    else
                        return stt[0]?.AddedBy;
            }
            else
                return "";
        } else { return ""; }
    }
    OpenTimelinePop(): void {
        this.timeLine.SaleOrderNo = this.SaleOrderNumber;
        this.timeLine.show();
    }
    ShowAllWorkPlanDetails(SalesOrderId: any) {
        let url = this.ApiUrl + "WorkPlan/getworkplanbysaleorderid/" + SalesOrderId;
        this.http.get<any>(url).subscribe(res => {
            this.WorkPlanModel = res;

        });
    }
    getJumboRollsById(id: any): void {
        const url = `${this.ApiUrl}workplan/getworkplanjumbobyworkplanordersid/${id}`;
        this.http.get<any>(url).subscribe(
            (res: any) => {
                this.jumboTableData = res.Data;
                this.NewWorkPlan.JumboInspection = [];
                this.NewWorkPlan.JumboInspection = res.Data.reduce((accumulator: any[], currentItem: any) => {
                    return accumulator.concat(currentItem.JumboInspection);
                }, []);
                this.RemainingQty = 0;
                this.calculateTotal();
                this.calculateJumboTotal();
                this.convertJumboDetailTimeZone();
            },
            (error: any) => {
                console.error("Error loading jumbo rolls:", error);
            }
        );
    }
    convertJumboDetailTimeZone() {
        this.jumboTableData.forEach(element => {
            element.JumboRollDate = formatDate(element.JumboRollDate.toString(), 'dd-MM-yyyy', "en-US");
            element.JumboRollStartTime = new Date(new DatetimeConverter().transform(element.JumboRollStartTime.toString()));
            element.JumboRollEndTime = new Date(new DatetimeConverter().transform(element.JumboRollEndTime.toString()));
        }
        );
    }
    calculateTotal() {
        this.TotalJumboQty = 0;
        this.TotalWeight = 0;
        this.jumboTableData.forEach((element) => {
            this.TotalJumboQty += parseFloat(element.JumboRolQty.toString());
            this.TotalWeight += parseFloat(element.Weight.toString());
        });
        var OrderQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.OrderQuantity || 0;
        if (this.TotalJumboQty >= OrderQty) {
            this.isQtyFull = true;
        } else {
            this.isQtyFull = false;
        }
    }
    showSOTrailsReport(SaleOrderId: number) {
        this.isTableLoading = true;
        let url =
            this.ApiUrl + 'saleorder/getsaleorderdataforviewbyid/' + SaleOrderId;
        this.postProcessArray = [];
        this.http.get<any>(url).subscribe((res) => {
            this.Orderlist = res;
            this.SaleOrderNumber = res.SaleOrderNumber;
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.Lacquer.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.Lacquer) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            this.postProcessArray = this.postProcessArray.length > 0 ? this.postProcessArray.sort((a, b) => a.Rank - b.Rank) : [];
            this.isVisible = true;
            this.calclulateTotalSalePrice();
            
            this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
            this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
            this.WorkPlanOrdersId = res.WorkPlanId
            this.calculateFabricQTY();
            this.GetAllActivityLog(this.SaleOrderNumber);

        });
    }
    GetSaleOrderDetails(SaleOrderId: number) {
        let url = this.ApiUrl + "saleorder/getsaleorderdatabyid/" + SaleOrderId;
        this.http.get<any>(url).subscribe(res => {
            this.Orderlist = res;
            if (this.Orderlist.Status.toString() != "WorkPlan") {
                this.IsInspectionCompleted = true
            }
            this.calclulateTotalSalePrice();
            this.Orderlist.SaleOrderProduction.RawGSMTotal = 0;
            this.Orderlist.SaleOrderProduction.LMConstant = 1.45;
this.Orderlist.SaleOrderProduction.SaleOrderProductionRawMaterial.forEach((element) => {

                this.Orderlist.SaleOrderProduction.RawGSMTotal += parseFloat(element.Quantity.toString()) * (isNaN(parseInt(element.AvgGsm?.toString())) ? 0 : parseInt(element.AvgGsm?.toString()))
            });
            this.Orderlist.SaleOrderProduction.MixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
            this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
                this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(element.Total) ? 0 : element.Total;
                this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(parseInt(element.WeightGsm?.toString())) ? 0 : parseInt(element.WeightGsm.toString());
            })

            this.Orderlist.SaleOrderProduction.FinalMixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingGSMTotal + this.Orderlist.SaleOrderProduction.RawGSMTotal;
            var totalprint = this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalprint);
            var totalEmbossing = this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalEmbossing);
            var totalVaccum = this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalVaccum);
            var totalTumbling = this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(totalTumbling);

            var totalElement = this.Orderlist.SaleOrderProduction?.SaleOrderProductionElement.reduce((sum, current) => sum + parseFloat(current.Total?.toString()), 0) ?? 0
            console.log(isNaN(totalElement) ?? 0);
            var totalRaw = this.Orderlist.SaleOrderProduction?.SaleOrderProductionRawMaterial.reduce((sum, current) => sum + parseFloat(current.TotalCost?.toString()), 0) ?? 0
            console.log(totalRaw);
            this.Orderlist.GrandTotal = parseFloat(this.Orderlist.SaleOrderProduction?.TotalCost.toString()) + parseFloat(this.Orderlist.SaleOrderProduction?.ColorPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.GrainPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.ThickPrice.toString()) +
                parseFloat(this.Orderlist.SaleOrderProduction?.WidthPrice.toString()) +
                parseFloat(isNaN(totalprint) == true ? "0" : totalprint.toString()) +
                parseFloat(isNaN(totalEmbossing) == true ? "0" : totalEmbossing.toString()) +
                parseFloat(isNaN(totalVaccum) == true ? "0" : totalVaccum.toString()) +
                parseFloat(isNaN(totalTumbling) == true ? "0" : totalTumbling.toString()) +
                parseFloat(isNaN(totalElement) == true ? "0" : totalElement.toString()) +
                parseFloat(totalRaw.toString());
            this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
            this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
            this.SelectedFabricId = this.Orderlist.SaleOrderProduction.FabricProductId > 0 ? this.Orderlist.SaleOrderProduction.FabricProductId : 0;
            this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(i => {
                this.checkedStatus[i.MixingName] = false;
            });
        });
    }
    GetAllProduction() {
        this.request.SaleOrderNumber = this.SaleOrderNumber;
        this.ProductionList = [];
        let url = this.ApiUrl + "issueproduct/GetIssueProductRequests";
        this.http.post<IssueProductModel[]>(url, this.request).subscribe(res => {
            console.log(`res`, res)
            this.IssueListOriginal = res;
            this.ProductionList = res.filter(x => x.ActionDate != null);
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllProduction();
            }
        });
    }
    GetAllActivityLog(SaleOrderNumber: string) {
        var request = {
            SaleOrderNumber: SaleOrderNumber,
            Status: null,
            DateFrom: null,
            DateTo: null,
            Addedby: null
        }
        let url = this.ApiUrl + "report/saleordertimelinereport";
        this.http.post<any>(url, request).subscribe(res => {
            console.log(res);
            this.ActivityLogList = res;
        }, res => {
            this.GetAllActivityLog(SaleOrderNumber);
        });
    }
    GetAllSaleOrderConsumedProducts(SaleOrderId: number) {
        this.loader.show();
        this.isConsumptionTableLoading = true;
        let url = this.ApiUrl + "consume/getallsaleorderconsumedproducts/" + SaleOrderId;
        this.http.get<ConsumedSaleOrderProductsStockModel[]>(url).subscribe({
            next: (res) => {
                this.ConsumedStockList = res;
                let consumedcount = this.ConsumedtotalItemsCount = 0;
                this.ConsumedStockList.forEach(x => {
                    consumedcount++;
                    x.SerialNo = consumedcount;
                });
                this.ConsumedtotalItemsCount = consumedcount;
                if (this.ConsumedtotalItemsCount < 1) {
                    this.NoConsumptionMsg = true;
                }
                this.isConsumptionTableLoading = false;
                this.loader.hide();
            },
            error: (res) => {
                this.loader.hide();
            }
        });
    }
    calculateFabricQTY() {
        if (this.Orderlist.SaleOrderProduction.FabricProductId != 0 && this.Orderlist.SaleOrderProduction.FabricProductId != null) {
            if (this.IsInspectionCompleted) {
                this.isUnitConversionNotDone = false;
                this.FabricGsm = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm;
                this.Orderlist.FormulationFabricProductUnit = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductUnit;
                this.Orderlist.FormulationFabricProducWidthInMeter = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter;
                this.Orderlist.FormulationFabricProductId = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductId;
                this.Orderlist.FormulationFabricProductName = this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductName;
            }
            if (this.Orderlist.FormulationFabricProductId == 0 || this.Orderlist.FormulationFabricProductId == null) {
                this.FabricQTY = 0
            }
            else if (this.Orderlist.FormulationFabricProductUnit == "Mtrs") {
                this.FabricQTY = parseFloat((this.Orderlist.SaleOrderProduction.OrderQuantity + (this.Orderlist.SaleOrderProduction.OrderQuantity * this.Orderlist.SaleOrderProduction.ExtraProduction) / 100).toFixed(2));
            }
            else {
                if (this.Orderlist.FormulationFabricProducWidthInMeter != null && this.Orderlist.FormulationFabricProducWidthInMeter > 0 && this.FabricGsm != null && this.FabricGsm > 0) {
                    this.FabricQTY = parseFloat((this.Orderlist.SaleOrderProduction.OrderQuantity + (this.Orderlist.SaleOrderProduction.OrderQuantity * this.FabricGsm) / 1000).toFixed(2));
                } else {
                    this.FabricQTY = parseFloat((this.Orderlist.SaleOrderProduction.OrderQuantity + (this.Orderlist.SaleOrderProduction.OrderQuantity * this.Orderlist.SaleOrderProduction.ExtraProduction) / 100).toFixed(2));
                }
                this.isUnitConversionNotDone = true;
            }
        }
    }
    getAutomatedApprovedCount(): number {
        return this.IssueListOriginal.filter(item => item.ActionDate === null).length;
    }
    GetBGColor(data: WorkPlanOrderModel): string {
        if (data.SaleOrder.SaleOrderId == this.WorkPlanOrdersId) {
            return '';
        }
        else {
            return '';
        }
    }
    drop(datalist: SaleOrderProductionMixingRawMaterialModel[], event: CdkDragDrop<string[]>): void {
        moveItemInArray(datalist, event.previousIndex, event.currentIndex);
    }
    openAddModel(data: any) {
        this.SelectedMixing = data;
    }
    calclulateTotalSalePrice() {
        this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
    }
    calculateTotalProfitLoss() {
        return (parseFloat(this.Orderlist.SaleOrderProduction.TotalSalePrice) - this.TotalCostPerLm * this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)
    }
    calculateJumboTotal() {
        this.RequestModel.CompletedQuantity = 0;
        this.jumboTableData.forEach((element) => {
            console.log(element)
            this.RequestModel.CompletedQuantity += parseFloat(element.Yield?.toString()) ?? 0;
            if (isNaN(this.RequestModel.CompletedQuantity)) {
                this.RequestModel.CompletedQuantity = 0;
            }
        });
        this.CalculateWastage();
    }
    CalculateWastage() {
        if (this.RequestModel.CompletedQuantity > this.RequestModel.ReceivedQuantity) {
            this.RequestModel.CompletedQuantity = 0;
            this.alertService.error('Completed QTY cannot be more than ' + this.RequestModel.ReceivedQuantity);
            return;
        }
        console.log(this.RequestModel)
        this.RequestModel.WastageQuantity = parseInt(this.RequestModel.ReceivedQuantity.toString()) - parseInt(this.RequestModel.CompletedQuantity.toString());
    }
    calculateReceivedTotal() {
        this.RequestModel.ReceivedQuantity = 0;
        this.jumboTableData.forEach((element) => {
            console.log(element)
            this.RequestModel.ReceivedQuantity += parseFloat(element.Yield?.toString()) ?? 0;
            if (isNaN(this.RequestModel.ReceivedQuantity)) {
                this.RequestModel.ReceivedQuantity = 0;
            }
        });
    }
    GetJumboInspectionList() {
        let count = this.totalItemsCount = 0;
        this.NewFilter.SaleOrderId = this.SaleOrderId;
        let url = this.ApiUrl + "saleorder/GetJumboInspectionListWithFilter";
        this.http.post<JumboInspectionModel[]>(url, this.NewFilter).subscribe(res => {
            this.JumboInspection = res;
            count = this.totalItemsCount;
            this.JumboInspection.forEach((x) => {
                count++;
                x.SerialNo = count;
                x.IsChecked = false
            });
            this.JumboInspection.sort((a, b) => b.JumboInspectionId + a.JumboInspectionId);
            if (this.JumboInspection.length <= 0) {
                return;
            }
        }, res => { this.loader.hide(); });
    }
    onUp(key: any, data: any) {
        let res = this.JumboInspection;
        data = data.sort((a: any, b: any) =>
            a[key] > b[key] ? 1 : -1
        );
    }
    onDown(key: any, data: any) {
        let res = this.JumboInspection;
        data = data.sort((a: any, b: any) =>
            a[key] < b[key] ? 1 : -1
        );
    }
}