import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";
import { PurchaseOrderModel, PurchaseOrderProductModel } from "../../Models/PurchaseOrderModel";
import { PoDrawerService } from "../../Services/PoDrawerService";
import { PoTimelineService } from "../../Services/PoTimeLineService";
import { LoadingService } from "../../Services/loadingService";

@Component({
  selector: 'app-PoDrawer',
  templateUrl: './PoDrawerView.component.html'
})

export class PoDrawerViewComponent {
  ApiUrl = environment.Api_Url;
  IsPopupOpen = this.loader.loading$;
  IsDisabled: boolean = false;
  isTableLoading: boolean = false;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false
  }
  Poid: any;
  PurchaseOrder: PurchaseOrderModel | undefined;
  isPurchaseOrderLoaded = false;
  refreshBtnLoading: boolean;
  IsTimelineOpen = false;
  constructor(
    private loader: PoDrawerService,
    public http: HttpClient,
    private timeLine: PoTimelineService,
    private loadsrv: LoadingService
  ) {
    this.loader.event_callback.subscribe(x => {
      this.showDrawerData(x);
    })
  }
  ngOnInit() {
    console.log(this.PurchaseOrder)
  }

  handleTimelineCancel(): void {
    this.isPurchaseOrderLoaded = false;
  }
  showDrawerData(Poid: string) {
    this.isTableLoading = true;
    this.loadsrv.show();
    this.Poid = Poid;
    let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/" + Poid;
    this.http.get<any>(url).subscribe(res => {
      this.PurchaseOrder = res;
      this.isPurchaseOrderLoaded = true; // Set the flag to true after PurchaseOrder is loaded
      this.isTableLoading = false;
      this.loadsrv.hide();
    }, res => {
      this.showDrawerData(Poid);
    });
  }
  OpenTimelinePop(data: any): void {
    this.IsTimelineOpen = false;
    this.timeLine.Poid = data.Poid;
    this.timeLine.show();

    // this.TimeLineObject = data;
    // this.GetAllActivityLog(data.Poid);
  }
  CalculatePendingQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY >= 0) {
      return RemainingQTY
    }
    return 0
  }
  CalculateExtraQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY < 0) {
      return '+' + (data.RecievedQuantity - data.Quantity).toFixed(2)
    }
    return 0;
  }
  CalculateGrandTotal(data: PurchaseOrderProductModel[], column: string) {
    var total = 0;
    switch (column) {
      case column = "OrderQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        break;
      }

      case column = "ReceivedQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.RecievedQuantity?.toString()), 0) ?? 0
        break;
      }

      case column = "PendingQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat((this.CalculatePendingQTY(current))?.toString()), 0) ?? 0
        break;
      }

      case column = "ExtraQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(this.CalculateExtraQTY(current)?.toString()), 0) ?? 0
        break;
      }
    }
    return parseFloat(total.toFixed(2));
  }
}
